/* eslint-disable prettier/prettier */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef } from 'react';

import { FontAwesome } from 'react-web-vector-icons';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateJob } from '~/store/modules/forms/FormJob/actions';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';
import { getStatus } from '~/system/convert';
import { DownloadFile, RenderStatusIconButton } from '~/system/helper';
import { USER_TYPE } from '~/system/enum';
import webservice from '~/services/api';
import BvsInput from '~/components/BvsInput';
import BvsAdmButton from '~/components/BvsAdmButton';
import SubHeader from '~/components/BvsSubHeader';
import BvsPanel from '~/components/BvsPanel';
import BvsForm from '~/components/BvsForm';
import BvsContainer from '~/components/BvsContainer';
import BvsButton from '~/components/BvsButton';
import useJobController from './hooks/useJobController';
import useForm from './hooks/useForm';
import useJobs from '~/hooks/useJobs';
import BvsToastResult from '~/components/BvsToastResult';
import BvsTableV4 from '../../components/BvsTableV4';
import moment from 'moment';

const Job = ({ match }) => {
  const dispatch = useDispatch();
  const { job, form } = useSelector((state) => state.formJob);
  const { enterpriseActive } = useSelector((state) => state.enterprises);
  const { onFieldChange, clean } = useForm();
  let { id } = match.params;
  const { jobs, fetch: getJobs } = useJobs({
    dropdown: false,
    loading: false,
  });
  const { onSave, changeStatus, deleteJob } = useJobController({
    jobId: id,
    onSaved: () => {
      getJobs();
      history.push('/jobs');
    },
  });
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const containerForm = useRef();
  const columns = [
    {
      key: 'status',
      label: 'Status',
      sort: true,
      content: (row) => getStatus(row.status),
    },
    {
      key: 'code',
      label: 'Código',
      sort: true,
    },
    {
      key: 'name',
      label: 'Nome do Cargo',
      sort: true,
      width: '100%',
    },
  ];

  // #region Methods
  const onEdit = async (jobId) => {
    try {
      dispatch(showLoading());
      const { data } = await webservice.get(`/jobs/${jobId}`);
      if (data.message || data.error) throw data;
      dispatch(
        updateJob({
          form,
          job: {
            ...job,
            code: data.code,
            name: data.name,
          },
        })
      );
      history.replace(`/jobs/${jobId}`);
      window.scrollTo({
        top: containerForm?.current.scrollTop,
        behavior: 'smooth',
      });
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message={
            err?.message || err?.error?.message || 'Falha ao localizar registro'
          }
        />
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const onCancel = () => {
    clean();
    history.replace(`/jobs`);
  };

  // #endregion
  useEffect(() => {
    getJobs();
    return () => {
      clean();
    };
  }, []);

  return (
    <>
      <SubHeader title="Cargos" subtitle="da empresa" />
      <BvsContainer>
        {user?.type === USER_TYPE.ADMIN && <BvsAdmButton />}
        <div className="col-md-12" ref={containerForm}>
          <BvsPanel title="Incluir">
            <BvsForm onSubmit={onSave} method="POST">
              <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsInput
                    name="code"
                    label="Código"
                    value={job.code}
                    onChange={(value) => onFieldChange('code', value)}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsInput
                    name="name"
                    label="Nome do Cargo"
                    value={job.name}
                    onChange={(value) => onFieldChange('name', value)}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-6">
                  <BvsButton
                    type="submit"
                    title="Salvar"
                    icon="fa fa-save"
                    customStyle={{ marginTop: '30px', float: 'right' }}
                    disabled={form.saving}
                  />
                  {id && (
                    <BvsButton
                      type="button"
                      title="Cancelar"
                      icon="fa fa-save"
                      onClick={() => onCancel()}
                      customStyle={{
                        marginTop: '30px',
                        marginRight: '30px',
                        float: 'right',
                      }}
                    />
                  )}
                </div>
              </div>
            </BvsForm>
          </BvsPanel>
        </div>

        <div className="col-md-12">
          <BvsPanel title="Relação de Cargos">
            <div className="col-md-12">
              <BvsTableV4
                columns={columns}
                rows={jobs.options.map((d) => ({ ...d._original }))}
                onRefresh={() => getJobs()}
                actions={[
                  (row) => (
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={<FontAwesome name="edit" color="#fff" size={12} />}
                      onClick={() => onEdit(row.id)}
                    />
                  ),
                  (row) => (
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={<FontAwesome name="close" color="#fff" size={12} />}
                      onClick={() => deleteJob(row, () => getJobs())}
                    />
                  ),
                  (row) => (
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={RenderStatusIconButton(row.status)}
                      onClick={() => changeStatus(row, () => getJobs())}
                    />
                  ),
                ]}
              />

              <div className="row justify-content-start">
                {/* <BvsButton
                  type="button"
                  title="Imprimir"
                  icon="fa fa-print"
                  disabled
                /> */}
                <BvsButton
                  type="button"
                  title="Excel"
                  icon="fa fa-file-excel-o"
                  className="mx-3"
                  onClick={async () => {
                    DownloadFile(
                      '/jobs/export/excel',
                      `cargo-${
                        enterpriseActive?.info?.social_name
                      }-${moment().format('DD.MM.YYYY HH:mm:ss')}.xlsx`
                    );
                  }}
                />
                <BvsButton
                  type="button"
                  title="Próxima Página"
                  icon=""
                  onClick={() => {
                    history.push('/workedDays');
                  }}
                />
              </div>
            </div>
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default Job;
