/* eslint-disable prettier/prettier */
/* eslint-disable import/order */

import { Input, InputContainer, InputWraper } from './style';

import { FontAwesome } from 'react-web-vector-icons';
import React, { useEffect, useRef } from 'react';

const BvsInputFile = ({ name, onChange, value=null}) => {
  const inputRef = useRef()
  const handleChange = e => {
    let f = e.target?.files[0];
    onChange && onChange(name, f);
  };
  useEffect(() => {
  
    if(inputRef?.current && !value){
      inputRef.current.value = null;
    }
  },[value])
  return (
    <InputContainer>
      <InputWraper>
        <FontAwesome name="upload" size={16} />
        <span className="name">
          {value ? `${value?.name}` : `Selecione  um arquivo`}
        </span>
      </InputWraper>
      <Input type="file" name={name} onChange={handleChange} ref={inputRef} />
    </InputContainer>
  );
};

export default BvsInputFile;
