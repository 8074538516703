import React from 'react';
import PropTypes from 'prop-types';
import BvsPanel from '~/components/BvsPanel';
import { TextValue, Subtitle } from './style';

function BvsIndicator({ title, subtitle, value, money }) {
  return (
    <BvsPanel
      title={title}
      customStyle={{ justifyContent: 'center', alignItems: 'center' }}
    >
      <TextValue>
        {new Intl.NumberFormat(
          'pt-BR',
          money
            ? {
                style: 'currency',
                currency: 'BRL',
              }
            : {
                style: 'decimal',
                maximumFractionDigits: 0,
              }
        ).format(value)}
      </TextValue>
      <Subtitle>{subtitle}</Subtitle>
    </BvsPanel>
  );
}
BvsIndicator.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  value: PropTypes.number,
  money: PropTypes.bool,
};
BvsIndicator.defaultProps = {
  title: '',
  subtitle: '',
  value: 0,
  money: false,
};
export default BvsIndicator;
