/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-param-reassign */

import React, { useEffect } from 'react';

import { FontAwesome } from 'react-web-vector-icons';
import { useHistory } from 'react-router-dom';
import { RenderStatusIconButton } from '~/system/helper';
import { getStatusCard } from '~/system/convert';
import BvsButton from '~/components/BvsButton';
import BvsContainer from '~/components/BvsContainer';
import { USER_TYPE } from '~/system/enum';
import BvsForm from '~/components/BvsForm';
import BvsPanel from '~/components/BvsPanel';
import SubHeader from '~/components/BvsSubHeader';
import BvsButtonGroup from '~/components/BvsButtonGroup';
import BvsAdmButton from '~/components/BvsAdmButton';
import BvsDropdown from '~/components/BvsDropdown';
import useController from './hooks/useController';
import useDepartments from '~/hooks/useDepartments';
import useWorkdays from '~/hooks/useWorkday';
import useShippingAddress from '~/hooks/useShippingAddress';
import { useSelector } from 'react-redux';
import useFilter from './hooks/useFilter';
import useStatusCard from '~/hooks/useStatusCard';
import BvsTableV4 from '../../components/BvsTableV4';

const AdministrativeCard = () => {
  const { user } = useSelector((state) => state.auth);
  const { query, filter } = useSelector(
    (state) => state.filterAdministrativeCard
  );
  const history = useHistory();
  const { onFieldChange } = useFilter();
  const {
    administrativeCards,
    changeStatus,
    fetch: getAdministrativeCard,
    onEdit,
    onDelete,
    applyFilter,
  } = useController();

  const { statusCard } = useStatusCard();

  const { departments, fetch: getDepartment } = useDepartments({
    loading: false,
  });
  const { workdays, fetch: getWorkday } = useWorkdays();
  const { shippingAddress, fetch: getShippingAddress } = useShippingAddress();
  const columns = [
    {
      key: 'status',
      label: 'Status',
      sort: true,
      content: (row) => getStatusCard(row.status),
    },
    {
      key: 'identification',
      label: 'Identificação',
      sort: true,
    },
    {
      key: 'name',
      label: 'Nome Impresso',
      sort: true,
      width: '100%',
    },
  ];

  useEffect(() => {
    getAdministrativeCard();
    getDepartment();
    getWorkday();
    getShippingAddress();
  }, []);

  return (
    <>
      <SubHeader
        title="Cartão Administrativo"
        subtitle="Visualize os cartões administrativos"
      />
      <BvsContainer>
        {user?.type === USER_TYPE.ADMIN && <BvsAdmButton />}
        <div className="col-md-12">
          <BvsPanel title="Filtros">
            <BvsForm onSubmit={applyFilter}>
              <div className="row">
                <div
                  className="col-12"
                  style={{
                    fontSize: '20px',
                    alignItens: 'center',
                    marginTop: '20px',
                    marginBottom: '20px',
                  }}
                >
                  Por grupo
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <BvsDropdown
                    title="Selecione um departamento..."
                    name="department_id"
                    label="Departamento"
                    value={query.department_id}
                    onSelectedItem={(value) =>
                      onFieldChange('department_id', value)
                    }
                    loading={departments.loading}
                    options={departments.options}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <BvsDropdown
                    title="Selecione um período..."
                    name="workday_id"
                    label="Período"
                    value={query.workday_id}
                    onSelectedItem={(value) =>
                      onFieldChange('workday_id', value)
                    }
                    loading={workdays.loading}
                    options={workdays.options}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <BvsDropdown
                    title="Selecione um endereço..."
                    name="shipping_address_id"
                    label="Endereços"
                    value={query.shipping_address_id}
                    onSelectedItem={(value) =>
                      onFieldChange('shipping_address_id', value)
                    }
                    loading={shippingAddress.loading}
                    options={shippingAddress.options}
                  />
                </div>
                <div
                  style={{ marginTop: '20px' }}
                  className="col-xs-12 col-sm-8 col-md-8 col-lg-8"
                >
                  <BvsButtonGroup
                    name="status"
                    label="Filtrados Por"
                    value={query.status}
                    onChange={onFieldChange}
                    options={statusCard.options}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <BvsButton
                    type="submit"
                    title="Filtrar"
                    icon="fa fa-filter"
                    customStyle={{ marginTop: 25, float: 'right' }}
                    disabled={filter.filtering}
                  />
                </div>
              </div>
            </BvsForm>
          </BvsPanel>
        </div>

        <div className="col-md-12">
          <BvsPanel title="Painel Administrativo">
            <div className="col-md-12">
              <BvsTableV4
                columns={columns}
                rows={administrativeCards}
                onRefresh={() => {
                  getAdministrativeCard();
                }}
                rowKey="id"
                actions={[
                  (row) => (
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={<FontAwesome name="edit" color="#fff" size={12} />}
                      onClick={() => onEdit(row.id)}
                    />
                  ),
                  (row) => (
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={<FontAwesome name="close" color="#fff" size={12} />}
                      onClick={() => onDelete(row.id)}
                    />
                  ),
                  (row) => (
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={RenderStatusIconButton(row.status)}
                      onClick={() => changeStatus(row.id)}
                    />
                  ),
                ]}
              />

              <div>
                {/* <BvsButton
                  type="button"
                  title="Inativos"
                  icon="fa fa-star-o"
                  customStyle={{ margin: '15px 5px' }}
                /> 
                <BvsButton
                  type="button"
                  title="Ativos"
                  icon="fa fa-star"
                  customStyle={{ margin: '15px 5px' }}
                />
                <BvsButton
                  type="button"
                  title="Cancelar"
                  icon="fa fa-close"
                  customStyle={{ margin: '15px 5px' }}
                /> */}
                <BvsButton
                  type="button"
                  title="Incluir Cartão"
                  icon="fa fa-plus"
                  customStyle={{ margin: '15px 5px' }}
                  onClick={() => history.replace(`/createAdministrativeCard`)}
                />
              </div>
            </div>
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default AdministrativeCard;
