import produce from 'immer';
import { useState } from 'react';
import api from '~/services/api';

const useController = () => {
  const [purchaseHistoric, setPurchaseHistoric] = useState({
    loading: true,
    data: null,
  });

  const getHistoric = async (employeeId) => {
    let historic = null;
    setPurchaseHistoric(
      produce(purchaseHistoric, (draft) => {
        draft.loading = true;
        return draft;
      })
    );
    try {
      const { data } = await api.get(`/purchaseHistoric/${employeeId}`);
      historic = data;
    } catch (err) {
      setPurchaseHistoric(
        produce(purchaseHistoric, (draft) => {
          draft.data = null;
          return draft;
        })
      );
    } finally {
      setPurchaseHistoric(
        produce(purchaseHistoric, (draft) => {
          draft.loading = false;
          draft.data = historic;
          return draft;
        })
      );
    }
  };
  return { purchaseHistoric, getHistoric };
};

export default useController;
