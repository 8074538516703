/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
import types from './types';

export function updateEmployeeFilter(payload) {
  return {
    type: types.UPDATE_EMPLOYEES,
    payload,
  };
}

export function resetEmployeeFilter() {
  return {
    type: types.RESET_EMPLOYEES,
  };
}
