import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { updateDepartment, resetDepartment } from './actions';
import types from './types';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';
import api from '~/services/api';

export function* addDepartment({ callback }) {
  const { department, form } = yield select((state) => state.formDepartment);
  try {
    yield put(
      updateDepartment({ form: { ...form, saving: true, disabled: true } })
    );
    const { data } = yield call(api.post, '/departments', {
      code: department.code,
      name: department.name,
    });
    yield put(
      updateDepartment({ form: { ...form, saving: false, disabled: false } })
    );
    if (data.error || data.message) {
      throw data;
    }

    yield put(resetDepartment());

    toast.success(
      <BvsToastResult message={`'${data.name}' salvo com sucesso!`} />
    );
    callback && callback(data);
  } catch (error) {
    toast.error(
      <BvsToastResult
        type="error"
        err={error}
        message="Falha ao salvar registro"
      />
    );
  } finally {
    yield put(
      updateDepartment({ form: { ...form, saving: false, disabled: false } })
    );
  }
}
export default all([takeLatest(types.ADD_DEPARTMENT, addDepartment)]);
