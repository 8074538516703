/* eslint-disable no-throw-literal */
/* eslint-disable no-empty */
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';
import api from '~/services/api';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';
import { updateOrder } from '~/store/modules/order/actions';
import moment from 'moment';
import { useState } from 'react';

const useCheckout = ({ orderId: pOrderId = null }) => {
  const { order } = useSelector((state) => state.order);
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [boletUrl, setBoletURL] = useState(null);
  const onSubmitOrder = async (ev) => {
    ev.preventDefault();
    dispatch(showLoading());
    try {
      const { data } = await api[pOrderId ? 'put' : 'post'](
        `/order/checkout${pOrderId ? `/${pOrderId}` : ''}`,
        {
          start_period_use: order.start_period_use,
          creditBag: order.creditBag,
          has_credit_bag: order.has_credit_bag,
          end_period_use: order.end_period_use,
          payday: order.payday,
          billing_address_email: order.billing_address_email,
          responsible_email: order.responsible_email,
          responsible_name: order.responsible_name,
          responsible_phone: order.responsible_phone,
          order_type: order.order_type,
          nfe_email: order.order_email_nfe,
          nfe_email_extra: order.order_email_nfe_extra,
          employees: order.employees,
        }
      );
      if (data.error || data.message) throw data.error;
      toast.success(
        <BvsToastResult
          message={`Pedido ${pOrderId ? 'salvo' : 'registrado'} com sucesso!`}
        />
      );
      history.replace(`/orders/checkout/${data.id}`);
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Falha ao gerar pedido"
        />
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const onPopulate = async () => {
    if (!pOrderId) return;
    dispatch(showLoading());
    try {
      const { data } = await api.get(`/order/${pOrderId}`);

      dispatch(
        updateOrder({
          ...order,
          email: data?.email,
          start_period_use: moment(data?.start_period_use).format('YYYY-MM-DD'),
          end_period_use: moment(data?.end_period_use).format('YYYY-MM-DD'),
          payday: moment(data?.payday).format('YYYY-MM-DD'),
          enterprise_id: data?.enterprise_id,
          billing_address_email: data?.billing_address_email,
          nfe_email: data?.nfe_email,
          responsible_name: data?.responsible_name,
          responsible_email: data?.responsible_email,
          responsible_phone: data?.responsible_phone,
          financial_status: data?.financial_status,
          others_cost: data?.others_cost,
          discount_cost: data?.discount,
          total_payable: data?.total,
          status: data?.status,
          order_type: data?.order_type,
          order_email_nfe: data?.order_email_nfe,
          order_email_nfe_extra: data?.order_email_nfe_extra,
          delivery_date: data?.delivery_date,
          totalBenefits: Number(data?.total_benefits) ?? 0,
          totalTransfer: Number(data?.total_transfer) ?? 0,
          taxManagerGeneral: Number(data?.total_manager_general) ?? 0,
          total: Number(data?.total - data?.credit_bag) ?? 0,
          creditBag: Number(data?.credit_bag) ?? 0,
          has_credit_bag: Boolean(data?.has_credit_bag),
          employees: [],
          fees: data?.fees?.map((i) => ({
            id: i.enterprise_tax_id,
            total: Number(i.total),
          })),
        })
      );
      dispatch(hideLoading());
    } catch (err) {
      dispatch(hideLoading());
      toast.error(
        <BvsToastResult
          err={err}
          type="error"
          message="Não foi possivel visualizar esse pedido."
        />
      );
      history.goBack();
    }
  };

  const emitBolet = async () => {
    dispatch(showLoading());
    try {
      if (!order.payday)
        throw {
          message:
            'Este pedido não possui data de pagamento para gerar boleto!',
        };

      const { data } = await api.get(`order/bolet/${pOrderId}/emit`);
      if (data.error || data.message) throw data;
      setBoletURL(data);
      setModalVisible(true);
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          message="Falha ao gerar boleto"
          err={err}
        />
      );
    } finally {
      dispatch(hideLoading());
    }
  };
  const onCloseModal = () => {
    setModalVisible(false);
  };
  return {
    onSubmitOrder,
    onPopulate,
    emitBolet,
    modalVisible,
    onCloseModal,
    boletUrl,
  };
};

export default useCheckout;
