/* eslint-disable react/no-array-index-key */
/* eslint-disable import/prefer-default-export */
import { createNewSortInstance } from 'fast-sort';

const naturalSort = createNewSortInstance({
  comparer: new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  }).compare,
});

export const BvsTableRow = ({
  columns = [],
  rows = [],
  actions = [],
  onSelect,
  actionOptions,
  selectedItems,
  onClickRow,
  sort,
  rowKey = (i) => i.id,
  pagination,
}) => {
  let applyModifiedRows = rows || [];
  const maxOnPage = pagination.currentPage * pagination.itemsPerPage;
  const hasSorting = Object.values(sort).some((i) => i !== null);
  const sortColumn = Object.keys(sort).find((key) =>
    sort[key] !== null ? key : null
  );
  const isAsc = sort[sortColumn];
  if (hasSorting) {
    applyModifiedRows = naturalSort(applyModifiedRows)[isAsc ? 'asc' : 'desc'](
      (u) => u[sortColumn]
    );
  }

  // Aplica pagination
  applyModifiedRows = applyModifiedRows.slice(
    (pagination.currentPage - 1) * pagination.itemsPerPage,
    maxOnPage
  );

  return applyModifiedRows.map((r, ri) => (
    <tr
      key={rowKey(r)}
      onClick={() => onClickRow && onClickRow(r, ri)}
      style={{ cursor: r.onClickRow ? 'pointer' : 'default' }}
      className={`${r.isActive ? 'row-selected' : ''} justify-content-end`}
    >
      {onSelect && (
        <td>
          <input
            type="checkbox"
            style={{ width: '25px', height: '15px', cursor: 'pointer' }}
            onChange={() => {
              onSelect(r);
            }}
            checked={selectedItems.map(rowKey).includes(rowKey(r))}
          />
        </td>
      )}
      {columns?.map((col, colIndex) => (
        <td key={`${col.key}_${(pagination.currentPage - 1) * 10 + (ri + 1)}`}>
          {col?.content ? col?.content(r, ri, col, colIndex) : r[col.key]}
        </td>
      ))}

      {actions?.length > 0 && (
        <td
          className="column-fixed-right"
          style={{
            width: actionOptions?.width || '220px',
            minWidth: actionOptions?.width || '220px',
          }}
        >
          <div>{actions.map((a) => a(r))}</div>
        </td>
      )}
    </tr>
  ));
};
