/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AntDesign } from 'react-web-vector-icons';
import { toMoney } from '~/system/helper';
import data from '~/data/reportByBenefit.data';
import BvsContainer from '~/components/BvsContainer';
import BvsPanel from '~/components/BvsPanel';
import SubHeader from '~/components/BvsSubHeader';
import BvsButton from '~/components/BvsButton';
import FooterReports from '~/pages/Reports/FooterReports';
import HeaderReports from '~/pages/Reports/HeaderReports';
import * as UI from './style';
import useController from './hooks/useController';

const ReportsByBenefits = ({ match }) => {
  const history = useHistory();
  const { reportData, getReportData } = useController();
  const { id } = match.params;

  const getTotalbenefits = () => {
    return reportData?.data?.benefitOperators
      .map((i) => i.benefits.length)
      .reduce((cur, old) => cur + old, 0);
  };

  const getQuantityBenefit = () => {
    return reportData?.data?.benefitOperators
      .map((items) =>
        items.benefits
          .map((item) => item.quantity)
          .reduce((cur, old) => cur + old, 0)
      )
      .reduce((cur, old) => cur + old);
  };

  const getValorTotalByBenefit = (valor1, valor2) => {
    return valor1 * valor2;
  };

  const resumeByGroup = (benefits) => {
    const calc = (transfer = false) =>
      benefits
        .map((b) => (transfer ? b.transferAmount : b.unitCost * b.quantity))
        .reduce((c, o) => c + o, 0);
    return { subTotalBenefit: calc(), subTotalBenefitTransfer: calc(true) };
  };

  const resume = () => {
    // Calculo para pegar tudo
    /** INICIO */
    let subTotal = reportData?.data?.benefitOperators
      .map((operator) => operator.benefits.map((b) => b.quantity * b.unitCost))
      .flat(1)
      .reduce((c, o) => c + o, 0);

    let subTotalRepase = reportData?.data?.benefitOperators
      .map((operator) => operator.benefits.map((i) => i.transferAmount))
      .flat(1)
      .reduce((c, o) => c + o, 0);

    /** FIM */

    return { subTotal, subTotalRepase, total: subTotal + subTotalRepase };
  };

  useEffect(() => {
    getReportData(id);
  }, []);

  return (
    <>
      <SubHeader
        title="Relatório de Aquisição de Benefícios"
        subtitle="Por Benefícios"
      />
      <BvsContainer>
        <div className="col-md-12">
          <BvsPanel title="Relatório de Aquisição de Benefícios">
            <BvsButton
              type="button"
              title="Voltar"
              onClick={() => {
                history.goBack();
              }}
            />
            <HeaderReports />
            {reportData.loading && (
              <div className="p-3 row justify-content-center align-items-center">
                <div className="col-12 text-center">
                  <h4>Carregando...</h4>
                </div>
                <div className="col-4">
                  <div className="progress">
                    <div
                      className="progress-bar bg-warning progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
              </div>
            )}
            {!reportData.loading && !reportData.data && (
              <div
                className="row middle-xs center-xs"
                style={{ padding: 60, textAlign: 'center' }}
              >
                <div className="col-md-12">
                  <h2>Não foi possivel encontrar registros</h2>
                  <h2 style={{ marginTop: '10px' }}>
                    <AntDesign name="frowno" />
                  </h2>
                </div>
              </div>
            )}
            {reportData?.data && (
              <>
                <BvsContainer
                  style={{
                    display: 'flex',
                    margin: '40px 20px 5px',
                    borderTop: '1.4px solid rgba(0, 0, 0, 0.1)',
                    borderBottom: '1.4px solid rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <UI.Titulo> Data de Emissão </UI.Titulo>
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'flex',
                    margin: '5px 20px',
                    borderBottom: '1.4px solid rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <UI.Data>{reportData?.data?.dateEmission}</UI.Data>
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'block',
                    marginLeft: '40px',
                    marginRight: '40px',
                    marginBottom: '40px',
                    padding: '0px',
                  }}
                >
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Razão Social</th>
                        <th scope="col" />
                        <th scope="col">Código</th>
                        <th scope="col">CNPJ</th>
                        <th scope="col">Inscrição Estadual</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row" colSpan="2">
                          {reportData?.data?.socialName}
                        </td>
                        <td>{reportData?.data?.id}</td>
                        <td>{reportData?.data?.cnpj}</td>
                        <td>{reportData?.data?.stateRegistration}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Endereço</th>
                        <th scope="col">CEP</th>
                        <th scope="col">Bairro</th>
                        <th scope="col">Municipio</th>
                        <th scope="col">Estado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row">{reportData?.data?.street}</td>
                        <td>{reportData?.data?.zipcode}</td>
                        <td>{reportData?.data?.district}</td>
                        <td>{reportData?.data?.city}</td>
                        <td>{reportData?.data?.state}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Contato</th>
                        <th scope="col" />
                        <th scope="col">Telefone</th>
                        <th scope="col">Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row" colSpan="2">
                          {reportData?.data?.ownerName}
                        </td>
                        <td>{reportData?.data?.ownerPhone}</td>
                        <td>{reportData?.data?.ownerEmail}</td>
                      </tr>
                    </tbody>
                  </table>
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'block',
                    marginLeft: '40px',
                    marginRight: '40px',
                    padding: '0px',
                    borderTop: '1.4px solid rgba(0, 0, 0, 0.1)',
                    borderBottom: '1.4px solid rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Mês Referência</th>
                        <th scope="col">
                          {reportData?.data?.reference ?? 'Não informado'}
                        </th>
                        <th scope="col">N.° de Pedido</th>
                        <th scope="col">{reportData?.data?.orderId}</th>
                      </tr>
                    </thead>
                  </table>
                </BvsContainer>
                {reportData?.data?.benefitOperators?.map((items) => {
                  return (
                    <>
                      <BvsContainer
                        style={{
                          display: 'box',
                          margin: '10px 40px',
                          padding: '0px',
                          marginBottom: '50px',
                        }}
                      >
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Código</th>
                              <th scope="col">Discriminação dos Benefícios</th>
                              <th scope="col">Valor Unitário</th>
                              <th scope="col">Qtde.</th>
                              <th scope="col">Valor Total</th>
                              <th scope="col">Valor Repasse</th>
                            </tr>
                          </thead>
                          <tbody>
                            {items?.benefits.map((item) => (
                              <tr>
                                <td scope="row">{item?.benefitCode}</td>
                                <td>{item?.subDescription}</td>
                                <td>{toMoney(item?.unitCost)}</td>
                                <td>{item?.quantity}</td>
                                <td>
                                  {toMoney(
                                    getValorTotalByBenefit(
                                      item?.quantity,
                                      item?.unitCost
                                    )
                                  )}
                                </td>
                                <td>{toMoney(item?.transferAmount)}</td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td
                                colSpan="4"
                                style={{
                                  textAlign: 'right',
                                  paddingRight: '15px',
                                }}
                              >
                                {items?.benefitOperatorName}
                              </td>
                              <td colSpan="1">
                                {toMoney(
                                  resumeByGroup(items?.benefits).subTotalBenefit
                                )}
                              </td>
                              <td>
                                {toMoney(
                                  resumeByGroup(items?.benefits)
                                    .subTotalBenefitTransfer
                                )}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </BvsContainer>
                    </>
                  );
                })}
                <BvsContainer
                  style={{
                    display: 'flex',
                    margin: '5px 20px',
                    padding: '10px',
                    borderTop: '2px solid rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <UI.TituloResumo>Resumo Relatório</UI.TituloResumo>
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'flex',
                    margin: '5px 20px',
                    padding: '0px',
                    borderTop: '2px solid rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <table className="table table-striped">
                    <thead
                      style={{
                        fontWeight: 'bold',
                        fontSize: '0.9rem',
                        textAlign: 'right',
                      }}
                    >
                      <tr>
                        <th
                          scope="col"
                          style={{
                            textAlign: 'right',
                          }}
                        >
                          Qtde de Benéficios
                        </th>
                        <th scope="col">{getTotalbenefits()}</th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            textAlign: 'right',
                          }}
                        >
                          Qtde Geral de Benéficios
                        </th>
                        <th>{getQuantityBenefit()}</th>
                      </tr>
                    </thead>
                  </table>
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'flex',
                    margin: '5px 20px',
                    padding: '0px',
                    borderTop: '2px solid rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{
                            textAlign: 'right',
                          }}
                        >
                          Discriminação dos Vales e Beneficios
                        </th>
                        <th
                          scope="col"
                          style={{
                            textAlign: 'right',
                          }}
                        >
                          Total Por Beneficio
                        </th>
                        <th
                          scope="col"
                          style={{
                            textAlign: 'right',
                          }}
                        >
                          Repasse
                        </th>
                      </tr>
                    </thead>
                    {reportData?.data?.benefitOperators.map((items) => {
                      return (
                        <tbody key={items?.benefitOperatorId}>
                          <tr>
                            <td
                              scope="row"
                              style={{
                                textAlign: 'right',
                              }}
                            >
                              {items?.benefitOperatorName}
                            </td>
                            <td
                              style={{
                                textAlign: 'right',
                              }}
                            >
                              {toMoney(
                                resumeByGroup(items?.benefits).subTotalBenefit
                              )}
                            </td>
                            <td
                              style={{
                                textAlign: 'right',
                              }}
                            >
                              {toMoney(
                                resumeByGroup(items?.benefits)
                                  .subTotalBenefitTransfer
                              )}
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                    <tfoot>
                      <tr>
                        <td
                          style={{
                            textAlign: 'right',
                            fontWeight: 'bold',
                          }}
                        >
                          Sub-total
                        </td>
                        <td
                          style={{
                            textAlign: 'right',
                            fontWeight: 'bold',
                          }}
                        >
                          {toMoney(resume().subTotal)}
                        </td>
                        <td
                          style={{
                            textAlign: 'right',
                            fontWeight: 'bold',
                          }}
                        >
                          {toMoney(resume().subTotalRepase)}
                        </td>
                      </tr>
                      <tr>
                        <td />
                        <td
                          style={{
                            textAlign: 'right',
                            fontWeight: 'bold',
                          }}
                        >
                          TOTAL
                        </td>
                        <td
                          style={{
                            textAlign: 'right',
                            fontWeight: 'bold',
                          }}
                        >
                          {toMoney(resume().total)}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </BvsContainer>
              </>
            )}
            <FooterReports periodUse={reportData?.data?.periodUse} />
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default ReportsByBenefits;
