/* eslint-disable no-continue */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';
import api from '~/services/api';
import BvsToastResult from '~/components/BvsToastResult';
import { useHistory } from 'react-router';

const useController = () => {
  const [administrativeCards, setAdministrativeCard] = useState([]);
  const { query } = useSelector((state) => state.filterAdministrativeCard);
  const dispatch = useDispatch();
  const history = useHistory();

  const fetch = async () => {
    dispatch(showLoading());
    let options = [];
    try {
      const { data } = await api.get(`/administrativeCard`);
      if (data.error || data.message) throw data;
      options = data;
      /**
       * Filtro
       */
      // Se algum filtro tiver preenchido
      if (Object.values(query).some((i) => i !== null && i !== '')) {
        options = options.filter((item) => {
          let isValid = [];
          for (let key in query) {
            if ([null, '', undefined].includes(query[key])) continue;
            if (key === 'department_id')
              isValid.push(String(item?.department?.id) === String(query[key]));
            if (key === 'workday_id')
              isValid.push(String(item?.workday?.id) === String(query[key]));
            if (key === 'shipping_address_id')
              isValid.push(
                String(item?.shippingAddress?.id) === String(query[key])
              );
            if (key === 'status')
              isValid.push(String(item.status) === String(query[key]));
          }
          return isValid.every((i) => i);
        });
      }
      setAdministrativeCard(options);
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Não foi possivel consultar os registros"
        />
      );
      setAdministrativeCard(options);
    } finally {
      dispatch(hideLoading());
    }
  };

  const changeStatus = async (administrativeCardId) => {
    dispatch(showLoading());
    try {
      const { data } = await api.put(
        `/administrativeCard/${administrativeCardId}/status`
      );
      if (data.message || data.error) throw data;
      toast.success(
        <BvsToastResult
          message={`'${data.identification.substr(
            0,
            20
          )}' status alterado com sucesso!`}
        />
      );
      fetch();
    } catch (err) {
      toast.error(
        <BvsToastResult
          err={err}
          type="error"
          message="Não foi possivel atualizar o status."
        />
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const onDelete = async (administrativeCardId) => {
    dispatch(showLoading());
    try {
      const { data } = await api.delete(
        `/administrativeCard/${administrativeCardId}`
      );
      if (data.error || data.message) throw data;
      toast.success(<BvsToastResult message="Registro excluido com sucesso" />);
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Falha ao excluir registro"
        />
      );
    } finally {
      fetch();
      dispatch(hideLoading());
    }
  };

  const onEdit = async (administrativeCardId) => {
    history.push(`/createAdministrativeCard/${administrativeCardId}`);
  };

  const applyFilter = (e) => {
    e.preventDefault();
    fetch();
  };

  return {
    onEdit,
    onDelete,
    changeStatus,
    fetch,
    administrativeCards,
    applyFilter,
  };
};
export default useController;
