import { useState } from 'react';
import { useDispatch } from 'react-redux';
import api from '~/services/api';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';

const useUsers = ({ loading = false }) => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState({
    options: [],
    loading: false,
  });

  const fetch = async () => {
    if (loading) dispatch(showLoading());
    setUsers({ ...users, loading: true });
    let options = [];
    try {
      const { data } = await api.get('/users');
      if (data.message || data.error) throw data;
      options = data.map((d) => ({ title: d.name, value: d.id, _original: d }));
      options.unshift({ title: 'Nenhum', value: null });
      setUsers({ ...users, options });
    } catch (error) {
      setUsers({ ...users, options });
    } finally {
      setUsers({ ...users, options, loading: false });
      if (loading) dispatch(hideLoading());
    }
  };

  return { users, fetch };
};

export default useUsers;
