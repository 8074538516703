import React from 'react';

const Termo = () => {
  return (
    <div>
      <h3>Contrato de Prestação de Serviços</h3>
      <p>
        A BENV’S GESTÃO DE SERVIÇOS LTDA, inscrita no CNPJ/MF sob o nº
        07.703.407001-24, com sede na Avenida Washington Luís, 3369 – Chácara
        Flora – São Paulo – SP, doravante denominada BENV’S e a EMPRESA,
        devidamente qualificada no documento denominado “Proposta” e previamente
        cadastrada neste Sistema de Gestão de Benefícios, que fazem partes
        integrantes e complementares deste CONTRATO, doravante denominada de
        “EMPRESA CLIENTE”; BENV’S e a EMPRESA CLIENTE também denominados,
        individualmente, como “Parte” e, em conjunto, como “Partes”;
      </p>
      <p>
        Resolvem as Partes celebrar o presente Contrato de Prestação de Serviços
        de Gestão de Benefícios como Vales-transportes, Alimentação, Refeição e
        Combustível, que se regerá pelas seguintes cláusulas e condições:
      </p>
      <p>
        1 – OBJETO Prestação de Serviços: Gestão e Aquisição dos Benefícios
        Vales-Transportes, Refeição, Alimentação e Combustível.
      </p>
      <p>
        1.1. O objetivo do presente contrato é a prestação de serviços de
        aquisição e administração dos benefícios nas formas de vales, bilhetes
        ou cartões; incluindo, quando cabível a autorização e recarga de
        valores, nos moldes estabelecidos pelos fornecedores ou emissores dos
        respectivos benefícios, à serem utilizados pelos empregados ou
        colaboradores da Empresa Cliente.
      </p>
      <p>
        1.2. O presente contrato regulamenta os serviços prestados pela BENV’S,
        não implicando em alterações nas características gerais dos serviços,
        devido as regras de cada Operadora, já utilizados e conhecidos pela
        Empresa Cliente.
      </p>
      <p>
        1.3. Os serviços descritos neste contrato têm abrangência em todo
        território NACIONAL, podendo ser atendido em todo e qualquer local de
        necessidade da Empresa Cliente, seja em suas filiais ou nos locais onde
        a mesma presta ou prestará serviços, desde que o local ou região
        solicitada para prestação de serviços da Benv’s Gestão de Serviços Ltda,
        tenham as condições e características já conhecidas pela Empresa
        Cliente, de acordo com as regras das Operadoras e leis Municipais,
        Estaduais e Federais.
      </p>
      <p>2 - OBRIGAÇÕES DA BENV’S</p>
      <p>A BENV’S, obriga-se a:</p>
      <p>
        a) Disponibilizar e manter uma equipe de suporte para auxiliar e/ou
        realizar, gerenciar e acompanhar os pedidos, emitir relatórios e boletos
        bancários para pagamento, manter uma equipe de suporte para atendimento
        tanto para os interlocutores quanto para os colaboradores: Os usuários
        dos benefícios sempre que necessário. Tendo como prazo de retorno dos
        e-mails em no máximo 24 horas*. *Salvo quando informações para o retorno
        depender exclusivamente das Operadoras. Onde neste caso, devemos
        respeitar os prazos individuais de cada uma e informar ao cliente estes
        prazos.
      </p>
      <p>
        b) Atender aos pedidos realizados e pagos antecipadamente pela Empresa
        Cliente e providenciar sua aquisição perante os fornecedores ou
        emissores, de acordo com os procedimentos determinados por cada
        Fornecedor/Operadora.
      </p>
      <p>
        c) Organizar os pedidos individualmente e entregá-los nos locais
        determinados pela Empresa Cliente, durante o horário comercial, de
        acordo com os prazos acordados. Quando o usuário receber apenas créditos
        para recarga em cartão eletrônico, disponibilizar os créditos na data
        acordada.
      </p>
      <p>
        d) Orientar a Empresa Cliente quanto à recarga e utilização dos
        benefícios, na forma estabelecida pelo respectivo fornecedor ou emissor
        (Operadora), bem como quanto a prazos de validade, normas de utilização,
        processos de recarga, cancelamento e outros.
      </p>
      <p>
        e) Preservar a Empresa Cliente adotando uma política de privacidade, não
        cedendo, divulgando, ou comercializando nomes, dados ou quaisquer outras
        informações, sejam para própria publicidade, referência ou quaisquer
        outros motivos, salvo autorização da Empresa Cliente.
      </p>
      <p>
        f) Executar uma prestação de serviço com extrema excelência e qualidade,
        de acordo com o que foi apresentado e oferecido a Empresa Cliente no
        momento da respectiva apresentação e assinatura do contrato e seus
        anexos.
      </p>
      <p>3 - OBRIGAÇÕES DA EMPRESA CLIENTE</p>
      <p>Empresa Cliente obriga-se a:</p>
      <p>
        a) Apresentar os pedidos dos benefícios nas formas estabelecidas pela
        BENV’S, através da internet ou modelo definido pela Benv’s e Empresa
        Cliente, responsabilizando-se pelo sigilo das senhas de segurança e/ou
        dados, correta utilização, conteúdo e informações inseridos em cada
        pedido.
      </p>
      <p>
        b) Pagar à BENV’S, através de boleto bancário emitido no fechamento dos
        pedidos, o valor dos benefícios e das taxas de serviço e entregas
        incidentes, calculadas e informadas no ato da realização de cada pedido.
        Correrão também por conta da Empresa Cliente quaisquer taxas ou
        acréscimos que sejam determinados pelos fornecedores ou emissores e que
        a eles devam ser repassados para aquisição dos benefícios, tais como
        custos de emissão ou reemissão de vales, bilhetes ou cartões, taxas de
        carregamento de créditos, taxas de processamento e outras, além das
        taxas bancarias em caso de movimentações e transferências financeiras.
      </p>
      <p>
        c) Conferir integralmente o pedido, tanto em relatórios para aprovação
        quanto no momento da entrega, assinando o respectivo recibo e documento
        de transporte, informando à BENV’S, no prazo máximo de 72 (Setenta e
        Duas) horas, eventual divergência entre as quantidades solicitadas e
        recebidas, a fim de que sejam tomadas as medidas cabíveis. Fica
        estipulado que a BENV’S somente se responsabilizará por eventuais
        diferenças realmente existentes, caso tenha sido realizada a conferência
        na presença da pessoa responsável pela realização da entrega dos
        cartões/vales, sendo que em qualquer outra hipótese e decorrido o prazo
        acima determinado, entender-se-á que a quantidade recebida corresponde
        ao que foi pedido e prescreverá qualquer direito de reclamação da
        Empresa Cliente;
      </p>
      <p>
        d) Orientar o responsável para que verifique as condições das
        embalagens, recusando o seu recebimento se apresentarem sinais de
        violação;
      </p>
      <p>
        e) Orientar seus empregados e colaboradores sobre as normas de
        utilização dos benefícios, bem como os procedimentos determinados pelos
        fornecedores e/ou emissores, tais como prazos de validade, normas de
        utilização, processos de recarga, cancelamento, dentre outros;
      </p>
      <p>
        f) Colocar à disposição da BENV’S, quando necessário e solicitado, todas
        as informações ou documentos eventualmente exigidos pelo fornecedor ou
        emissor do benefício;
      </p>
      <p>
        g) Informar a BENV’S com antecedência mínima de 10 (Dez) dias úteis da
        data a ser realizada o pedido, eventuais férias, afastamentos, folgas,
        demissões e em especial as admissões ou qualquer outra informação que
        possa haver divergências no pedido com a realidade, pedido este quando
        for necessário o envio do arquivo de pedidos pela Benv’s. Caso este
        prazo não seja respeitado, as alterações serão realizadas no mês
        subsequente. Caso o arquivo seja gerado pela Empresa Cliente as
        informações de eventuais férias, afastamentos, folgas e demissões,
        poderão ser informados no momento do envio do arquivo, ficando as
        ADMISSÕES no mesmo prazo informado acima, para que a Benv’s providencie
        e entregue os cartões aos colaboradores que ainda não os possuem;
      </p>
      <p>
        h) Quando o pedido for gerado pelo Gestor de Contas Benv’s, a Empresa
        Cliente deverá enviar todas as solicitações como alterações de
        benefícios, dias trabalhados, demissões, admissões de uma única vez,
        seja ela por e-mail ou arquivo e após o Gestor de Contas gerar o pedido
        e o mesmo ser aprovado pela Empresa Cliente, qualquer alteração no
        mesmo, deverá ser realizado pela Empresa Cliente através do seu Acesso a
        sua Conta com Login e Senha. Essas alterações realizadas devem ser
        informadas ao seu Gestor de Contas Benv’s, para que imediatamente possam
        tomar as ações cabíveis para conclusão das alterações realizadas pela
        Empresa Cliente.
      </p>
      <p>4 - CONDIÇÕES DE CONTRATAÇÃO</p>
      <p>
        4.1. Os pedidos serão efetuados pela Empresa Cliente via Internet
        através do e-mail disponibilizado pela Benv’s ou pelo Sistema de Gestão
        de Benefícios “www.benvs.com.br” a partir de dados cadastrados on-line
        ou importados através de arquivos em formatos padronizados,
        disponibilizado a qualquer momento pela BENV’S.
      </p>
      <p>
        4.2. A Empresa Cliente deverá realizar o pagamento com antecedência da
        data prevista para a entrega (5 dias úteis antes da data que necessita
        receber os Benefícios), conforme o prazo adotado para cada região do
        País e operadoras, informado no fechamento do pedido. O pagamento deverá
        ser feito através de boleto bancário emitido no fechamento do respectivo
        pedido, sendo vedado o pagamento através de depósito, TED ou DOC ou por
        outras modalidades que impeçam sua identificação.
      </p>
      <p>
        4.2.1. Não serão aceitas solicitações de cancelamento ou alteração das
        características do pedido após o seu pagamento, podendo ser alterados
        apenas os valores a serem entregues, caso os mesmos ainda não tenham
        sido pagos as Operadoras e ficará a diferença na conta da Empresa
        Cliente, dentro da BENV’S e tal valor será incorporado ao pedido
        posterior, ou utilizado conforme solicitação da Empresa Cliente.
      </p>
      <p>
        4.2.2. A realização do pagamento em data anterior ou posterior à
        informada pela Empresa Cliente implicará na antecipação ou prorrogação
        automática da entrega do pedido na mesma quantidade de dias úteis.
      </p>
      <p>
        4.2.3. A BENV’S realizará as entregas através de fornecedores
        especializados, que manterão seguros os valores transportados. Caso
        ocorra sinistro no trajeto da entrega, tais como roubos, furtos,
        apropriação indébita ou danos, sem que haja culpa da Empresa Cliente a
        BENV’S providenciará a reposição dos benefícios à Empresa Cliente no
        prazo máximo de 20 (Vinte) dias úteis nos casos de Benefícios em Mídia
        (Papel), bem como orientar o RH da Empresa Cliente e os proprietários
        dos cartões quanto aos processamentos dos cancelamentos e gerações das
        segundas vias dos cartões que por ventura possam sofrer o sinistro,
        respeitando os prazos de cada Operadora-Emissor dos Cartões. Este prazo
        se faz necessário para providenciar à documentação do sinistro e
        reprocessamento do pedido, cancelamento dos cartões e geração da Segunda
        via dos mesmos, respeitando e de acordo com as regras de cada
        Emissor/Operadora. As providências serão tomadas imediatamente. Tal
        responsabilidade da BENV’S cessará no ato da entrega dos benefícios à
        Empresa Cliente.
      </p>
      <p>
        4.2.4. A Empresa Cliente, autoriza a Benv’s Gestão de Benefícios, sempre
        que mesma julgar necessário à contratação de profissionais e/ou empresas
        especializadas para auxiliá-la na gestão, coleta, entrega dos cartões e
        benefícios.
      </p>
      <p>
        4.3. A Empresa Cliente pagará à BENV’S GESTÃO DE BENEFÍCIOS o valor
        total dos benefícios, de acordo com os preços e tarifas públicas
        vigentes na data do pagamento, acrescidos do valor do serviço composta
        por percentual calculado sobre o total do pedido e por valor fixo por
        ponto de entrega, caso houver a necessidade de entrega, conforme
        discrições constantes na Proposta e cadastrada neste Sistema de Gestão
        de Benefícios.
      </p>
      <p>
        4.3.a O percentual constante na Proposta e no Sistema mencionados, é
        calculado sobre o valor total a ser pago/creditado da compra dos
        Benefícios geridos pela CONTRATADA.
      </p>
      <p>
        4.3.1. Ao valor total dos benefícios serão incorporados quaisquer ônus,
        taxas ou acréscimos que o fornecedor, provedor ou emissor (Operadora)
        dos benefícios impuser para atendimento ao mercado de um modo geral,
        tais como custos de emissão ou reemissão, taxas de carregamento de
        créditos, taxas de processamento, entre outras, cujas informações serão
        disponibilizadas à Empresa Cliente.
      </p>
      <p>5 - ABRANGÊNCIA</p>
      <p>
        5.1. As condições estipuladas neste Contrato se aplicam unicamente aos
        serviços que envolvam a aquisição e gestão de Vales-transportes,
        Refeição, Alimentação e Combustível, podendo ser estendidas mediante
        termo aditivo a outros benefícios que pela sua natureza utilizem forma
        de operacionalização similar, tais como vales, bilhetes ou cartões para
        pagamento de medicamentos, premiação etc.
      </p>
      <p>
        5.2 A Contratante autoriza a Contratada a utilizar e inserir seu nome em
        seu site (Página Clientes) ou em qualquer outro meio de publicidade, com
        aprovação previa após análise da contratante da arte aplicada.
      </p>
      <p>6 - VIGÊNCIA e RESCISÃO</p>
      <p>
        6.1. Este Contrato vigorará por prazo indeterminado a partir de sua
        aceitação. Podendo ser rescindido por qualquer das partes, mediante
        comunicação por escrito, de acordo com os procedimentos descritos no
        item 6.2, com pelo menos 90 (Noventa) dias de antecedência da data que
        deseja o fim do contrato, sem aplicação de qualquer penalidade, multa ou
        restituição, sem isentar da obrigação da Empresa Cliente a pagar os
        valores pendentes, eventualmente existentes. 6.2. A rescisão deste
        contrato e seus anexos sendo realizada até 90 (Noventa) dias de
        antecedência da data que deseja o fim do contrato e os motivos desta
        rescisão não sendo por descumprimentos das obrigações descritas neste
        contrato e seus anexos pela Contratada, a Contratante obriga-se a pagar
        multa equivalente a uma prestação de serviço. Valores este a ser
        definido pela média dos últimos 3 (Três) meses.
      </p>
      <p>
        a) Em caso de propostas recebidas pela Contratante de outras empresas, a
        Contratada tem prioridade na negociação, podendo a Contratante rescindir
        este contrato e seus anexos e firmar um novo contrato com qualquer outra
        empresa, somente após todas as negociações com esta Contratada se
        encerrar com nenhum acordo de continuidade do referido contrato de
        prestação de serviço;
      </p>
      <p>
        b) Mediante as condições do item”a”, o contrato poderá ser rescindido
        por quaisquer das partes, sem ônus, mediante o aviso prévio de 90
        (Noventa) dias de antecedência da data que deseja o fim do contrato.
        Ficando ao Contratante obrigada a pagar a contratada pelos serviços já
        realizados e não quitados até o momento da rescisão;
      </p>
      <p>
        c) Após a rescisão, a Benv’s tem até 30 (Trinta) dias corridos para
        sanar eventuais pendências.
      </p>
      <p>
        6.3. O presente Contrato poderá, ainda, ser rescindido de pleno direito,
        com efeito imediato, nas seguintes hipóteses: (i) mediante aviso da
        parte inocente à parte infratora, em caso de inadimplemento ou violação
        de qualquer das cláusulas ou condições aqui estabelecidas e desde que
        tal inadimplemento não seja sanado pela parte infratora, no prazo de 10
        (Dez) dias úteis após ter recebido comunicação nesse sentido da parte
        inocente, ou (ii) falência, recuperação judicial, liquidação judicial ou
        extrajudicial ou insolvência da outra parte.
      </p>
      <p>7 - FORO</p>
      <p>
        7.1. Fica eleito o foro da comarca de São Paulo, estado de São Paulo,
        para dirimir quaisquer dúvidas decorrentes deste Contrato. Neste ato, ao
        confirmar a aceitação a este Contrato, a Empresa Cliente manifesta sua
        concordância e confirmação às características dos serviços prestados e
        às condições ora estabelecidas.
      </p>
    </div>
  );
};
export default Termo;
