/* eslint-disable consistent-return */
/* eslint-disable react/no-this-in-sfc */
import BvsPanel from '~/components/BvsPanel';
import Highcharts from 'react-highcharts';
import { useEffect, useState } from 'react';
import { toMoney } from '~/system/helper';
import api from '../../../services/api';
import { useSelector } from 'react-redux';

const OrderChart = () => {
  const { enterpriseActive } = useSelector((state) => state.enterprises);

  const [config, setConfig] = useState({
    chart: {
      height: 250,
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    xAxis: {
      categories: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Total (R$)',
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormatter(e) {
        return `<br/><strong style="color: ${
          this.color
        }">Total Pedido</strong>  ${toMoney(this.y)}`;
      },
      // footerFormat: '</table>',
      shared: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: 'Total Pedidos (R$)',
        showInLegend: false,
        data: [],
      },
    ],
  });

  const getData = async () => {
    try {
      const { data: series } = await api.get('/dashboard/orderByYear');
      setConfig({
        ...config,
        series,
      });
    } catch (_) {
      return [
        {
          name: 'Total Pedidos (R$)',
          showInLegend: false,
          data: new Array(12).fill(0),
        },
      ];
    }
  };
  useEffect(() => {
    if (enterpriseActive?.info?.id) getData();
  }, [enterpriseActive]);

  return (
    <div
      className="container my-3 p-4 "
      style={{
        backgroundColor: '#fff',
        minHeight: 300,
      }}
    >
      <Highcharts config={config} />;
    </div>
  );
};

export default OrderChart;
