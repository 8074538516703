import styled from 'styled-components';

export const Header = styled.header`
  min-height: 150px;
  display: block;
  width: 100%;
  background-color: #fff;
  position: relative;

  > div {
    a {
      width: 160px;
      position: relative;
      vertical-align: middle;

      img {
        height: auto;
        max-height: 100%;
        width: 156px;
        margin-left: 6px;
        margin-top: 2px;
      }
    }
  }
`;
export const MenuWrapper = styled.div`
  flex: 2;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: 576px) {
    display: block;
    width: 100%;
    justify-content: center;
    align-items: center;
    img {
      margin: 0 !important;
      width: 100% !important;
    }
  }
`;
export const Container = styled.div`
  max-width: 1130px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0px auto;
  padding-top: 14px;
  @media (max-width: 576px) {
    display: block;
    flex-wrap: wrap;
    justify-content: center !important;
  }
`;

export const UserContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  width: 100%;
  max-width: 260px;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  cursor: pointer;
  user-select: none;
  @media (max-width: 576px) {
    margin-top: 5px !important;
    margin-bottom: 5px;
  }
`;

export const UserWrapper = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
`;
export const Avatar = styled.img`
  max-width: 100%;
  object-fit: cover;
`;
export const AvatarCircle = styled.div`
  width: ${({ size }) => (size === 'xs' ? 30 : 60)}px;
  height: ${({ size }) => (size === 'xs' ? 30 : 60)}px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  z-index: 3;
`;
export const UserInfo = styled.div`
  z-index: 3;
`;
