import React, { useEffect } from 'react';

import { FontAwesome } from 'react-web-vector-icons';
import BvsTableV4 from '../../components/BvsTableV4';
import BvsSubHeader from '~/components/BvsSubHeader/index';
import BvsPanel from '~/components/BvsPanel';
import BvsContainer from '~/components/BvsContainer';
import BvsIndicator from './Indicator';
import useDashboard from './hooks/useDashboard';
import BvsModal from '~/components/BvsModal';
import BvsButton from '~/components/BvsButton';

export default function DashboardAdmin() {
  const {
    indicator,
    enterprises,
    showModal,
    getEnterprises,
    getIndicator,
    onEditEnterprise,
    onDeleteEnterprise,
    toggleModal,
  } = useDashboard();
  const columns = [
    {
      key: 'social_name',
      label: 'Razão Social',
      sort: true,
    },
    {
      key: 'fantasy_name',
      label: 'Nome Fantasia',
      sort: true,
    },
    {
      key: 'cnpj',
      label: 'CNPJ',
      sort: true,
    },
    {
      key: 'owner_name',
      label: 'Responsável',
      sort: true,
      content: (row) => row?.shippingAddress[0]?.firstResponsible?.name,
    },
    {
      key: 'owner_phone',
      label: 'Contato',
      sort: true,
      content: (row) => row?.shippingAddress[0]?.firstResponsible?.phone,
    },
    {
      key: 'owner_email',
      label: 'E-mail',
      sort: true,
      width: '350px',
      content: (row) => row?.shippingAddress[0]?.firstResponsible?.email,
    },
  ];
  useEffect(() => {
    getEnterprises();
    getIndicator();
  }, []);

  return (
    <>
      <BvsSubHeader title="Painel de Controle" />
      <BvsContainer>
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <BvsPanel title="Pesquisa Rápida | Empresas">
              <div className="col-md-12">
                <BvsTableV4
                  columns={columns}
                  rows={enterprises}
                  onRefresh={() => getEnterprises()}
                  actionOptions={{ width: '160px' }}
                  actions={[
                    (row) => (
                      <BvsButton
                        type="button"
                        className="btn btn-xs"
                        customStyle={{ marginRight: '5px' }}
                        icon={
                          <FontAwesome name="edit" color="#fff" size={12} />
                        }
                        onClick={() => onEditEnterprise(row.id)}
                      />
                    ),
                    (row) => (
                      <BvsButton
                        type="button"
                        className="btn btn-xs"
                        customStyle={{ marginRight: '5px' }}
                        icon={
                          <FontAwesome name="close" color="#fff" size={12} />
                        }
                        onClick={() => toggleModal('show', true, row.id)}
                      />
                    ),
                  ]}
                />
              </div>
            </BvsPanel>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <BvsIndicator
              title="Empresas"
              subtitle="Empresas Clientes"
              value={indicator?.totalEnterprise ?? 0}
            />
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <BvsIndicator
              title="Pedidos"
              subtitle="Pedido Realizados"
              value={indicator?.totalOrders ?? 0}
            />
          </div>
        </div>
      </BvsContainer>

      <BvsModal
        visible={showModal.show}
        onClose={() => {
          toggleModal('show', false, null);
        }}
        footer={() => (
          <div className="row d-flex justify-content-end">
            <div className="text-nowrap px-2">
              <BvsButton
                customStyle={{
                  backgroundColor: '#c0392b',
                  borderColor: '#c0392b',
                }}
                title="Não"
                onClick={() => toggleModal('show', false, null)}
              />
            </div>
            <div className="text-nowrap px-2">
              <BvsButton
                title="Sim"
                onClick={() => {
                  onDeleteEnterprise(showModal.id);
                  toggleModal('show', false, null);
                }}
              />
            </div>
          </div>
        )}
      >
        <div className="container">
          <p className="lead">Tem certeza que deseja excluir essa empresa?</p>
        </div>
      </BvsModal>
    </>
  );
}
