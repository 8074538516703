/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Table = styled.table`
  line-height: 20px;
  width: 100%;
  font-size: 0.85rem;
`;
export const TableHead = styled.thead`
  font-weight: bold;
  font-size: 0.9rem;
  border-bottom: 1px solid rgba(169, 156, 156, 0.5);
  border-top: 1px solid rgba(169, 156, 156, 0.5);
`;
export const TableTH = styled.th`
  padding: 11px 0;
  text-align: center;
`;
export const TableTD = styled.td`
  padding: 11px 0;
  text-align: center;
  background-color: rgba(169, 156, 156, 0.05);
  border-bottom: 1px solid rgba(169, 156, 156, 0.5);
  border-top: 1px solid rgba(169, 156, 156, 0.5);
`;
export const TableTDResumo = styled.td`
  padding: 11px 40px;
  text-align: right;
`;
export const TableTDResumo2 = styled.td`
  padding: 5px 40px;
  text-align: right;
  font-weight: bold;
`;
