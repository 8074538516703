import { toast } from 'react-toastify';
import { all, takeLatest, put, select, call } from 'redux-saga/effects';
import { showLoading, hideLoading } from '~/store/modules/loader/actions';
import BvsToastResult from '~/components/BvsToastResult';
import types from './types';
import api from '~/services/api';

function* confirmCancel({ callback }) {
  try {
    const { form, cursorOrder } = yield select((state) => state.orderCancel);
    yield put(showLoading());
    if (form.justification.trim().length === 0) {
      toast.info(
        <BvsToastResult type="info" message="Informe a justificativa!" />
      );
      return;
    }

    const { data } = yield call(api.post, `/order/cancel/${cursorOrder.id}`, {
      justification: form.justification,
    });

    if (data.error || data.message) throw data;
    toast.success(`Pedido #${cursorOrder.id} cancelado com sucesso.`);

    callback &&
      callback({
        error: false,
      });
  } catch (err) {
    toast.error(
      <BvsToastResult
        type="error"
        err={err}
        message="Não foi possivel cancelar este pedido."
      />
    );
    callback && callback({ error: true, message: err.message });
  } finally {
    yield put(hideLoading());
  }
}
export default all([takeLatest(types.CONFIRM_CANCEL, confirmCancel)]);
