import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/modules/forms/FormOperatorBenefit/actions';

const useForm = () => {
  const dispatch = useDispatch();
  const { operator_benefit, form } = useSelector(
    (state) => state.formOperatorBenefit
  );

  const onFieldChange = (key, value) => {
    dispatch(
      actions.updateOperatorBenefit({
        form: { ...form },
        operator_benefit: {
          ...operator_benefit,
          [key]: value,
        },
      })
    );
  };

  const clean = () => {
    dispatch(actions.resetOperatorBenefit());
  };

  return { onFieldChange, clean };
};

export default useForm;
