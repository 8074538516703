import React, { useRef } from 'react';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from '~/services/api';
import BvsButton from '~/components/BvsButton';
import BvsToastResult from '../BvsToastResult';

const BvsImport = ({ uri, title }) => {
  const { socket } = useSelector((state) => state.app);
  const fileRef = useRef();

  const onTriggredFile = (e) => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };
  const Onimport = async (e) => {
    let file = e.target?.files?.[0];
    if (file) {
      let formData = new FormData();
      formData.append('file', file);
      formData.append('socketId', socket?.id);
      e.target.value = '';
      const { data } = await api.post(`${uri}`, formData, {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      });
      if (!data.message) {
        toast.warn(
          <BvsToastResult
            type="warn"
            message="Sua importação esta sendo processada, avisaremos quando acabar. aguarde"
          />
        );
      } else {
        toast.error(
          <BvsToastResult
            type="error"
            message="Falha ao importar"
            error={data}
          />
        );
      }
    }
  };

  return (
    <>
      <input
        type="file"
        ref={fileRef}
        onChange={Onimport}
        style={{ display: 'none' }}
      />
      <BvsButton
        type="button"
        title={title}
        icon=""
        customStyle={{ margin: '5px' }}
        onClick={onTriggredFile}
      />
    </>
  );
};
export default BvsImport;
