// eslint-disable-next-line import/no-anonymous-default-export
export default {
  containerSize: {
    xxl: '1320px',
    xl: '1140px',
    lg: '960px',
    md: '720px',
    sm: '540px',
    xs: '100%',
  },
};
