/* eslint-disable import/namespace */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { AntDesign } from 'react-web-vector-icons';
import { toMoney } from '~/system/helper';
import BvsContainer from '~/components/BvsContainer';
import BvsPanel from '~/components/BvsPanel';
import SubHeader from '~/components/BvsSubHeader';
import BvsButton from '~/components/BvsButton';
import FooterReports from '~/pages/Reports/FooterReports';
import HeaderReports from '~/pages/Reports/HeaderReports';
import * as UI from './style';
import useController from './hooks/useController';

const ReportsByDepartmentPerUsers = ({ match }) => {
  const history = useHistory();
  const { reportData, getReportData } = useController();
  const { id } = match.params;

  const getValueTotal = () => {
    return reportData?.data?.departments
      .map((e) =>
        e.employees
          .map((i) => i.quantity * i.unitCost * i.quantityMonth)
          .reduce((cur, old) => cur + old, 0)
      )
      .reduce((cur, old) => cur + old, 0);
  };

  const groupBy = (employees) => {
    return employees.reduce((result, currentValue) => {
      if (result) {
        let searched = result.findIndex(
          (i) => i.benefitGroupId === currentValue.benefitGroupId
        );
        if (searched > -1) {
          result[searched].data.push(currentValue);
        } else
          result.push({
            benefitGroupId: currentValue.benefitGroupId,
            name: currentValue.benefitGroupName,
            data: [currentValue],
          });
      } else {
        result = [
          {
            benefitGroupId: currentValue.benefitGroupId,
            name: currentValue.benefitGroupName,
            data: [currentValue],
          },
        ];
      }
      return result;
    }, null);
  };

  useEffect(() => {
    getReportData(id);
  }, []);
  return (
    <>
      <SubHeader
        title="Relatório de Aquisição de Beneficios"
        subtitle="Por Departamento - Detalhado por Usuario"
      />
      <BvsContainer>
        <div>
          <BvsPanel title="Relatório de Aquisição de Benefícios Por Departamento - Detalhado por Usuario">
            <BvsButton
              type="button"
              title="Voltar"
              onClick={() => {
                history.goBack();
              }}
            />
            <HeaderReports />
            {reportData.loading && (
              <div className="p-3 row justify-content-center align-items-center">
                <div className="col-12 text-center">
                  <h4>Carregando...</h4>
                </div>
                <div className="col-4">
                  <div className="progress">
                    <div
                      className="progress-bar bg-warning progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
              </div>
            )}
            {!reportData?.loading && !reportData?.data && (
              <div
                className="row middle-xs center-xs"
                style={{ padding: 60, textAlign: 'center' }}
              >
                <div className="col-md-12">
                  <h2>Não foi possivel encontrar registros</h2>
                  <h2 style={{ marginTop: '10px' }}>
                    <AntDesign name="frowno" />
                  </h2>
                </div>
              </div>
            )}
            {!reportData?.loading && reportData?.data && (
              <>
                <BvsContainer
                  style={{
                    display: 'flex',
                    margin: '40px 20px 5px',
                    borderTop: '1.4px solid rgba(0, 0, 0, 0.1)',
                    borderBottom: '1.4px solid rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <UI.Titulo> Data de Emissão </UI.Titulo>
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'flex',
                    margin: '5px 20px 5px',
                    borderBottom: '1.4px solid rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <UI.Data>{reportData?.data?.dateEmission}</UI.Data>
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'block',
                    marginLeft: '40px',
                    marginRight: '40px',
                    marginBottom: '40px',
                    padding: '0px',
                  }}
                >
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Razão Social</th>
                        <th scope="col" />
                        <th scope="col">Código</th>
                        <th scope="col">CNPJ</th>
                        <th scope="col">Inscrição Estadual</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row" colSpan="2">
                          {reportData?.data?.socialName}
                        </td>
                        <td>{reportData?.data?.id}</td>
                        <td>{reportData?.data?.cnpj}</td>
                        <td>{reportData?.data?.stateRegistration}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Endereço</th>
                        <th scope="col">CEP</th>
                        <th scope="col">Bairro</th>
                        <th scope="col">Municipio</th>
                        <th scope="col">Estado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row">{reportData?.data?.street}</td>
                        <td>{reportData?.data?.zipcode}</td>
                        <td>{reportData?.data?.district}</td>
                        <td>{reportData?.data?.city}</td>
                        <td>{reportData?.data?.state}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Contato</th>
                        <th scope="col" />
                        <th scope="col">Telefone</th>
                        <th scope="col">Email</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row" colSpan="2">
                          {reportData?.data?.ownerName}
                        </td>
                        <td>{reportData?.data?.ownerPhone}</td>
                        <td colSpan="2">{reportData?.data?.ownerEmail}</td>
                      </tr>
                    </tbody>
                  </table>
                </BvsContainer>
                {reportData?.data?.departments.map((department) => (
                  <>
                    <BvsContainer
                      style={{
                        display: 'block',
                        marginLeft: '40px',
                        marginRight: '40px',
                        padding: '0px',
                        borderTop: '1.4px solid rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Código</th>
                            <th scope="col">Departamento</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td scope="row">{department?.departmentCode}</td>
                            <td>{department?.departmentName}</td>
                          </tr>
                        </tbody>
                      </table>
                    </BvsContainer>
                    <BvsContainer
                      style={{
                        display: 'block',
                        marginLeft: '40px',
                        marginRight: '40px',
                        marginBottom: '40px',
                        padding: '0px',
                      }}
                    >
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Mes Referencia</th>
                            <th scope="col">
                              {department?.departmentReference}
                            </th>
                            <th scope="col">Nº do Pedido</th>
                            <th scope="col">{department?.departmentOrderId}</th>
                          </tr>
                        </thead>
                      </table>
                    </BvsContainer>
                    <BvsContainer
                      style={{
                        display: 'block',
                        marginLeft: '40px',
                        marginRight: '40px',
                        marginBottom: '40px',
                        padding: '0px',
                      }}
                    >
                      <table
                        className="table table-striped"
                        style={{ borderTop: '1.4px solid rgba(0, 0, 0, 0.1)' }}
                      >
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: '2%' }}>
                              Matricula
                            </th>
                            <th
                              scope="col"
                              style={{ paddingLeft: '5px', width: '15%' }}
                            >
                              Colaborador
                            </th>
                            <th scope="col" />
                            <th scope="col">CPF</th>
                            <th scope="col" style={{ width: '2%' }}>
                              Cod.
                            </th>
                            <th scope="col" style={{ width: '20%' }}>
                              Discriminacao dos beneficios
                            </th>
                            <th scope="col" />
                            <th scope="col">Unitario</th>
                            <th scope="col">Qt.Dia</th>
                            <th scope="col" style={{ width: '2%' }}>
                              Qt.Per
                            </th>
                            <th scope="col">Valor Total</th>
                          </tr>
                        </thead>
                        {department.employees.map((employees) => (
                          <>
                            <tbody>
                              <tr style={{ fontSize: '0.80rem' }}>
                                <td scope="row" style={{ paddingLeft: '5px' }}>
                                  {employees?.registry}
                                </td>
                                <td colSpan="2">{employees?.name}</td>
                                <td style={{ paddingLeft: '5px' }}>
                                  {employees?.cpf}
                                </td>
                                <td>{employees?.benefitCode}</td>
                                <td colSpan="2">{employees?.subDescription}</td>
                                <td>{toMoney(employees?.unitCost)}</td>
                                <td>{employees?.quantity}</td>
                                <td>{employees?.quantityMonth}</td>
                                <td>
                                  {toMoney(
                                    employees?.unitCost *
                                      employees?.quantity *
                                      employees?.quantityMonth
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </>
                        ))}
                        <tfoot>
                          <tr>
                            <td
                              scope="row"
                              colSpan="9"
                              style={{ textAlign: 'right' }}
                            >
                              Qta. De Colaborador:
                            </td>
                            <td
                              colSpan="2"
                              style={{ textAlign: 'right', fontWeight: 'bold' }}
                            >
                              {department?.employees.length}
                            </td>
                          </tr>
                          <tr>
                            <td
                              scope="row"
                              colSpan="9"
                              style={{ textAlign: 'right' }}
                            >
                              Qta. De Beneficios:
                            </td>
                            <td
                              colSpan="2"
                              style={{ textAlign: 'right', fontWeight: 'bold' }}
                            >
                              {department?.employees
                                .map((q) => q.quantity)
                                .reduce((cur, old) => cur + old, 0)}
                            </td>
                          </tr>
                          <tr>
                            <td
                              scope="row"
                              colSpan="9"
                              style={{ textAlign: 'right' }}
                            >
                              Qta. Geral de beneficios:
                            </td>
                            <td
                              colSpan="2"
                              style={{ textAlign: 'right', fontWeight: 'bold' }}
                            >
                              {department?.employees
                                .map((e) => e.quantityMonth)
                                .reduce((cur, old) => cur + old, 0)}
                            </td>
                          </tr>
                          {groupBy(department?.employees).map((i) => (
                            <>
                              <tr>
                                <td
                                  scope="row"
                                  colSpan="9"
                                  style={{ textAlign: 'right' }}
                                >
                                  TOTAL&nbsp;
                                  {i?.name}:
                                </td>
                                <td
                                  colSpan="2"
                                  style={{
                                    textAlign: 'right',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {toMoney(
                                    i?.data
                                      .map(
                                        (v) =>
                                          v.unitCost *
                                          v.quantity *
                                          v.quantityMonth
                                      )
                                      .reduce((cur, old) => cur + old, 0)
                                  )}
                                </td>
                              </tr>
                            </>
                          ))}
                        </tfoot>
                      </table>
                    </BvsContainer>
                  </>
                ))}
                <BvsContainer
                  style={{
                    display: 'block',
                    marginLeft: '40px',
                    marginRight: '40px',
                    marginBottom: '40px',
                    padding: '0px',
                  }}
                >
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="10" style={{ textAlign: 'right' }}>
                          Valor Total:
                        </td>
                        <td colSpan="2" style={{ textAlign: 'right' }}>
                          {toMoney(getValueTotal())}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </BvsContainer>
              </>
            )}
            <FooterReports periodUse={reportData?.data?.periodUse} />
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default ReportsByDepartmentPerUsers;
