import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';
import api from '~/services/api';
import { setEnterpriseActive } from '~/store/modules/enterpriseActive/actions';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';

const useDashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [enterprises, setEnterprises] = useState([]);
  const [indicator, setIndicator] = useState({
    totalEnterprise: 0,
    totalOrders: 0,
  });

  const [showModal, setShowModal] = useState({
    id: null,
    show: false,
  });
  /**
   * getEnterprise
   * @description Responsável por listar as empresas
   */
  const getEnterprises = async () => {
    dispatch(showLoading());
    let list = [];
    try {
      const { data } = await api.get(`/enterprises`);
      if (data.message || data.error) throw data;
      list = data;
    } catch (err) {
      setEnterprises(list);
      toast.error(
        <BvsToastResult
          type="error"
          message={
            err?.message ||
            err?.error?.message ||
            'Não foi possivel atualizar a lista neste momento.'
          }
        />
      );
    } finally {
      setEnterprises(list);
      dispatch(hideLoading());
    }
  };

  /**
   * onEdit
   * @description Responsável pela edição de uma empresa
   */
  const onEditEnterprise = (enterpriseId) => {
    dispatch(
      setEnterpriseActive(enterpriseId, () => {
        history.push(`/enterprises/${enterpriseId}`);
      })
    );
  };

  /**
   * onDeleteEnterprise
   * @param {*} enterpriseId
   * @description Responsáve por Excluir uma empresa
   */
  const onDeleteEnterprise = async (enterpriseId) => {
    try {
      const { data } = await api.delete(`/enterprises/${enterpriseId}`);
      if (data.message || data.error) throw data;
      toast.success(<BvsToastResult message="Empresa excluida com sucesso." />);
    } catch (error) {
      toast.error(
        <BvsToastResult
          type="error"
          err={error}
          message={
            error?.message ||
            error?.error?.message ||
            'Não foi possivel excluir a empresa neste momento.'
          }
        />
      );
    }
    await getEnterprises();
  };

  /**
   * getIndicator
   * @description Responsável por buscar os indicadores
   */
  const getIndicator = async () => {
    dispatch(showLoading());
    let response = {};
    try {
      const { data } = await api.get('/indicator/administrative');
      if (data.message || data.error) throw data;
      response = data;
    } catch (err) {
      setIndicator(response);
    } finally {
      setIndicator(response);
      dispatch(hideLoading());
    }
  };

  const toggleModal = (name, value, id) => {
    setShowModal({ ...showModal, [name]: value, id });
  };

  return {
    enterprises,
    indicator,
    showModal,
    getEnterprises,
    getIndicator,
    onEditEnterprise,
    onDeleteEnterprise,
    toggleModal,
  };
};

export default useDashboard;
