/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_VALUE = {
  loading: false,
};

function loader(state = INITIAL_VALUE, action) {
  switch (action.type) {
    case types.SHOW_LOADER:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case types.HIDE_LOADER:
      return produce(state, (draft) => {
        draft.loading = false;
      });
    default:
      return state;
  }
}
export default loader;
