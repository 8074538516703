/* eslint-disable no-empty-pattern */
/* eslint-disable import/order */

import React, { useEffect, useState } from 'react';

import BvsButton from '~/components/BvsButton';
import BvsContainer from '~/components/BvsContainer';
import BvsForm from '~/components/BvsForm';
import BvsInputV2 from '~/components/BvsInputV2';
import BvsPanel from '~/components/BvsPanel';
import BvsTableV4 from '../../components/BvsTableV4';
import { FontAwesome } from 'react-web-vector-icons';
import SubHeader from '~/components/BvsSubHeader';
import api from '~/services/api';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

const Region = ({ match }) => {
  const history = useHistory();
  const [regions, setRegions] = useState([]);
  const { register, handleSubmit, errors, setValue } = useForm();
  const { addToast } = useToasts();
  const { id } = match.params;

  const fetchRegions = async () => {
    const { data } = await api.get(`/regions`);
    setRegions(data);
  };

  const columns = [
    {
      key: 'code',
      sort: true,
      label: 'Código',
    },
    {
      key: 'name',
      sort: true,
      label: 'Nome da Região',
      width: '100%',
    },
  ];

  const save = async (values, { target: form }) => {
    const { data } = await api[id ? 'put' : 'post'](
      `/regions${id ? `/${id}` : ''}`,
      values
    );
    if (!data.message) {
      addToast(`'${values.name}' salvo com sucesso!`, {
        appearance: 'success',
        autoDismiss: true,
      });

      onCancel();
      form.reset();
    } else {
      addToast(data.message, { appearance: 'error', autoDismiss: true });
    }
    await fetchRegions();
  };

  const onDelete = async (regionId) => {
    const { data } = await api.delete(`/regions/${regionId}`);
    if (!data.message) {
      addToast(`'${data.name}' excluido com sucesso!`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } else {
      addToast(data.message, { appearance: 'error', autoDismiss: true });
    }
    await fetchRegions();
  };

  const onEdit = async (regionId) => {
    const { data } = await api.get(`/regions/${regionId}`);
    if (!data.message) {
      history.replace(`/regions/${regionId}`);
      setValue('code', data.code);
      setValue('name', data.name);
    } else {
      addToast(data.message, { appearance: 'error', autoDismiss: true });
    }
    await fetchRegions();
  };

  const onCancel = () => {
    setValue('code', '');
    setValue('name', '');
    history.replace(`/regions`);
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  return (
    <>
      <SubHeader title="Regiões" />
      <BvsContainer>
        <div className="col-md-12">
          <BvsPanel title="Incluir">
            <BvsForm onSubmit={handleSubmit(save)}>
              <div className="row">
                <div
                  className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
                  style={{ marginTop: 'auto' }}
                >
                  <BvsInputV2
                    ref={register({ required: true })}
                    name="code"
                    label="Código"
                    cols="12|4|4|3"
                    error={errors.code}
                    required
                  />
                </div>
                <div
                  className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
                  style={{ marginTop: 'auto' }}
                >
                  <BvsInputV2
                    ref={register({ required: true })}
                    name="name"
                    label="Nome da Região"
                    cols="12|4|4|3"
                    error={errors.code}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <BvsButton
                    type="submit"
                    title="Salvar"
                    icon="fa fa-save"
                    customStyle={{ marginTop: '30px', float: 'right' }}
                  />
                  {id && (
                    <BvsButton
                      type="button"
                      title="Cancelar"
                      icon="fa fa-save"
                      onClick={onCancel}
                      customStyle={{
                        marginTop: '30px',
                        marginRight: '30px',
                        float: 'right',
                      }}
                    />
                  )}
                </div>
              </div>
            </BvsForm>
          </BvsPanel>
        </div>
        <div className="col-md-12">
          <BvsPanel title="Relação de Regiões">
            <div className="col-md-12">
              <BvsTableV4
                columns={columns}
                rows={regions}
                onRefresh={() => {
                  fetchRegions();
                }}
                rowKey="id"
                actions={[
                  (row) => (
                    <>
                      <BvsButton
                        type="button"
                        className="btn btn-xs"
                        customStyle={{ marginRight: '5px' }}
                        icon={
                          <FontAwesome name="edit" color="#fff" size={12} />
                        }
                        onClick={() => onEdit(row.id)}
                      />
                      <BvsButton
                        type="button"
                        className="btn btn-xs"
                        customStyle={{ marginRight: '5px' }}
                        icon={
                          <FontAwesome name="close" color="#fff" size={12} />
                        }
                        onClick={() => onDelete(row.id)}
                      />
                    </>
                  ),
                ]}
              />
            </div>
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default Region;
