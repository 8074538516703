import React, { useState } from 'react';
import BvsButton from '../../../components/BvsButton';
import useController from './hooks/useController';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { toMoney } from '~/system/helper';
import { getStatusFinancial } from '~/system/convert';
import api from '~/services/api';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';

const LastRequest = () => {
  const { orders, getLastOrder } = useController();
  const { enterpriseActive } = useSelector((state) => state.enterprises);

  const repeatOrder = async (x) => {
    try {
      await api.post(`/repeatOrder/${x[0].id}`);
    } catch (e) {
      toast.error(
        <BvsToastResult type="warn" message="Erro ao buscar ultimo pedido" />
      );
    }
    toast.success(
      <BvsToastResult type="success" message="Sucesso ao replicar pedido" />
    );
  };

  return (
    <>
      <table className="table table-hover">
        <thead>
          <tr>
            <th colSpan="5">Último Pedido</th>
            <th>
              <div className="d-flex text-nowrap justify-content-end">
                <BvsButton
                  type="button"
                  className="btn btn-sm"
                  icon="fa fa-fw fa-copy"
                  title="Repetir Pedido"
                  onClick={() => repeatOrder(orders)}
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Emissão</td>
            <td>Valor</td>
            <td>Sit. Financeira</td>
            <td>Pagamento</td>
            <td>Entrega</td>
            <td>Usuario no Pedido</td>
          </tr>
          {orders?.map((i) => (
            <tr key={i.id}>
              <td>{moment(i.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
              <td>{toMoney(Number(i.total ?? 0))}</td>
              <td>
                <span
                  className="badge"
                  style={{
                    backgroundColor: getStatusFinancial(i.status).color,
                  }}
                >
                  {getStatusFinancial(i.status).title}
                </span>
              </td>
              <td>{moment(i.payday).format('DD/MM/YYYY')}</td>
              <td>
                {moment(i.payday)
                  .add(Number(enterpriseActive?.info?.delivery_day), 'days')
                  .format('DD/MM/YYYY')}
              </td>
              <td>
                <strong>{i.totalEmployees}</strong> &nbsp;&nbsp;
                <i className="fa fa-users" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* {modalVisible && (
        <BvsModal
          visible
          title="Selecione as datas"
          onClose={() => {
            setModalVisible(false);
          }}
          footer={() => (
            <div>
              <BvsButton
                title="Fechar"
                onClick={() => {
                  setModalVisible(false);
                }}
              />
            </div>
          )}
        >
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-3">
              <div className="p-3">
                <label>Data de pagamento</label>
                <input
                  name="birthday"
                  type="date"
                  onChange={e => setPayDay(e.target.value)}
                />
              </div>
              <div className="p-3">
                <label>Data incio de uso</label>
                <input
                  name="birthday"
                  type="date"
                  onChange={e => setInitDay(e.target.value)}
                />
              </div>
              <div className="p-3">
                <label>Data Final de uso</label>
                <input
                  name="birthday"
                  type="date"
                  onChange={e => setEndDay(e.target.value)}
                />
              </div>
              <div
                style={{ margin: '20px 0' }}
                className="d-flex text-nowrap justify-content-end"
              >
                <BvsButton
                  type="button"
                  className="btn btn-sm"
                  icon="fa fa-fw fa-copy"
                  title="Repetir Pedido"
                  onClick={() => repeatOrder(orders)}
                />
              </div>
            </div>
          </div>
        </BvsModal>
      )} */}
    </>
  );
};
export default LastRequest;
