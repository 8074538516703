/* eslint-disable no-param-reassign */

import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { USER_TYPE } from '~/system/enum';
import BvsButton from '~/components/BvsButton';
import BvsContainer from '~/components/BvsContainer';
import BvsDropdown from '~/components/BvsDropdown';
import BvsPanel from '~/components/BvsPanel';
import SubHeader from '~/components/BvsSubHeader';
import BvsAdmButton from '~/components/BvsAdmButton';
import useFilter from './hooks/useFilter';
import useController from './hooks/useController';
import useDepartments from '~/hooks/useDepartments';
import useShippingAddress from '~/hooks/useShippingAddress';
import useBenefitGroup from '~/hooks/useBenefitGroup';
import useStatusEmployee from '~/hooks/useStatusEmployee';

const ExportRegister = () => {
  const { query } = useSelector((state) => state.filterExport);
  const { user } = useSelector((state) => state.auth);
  const { onFieldChange } = useFilter();
  const { departments, fetch: getDepartment } = useDepartments({
    loading: false,
  });
  const { shippingAddress, fetch: getShippingAddress } = useShippingAddress();
  const { benefitGroup, fetch: getBenefitGroup } = useBenefitGroup({
    loading: false,
  });
  const { statusEmployee: status } = useStatusEmployee();
  const { exportExcel } = useController();

  useEffect(() => {
    getDepartment();
    getShippingAddress();
    getBenefitGroup();
  }, []);

  return (
    <>
      <SubHeader title="Exportar/Download" subtitle="todos os dados" />
      <BvsContainer>
        {user?.type === USER_TYPE.ADMIN && <BvsAdmButton />}
        <div className="col-md-12">
          <BvsPanel title="Filtros de Exportação">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 p-3">
                <BvsDropdown
                  title="Selecione um grupo..."
                  name="benefit_group_id"
                  label="Grupo de Beneficios"
                  value={query.benefit_group_id}
                  onSelectedItem={(value) =>
                    onFieldChange('benefit_group_id', value)
                  }
                  loading={benefitGroup.loading}
                  options={benefitGroup.options}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 p-3">
                <BvsDropdown
                  title="Selecione um endereço..."
                  name="shipping_address_id"
                  label="Endereços"
                  value={query.shipping_address_id}
                  onSelectedItem={(value) =>
                    onFieldChange('shipping_address_id', value)
                  }
                  loading={shippingAddress.loading}
                  options={shippingAddress.options}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 p-3">
                <BvsDropdown
                  title="Selecione um departamento..."
                  name="department_id"
                  label="Departamento"
                  value={query.department_id}
                  onSelectedItem={(value) =>
                    onFieldChange('department_id', value)
                  }
                  loading={departments.loading}
                  options={departments.options}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 p-3">
                <BvsDropdown
                  title="Selecione um status"
                  name="status"
                  label="Status"
                  value={query.status}
                  onSelectedItem={(value) => onFieldChange('status', value)}
                  loading={status.loading}
                  options={status.options}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-3">
                <BvsButton
                  type="button"
                  title="Exportar"
                  icon="fa fa-chevron-right"
                  customStyle={{ float: 'right' }}
                  onClick={exportExcel}
                />
              </div>
            </div>
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default ExportRegister;
