import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import BvsButton from '~/components/BvsButton';
import BvsModal from '~/components/BvsModal';
import { historicEmployee } from '~/store/modules/app/actions';
import { useEffect } from 'react';
import { mask } from 'remask';

const HistoricEmployee = () => {
  const dispatch = useDispatch();
  const {
    historicEmployee: { components, state: historicEmployeeState },
  } = useSelector((state) => state.app);
  const setComponents = (component, state) => {
    dispatch(
      historicEmployee('update', {
        components: {
          ...components,
          [component]: state,
        },
        state: historicEmployeeState,
      })
    );
  };
  return (
    <BvsModal
      visible={components.modalVisible}
      title="Registro de Alterações"
      onClose={() => {
        setComponents('modalVisible', false);
      }}
      size="xl"
      footer={() => (
        <div>
          <BvsButton
            title="Fechar"
            onClick={() => {
              setComponents('modalVisible', false);
            }}
          />
        </div>
      )}
    >
      {historicEmployeeState?.loading && (
        <div className="p-3 row justify-content-center align-items-center">
          <div className="col-12 text-center">
            <h4>Carregando...</h4>
          </div>
          <div className="col-4">
            <div className="progress">
              <div
                className="progress-bar bg-warning progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </div>
      )}
      {!historicEmployeeState?.loading && historicEmployeeState?.data && (
        <>
          <div className="row justify-content-around">
            <div className="col-12">
              <table className="table">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>CPF</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{historicEmployeeState?.data?.name}</td>
                    <td>
                      {mask(
                        historicEmployeeState?.data?.cpf ?? '',
                        '999.999.999-99'
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table">
                <thead>
                  <tr>
                    <th>Cadastrado Por:</th>
                    <th>Cadastrado Em: </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{historicEmployeeState?.data?.createdBy}</td>
                    <td>
                      {historicEmployeeState?.data?.createdAt &&
                        moment(historicEmployeeState?.data?.createdAt)?.format(
                          'DD/MM/YYYY'
                        )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-bold">Alteração</th>
                    <th className="text-bold">Data - Hora</th>
                    <th className="text-bold" style={{ width: 150 }}>
                      Motivo
                    </th>
                    <th className="text-bold">Alterado Por</th>
                  </tr>
                </thead>
                <tbody>
                  {historicEmployeeState?.data?.historic?.map((historic) => (
                    <tr>
                      <td>{historic?.typeChange}</td>
                      <td>
                        {historic?.createdAt &&
                          moment(
                            historic?.createdAt,
                            'YYYY-MM-DD HH:mm:ss'
                          ).format('DD/MM/YYYY HH:mm:ss')}
                      </td>
                      <td>{historic?.reason}</td>
                      <td>{historic?.createdBy}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </BvsModal>
  );
};

export default HistoricEmployee;
