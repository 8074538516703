/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
import types from './types';

export function updateFilter(payload) {
  return {
    type: types.UPDATE_FIELDS_FILTER,
    payload,
  };
}

export function resetFilter() {
  return {
    type: types.RESET_FIELDS,
  };
}
