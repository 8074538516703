/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
import types from './types';

export function updateImport(payload) {
  return {
    type: types.UPDATE_IMPORT,
    payload,
  };
}

export function resetImport() {
  return {
    type: types.RESET_IMPORT,
  };
}
