import { useState } from 'react';
import api from '~/services/api';

const useController = () => {
  const [employeeInfo, setEmployeeInfo] = useState({
    data: [],
    loading: true,
  });

  const fetch = async (employeeId, benefits) => {
    let data = null;
    try {
      const { data: res } = await api.post(`/optionVT/${employeeId}`, {
        benefits,
      });

      data = res;
    } catch (err) {
      data = null;
    } finally {
      setEmployeeInfo({
        data,
        loading: false,
      });
    }
  };
  return {
    employeeInfo,
    fetch,
  };
};

export default useController;
