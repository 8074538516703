import React from 'react';
import BvsContainer from '~/components/BvsContainer';
import logo from '../../../assets/logo.png';
import * as UI from './style';

const HeaderReports = () => {
  return (
    <>
      <BvsContainer
        style={{
          display: 'flex',
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        }}
      >
        <div className="col-md-6">
          <UI.H1>Benv&apos;s Gestão e Serviços Ltda.</UI.H1>
          <br />
          <UI.Text>Avenida Washigton Luís, 3369 - Chácara Flora</UI.Text>
          <UI.Text>São Paulo-SP CEP:04627-000</UI.Text>
          <UI.Text>
            CNPJ: 07.703.407/0001-24 - Inscrição municipal: 3.461.784-1
          </UI.Text>
          <UI.Text>Contato: 3223-8930 - http://www.benvs.com.br</UI.Text>
        </div>

        <UI.ContainerImg className="col-md-6">
          <UI.Img src={logo} alt="logo" />
        </UI.ContainerImg>
      </BvsContainer>
    </>
  );
};

export default HeaderReports;
