/* eslint-disable func-names */
import React from 'react';
import Big from 'big.js';
import { FontAwesome } from 'react-web-vector-icons';
import api from '~/services/api';
import { mask, unMask } from 'remask';
import { getStatus } from './convert';
import { STATUS } from './enum';
import UserImg from '~/assets/sample-user.jpg';

const RenderStatusIconButton = (status, color = '#fff') => {
  switch (status) {
    case STATUS.ATIVO:
      return <FontAwesome name="thumbs-down" color={color} size={12} />;
    case STATUS.CANCELADO:
    case STATUS.INATIVO:
      return <FontAwesome name="thumbs-up" color={color} size={12} />;
    default:
      return <FontAwesome name="bug" color={color} size={12} />;
  }
};

const GetDepartments = (departments) => {
  let data = departments;
  if (departments.docs) data = departments.docs;

  return data.map((department, index) => ({
    ...department,
    status_enum: department.status,
    status: getStatus(department.status),
  }));
};

const GetJobs = (jobs) => {
  let data = jobs;
  if (jobs.docs) data = jobs.docs;

  return data.map((job, index) => ({
    ...job,
    status_enum: job.status,
    status: getStatus(job.status),
  }));
};

const GetWorkedDays = (workedDays) => {
  let data = workedDays;
  if (workedDays.docs) data = workedDays.docs;

  return data.map((workedDay, index) => ({
    ...workedDay,
    status_enum: workedDay.status,
    status: getStatus(workedDay.status),
  }));
};

const DownloadFile = async (uri, filename, body) => {
  const response = await api.get(uri, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...body,
    },
  });

  console.log(response);

  const blob = new Blob([response.data], {
    type: response.headers['content-type'],
    encoding: 'UTF-8',
  });
  const a = document.createElement('a');
  document.body.appendChild(a);

  const blobUrl = window.URL.createObjectURL(blob);
  a.href = blobUrl;
  a.download = filename;
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(blobUrl);
    document.body.removeChild(a);
  }, 0);
};

const DownloadExcel = async (uri, filename) => {
  const response = await api.get(uri);
  window.open(response.data);
};
export {
  RenderStatusIconButton,
  GetDepartments,
  GetJobs,
  GetWorkedDays,
  DownloadFile,
  DownloadExcel,
};

export function formatCurrency(value, localeConfig, currencyName) {
  const numberConfig = localeConfig.formats.number[currencyName];
  const formatter = new global.Intl.NumberFormat(
    localeConfig.locale,
    numberConfig
  );

  return formatter.format(Big(value));
}

export function toDecimal(value) {
  return Number(
    value.replace('R$ ', '').replace(/[\.]/g, '').replace(/[\,]/, '.')
  );
}

export function toMoney(value, showcurrency = true) {
  let options = {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  if (showcurrency)
    options = {
      style: 'currency',
      currency: 'BRL',
    };
  return new Intl.NumberFormat('pt-BR', options).format(value);
}

export function copy(o) {
  let cp = Object.create(Object.getPrototypeOf(o));
  let propNames = Object.getOwnPropertyNames(o);

  propNames.forEach(function (name) {
    let desc = Object.getOwnPropertyDescriptor(o, name);
    Object.defineProperty(copy, name, desc);
  });

  return cp;
}

export class Money extends Number {
  constructor(value, precision = 2) {
    super(Number(value.toFixed(precision)));
  }
}

export function getMask(type, value) {
  let newValue;
  switch (type) {
    case 'phone':
      newValue = mask(value, ['(99) 9999-9999', '(99) 9 9999-9999']);
      break;
    case 'rg':
      newValue = mask(value, ['99.999.999-S']);
      break;
    case 'cpf/cnpj':
      newValue = mask(value, ['999.999.999-99', '99.999.999/9999-99']);
      break;
    default:
      newValue = value;
      break;
  }
  return newValue;
}

export function getAvatar(avatarUrl = null) {
  if (avatarUrl === null) return UserImg;
  return `${process.env.REACT_APP_ENDPOINT}/uploads/avatar/${avatarUrl}`;
}

export function isDateValid(d) {
  return d instanceof Date && !isNaN(d);
}
