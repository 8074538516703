import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 10px;
  right: 0;
  padding: 0px 15px;
  box-sizing: border-box;
  z-index: 2;

  @media (max-width: 576px) {
    position: relative !important;
    justify-content: center;
    top: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const EnterpriseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  padding-right: 15px;
  align-items: center;
  cursor: pointer;

  @media (max-width: 576px) {
    padding-bottom: 10px;
    padding-right: 0px;
  }
`;

export const Actions = styled.div`
  border-left: 2px solid #f2f2f2;
  padding-left: 5px;
  width: 200px;
  justify-content: space-between;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  @media (max-width: 576px) {
    padding: 0;
  }
`;

export const Title = styled.p`
  color: #d9d9d9;
`;

export const InfoEnterprise = styled.p`
  font-weight: bold;
  color: #000011;
`;
