/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
import types from './types';

export function updateChangePassword(payload) {
  return {
    type: types.UPDATE_CHANGE_PASSWORD,
    payload,
  };
}

export function resetChangePassword() {
  return {
    type: types.RESET_CHANGE_PASSWORD,
  };
}
