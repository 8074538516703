/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import BvsContainer from '~/components/BvsContainer';
import { EnterpriseLogo, SuperFooterStyle } from './style';
import Logo from '~/assets/logo_footer.png';
import AppConfig from '~/system/app.json';

const SuperFooter = () => {
  return (
    <SuperFooterStyle>
      <BvsContainer>
        <div className="row">
          <EnterpriseLogo src={Logo} />
        </div>
        <div className="row justify-content-center p-3">
          <h6 style={{ color: '#fff', fontWeight: 'bold' }}>
            <u style={{ cursor: 'pointer' }}>v{AppConfig.version}</u>
          </h6>
        </div>
      </BvsContainer>
    </SuperFooterStyle>
  );
};

export default SuperFooter;
