import { useSelector } from 'react-redux';

const useFilter = () => {
  const { filter } = useSelector((state) => state.orderDetail);

  const applyFilter = (data) => {
    if (filter.query !== '') {
      return data.filter((i) =>
        [i.name, i?.department?.name, i?.job?.name].some(
          (q) => q.toLowerCase().indexOf(filter.query.toLowerCase()) >= 0
        )
      );
    }
    return data;
  };
  return {
    filter,
    applyFilter,
  };
};

export default useFilter;
