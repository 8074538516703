/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */

import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AntDesign } from 'react-web-vector-icons';
import { toast } from 'react-toastify';
import { STATUS_CARD } from '~/system/enum';
import * as actions from '~/store/modules/forms/FormAdministrativeCard/actions';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';
import api from '~/services/api';
import BvsButton from '~/components/BvsButton';
import BvsContainer from '~/components/BvsContainer';
import BvsDropdown from '~/components/BvsDropdown';
import BvsForm from '~/components/BvsForm';
import BvsInput from '~/components/BvsInput';
import BvsPanel from '~/components/BvsPanel';
import SubHeader from '~/components/BvsSubHeader';
import BvsSearchBenefits from '~/components/BvsSearchBenefits';
import useStatusCard from '~/hooks/useStatusCard';
import useDepartments from '~/hooks/useDepartments';
import useWorkdays from '~/hooks/useWorkday';
import BvsToastResult from '~/components/BvsToastResult';
import useShippingAddress from '~/hooks/useShippingAddress';

const CreateAdministrativeCard = ({ match }) => {
  const { administrative_card, form } = useSelector(
    (state) => state.formAdministrativeCard
  );
  const dispatch = useDispatch();
  const history = useHistory();
  let { id } = match.params;
  const [visible, setVisible] = useState(false);
  const [optionSelected, setOptionSelected] = useState([]);
  const { statusCard } = useStatusCard();
  const { departments, fetch: getDepartments } = useDepartments({
    loading: false,
  });
  const { workdays, fetch: getWorkdays } = useWorkdays();
  const { shippingAddress, fetch: getShippingAddress } = useShippingAddress();

  const setAdministrativeCard = (key, value) => {
    dispatch(
      actions.updateAdministrativeCard({
        administrative_card: { ...administrative_card, [key]: value },
      })
    );
  };

  const [benefitGroup, setBenefitGroup] = useState({
    value: '',
    title: '',
  });

  const populate = async () => {
    dispatch(showLoading());
    try {
      dispatch(
        actions.updateAdministrativeCard({
          form: { saving: true },
          administrative_card,
        })
      );
      const { data } = await api.get(
        `/administrativeCard${id ? `/${id}` : ``}`
      );
      if (data.error || data.message) throw data;
      dispatch(
        actions.updateAdministrativeCard({
          administrative_card: {
            code: data.code,
            identification: data.identification,
            name: data.name,
            status: data.status,
            benefit_group_id: data.benefit_group_id,
            benefit_id: data.benefit_id,
            shipping_address_id: data.shipping_address_id,
            card_number: data.card_number,
            department_id: data.department_id,
            workday_id: data.workday_id,
            daily_quantity: data.daily_quantity,
            monthly_quantity: data.monthly_quantity,
          },
        })
      );
      setBenefitGroup({
        value: data?.benefit?.benefitGroup.id,
        title: data?.benefit?.benefitGroup.name,
      });
      setOptionSelected([
        { title: data?.benefit?.name, value: data?.benefit?.id },
      ]);
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="houve um erro ao Requisitar dados para Edição."
        />
      );
    } finally {
      dispatch(hideLoading());
      dispatch(
        actions.updateAdministrativeCard({
          form: { saving: false },
        })
      );
    }
  };

  const save = async (e) => {
    e.preventDefault();
    dispatch(showLoading());
    try {
      dispatch(
        actions.updateAdministrativeCard({
          form: { saving: true },
        })
      );
      const { data } = await api[id ? 'put' : 'post'](
        `/administrativeCard${id ? `/${id}` : ''}`,
        administrative_card
      );
      if (data.error || data.message) throw data;
      toast.success(<BvsToastResult message="salvo com sucesso!" />);
      dispatch(actions.resetAdministrativeCard());
      onCancel();
      history.replace('/administrativeCard');
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          message={
            err?.message ||
            err?.error?.message ||
            'Não foi possivel salvar no momento'
          }
          err={err}
        />
      );
    } finally {
      dispatch(hideLoading());
      dispatch(
        actions.updateAdministrativeCard({
          form: { saving: false },
        })
      );
    }
  };

  const onCancel = () => {
    history.replace(`/administrativeCard`);
  };

  useEffect(() => {
    if (id) {
      populate();
    }
    getDepartments();
    getWorkdays();
    getShippingAddress();
  }, []);

  return (
    <>
      <SubHeader title="Cartão Administrativo" />
      <BvsContainer>
        <div className="col-md-12">
          <BvsPanel title="Dados Cadastrais da Empresa">
            <BvsForm onSubmit={save}>
              <div className="row">
                <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2 p-3">
                  <BvsInput
                    name="code"
                    label="Código"
                    value={administrative_card.code}
                    onChange={(value) => setAdministrativeCard('code', value)}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="identification"
                    label="Identificação"
                    value={administrative_card.identification}
                    onChange={(value) =>
                      setAdministrativeCard('identification', value)
                    }
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="name"
                    label="Nome Impresso"
                    value={administrative_card.name}
                    onChange={(value) => setAdministrativeCard('name', value)}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 p-3">
                  <BvsDropdown
                    title="Selecione..."
                    name="status"
                    label="Status"
                    value={administrative_card.status}
                    onSelectedItem={(value) =>
                      setAdministrativeCard('status', value)
                    }
                    loading={statusCard.loading}
                    options={statusCard.options}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsDropdown
                    title="Selecione um endereço..."
                    name="shipping_address_id"
                    label="Endereços"
                    value={administrative_card.shipping_address_id}
                    onSelectedItem={(value) =>
                      setAdministrativeCard('shipping_address_id', value)
                    }
                    loading={shippingAddress.loading}
                    options={shippingAddress.options}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="card_number"
                    label="Numero do Cartão"
                    value={administrative_card.card_number}
                    onChange={(value) =>
                      setAdministrativeCard('card_number', value)
                    }
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsDropdown
                    title="Selecione um departamento..."
                    name="department_id"
                    label="Departamento"
                    value={administrative_card.department_id}
                    onSelectedItem={(value) =>
                      setAdministrativeCard('department_id', value)
                    }
                    loading={departments.loading}
                    options={departments.options}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsDropdown
                    title="Selecione um periodo..."
                    name="workday_id"
                    label="Periodo de Trabalho"
                    value={administrative_card.workday_id}
                    onSelectedItem={(value) =>
                      setAdministrativeCard('workday_id', value)
                    }
                    loading={workdays.loading}
                    options={workdays.options}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="daily_quantity"
                    label="Quantidade Diária"
                    value={administrative_card.daily_quantity}
                    onChange={(value) =>
                      setAdministrativeCard('daily_quantity', value)
                    }
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="monthly_quantity"
                    label="Quantidade Mensal"
                    value={administrative_card.monthly_quantity}
                    onChange={(value) =>
                      setAdministrativeCard('monthly_quantity', value)
                    }
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 p-3">
                  <BvsDropdown
                    title="Selecione um beneficio..."
                    name="benefit_id"
                    label="Beneficios"
                    search
                    hasModal
                    onClick={() => setVisible(true)}
                    value={administrative_card.benefit_id}
                    options={optionSelected}
                  >
                    <BvsSearchBenefits
                      visible={visible}
                      onClose={(row) => {
                        setVisible(false);
                        setAdministrativeCard('benefit_id', row?.id || null);
                        setBenefitGroup({
                          value: row.benefitGroup.id,
                          title: row.benefitGroup.name,
                        });
                        setOptionSelected([
                          { title: row?.name, value: row?.id },
                        ]);
                      }}
                    />
                  </BvsDropdown>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsDropdown
                    title="Selecione um grupo..."
                    name="benefit_group_id"
                    label="Grupo de Beneficios"
                    search
                    value={benefitGroup.value}
                    options={[benefitGroup]}
                    disabled
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-3">
                  <BvsButton
                    type="submit"
                    title="Salvar"
                    icon="fa fa-save"
                    disabled={form.saving}
                    className="mr-3"
                  />
                  <BvsButton
                    type="button"
                    title="Voltar"
                    icon="fa fa-undo"
                    onClick={() => history.push('/administrativeCard')}
                    disabled={form.saving}
                  />
                </div>
              </div>
            </BvsForm>
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default CreateAdministrativeCard;
