/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
// import Reactotron from '~/ReactotronConfig';
import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,

  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  //   ? composeWithDevTools(
  //       applyMiddleware(sagaMiddleware),
  //       Reactotron.createEnhancer()
  //     )
  //   : compose(applyMiddleware(sagaMiddleware), Reactotron.createEnhancer()) // DEV
  compose(applyMiddleware(sagaMiddleware)) // -  PROD
);

// compose(applyMiddleware(sagaMiddleware), Reactotron.createEnhancer()),
sagaMiddleware.run(rootSaga);

export default store;
