import React, { useEffect, useState } from 'react';

import api from '~/services/api';
import BvsContainer from '~/components/BvsContainer';
import BvsPanel from '~/components/BvsPanel';
import BvsTableV4 from '../../components/BvsTableV4';
import SubHeader from '~/components/BvsSubHeader';

const State = () => {
  const [states, setStates] = useState([]);

  const fetchStates = async () => {
    const { data } = await api.get(`/states`);
    setStates(data);
  };

  const columns = [
    {
      key: 'uf',
      sort: true,
      label: 'UF',
    },
    {
      key: 'name',
      label: 'Nome do Estado',
      sort: true,
      width: '100%',
    },
  ];

  useEffect(() => {
    fetchStates();
  }, []);

  return (
    <>
      <SubHeader title="Estados" />
      <BvsContainer>
        <div className="col-md-12">
          <BvsPanel title="Relação de Estados">
            <div className="col-md-12">
              <BvsTableV4
                tableKey="states"
                columns={columns}
                rows={states}
                onRefresh={() => {
                  fetchStates();
                }}
                rowKey="id"
              />
            </div>
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default State;
