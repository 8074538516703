/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AntDesign } from 'react-web-vector-icons';
import { toMoney } from '~/system/helper';
// import reportData from '~/data/reportByUser.data';
import BvsContainer from '~/components/BvsContainer';
import BvsPanel from '~/components/BvsPanel';
import SubHeader from '~/components/BvsSubHeader';
import BvsButton from '~/components/BvsButton';
import FooterReports from '~/pages/Reports/FooterReports';
import HeaderReports from '~/pages/Reports/HeaderReports';
import * as UI from './style';

import useController from './hooks/useController';
import { mask } from 'remask';

const ReportsByUsers = ({ match }) => {
  const history = useHistory();
  const { reportData, getReportData } = useController();
  const { id } = match.params;

  // const reportData = null;

  const groupByEmployee = (employee) => {
    return employee.benefits.reduce((result, currentValue) => {
      if (result) {
        let searched = result.findIndex(
          (i) => i.benefitGroupId === currentValue.benefitGroupId
        );
        if (searched > -1) {
          result[searched].data.push(currentValue);
        } else
          result.push({
            benefitGroupId: currentValue.benefitGroupId,
            benefitGroupName: currentValue.benefitGroupName,
            data: [currentValue],
          });
      } else {
        result = [
          {
            benefitGroupId: currentValue.benefitGroupId,
            benefitGroupName: currentValue.benefitGroupName,
            data: [currentValue],
          },
        ];
      }
      return result;
    }, null);
  };

  const getResume = () => {
    if (!reportData?.data)
      return {
        total: 0,
        totalRepasse: 0,
      };
    const totalBenefit = reportData?.data?.employees
      .map((employee) =>
        employee.benefits
          .map((b) => b.quantity * b.unitCost)
          .reduce((cur, old) => cur + old, 0)
      )
      .reduce((cur, old) => cur + old, 0);
    const subTotalRepasse = reportData?.data?.employees
      .map((employee) =>
        employee.benefits
          .map((b) => b.transferAmount)
          .reduce((cur, old) => cur + old, 0)
      )
      .reduce((cur, old) => cur + old, 0);

    return {
      total: totalBenefit,
      totalRepasse: subTotalRepasse,
    };
  };

  useEffect(() => {
    getReportData(id);
  }, []);
  return (
    <>
      <SubHeader title="Relatório por Usuário" subtitle="" />
      <BvsContainer>
        <div className="col-md-12">
          <BvsPanel title="Relatório por Usuário">
            <BvsButton
              type="button"
              title="Voltar"
              onClick={() => {
                history.goBack();
              }}
            />
            <HeaderReports />
            {reportData.loading && (
              <div className="p-3 row justify-content-center align-items-center">
                <div className="col-12 text-center">
                  <h4>Carregando...</h4>
                </div>
                <div className="col-4">
                  <div className="progress">
                    <div
                      className="progress-bar bg-warning progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
              </div>
            )}
            {!reportData?.loading && !reportData?.data && (
              <div
                className="row middle-xs center-xs"
                style={{ padding: 60, textAlign: 'center' }}
              >
                <div className="col-md-12">
                  <h2>Não foi possivel encontrar registros</h2>
                  <h2 style={{ marginTop: '10px' }}>
                    <AntDesign name="frowno" />
                  </h2>
                </div>
              </div>
            )}
            {!reportData?.loading && reportData?.data && (
              <>
                <BvsContainer
                  style={{
                    display: 'flex',
                    margin: '40px 20px 5px',
                    borderTop: '1.4px solid rgba(0, 0, 0, 0.1)',
                    borderBottom: '1.4px solid rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <UI.Titulo> Data de Emissão </UI.Titulo>
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'flex',
                    margin: '5px 20px 5px',
                    borderBottom: '1.4px solid rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <UI.Data>{reportData?.data?.dateEmission}</UI.Data>
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'block',
                    marginLeft: '40px',
                    marginRight: '40px',
                    marginBottom: '40px',
                    padding: '0px',
                  }}
                >
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Razão Social</th>
                        <th scope="col" />
                        <th scope="col">Código</th>
                        <th scope="col">CNPJ</th>
                        <th scope="col">Inscrição Estadual</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row" colSpan="2">
                          {reportData?.data?.socialName}
                        </td>
                        <td>{reportData?.data?.id}</td>
                        <td>{reportData?.data?.cnpj}</td>
                        <td>{reportData?.data?.stateRegistration}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Endereço</th>
                        <th scope="col">CEP</th>
                        <th scope="col">Bairro</th>
                        <th scope="col">Municipio</th>
                        <th scope="col">Estado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row">{reportData?.data?.street}</td>
                        <td>{reportData?.data?.zipcode}</td>
                        <td>{reportData?.data?.district}</td>
                        <td>{reportData?.data?.city}</td>
                        <td>{reportData?.data?.state}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Contato</th>
                        <th scope="col" />
                        <th scope="col">Telefone</th>
                        <th scope="col">Email</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row" colSpan="2">
                          {reportData?.data?.ownerName}
                        </td>
                        <td>{reportData?.data?.ownerPhone}</td>
                        <td colSpan="2">{reportData?.data?.ownerEmail}</td>
                      </tr>
                    </tbody>
                  </table>
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'block',
                    marginLeft: '40px',
                    marginRight: '40px',
                    marginBottom: '40px',
                    padding: '0px',
                  }}
                >
                  {reportData?.data?.employees?.map((employee) => (
                    <>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Matricula</th>
                            <th scope="col">Colaborador</th>
                            <th scope="col" />
                            <th scope="col">CPF</th>
                            <th scope="col">RG</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td scope="row">{employee?.registry}</td>
                            <td colSpan="2">{employee?.name}</td>
                            <td>{mask(employee?.cpf, '999.999.999-99')}</td>
                            <td>{mask(employee?.rg, '99.999.999-9')}</td>
                          </tr>
                        </tbody>
                      </table>

                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Código</th>
                            <th scope="col">Departamento</th>
                            <th scope="col" />
                            <th scope="col">Código</th>
                            <th scope="col">Cargo</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td scope="row">{employee?.codeDepartment}</td>
                            <td colSpan="2">{employee?.nameDepartment}</td>
                            <td>{employee?.codeJob}</td>
                            <td>{employee?.nameJob}</td>
                          </tr>
                        </tbody>
                      </table>

                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Mês Referência</th>
                            <th scope="col">{reportData?.data?.reference}</th>
                            <th scope="col" />
                            <th scope="col">N.º de Pedido </th>
                            <th scope="col">{reportData?.data?.orderId}</th>
                          </tr>
                        </thead>
                      </table>

                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Código</th>
                            <th scope="col">
                              Discriminação dos Vales e Beneficios
                            </th>
                            <th scope="col" />
                            <th scope="col">Unitário (R$)</th>
                            <th scope="col">Quantidade</th>
                            <th scope="col">Valor Total</th>
                            <th scope="col">Repasse</th>
                          </tr>
                        </thead>
                        <tbody>
                          {employee.benefits.map((benefit) => (
                            <tr>
                              <td scope="row">{benefit?.benefitGroupId}</td>
                              <td colSpan="2">{benefit?.subDescription}</td>
                              <td>{toMoney(benefit?.unitCost)}</td>
                              <td>{benefit?.quantity}</td>
                              <td>
                                {toMoney(benefit?.unitCost * benefit?.quantity)}
                              </td>
                              <td>{toMoney(benefit?.transferAmount)}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          {groupByEmployee(employee).map((i) => (
                            <tr
                              style={{
                                borderTop: '1px solid rgba(0, 0, 0, 0.2)',
                                borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                              }}
                            >
                              <td scope="row" colSpan="3">
                                &nbsp;
                              </td>
                              <td colSpan="2" style={{ textAlign: 'left' }}>
                                {i?.benefitGroupName}
                              </td>
                              <td style={{ textAlign: 'left' }}>
                                {toMoney(
                                  i?.data
                                    .map((v) => v.unitCost * v.quantity)
                                    .reduce((cur, old) => cur + old, 0)
                                )}
                              </td>
                              <td style={{ textAlign: 'left' }}>
                                {toMoney(
                                  i?.data
                                    .map((v) => v.transferAmount)
                                    .reduce((cur, old) => cur + old, 0)
                                )}
                              </td>
                            </tr>
                          ))}
                        </tfoot>
                      </table>
                    </>
                  ))}
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th />
                        <th />
                        <th />
                        <th />
                        <th />
                        <th />
                        <th
                          style={{
                            width: '12%',
                          }}
                        />
                        <th
                          style={{
                            width: '12%',
                          }}
                        />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          scope="row"
                          colSpan="6"
                          style={{
                            textAlign: 'right',
                            fontWeight: 'bold',
                          }}
                        >
                          Valor total
                        </td>
                        <td
                          style={{
                            textAlign: 'left',
                            fontWeight: 'bold',
                          }}
                        >
                          {toMoney(getResume().total)}
                        </td>
                        <td
                          style={{
                            textAlign: 'left',
                            fontWeight: 'bold',
                          }}
                        >
                          {toMoney(getResume().totalRepasse)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </BvsContainer>
              </>
            )}
            <FooterReports periodUse={reportData?.periodUse} />
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default ReportsByUsers;
