/* eslint-disable no-empty */
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '~/store/modules/filter/FilterEmployees/actions';

const useFilter = () => {
  const dispatch = useDispatch();
  const { query } = useSelector((state) => state.filterEmployees);

  const onFieldChange = (key, value) => {
    dispatch(
      actions.updateEmployeeFilter({
        query: { ...query, [key]: value },
      })
    );
  };

  return {
    onFieldChange,
  };
};

export default useFilter;
