import { useDispatch, useSelector } from 'react-redux';
import { updateChangePassword } from '~/store/modules/forms/FormChangePassword/actions';

const useForm = () => {
  const dispatch = useDispatch();
  const { change_password, form } = useSelector(
    (state) => state.formChangePassword
  );

  const onFieldChange = (key, value) => {
    dispatch(
      updateChangePassword({
        form: { ...form },
        change_password: {
          ...change_password,
          [key]: value,
        },
      })
    );
  };
  return { onFieldChange };
};

export default useForm;
