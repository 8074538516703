import { useDispatch, useSelector } from 'react-redux';
import { getNameTax } from '~/system/convert';
import { updateOrder } from '~/store/modules/order/actions';
import { useHistory } from 'react-router';
import _ from 'lodash';

const useHelper = ({ id = null }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { order } = useSelector((state) => state.order);
  const { enterpriseActive } = useSelector((state) => state.enterprises);

  const getTotalBenefits = () => {
    return (
      Number(
        order.employees
          .map((e) => e.benefits.map(getSubtotal))
          .flat(1)
          .reduce((prev, cur) => prev + cur, 0)
          .toFixed(2)
      ) ?? 0
    );
  };
  const getTotalTransfer = () => {
    return (
      Number(
        order.employees
          .map((e) => e.benefits.map(getTransferAmount))
          .flat(1)
          .reduce((prev, cur) => prev + cur, 0)
          .toFixed(2)
      ) + getTotalCostOrderAndBenefit()
    );
  };
  const getTotalCostOrderAndBenefit = () => {
    let arrBenefitCostPerOrder = order.employees
      .map((e) =>
        e.benefits.map((b) => ({
          id: b.benefit.id,
          cost: b.benefit.cost_per_order,
        }))
      )
      .flat(1);

    return arrBenefitCostPerOrder
      .filter(
        (item, pos) =>
          arrBenefitCostPerOrder.map((i) => i.id).indexOf(item.id) === pos
      )
      .map((i) => Number(i.cost) ?? 0)
      .reduce((prev, cur) => prev + cur, 0);
  };
  const getTaxManagerGeneral = () => {
    const taxManagerGeneral =
      (Number(enterpriseActive?.info?.tax_general_manager) ?? 0) / 100;
    return Number((getTotalBenefits() * taxManagerGeneral).toFixed(2));
  };

  const getFees = (totalPerGroup) => {
    return enterpriseActive?.info?.fees?.map((fee) => ({
      id: fee.benefitGroupId,
      name: getNameTax(fee.benefitGroupId, fee.benefitGroupName),
      total: totalPerGroup[fee.benefitGroupId]
        ? Number(
            (Number(fee.percentage) > 0
              ? totalPerGroup[fee.benefitGroupId] *
                ((Number(fee.percentage) ?? 0) / 100)
              : Number(fee.cost) ?? 0
            ).toFixed(2)
          )
        : 0,
    }));
  };

  const getDeliveryTax = () => {
    return Number(enterpriseActive?.info?.delivery_cost);
  };
  const getDiscount = () => {
    return Number(enterpriseActive?.info?.discount);
  };
  const getOthersCost = () => {
    return Number(enterpriseActive?.info?.other_cost);
  };

  const getTotalPerGroup = () => {
    return order.employees
      .map((e) =>
        e.benefits.map((b) => ({
          benefitGroupId: b?.benefit?.benefitGroup?.id,
          total: getSubtotal(b),
        }))
      )
      .flat(1)
      .reduce((r, a) => {
        r[a.benefitGroupId] = r[a.benefitGroupId] || [];
        r[a.benefitGroupId] = Number(
          (Number(r[a.benefitGroupId] ?? 0) + Number(a.total)).toFixed(2)
        );
        return r;
      }, Object.create(null));
  };

  const checkout = () => {
    // calcula os valores
    let totalBenefits = getTotalBenefits();
    let totalPerGroup = getTotalPerGroup();
    let totalTransfer = getTotalTransfer();
    let taxManagerGeneral = getTaxManagerGeneral();
    let deliveryTax = getDeliveryTax();
    let otherCost = getOthersCost();
    let discount = getDiscount();
    let fees = getFees(totalPerGroup);
    let total = Number(
      (
        totalBenefits +
        totalTransfer +
        taxManagerGeneral +
        fees.map((f) => f.total).reduce((p, c) => p + c, 0) +
        deliveryTax +
        otherCost -
        discount
      ).toFixed(2)
    );
    // atualizo o objeto de pedido
    dispatch(
      updateOrder({
        ...order,
        totalBenefits,
        totalTransfer,
        taxManagerGeneral,
        fees,
        others_cost: otherCost,
        discount,
        delivery_cost: deliveryTax,
        total,
      })
    );
    if (id) {
      history.push(`/orders/checkout/${id}`, { order });
    } else {
      history.push(`/orders/checkout`);
    }

    // Move para o checkout
  };
  const groupBy = (benefits) => {
    return benefits.reduce((result, currentValue) => {
      if (result) {
        let searched = result.findIndex(
          (i) => i?.benefitGroupId === currentValue?.benefit?.benefitGroup?.id
        );
        if (searched > -1) {
          result[searched].data.push(currentValue);
        } else result.push(currentValue);
      } else {
        result = [
          {
            benefitGroupId: currentValue?.benefit?.benefitGroup?.id,
            name: currentValue?.benefit?.benefitGroup?.name,
            data: [currentValue],
          },
        ];
      }
      return result;
    }, null);
  };

  const getSubtotal = (employeeBenefit) => {
    return (
      Number(employeeBenefit?.benefit?.unit_cost) *
      employeeBenefit?.daily_quantity *
      Number(String(employeeBenefit?.month_quantity).replace(/[^\d]/g, ''))
    );
  };
  const getTransferAmount = (employeeBenefit) => {
    const subTotal =
      (Number(employeeBenefit?.benefit?.unit_cost) ?? 0) *
      (employeeBenefit?.daily_quantity ?? 0) *
      (Number(String(employeeBenefit?.month_quantity).replace(/[^\d]/g, '')) ??
        0);

    const tax =
      subTotal * (Number(employeeBenefit?.benefit?.percentage_total) / 100) +
      Number(employeeBenefit?.benefit?.cost_per_employee || 0);
    return tax;
  };

  return { checkout, groupBy, getSubtotal, getTransferAmount };
};

export default useHelper;
