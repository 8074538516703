import styled from 'styled-components';
/* eslint-disable import/prefer-default-export */
export const TableHeaderActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const Table = styled.table`
  width: 100%;
  padding: 0;
  margin: 0 auto;
  background: #fff;
  font-size: 0.875rem;
  text-align: left;
  border-spacing: 0;
  border-collapse: separate;
  table-layout: auto;

  > thead > tr > th {
    border-top: 1px solid #eceeef;
    font-weight: bold;
    border-bottom: 2px solid #eceeef !important;
  }
  > thead > tr > th:not(.column-fixed-right),
  > tfoot > tr > td,
  > tbody > tr > td:not(.column-fixed-right) {
    box-sizing: border-box;
    height: 40px;
    padding: 0 20px;
    border: none;
    border-top: 1px solid #eceeef;
    border-bottom: 1px solid #eceeef;
    word-break: break-all;
    vertical-align: middle;
    width: 1px;
    white-space: nowrap;
  }
  > tbody > tr:hover > td {
    background: #beddcd60;
  }
  .column-fixed-right {
    position: -webkit-sticky;
    position: sticky;
    right: 0;
    z-index: 2;
    display: flex;
    background: #fff;
    box-shadow: -3px 3px 5px 0px rgb(0 0 0 / 10%);
    max-width: 230px;
  }

  th.column-fixed-right {
    box-sizing: border-box;
    height: 40px;
    padding: 0 20px;
    border: none;
    border-top: 1px solid #eceeef;
    border-bottom: 1px solid #eceeef !important;
    word-break: break-all;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    display: table-cell;
  }
  td.column-fixed-right {
    box-sizing: border-box;
    height: 40px;
    padding: 0 20px;
    border: none;
    border-top: 1px solid #eceeef;
    word-break: break-all;
    vertical-align: middle;
    display: table-cell;
    background-color: #fff !important;
  }
`;

export const TableFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: space-between;
`;

export const PaginationItem = styled.li`
  padding: 5px 10px;
  background: ${(props) => (props.active ? '#BEDDCD' : '#fff')};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;

  > span {
    color: ${(props) => (!props.active ? '#BEDDCD' : '#fff')};
  }
`;

export const PaginationContainer = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  /* margin: 3rem; */
  border-radius: 0.6rem;
  background: #ffffff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  padding: 0px;
  margin: 0;
`;

export const PaginationNav = styled.li`
  padding: 0px 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
`;
