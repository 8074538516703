/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
import types from './types';

export function updateJob(payload) {
  return {
    type: types.UPDATE_JOB,
    payload,
  };
}

export function resetJob() {
  return {
    type: types.RESET_JOB,
  };
}

export function addJob(callback) {
  return {
    type: types.ADD_JOB,
    callback,
  };
}
