import React from 'react';
import BvsContainer from '~/components/BvsContainer';
import * as UI from './style';
import logo from '../../../assets/logo.png';

const FooterReports = ({ periodUse = null }) => {
  return (
    <>
      <BvsContainer style={{ display: 'flex' }}>
        <div className="col-md-6">
          {periodUse && (
            <UI.DataReview>Periodo de utilização {periodUse}</UI.DataReview>
          )}
        </div>
        <UI.ContainerImg className="col-md-6">
          <UI.Img src={logo} alt="logo" />
          <div>
            <UI.Text>Seja bem-vindo ao</UI.Text>
            <UI.Text2>Conceito Benv&apos;s</UI.Text2>
          </div>
        </UI.ContainerImg>
      </BvsContainer>
    </>
  );
};

export default FooterReports;
