import styled from 'styled-components';

export const UserMenuWrapper = styled.div`
  position: absolute;
  width: 115%;
  background: #fff;
  top: -10px;
  padding: 15px 15px;
  z-index: 2;
  left: -10px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 1px rgb(0 0 0 / 20%);

  ul {
    margin-top: 70px;
    padding: 5px 0;
    padding-bottom: 0;
    border-top: 1px solid #e5e5e5;
  }
`;

export const UserMenuItem = styled.li`
  display: flex;
  padding: 5px 10px;
  align-items: center;

  :hover {
    background: #cccccc;
    color: #fff;

    i {
      color: #fff !important;
    }
  }
`;
