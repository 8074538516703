/* eslint-disable no-continue */
/* eslint-disable guard-for-in */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';
import api from '~/services/api';
import { setEnterpriseActive } from '~/store/modules/enterpriseActive/actions';
import _ from 'lodash';

import { hideLoading, showLoading } from '~/store/modules/loader/actions';
import { mask, unMask } from 'remask';
import moment from 'moment';
import { getEmployeeStatus } from '../../../system/convert';

const useController = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [employees, setEmployees] = useState({
    options: [],
    loading: false,
  });
  const { query } = useSelector((state) => state.filterEmployees);
  const { enterpriseActive } = useSelector((state) => state.enterprises);
  const getColumns = () => {
    let columns = [
      {
        key: 'registry',
        label: 'Matrícula',
        sort: true,
      },
      {
        key: 'name',
        label: 'Nome',
        sort: true,
      },
      {
        key: 'cpf',
        label: 'CPF',
        sort: true,
        content: (row) => mask(row.cpf, '999.999.999-99'),
      },
      {
        key: 'status',
        label: 'Status',
        sort: true,
        content: (row) => getEmployeeStatus(row.status),
      },
      {
        key: 'cnpj',
        label: 'Cnpj',
        sort: true,
        content: (row) =>
          mask(row?.enterprise?.cnpj || '', '99.999.999/9999-99'),
      },
      {
        key: 'social_name',
        label: 'Razão Social',
        sort: true,
      },
      {
        key: 'admission_date',
        label: 'Contratado em',
        sort: true,
        width: '100%',
        content: (row) =>
          (row?.admission_date &&
            moment(row?.admission_date).format('DD/MM/YYYY')) ||
          '',
      },
    ];

    if (enterpriseActive?.info) columns.splice(4, 2);
    return columns;
  };

  const fetch = async () => {
    setEmployees({ ...employees, loading: true });
    let options = [];
    dispatch(showLoading());
    try {
      const { data } = await api.get('/employees');
      if (data.error || data.message) throw data;
      options = data;
      /**
       * Filtro
       */
      // Se algum filtro tiver preenchido
      if (Object.values(query).some((i) => i !== null && i !== '')) {
        options = options.filter((item) => {
          let isValid = [];
          for (let key in query) {
            if ([null, '', undefined].includes(query[key])) continue;
            if (key === 'registry' && query[key])
              isValid.push(
                String(item[key]).toUpperCase() ===
                  String(query[key]).toUpperCase()
              );
            if (key === 'name')
              isValid.push(
                String(item[key])
                  .toUpperCase()
                  .indexOf(query[key].toUpperCase()) > -1
              );
            if (key === 'cpf') {
              isValid.push(
                unMask(item[key], '999.999.999-99') ===
                  unMask(query[key], '999.999.999-99')
              );
            }
            if (key === 'department_id')
              isValid.push(String(item?.department?.id) === String(query[key]));
            if (key === 'workday_id')
              isValid.push(String(item?.workday?.id) === String(query[key]));
            if (key === 'shipping_address_id')
              isValid.push(
                String(item?.shippingAddress?.id) === String(query[key])
              );
            if (key === 'admission_date')
              isValid.push(moment(item?.admission_date).isSame(query[key]));
            if (key === 'demission_date')
              isValid.push(
                moment(item?.demission_date).isSameOrBefore(query[key])
              );
            if (key === 'status')
              isValid.push(String(item.status) === String(query[key]));
          }
          return isValid.every((i) => i);
        });
      }
      setEmployees({ ...employees, options, loading: false });
      dispatch(hideLoading());
    } catch (err) {
      dispatch(hideLoading());

      setEmployees({ ...employees, options, loading: false });
      toast.error(
        <BvsToastResult
          type="error"
          message={
            err?.message || err?.error?.message || 'Falha ao consultar pedidos.'
          }
        />
      );
    }
  };

  const changeStatus = async (employeesId) => {
    dispatch(showLoading());
    try {
      const { data } = await api.put(`/employees/${employeesId}/status`);
      if (data.message || data.error) throw data;
      toast.success(<BvsToastResult message="Status alterado com sucesso" />);
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Falha ao atualizar o status"
        />
      );
    } finally {
      dispatch(hideLoading());
      await fetch();
    }
  };

  const changeMultipleStatus = async (selectedItems = [], pStatus = false) => {
    if (selectedItems.length === 0) {
      toast.warning(
        <BvsToastResult
          type="warn"
          message="Por favor selecione pelo menos um funcionário"
        />
      );
      return;
    }
    dispatch(showLoading());
    try {
      const { data } = await api.put(`/employees/status/multiple`, {
        employees: selectedItems.map((i) => i.id),
        status: pStatus,
      });
      if (data?.error || data?.message) throw data;
      toast.success(<BvsToastResult message="Status alterado com sucesso!" />);
      await fetch();
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          message="Falha ao atualizar multiplos status"
          err={err}
        />
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const onDelete = async (employeesId) => {
    dispatch(showLoading());
    try {
      const { data } = await api.delete(`/employees/${employeesId}`);
      if (data.error || data.message) throw data;
      toast.success(<BvsToastResult message="Excluido com sucesso!" />);
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          message="Falha ao atualizar deletar registro"
          err={err}
        />
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const onEdit = (employee) => {
    if (!enterpriseActive?.info?.id)
      dispatch(setEnterpriseActive(employee?.enterprise?.id));
    history.push(`/createEmployees/${employee.id}`);
  };

  const applyFilter = (e) => {
    e.preventDefault();
    fetch();
  };
  return {
    getColumns,
    onDelete,
    changeMultipleStatus,
    changeStatus,
    fetch,
    onEdit,
    employees,
    applyFilter,
  };
};

export default useController;
