/* eslint-disable import/prefer-default-export */
import { FontAwesome } from 'react-web-vector-icons';
import { getPagingRange } from '../BvsTableV2/util/helper';
import {
  PaginationContainer,
  PaginationNav as PaginationNavBase,
  TableFooter,
  PaginationItem as PaginationItemBase,
} from './style';

const PaginationNav = (type, onClick, label) => {
  return (
    <PaginationNavBase key={type} onClick={onClick}>
      {label}
    </PaginationNavBase>
  );
};

const PaginationItem = ({ active, onClick, label }) => {
  return (
    <PaginationItemBase {...{ active, onClick }}>
      <span>{label}</span>
    </PaginationItemBase>
  );
};
export const BvsTableFooter = ({
  total = 0,
  itemsPerPage = 10,
  noEntriesLabel = 'Sem registros para exibir',
  toEntries,
  fromEntries,
  currentPage,
  nextPageLabel,
  previousPageLabel,
  onPage,
  entriesLabels = ['Mostrando: ', 'de', 'de', 'resultados'],
}) => {
  const renderPagination = () => {
    const length = Math.ceil(total / (itemsPerPage || 1));
    const previousPage = currentPage === 1 ? 1 : currentPage - 1;
    const nextPage = currentPage === length ? length : currentPage + 1;
    let pageButtons = [
      PaginationNav(
        'previous',
        () => handlePageChanged(previousPage + 1),
        previousPageLabel || <FontAwesome name="chevron-left" size={10} />
      ),
    ];

    const pageNumbers = getPagingRange(currentPage, {
      min: 0,
      total: length,
      length: 5,
    }).map((value, index) => {
      return (
        <PaginationItem
          {...{
            label: value + 1,
            key: value + 1,
            onClick: () => handlePageChanged(value + 1),
            active: value + 1 === currentPage,
          }}
        />
      );
    });

    pageButtons = pageButtons.concat(pageNumbers);
    pageButtons.push(
      PaginationNav(
        'next',
        () => handlePageChanged(nextPage),
        nextPageLabel || <FontAwesome name="chevron-right" size={10} />
      )
    );

    return pageButtons;
  };

  const handlePageChanged = (nextPage) => {
    onPage && onPage(nextPage);
  };
  const pageButtons = renderPagination();
  return (
    <TableFooter>
      <div>
        {total > 0 ? (
          <p>
            {entriesLabels[0]} {fromEntries} {entriesLabels[1]} {toEntries}{' '}
            {entriesLabels[2]} {total} {entriesLabels[3]}
          </p>
        ) : (
          <p>{noEntriesLabel}</p>
        )}
      </div>
      <div>
        <PaginationContainer>{pageButtons}</PaginationContainer>
      </div>
    </TableFooter>
  );
};
