import produce from 'immer';
import { useState } from 'react';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';
import api from '~/services/api';

const useBenefits = ({ employeeId = null }) => {
  const [benefits, setBenefits] = useState([]);

  const fetch = async () => {
    let data = [];
    try {
      const { data: res } = await api.get(`/employees/${employeeId}/benefits`);
      if (res.error || res.message) throw res;
      data = produce(data, (draft) => {
        draft = res;
        return draft;
      });
    } catch (err) {
      data = [];
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Não foi possivel consultar os beneficios deste colaborador."
        />
      );
    } finally {
      setBenefits(data);
    }
  };
  return {
    benefits,
    fetch,
  };
};

export default useBenefits;
