import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { updateWorkday, resetWorkday } from './actions';
import types from './types';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';
import api from '~/services/api';

export function* addWorkday({ callback }) {
  const { workday, form } = yield select((state) => state.formWorkday);
  try {
    yield put(
      updateWorkday({ form: { ...form, saving: true, disabled: true } })
    );
    const { data } = yield call(api.post, '/workdays', {
      code: workday.code,
      description: workday.description,
      quantity: workday.quantity,
    });
    yield put(
      updateWorkday({ form: { ...form, saving: false, disabled: false } })
    );
    if (data.error || data.message) {
      throw data;
    }

    yield put(resetWorkday());

    toast.success(
      <BvsToastResult message={`'${data.description}' salvo com sucesso!`} />
    );
    callback && callback(data);
  } catch (error) {
    toast.error(
      <BvsToastResult
        type="error"
        err={error}
        message="Falha ao salvar registro"
      />
    );
  } finally {
    yield put(
      updateWorkday({ form: { ...form, saving: false, disabled: false } })
    );
  }
}
export default all([takeLatest(types.ADD_WORKDAY, addWorkday)]);
