import React, { useEffect, useRef, useState } from 'react';

import { FontAwesome } from 'react-web-vector-icons';
import { getStatus } from '~/system/convert';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/modules/forms/FormOperator/actions';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';
import { toast } from 'react-toastify';
import webservice from '~/services/api';
import SubHeader from '~/components/BvsSubHeader';
import BvsTableV4 from '../../components/BvsTableV4';
import BvsPanel from '~/components/BvsPanel';
import BvsInput from '~/components/BvsInput';
import BvsForm from '~/components/BvsForm';
import BvsButton from '~/components/BvsButton';
import BvsToastResult from '~/components/BvsToastResult';
import { RenderStatusIconButton } from '../../system/helper';

const Operator = ({ match }) => {
  const { operator, form } = useSelector((state) => state.formOperator);
  const dispatch = useDispatch();
  const history = useHistory();
  const [list, setList] = useState([]);
  let { id } = match.params;
  const containerForm = useRef();

  const setOperator = (key, value) => {
    dispatch(
      actions.updateOperator({
        operator: {
          ...operator,
          [key]: value,
        },
      })
    );
  };

  const fetchOperator = async () => {
    dispatch(showLoading());
    try {
      const { data } = await webservice.get(`/benefitOperators`);
      if (data.message || data.error) throw data;
      setList(data);
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          message={
            err?.message ||
            err?.error?.message ||
            'Falha ao recarregar registro'
          }
          error={err}
        />
      );
      setOperator([]);
    } finally {
      dispatch(hideLoading());
    }
  };

  const columns = [
    {
      key: 'status',
      label: 'Status',
      sort: true,
      content: (row) => {
        row.status_enum = row.status;
        row.status = getStatus(row.status);
      },
    },
    {
      key: 'code',
      label: 'Código',
      sort: true,
    },
    {
      key: 'name',
      label: 'Nome do Benefício',
      sort: true,
      width: '100%',
    },
  ];

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(
        actions.updateOperator({
          ...operator,
          form: {
            saving: true,
          },
        })
      );
      const { data } = await webservice[id ? 'put' : 'post'](
        `/benefitOperators${id ? `/${id}` : ''}`,
        operator
      );
      if (data.error) throw data.error;
      toast.success(
        <BvsToastResult
          message="Salvo com
            Sucesso"
        />
      );
      fetchOperator();
      onCancel();
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          message={
            err?.message || err?.error?.message || 'Falha ao salvar registro'
          }
          error={err}
        />
      );
    } finally {
      dispatch(
        actions.updateOperator({
          ...operator,
          form: {
            saving: false,
          },
        })
      );
    }
  };

  const onDelete = async (operadorId) => {
    try {
      dispatch(
        actions.updateOperator({
          ...operator,
          form: {
            saving: true,
          },
        })
      );
      const { data } = await webservice.delete(
        `/benefitOperators/${operadorId}`
      );
      if (data.message || data.error) throw data;
      toast.success(<BvsToastResult message="excluido com Sucesso" />);
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          message={
            err?.message || err?.error?.message || 'Falha ao excluir registro'
          }
          error={err}
        />
      );
    } finally {
      fetchOperator();
      dispatch(
        actions.updateOperator({
          ...operator,
          form: {
            saving: false,
          },
        })
      );
    }
  };

  const onEdit = async (departmentId) => {
    try {
      dispatch(showLoading());
      const { data } = await webservice.get(
        `/benefitOperators/${departmentId}`
      );
      if (data.message || data.error) throw data;
      dispatch(
        actions.updateOperator({
          form,
          operator: {
            ...operator,
            code: data.code,
            name: data.name,
          },
        })
      );
      history.replace(`/operator/${departmentId}`);
      window.scrollTo({
        top: containerForm?.current.scrollTop,
        behavior: 'smooth',
      });
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          message={
            err?.message || err?.error?.message || 'Falha ao localizar registro'
          }
          error={err}
        />
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const changeStatus = async (operatorId) => {
    try {
      dispatch(showLoading());
      const { data } = await webservice.put(
        `/benefitOperators/${operatorId}/status`
      );
      if (data.message || data.error) throw data;
      toast.success(
        <BvsToastResult message="Status atualizado com sucesso." />
      );
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          message="Falha ao atualizar o status"
          error={err}
        />
      );
    } finally {
      dispatch(hideLoading());
    }

    await fetchOperator();
  };

  const onCancel = () => {
    dispatch(actions.resetOperator());
    history.replace(`/operator`);
  };

  useEffect(() => {
    fetchOperator();

    return () => {
      dispatch(actions.resetOperator());
    };
  }, []);

  return (
    <>
      <SubHeader title="Operadora" />
      <div className="col-md-12" ref={containerForm}>
        <BvsPanel title="Nova Operadora" visible>
          <BvsForm onSubmit={onSubmit}>
            <div className="row">
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                <BvsInput
                  name="code"
                  label="Código"
                  value={operator.code}
                  onChange={(value) => setOperator('code', value)}
                  required
                />
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                <BvsInput
                  name="name"
                  label="Nome"
                  value={operator.name}
                  onChange={(value) => setOperator('name', value)}
                  required
                />
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-6">
                <BvsButton
                  type="submit"
                  title="Salvar"
                  icon="fa fa-save"
                  disabled={form.saving}
                />
                {id && (
                  <BvsButton
                    type="button"
                    title="Cancelar"
                    icon="fa fa-save"
                    onClick={() => {
                      onCancel();
                    }}
                    customStyle={{
                      marginLeft: '30px',
                    }}
                  />
                )}
              </div>
            </div>
          </BvsForm>
        </BvsPanel>

        <BvsPanel title="Relação de Operadoras">
          <div className="col-md-12">
            <BvsTableV4
              columns={columns}
              rows={list}
              onRefresh={() => {
                fetchOperator();
              }}
              rowKey="id"
              actionOptions={{ width: '225px' }}
              actions={[
                (row) => (
                  <>
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={<FontAwesome name="edit" color="#fff" size={12} />}
                      onClick={() => onEdit(row.id)}
                    />
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={<FontAwesome name="close" color="#fff" size={12} />}
                      onClick={() => onDelete(row.id)}
                    />
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={RenderStatusIconButton(row.status)}
                      onClick={() => changeStatus(row.id)}
                    />
                  </>
                ),
              ]}
            />
            <div>
              <BvsButton
                type="button"
                title="Voltar"
                icon="fa fa-undo"
                customStyle={{ margin: '5px' }}
                onClick={(e) => history.push(`benefit`)}
              />
            </div>
          </div>
        </BvsPanel>
      </div>
    </>
  );
};

export default Operator;
