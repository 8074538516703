import { useDispatch, useSelector } from 'react-redux';
import { updateFilter } from '~/store/modules/filter/FilterExport/actions';

const useFilter = () => {
  const dispatch = useDispatch();
  const { query } = useSelector((state) => state.filterExport);

  const onFieldChange = (key, value) => {
    dispatch(
      updateFilter({
        query: { ...query, [key]: value },
      })
    );
  };

  return {
    onFieldChange,
  };
};

export default useFilter;
