import types from './types';

export function updatePaginate(payload) {
  return {
    type: types.UPDATE_PAGINATE,
    payload,
  };
}

export function resetPaginate() {
  return {
    type: types.RESET_PAGINATE,
  };
}

export function updateOrderBy(payload) {
  return {
    type: types.UPDATE_ORDERBY,
    payload,
  };
}

export function resetOrderBy() {
  return {
    type: types.RESET_ORDERBY,
  };
}

export function updateFilter(payload) {
  return {
    type: types.UPDATE_FILTER,
    payload,
  };
}

export function resetFilter() {
  return {
    type: types.RESET_FILTER,
  };
}

export function updateChangeDays(payload) {
  return {
    type: types.UPDATE_CHANGE_DAYS,
    payload,
  };
}
