/* eslint-disable no-plusplus */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
export const defaultCache = { searchQuery: '' };
/**
 * Responsável por criar um onde determina de onde deve começar e até onde deve ir
 * @param {*} len
 * @param {*} start
 */
export const range = (len, start) =>
  Array(len)
    .fill(null)
    .map((_, key) => key)
    .filter((i) => i >= start);

/**
 * Responsável por lidar com o cache da tabela
 * @param {*} storage
 * @param {*} tableKey
 */
export function getStorage(storage, tableKey) {
  if (storage[tableKey]) {
    return storage[tableKey];
  }
  return defaultCache;
}

export function setStorage(storage = {}, tableKey, prop, val) {
  if (storage[tableKey] === undefined) {
    storage[tableKey] = {};
  }
  storage[tableKey][prop] = val;
}

/**
 * Localizar as linhas de acordo com a query
 * @param {*} rows
 * @param {*} columns
 * @param {*} searchQuery
 */
export function filterRows(rows, columns, searchQuery = '') {
  const filteredRows = [];
  if (searchQuery === null || searchQuery === '') {
    return rows;
  }
  rows.forEach((row) => {
    columns.some((column) => {
      if (column?.content) {
        const rowValue = String(column.content(row)).toLowerCase();
        if (
          rowValue.length >= searchQuery.length &&
          rowValue.indexOf(searchQuery.toLowerCase()) >= 0
        ) {
          filteredRows.push(row);
          return true;
        }
      } else if (row[column.key] !== undefined && row[column.key] !== null) {
        const rowValue = String(row[column.key]).toLowerCase();
        if (
          rowValue.length >= searchQuery.length &&
          rowValue.indexOf(searchQuery.toLowerCase()) >= 0
        ) {
          filteredRows.push(row);
          return true;
        }
      }
      return false;
    });
  });
  return filteredRows;
}

export function sortBy(sortColumn, sortOrder) {
  const lowerCase = (value) =>
    typeof value === 'string' && isNaN(Number(value))
      ? value.toLowerCase()
      : value;
  const key = (row) =>
    lowerCase
      ? isNaN(Date.parse(lowerCase(row[sortColumn])))
        ? lowerCase(row[sortColumn])
        : new Date(lowerCase(row[sortColumn]))
      : row[sortColumn];
  const result = (row1, row2) =>
    (row1 < row2 ? -1 : row1 > row2 ? 1 : 0) *
    [-1, 1][Number(Boolean(sortOrder))];

  return function (a, b) {
    return result(key(a), key(b));
  };
}

export function getPagingRange(
  current,
  { min = 1, total = 20, length = 5 } = {}
) {
  if (length > total) length = total;

  let start = current - Math.floor(length / 2);
  start = Math.max(start, min);
  start = Math.min(start, min + total - length);

  return Array.from({ length }, (el, i) => start + i);
}
