/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useState, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import { toMoney } from '~/system/helper';
import * as UI from '../style';
import useController from './hook/useController';

const optionBenefit = (props) => {
  const { employeeInfo, fetch: getEmployeeInfo } = useController();

  const totalValue = (a, b) => {
    return a * b;
  };

  const calculate = (data) => {
    return data?.benefits
      ?.map((b) => b?.unit_cost * b?.quantity)
      ?.flat(1)
      ?.reduce((c, o) => c + o, 0);
  };

  const componentRef = useRef();

  useEffect(() => {
    getEmployeeInfo(
      props?.match?.params?.employeeId,
      props?.location?.state?.benefits ?? []
    );
  }, []);
  return (
    <div
      style={{
        background: '#fffefa',
      }}
    >
      {employeeInfo?.loading && (
        <div className="p-3 row justify-content-center align-items-center">
          <div className="col-12 text-center">
            <h4>Carregando...</h4>
          </div>
          <div className="col-4">
            <div className="progress">
              <div
                className="progress-bar bg-warning progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </div>
      )}
      {!employeeInfo?.loading && employeeInfo?.data && (
        <UI.containerV>
          <UI.container ref={componentRef}>
            <p style={{ paddingTop: '10px' }}>Gestão de Beneficio</p>
            <UI.Titulo>OPÇÃO DE VALE-TRANSPORTE</UI.Titulo>
            <p style={{ fontWeight: 'bold' }}>I - Dados do Empregador.</p>
            <br />
            <p style={{ fontWeight: 'normal' }}>
              Razão Social:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {employeeInfo?.data?.name}
              </span>
              &nbsp;&nbsp;CNPJ:
              <span style={{ fontWeight: 'bold' }}>
                {employeeInfo?.data?.cnpj}
              </span>
            </p>
            <br />
            <p style={{ fontWeight: 'bold' }}>II - Dados do Usuário.</p>
            <UI.Table>
              <UI.TableHead>
                <tr>
                  <UI.TableTH>Matrícula:</UI.TableTH>
                  <UI.TableTH>Nome Completo:</UI.TableTH>
                  <UI.TableTH>CPF:</UI.TableTH>
                  <UI.TableTH>RG:</UI.TableTH>
                  <UI.TableTH>Departamento:</UI.TableTH>
                  <UI.TableTH>Horário de trabalho:</UI.TableTH>
                </tr>
              </UI.TableHead>
              <tbody>
                <UI.TableTD>
                  {employeeInfo?.data?.employee?.registry}
                </UI.TableTD>
                <UI.TableTD>{employeeInfo?.data?.employee?.name}</UI.TableTD>
                <UI.TableTD>{employeeInfo?.data?.employee?.cpf}</UI.TableTD>
                <UI.TableTD>{employeeInfo?.data?.employee?.rg}</UI.TableTD>
                <UI.TableTD>
                  {employeeInfo?.data?.employee?.department}
                </UI.TableTD>
                <UI.TableTD>
                  {employeeInfo?.data?.employee?.workdays}
                </UI.TableTD>
              </tbody>
            </UI.Table>
            <p style={{ fontWeight: 'normal' }}>
              Endereco de residencia: <br />
              <span style={{ fontWeight: 'bold' }}>
                {employeeInfo?.data?.workStreet}
              </span>
            </p>
            <br />
            <p style={{ fontWeight: 'normal' }}>
              Endereco de trabalho: <br />
              <span style={{ fontWeight: 'bold' }}>
                {employeeInfo?.data?.employee?.street}
              </span>
            </p>
            <br />
            <p style={{ fontWeight: 'bold' }}>
              III - Tipo de condução, valor e quantidade utilizada diariamente
              para ida e volta do endereço residencial ao endereço de trabalho.
            </p>
            <UI.Table>
              <UI.TableHead>
                <tr style={{ fontWeight: 'bold' }}>
                  <UI.TableTH>Codigo:</UI.TableTH>
                  <UI.TableTH style={{ width: '40%', textAlign: 'center' }}>
                    Descrição do vale-transporte:
                  </UI.TableTH>
                  <UI.TableTH>Quantidade:</UI.TableTH>
                  <UI.TableTH>Valor unitário:</UI.TableTH>
                  <UI.TableTH>Total:</UI.TableTH>
                </tr>
              </UI.TableHead>
              <tbody>
                {employeeInfo?.data?.benefits.map((b) => (
                  <tr style={{ fontWeight: 'normal' }}>
                    <UI.TableTD>{b?.code}</UI.TableTD>
                    <UI.TableTD style={{ textAlign: 'center' }}>
                      {b?.description}
                    </UI.TableTD>
                    <UI.TableTD>{b?.quantity}</UI.TableTD>
                    <UI.TableTD>{toMoney(b?.unit_cost)}</UI.TableTD>
                    <UI.TableTD>
                      {toMoney(totalValue(b?.unit_cost, b?.quantity))}
                    </UI.TableTD>
                  </tr>
                ))}
              </tbody>
            </UI.Table>
            <hr />
            <UI.Table style={{ margin: '0px' }}>
              <UI.TableHead>
                <tr style={{ fontWeight: 'bold' }}>
                  <UI.TableTH />
                  <UI.TableTH />
                  <UI.TableTH />
                  <UI.TableTH />
                  <UI.TableTH />
                </tr>
              </UI.TableHead>
              <tbody>
                <tr style={{ fontWeight: 'normal' }}>
                  <UI.TableTD />
                  <UI.TableTD />
                  <UI.TableTD />
                  <UI.TableTD />
                  <UI.TableTD style={{ textAlign: 'center' }}>
                    Valor Total:&nbsp;&nbsp;
                    {toMoney(calculate(employeeInfo?.data))}
                  </UI.TableTD>
                </tr>
              </tbody>
            </UI.Table>

            <p style={{ padding: '5px' }}>
              IV - A presente declaração será renovada anualmente ou sempre que
              houver alteração de endereço residencial ou meio de transporte do
              empregado, sob pena de suspensão do benefício, ou até cumprimento
              da exigência.
            </p>
            <p style={{ padding: '5px' }}>
              V - O beneficiário compromete-se a utilizar o vale-transporte
              exclusivamente para o efetivo deslocamento residência-trabalho e
              vice-versa.
            </p>
            <p style={{ padding: '5px' }}>
              VI - A DECLARAÇÃO FALSA ou USO INDEVIDO do beneficio caracteriza
              rescisão do contrato individual de trabalho por justa causa, ato
              de improbidade, conforme CLT, artigo 482, A.
            </p>
            <UI.Table style={{ margin: '0px' }}>
              <UI.TableHead>
                <tr style={{ fontWeight: 'bold' }}>
                  <UI.TableTH />
                  <UI.TableTH />
                  <UI.TableTH />
                  <UI.TableTH />
                  <UI.TableTH />
                </tr>
              </UI.TableHead>
              <tbody>
                <tr style={{ fontWeight: 'normal' }}>
                  <UI.TableTD />
                  <UI.TableTD />
                  <UI.TableTD />
                  <UI.TableTD />
                  <UI.TableTD>
                    ___________________________,____________de___________________de____________.
                  </UI.TableTD>
                </tr>
              </tbody>
            </UI.Table>
            <UI.Table>
              <UI.TableHead>
                <tr style={{ fontWeight: 'bold' }}>
                  <UI.TableTH
                    style={{
                      borderBottom: '1px solid black',
                      width: '20%',
                      height: '40px',
                    }}
                  />
                  <UI.TableTH />
                  <UI.TableTH />
                  <UI.TableTH
                    style={{ borderBottom: '1px solid black', width: '20%' }}
                  />
                  <UI.TableTH />
                </tr>
              </UI.TableHead>
              <tbody>
                <tr style={{ fontWeight: 'normal' }}>
                  <UI.TableTD
                    style={{ textAlign: 'center', fontWeight: 'bold' }}
                  >
                    Assinatura do Funcionário
                  </UI.TableTD>
                  <UI.TableTD />
                  <UI.TableTD />
                  <UI.TableTD
                    style={{ textAlign: 'center', fontWeight: 'bold' }}
                  >
                    Assinatura do Responsavel/Carimbo
                  </UI.TableTD>
                </tr>
              </tbody>
            </UI.Table>
          </UI.container>
          <UI.containerB>
            <ReactToPrint
              trigger={() => <UI.buttonI>Imiprimir</UI.buttonI>}
              content={() => componentRef.current}
            />
          </UI.containerB>
        </UI.containerV>
      )}
    </div>
  );
};

export default optionBenefit;
