/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';

export const ContainerImg = styled.div`
  display: flex;
`;
export const Img = styled.img`
  margin-left: auto;
`;
/* textos */
export const H1 = styled.h1`
  font-size: 1.5rem;
`;

export const Text = styled.h1`
  font-size: 0.93rem;
  letter-spacing: normal;
  line-height: 20px;
`;
