import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';
import api from '~/services/api';
import { updateDepartment } from '~/store/modules/forms/FormDepartment/actions';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';
import { STATUS } from '~/system/enum';
import useForm from './useForm';

const useController = ({ departmentId: pDepartmentId, onSaved = null }) => {
  const dispatch = useDispatch();
  const { clean } = useForm();
  const { department } = useSelector((state) => state.formDepartment);

  const onSave = async (e) => {
    e.preventDefault();
    dispatch(showLoading());
    try {
      dispatch(
        updateDepartment({
          ...department,
          form: {
            saving: true,
          },
        })
      );

      const { data } = await api[pDepartmentId ? 'put' : 'post'](
        `/departments${pDepartmentId ? `/${pDepartmentId}` : ''}`,
        {
          code: department.code,
          name: department.name,
        }
      );

      if (data.error || data.message) throw data.error;
      toast.success(
        <BvsToastResult message={`'${data.name}' salvo com sucesso!`} />
      );
      clean();
      onSaved && onSaved(data);
    } catch (error) {
      toast.error(
        <BvsToastResult
          type="error"
          err={error}
          message="Falha ao salvar registro"
        />
      );
    } finally {
      dispatch(
        updateDepartment({
          form: {
            saving: false,
          },
        })
      );
      dispatch(hideLoading());
    }
  };

  const changeStatus = async (
    { id: departmentId, status_enum: status },
    cb
  ) => {
    try {
      const { data } = await api.put(`/departments/${departmentId}/status`);
      if (data.error || data?.message) throw data;
      toast.success(
        <BvsToastResult
          message={
            status === STATUS.ATIVO
              ? "Alterado para 'Inativo' com sucesso"
              : "Alterado para 'Ativo' com sucesso."
          }
        />
      );
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Falha ao atualizar o status"
        />
      );
    } finally {
      cb && cb();
    }
  };

  const deleteDepartment = async ({ id: departmentId }, cb) => {
    try {
      const { data } = await api.delete(`/departments/${departmentId}`);
      if (data.error || data.message) throw data;
      toast.success(
        <BvsToastResult message={`'${data.name}' excluido com sucesso!`} />
      );
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Falha ao excluir registro"
        />
      );
    } finally {
      cb && cb();
    }
  };

  return {
    onSave,
    changeStatus,
    deleteDepartment,
  };
};

export default useController;
