/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { AntDesign } from 'react-web-vector-icons';
import { toMoney } from '~/system/helper';

import BvsContainer from '~/components/BvsContainer';
import BvsPanel from '~/components/BvsPanel';
import SubHeader from '~/components/BvsSubHeader';
import BvsButton from '~/components/BvsButton';
import FooterReports from '~/pages/Reports/FooterReports';
import HeaderReports from '~/pages/Reports/HeaderReports';
import * as UI from './style';
import useController from './hooks/useController';

const ReportsByDepartment = ({ match }) => {
  const history = useHistory();
  const { reportData, getReportData } = useController();
  const { id } = match.params;

  const getResume = () => {
    const totalBenefit = reportData?.data?.departments
      .map((benefit) => benefit.quantity)
      .reduce((cur, old) => cur + old, 0);

    const totalValueBenefit = reportData?.data?.departments
      .map((benefit) => benefit.total)
      .reduce((cur, old) => cur + old, 0);

    const quantityDepartament = reportData?.data?.departments.length;

    return {
      totalBenefit,
      totalValueBenefit,
      quantityDepartament,
    };
  };

  useEffect(() => {
    getReportData(id);
  }, []);

  return (
    <>
      <SubHeader
        title="Relatório de Aquisição de Benefícios"
        subtitle="Por Departamento - Detalhado por Departamento"
      />
      <BvsContainer>
        <div>
          <BvsPanel title="Relatório de Aquisição de Benefícios Por Departamento - Detalhado por departmento">
            <BvsButton
              type="button"
              title="Voltar"
              onClick={() => {
                history.goBack();
              }}
            />
            <HeaderReports />
            {reportData.loading && (
              <div className="p-3 row justify-content-center align-items-center">
                <div className="col-12 text-center">
                  <h4>Carregando...</h4>
                </div>
                <div className="col-4">
                  <div className="progress">
                    <div
                      className="progress-bar bg-warning progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
              </div>
            )}
            {!reportData?.loading && !reportData?.data && (
              <div
                className="row middle-xs center-xs"
                style={{ padding: 60, textAlign: 'center' }}
              >
                <div className="col-md-12">
                  <h2>Não foi possivel encontrar registros</h2>
                  <h2 style={{ marginTop: '10px' }}>
                    <AntDesign name="frowno" />
                  </h2>
                </div>
              </div>
            )}
            {!reportData?.loading && reportData?.data && (
              <>
                <BvsContainer
                  style={{
                    display: 'flex',
                    margin: '40px 20px 5px',
                    borderTop: '1.4px solid rgba(0, 0, 0, 0.1)',
                    borderBottom: '1.4px solid rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <UI.Titulo> Data de Emissão </UI.Titulo>
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'flex',
                    margin: '5px 20px 5px',
                    borderBottom: '1.4px solid rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <UI.Data>{reportData?.data?.dateEmission}</UI.Data>
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'block',
                    marginLeft: '40px',
                    marginRight: '40px',
                    marginBottom: '40px',
                    padding: '0px',
                  }}
                >
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Razão Social</th>
                        <th scope="col" />
                        <th scope="col">Código</th>
                        <th scope="col">CNPJ</th>
                        <th scope="col">Inscrição Estadual</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row" colSpan="2">
                          {reportData?.data?.socialName}
                        </td>
                        <td>{reportData?.data?.id}</td>
                        <td>{reportData?.data?.cnpj}</td>
                        <td>{reportData?.data?.stateRegistration}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Endereço</th>
                        <th scope="col">CEP</th>
                        <th scope="col">Bairro</th>
                        <th scope="col">Municipio</th>
                        <th scope="col">Estado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row">{reportData?.data?.street}</td>
                        <td>{reportData?.data?.zipcode}</td>
                        <td>{reportData?.data?.district}</td>
                        <td>{reportData?.data?.city}</td>
                        <td>{reportData?.data?.state}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Contato</th>
                        <th scope="col" />
                        <th scope="col">Telefone</th>
                        <th scope="col">Email</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row" colSpan="2">
                          {reportData?.data?.ownerName}
                        </td>
                        <td>{reportData?.data?.ownerPhone}</td>
                        <td colSpan="2">{reportData?.data?.ownerEmail}</td>
                      </tr>
                    </tbody>
                  </table>
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'block',
                    marginLeft: '40px',
                    marginRight: '40px',
                    marginBottom: '40px',
                    padding: '0px',
                    borderTop: '1.4px solid rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Código</th>
                        <th scope="col">Departamento</th>
                        <th scope="col">Qtde.</th>
                        <th scope="col">Valor Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportData?.data?.departments?.map((items) => (
                        <tr key={items.departmentCode}>
                          <td scope="row">{items?.departmentCode}</td>
                          <td>{items?.departmentName}</td>
                          <td>{items?.quantity}</td>
                          <td>{toMoney(items?.total)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'block',
                    marginLeft: '40px',
                    marginRight: '40px',
                    marginBottom: '40px',
                    padding: '0px',
                  }}
                >
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <td
                          scope="row"
                          style={{
                            textAlign: 'right',
                            fontWeight: 'bold',
                          }}
                        >
                          Total Geral de Depto.
                        </td>
                        <td
                          style={{
                            fontWeight: 'normal',
                            textAlign: 'center',
                          }}
                        >
                          {getResume().quantityDepartament}
                        </td>
                      </tr>
                      <tr>
                        <td
                          scope="row"
                          style={{
                            textAlign: 'right',
                            fontWeight: 'bold',
                          }}
                        >
                          Total Geral de Qta. Beneficios
                        </td>
                        <td
                          style={{
                            fontWeight: 'normal',
                            textAlign: 'center',
                          }}
                        >
                          {getResume().totalBenefit}
                        </td>
                      </tr>
                      <tr>
                        <td
                          scope="row"
                          style={{
                            textAlign: 'right',
                            fontWeight: 'bold',
                          }}
                        >
                          Valor Total Geral de Beneficios
                        </td>
                        <td
                          style={{
                            fontWeight: 'normal',
                            textAlign: 'center',
                          }}
                        >
                          {toMoney(getResume().totalValueBenefit)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          scope="row"
                          style={{
                            textAlign: 'right',
                            fontWeight: 'bold',
                          }}
                        >
                          Valor Total
                        </td>
                        <td
                          style={{
                            fontWeight: 'normal',
                            textAlign: 'center',
                          }}
                        >
                          {toMoney(getResume().totalValueBenefit)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </BvsContainer>
              </>
            )}
            <FooterReports periodUse={reportData?.data?.periodUse} />
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default ReportsByDepartment;
