/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';

export const container = styled.div`
  background: #fffefa;
  display: block;
  margin: 10px auto;
  padding: 10px 40px;
  max-width: 1200px;
  color: #403f3e;
  font-weight: bold;
`;

export const containerV = styled.div`
  border-top: 3px solid #a81e21;
  border-bottom: 3px solid #a81e21;
  background: #fffefa;
  margin: 10px 0px;
  max-width: 100vw;
`;
export const containerB = styled.div`
  background: #fffefa;
  display: block;
  margin: 40px auto;
  max-width: 1200px;
`;
export const buttonI = styled.button`
  padding: 10px;
`;
export const Titulo = styled.p`
  font-size: 2rem;
  margin: 20px auto;
  text-align: center;
`;

export const Table = styled.table`
  width: 100%;
  margin: 10px;
`;
export const TableHead = styled.thead`
  font-weight: normal;
`;
export const TableTH = styled.th`
  padding: 10px 0 0 0;
  width: 10%;
`;
export const TableTD = styled.td`
  text-align: left;
  padding: 2px;
`;
