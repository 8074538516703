import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import BvsInput from '~/components/BvsInput';

import api from '~/services/api';
import Content from '../Login/Content';
import Footer from '../Login/Footer';
import Header from '../Login/Header';
import SuperFooter from '../Login/SuperFooter';
import {
  Button,
  Container,
  FormInline,
  FormSubTitle,
  FormTitle,
  InputGroup,
  Wrapper,
} from './style';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';

const HistoryCPF = () => {
  const history = useHistory();
  const [cpf, setCpf] = useState('');
  const [dateBirth, setDateBirth] = useState('');

  const onSearch = async () => {
    if (cpf === '') {
      toast.error(
        <BvsToastResult type="error" message="Necessario inserir o CPF!" />
      );
      return;
    }

    if (dateBirth === '') {
      toast.error(
        <BvsToastResult
          type="error"
          message="Necessario inserir a Data de nascimento!"
        />
      );
      return;
    }

    const { data } = await api.post('/history-by-cpf', {
      cpf,
      dateBirth,
    });

    if (!data) {
      toast.warn(
        <BvsToastResult type="warn" message="Nenhum registro encontrado!" />
      );
      return;
    }
    history.push(`/historyResult/${data.id}`);
  };
  return (
    <div>
      <Header />
      <Content
        style={{
          minHeight: '70vh',
        }}
      >
        <Wrapper style={{ maxWidth: '700px' }}>
          <Container>
            <div style={{ width: '90%' }}>
              <FormTitle>
                <i className="icon-login" style={{ color: '#7E898F' }} />
                Historico de compras
              </FormTitle>
              <FormSubTitle>
                Informe seu CPF e Data de nascimento para receber o historico de
                compras atrelado ao mesmo.
              </FormSubTitle>

              <FormInline className="d-none d-sm-flex">
                <InputGroup>
                  <div className="col-xs-12 col-sm-12 col-md-6 middle-xs">
                    <BvsInput
                      name="cpf"
                      required
                      label="CPF"
                      mask="cpf"
                      value={cpf}
                      onChange={(value) => setCpf(value)}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 middle-xs">
                    <BvsInput
                      name="dateBirth"
                      required
                      label="Data de nascimento"
                      mask="date"
                      value={dateBirth}
                      onChange={(value) => setDateBirth(value)}
                    />
                  </div>
                </InputGroup>
                <div>
                  <Button type="button" onClick={() => onSearch()}>
                    Buscar
                  </Button>
                </div>
              </FormInline>
            </div>
          </Container>
        </Wrapper>
      </Content>
      <SuperFooter />
      <Footer />
    </div>
  );
};

export default HistoryCPF;
