/* eslint-disable import/order */
/* eslint-disable no-unused-vars */

import 'moment/locale/pt-br';

import * as ENUM from '~/system/enum';
import * as UI from './style';

import React, { Fragment, useEffect, useState } from 'react';

import CalendarControl from './calendar-control';
import moment from 'moment';
import uuid from 'uuid-random';

const BvsCalendar = ({ OnDateClicked = () => {} }) => {
  const [cells, setCells] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(
    moment(new Date()).format('YYYY-MM-01 H:m:s')
  );

  const [events, setEvents] = useState({});
  const dayWeeks = Object.values(ENUM.DAYS_WEEK);

  // eslint-disable-next-line consistent-return
  const generateDates = (monthToShow = moment()) => {
    // Verifico se a data foi reconhecida como um moment
    if (!moment.isMoment(monthToShow)) return null;

    // Obtenha a primeira data do mes
    const dateStart = moment(monthToShow).startOf('month');

    // Obtenha o final do mes
    const dateEnd = moment(monthToShow).endOf('month');
    let cellsTmp = [];
    // Regressamos ate encontrar a ultima segunda-feira
    while (dateStart.day() !== ENUM.DAYS_WEEK.SUNDAY) {
      dateStart.subtract(1, 'days');
    }

    // // Encontrar o ultimo dia a mostrar no calendario
    while (dateEnd.day() !== ENUM.DAYS_WEEK.SATURDAY) {
      dateEnd.add(1, 'days');
    }

    do {
      cellsTmp.push({
        date: moment(dateStart),
        isInCurrentMonth: dateStart.month() === monthToShow.month(),
      });
      // Adicione um dia, caso contrario cria um loop

      dateStart.add(1, 'days');
    } while (dateStart.isSameOrBefore(dateEnd));

    setCells(cellsTmp);
  };

  const changeMonth = (next = false) => {
    let tmp = moment(currentMonth);
    if (next) tmp = tmp.add(1, 'months');
    else tmp = tmp.subtract(1, 'months');

    setCurrentMonth(tmp);
  };

  useEffect(() => {
    generateDates(moment(currentMonth));
  }, [currentMonth]);

  return (
    <UI.Calendar>
      <UI.CalendarHeader>
        <CalendarControl onClick={(status) => changeMonth(status)} />
        <UI.MonthName>{moment(currentMonth).format('MMMM YYYY')}</UI.MonthName>
        <CalendarControl next onClick={(status) => changeMonth(status)} />
      </UI.CalendarHeader>
      <UI.CalendarBody>
        <UI.Grid>
          <UI.GridHeader>
            {dayWeeks.map((dayWeekIndex, index) => (
              <UI.GridCellGh key={uuid()}>
                {moment().day(dayWeekIndex).format('ddd')}
              </UI.GridCellGh>
            ))}
          </UI.GridHeader>
          <UI.GridBody>
            {cells.map((day, index) => (
              <Fragment key={`day_${uuid()}`}>
                <UI.GridCellGd>
                  <UI.DayName>{day.date.date()}</UI.DayName>
                </UI.GridCellGd>
              </Fragment>
            ))}
          </UI.GridBody>
        </UI.Grid>
      </UI.CalendarBody>
    </UI.Calendar>
  );
};

export default BvsCalendar;
