import React from 'react';
import moment from 'moment';
import BvsModal from '~/components/BvsModal';
import BvsButton from '../../../components/BvsButton';
import { toMoney } from '../../../system/helper';

const ModalBenefitDetail = ({ benefitInfo, ...rest }) => {
  return (
    <BvsModal
      {...rest}
      title="Detalhes do Benefício"
      footer={() => (
        <div>
          <BvsButton title="Fechar" onClick={rest.onClose} />
        </div>
      )}
    >
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Operadora</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{benefitInfo?.benefitOperator?.name}</td>
          </tr>
        </tbody>
      </table>

      <table className="table table-hover">
        <thead>
          <tr>
            <th>Código</th>
            <th>Descrição</th>
            <th>Un</th>
            <th>Grupo</th>
            <th>Região</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{benefitInfo?.code}</td>
            <td>{benefitInfo?.name}</td>
            <td>{toMoney(benefitInfo?.unit_cost)}</td>
            <td>{benefitInfo?.benefitGroup}</td>
            <td>{benefitInfo?.region}</td>
          </tr>
        </tbody>
      </table>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Facial</th>
            <th>Blocado</th>
            <th>Unitário</th>
            <th>Periodo</th>
            <th>Cartão</th>
            <th>Dados Obrigatórios</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{benefitInfo?.facial === true ? 'Sim' : 'Não'}</td>
            <td>{benefitInfo?.voucher_type_block === true ? 'Sim' : 'Não'}</td>
            <td>{benefitInfo?.unit === true ? 'Sim' : 'Não'}</td>
            <td>{benefitInfo?.period === true ? 'Sim' : 'Não'}</td>
            <td>{benefitInfo?.required_data === true ? 'Sim' : 'Não'}</td>
            <td />
          </tr>
        </tbody>
      </table>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Custo 1ª Via</th>
            <th>Custo 2ª Via</th>
            <th>Custo por Crédito</th>
            <th>Min. 1º Crédito</th>
            <th>Min. Demais Crédito</th>
            <th>Máx. Demais Crédito</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{toMoney(benefitInfo?.cost_original) || ' - '}</td>
            <td>{toMoney(benefitInfo?.cost_duplicate) || ' - '}</td>
            <td>{toMoney(benefitInfo?.cost_per_credit) || ' - '}</td>
            <td>{toMoney(benefitInfo?.minimal_original_cost) || ' - '}</td>
            <td>{toMoney(benefitInfo?.minimal_others_cost) || ' - '}</td>
            <td>{toMoney(benefitInfo?.maximum_others_cost) || ' - '}</td>
          </tr>
        </tbody>
      </table>

      <table className="table table-hover">
        <thead>
          <tr>
            <th>Integração</th>
            <th>Tipo de Pagamento</th>
            <th>Prazo para Liberação dos Créditos</th>
            <th>Prazo para geração do Cartão na Operadora</th>
            <th>Status dos Cartões</th>
            <th>Custo/Condições para geração do Cartão</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{benefitInfo?.integration || ' - '}</td>
            <td>{benefitInfo?.payment_method || ' - '}</td>
            <td>{benefitInfo?.deadline_credit || ' - '}</td>
            <td>{benefitInfo?.deadline_card_operator || ' - '}</td>
            <td>{benefitInfo?.card_status || ' -  '}</td>
            <td>{benefitInfo?.cost_card_generation || ' - '}</td>
          </tr>
        </tbody>
      </table>

      <table className="table table-hover">
        <thead>
          <tr>
            <th>Tipo de Carta para Retirada do Cartão/Passes.</th>
            <th>Geração do Pedido</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{benefitInfo?.type_letter_card || ' - '}</td>
            <td>{benefitInfo?.type_order_generation || ' - '}</td>
          </tr>
        </tbody>
      </table>

      <table className="table table-hover">
        <thead>
          <tr>
            <th>Website</th>
            <th>Telefone</th>
            <th>E-mail</th>
            <th>Login</th>
            <th>Senha</th>
            <th>Data do Último Reajuste</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{benefitInfo?.website || ' - '}</td>
            <td>{benefitInfo?.phone || ' - '}</td>
            <td>{benefitInfo?.email || ' - '}</td>
            <td>{benefitInfo?.login || ' - '}</td>
            <td>{benefitInfo?.password || ' - '}</td>
            <td>
              {(benefitInfo?.date_last_adjustment &&
                moment(benefitInfo?.date_last_adjustment).format(
                  'DD/MM/YYYY'
                )) ||
                ' - '}
            </td>
          </tr>
        </tbody>
      </table>
    </BvsModal>
  );
};

export default ModalBenefitDetail;
