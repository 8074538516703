import { combineReducers } from 'redux';

import auth from './auth/reducer';
import enterprises from './enterpriseActive/reducer';
import loader from './loader/reducer';
import app from './app/reducer';
import home from './home/reducer';
/**
 * ANCHOR
 * @description: Responsável pelos forms
 *
 */
// #region - Forms
import formLogin from './forms/FormLogin/reducer';
import formEnterprise from './forms/FormEnterprise/reducer';
import formDepartment from './forms/FormDepartment/reducer';
import formJob from './forms/FormJob/reducer';
import formOperator from './forms/FormOperator/reducer';
import formOperatorBenefit from './forms/FormOperatorBenefit/reducer';
import formImport from './forms/FormImport/reducer';
import formImportOrders from './forms/FormImportOrder/reducer';
import formChangePassword from './forms/FormChangePassword/reducer';
import formUsers from './forms/FormUsers/reducer';
import formWorkday from './forms/FormWorkday/reducer';
import formEmployees from './forms/FormEmployees/reducer';
import formEmployeeBenefits from './forms/FormEmployeeBenefits/reducer';
import formAdministrativeCard from './forms/FormAdministrativeCard/reducer';
import formShippingAddress from './forms/FormShippingAddress/reducer';
import formPermission from './forms/FormPermission/reducer';
/** FIM FORM */
// #endregion
/**
 * ANCHOR
 * @description: Responsável pelos reducers de filtro
 */
// #region -- Filters
import filterAdministrativeCard from './filter/FilterAdministrativeCard/reducer';
import filterEmployees from './filter/FilterEmployees/reducer';
import filterExport from './filter/FilterExport/reducer';
import filterOrders from './filter/FilterOrders/reducer';
import filterSearchBenefits from './filter/FilterSearchBenefits/reducer';
import filterCreateOrder from './filter/FilterCreateOrders/reducer';
// #endregion --

/**
 * ANCHOR Fluxos & Rotinas
 * @description Responsável pelos reducers de rotinas & fluxos
 */
// #region Fluxos & Rotinas
import order from './order/reducer';
import orderCreate from './order/create/reducer';
import orderDetail from './order/detail/reducer';
import orderCancel from './order/cancel/reducer';
import benefit from './benefit/reducer';
// #endregion
import employee from './employee/reducer';

export const appReducer = combineReducers({
  auth,
  enterprises,
  loader,
  app,
  home,
  formLogin,
  formOperator,
  formOperatorBenefit,
  formEnterprise,
  formDepartment,
  formJob,
  formUsers,
  formWorkday,
  formImport,
  formEmployeeBenefits,
  formEmployees,
  formChangePassword,
  formAdministrativeCard,
  formShippingAddress,
  formPermission,
  filterEmployees,
  filterAdministrativeCard,
  filterExport,
  filterOrders,
  filterSearchBenefits,
  filterCreateOrder,
  order,
  orderCreate,
  orderDetail,
  formImportOrders,
  orderCancel,
  employee,
  benefit,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }

  if (action.type === 'CHANGE_ENTERPRISE') {
    Object.keys(state).forEach((key) => {
      if (!['app', 'auth', 'enterprises'].includes(key)) {
        state[key] = undefined;
      }
    });
  }

  return appReducer(state, action);
};

export default rootReducer;
