/* eslint-disable import/order */

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import React from 'react';

import AdministrativeCard from '~/pages/AdministrativeCard';
import City from '~/pages/Cities';
import CompleteOrders from '~/pages/Order/Checkout';
import CreateAdministrativeCard from '~/pages/CreateAdministrativeCard';
import CreateEmployees from '~/pages/CreateEmployees';
import CreateOrder from '~/pages/Order/CreateOrder';
import Department from '~/pages/Department';
import Employees from '~/pages/Employees';
import Enterprise from '~/pages/Enterprise';
import EnterpriseList from '~/pages/EnterpriseList';
import ExportDownload from '~/pages/ExportRegister';
import Group from '~/pages/Group';
import Home from '~/pages/Home';
import ImportGeneralRegisters from '~/pages/ImportGeneralRegisters';
import EmployeeBenefits from '~/pages/EmployeesBenefits';
import Job from '~/pages/Job';
import Layout from '../layouts/main/index';
import ListOrders from '~/pages/ListOrders';
import ReceiptPurchase from '~/pages/ReceiptPurchase';
import Login from '~/pages/Login/index';
import Occurrence from '~/pages/Occurrence';
import OcorrenceDetails from '~/pages/Occurrence/detail';
import Operator from '~/pages/Operator';
import Benefit from '~/pages/Benefit';
import Permissions from '~/pages/Permissions';
import ProtocolsReceipts from '~/pages/ProtocolsReceipts';
import PurchaseHistoric from '~/pages/Reports/PurchaseHistoric';
import Region from '~/pages/Region';
import ChangePassword from '~/pages/ChangePassword';
import ReportsByAddress from '~/pages/Reports/ReportsByAddress';
import ReportsByBenefits from '~/pages/Reports/ReportsByBenefits';
import ReportsByDepartment from '~/pages/Reports/ReportsByDepartment';
import ReportsByDepartmentPerBenefits from '~/pages/Reports/ReportsByDepartmentPerBenefits';
import ReportsByDepartmentPerUsers from '~/pages/Reports/ReportsByDepartmentPerUsers';
import ReportsByStatus from '~/pages/Reports/ReportsByStatus';
import ReportsByStatusPerUsers from '~/pages/Reports/ReportsByStatusPerUsers';
import ReportsByUsers from '~/pages/Reports/ReportsByUsers';
import ShippingAddress from '~/pages/ShippingAddress';
import Shortcuts from '~/pages/Shortcuts';
import State from '~/pages/States';
import User from '~/pages/User';
import CreateOrdersByImport from '~/pages/CreateOrderByImport';
import VerifyCreateOrder from '~/pages/Order/Detail';
import WorkedDay from '~/pages/WorkedDays';
import OptionBenefit from '~/pages/CreateEmployees/OptionBenefit';
import HistoryCPF from '~/pages/HistoryCPF';
import { navigationRef } from './rootNavigation';
import { useSelector } from 'react-redux';
import ReportSelector from '../pages/Reports/ReportSelector';
import ReportPurchaseHistoricWithoutAuth from '~/pages/HistoryCPF/historyCpfResult';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { token } = useSelector((state) => state.auth);
  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};

const Router = () => {
  return (
    <BrowserRouter ref={navigationRef}>
      <Switch>
        <Route exact path="/" component={Login} />

        <PrivateRoute
          path="/home"
          component={() => <Layout screen={<Home />} />}
        />
        <PrivateRoute
          path={['/departments/:id', '/departments']}
          component={(props) => (
            <Layout {...props} screen={<Department {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/jobs/:id', '/jobs']}
          component={(props) => (
            <Layout {...props} screen={<Job {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/workedDays/:id', '/workedDays']}
          component={(props) => (
            <Layout {...props} screen={<WorkedDay {...props} />} />
          )}
        />
        <PrivateRoute
          path="/cities"
          component={() => <Layout screen={<City />} />}
        />
        <PrivateRoute
          path="/states"
          component={() => <Layout screen={<State />} />}
        />
        <PrivateRoute
          path={['/regions/:id', '/regions']}
          component={(props) => (
            <Layout {...props} screen={<Region {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/groups/:id', '/groups']}
          component={(props) => (
            <Layout {...props} screen={<Group {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/enterprises/:id', '/enterprises']}
          component={(props) => (
            <Layout {...props} screen={<Enterprise {...props} />} />
          )}
        />
        <PrivateRoute
          path={[
            '/enterprise/:enterpriseId/shippingAddress/:id',
            '/enterprise/:enterpriseId/shippingAddress',
          ]}
          component={(props) => (
            <Layout {...props} screen={<ShippingAddress {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/enterpriseList/:id', '/enterpriseList']}
          component={(props) => (
            <Layout {...props} screen={<EnterpriseList {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/orders/create']}
          component={(props) => (
            <Layout {...props} screen={<CreateOrder {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/orders/receiptPurchase/:id', '/orders/receiptPurchase']}
          component={(props) => (
            <Layout {...props} screen={<ReceiptPurchase {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/order/detail/:id', '/order/detail']}
          component={(props) => (
            <Layout {...props} screen={<VerifyCreateOrder {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/orders/checkout/:id', '/orders/checkout']}
          component={(props) => (
            <Layout {...props} screen={<CompleteOrders {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/orders']}
          component={(props) => (
            <Layout {...props} screen={<ListOrders {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/benefit/:id', '/benefit']}
          component={(props) => (
            <Layout {...props} screen={<Benefit {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/operator/:id', '/operator']}
          component={(props) => (
            <Layout {...props} screen={<Operator {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/occurrence/:id', '/occurrence']}
          component={(props) => (
            <Layout {...props} screen={<Occurrence {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/shortcuts/:id', '/shortcuts']}
          component={(props) => (
            <Layout {...props} screen={<Shortcuts {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/permissions/:id', '/permissions']}
          component={(props) => (
            <Layout {...props} screen={<Permissions {...props} />} />
          )}
        />

        <PrivateRoute
          path={[
            '/employee/:employeeId/benefits/:benefitId',
            '/employee/:employeeId/benefits',
          ]}
          component={(props) => (
            <Layout {...props} screen={<EmployeeBenefits {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/administrativeCard/:id', '/administrativeCard']}
          component={(props) => (
            <Layout {...props} screen={<AdministrativeCard {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/createAdministrativeCard/:id', '/createAdministrativeCard']}
          component={(props) => (
            <Layout
              {...props}
              screen={<CreateAdministrativeCard {...props} />}
            />
          )}
        />
        <PrivateRoute
          path={['/users/:id', '/users']}
          component={(props) => (
            <Layout {...props} screen={<User {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/employees/:id', '/employees']}
          component={(props) => (
            <Layout {...props} screen={<Employees {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/importGeneralRegisters/:id', '/importGeneralRegisters']}
          component={(props) => (
            <Layout {...props} screen={<ImportGeneralRegisters {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/exportDownload/:id', '/exportDownload']}
          component={(props) => (
            <Layout {...props} screen={<ExportDownload {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/createEmployees/:id', '/createEmployees']}
          component={(props) => (
            <Layout {...props} screen={<CreateEmployees {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/protocolsReceipts/:id', '/protocolsReceipts']}
          component={(props) => (
            <Layout {...props} screen={<ProtocolsReceipts {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/occurrenceDetails/:id', '/occurrenceDetails']}
          component={(props) => (
            <Layout {...props} screen={<OcorrenceDetails {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/reportsbybenefits/:id', '/reportsbybenefits']}
          component={(props) => (
            <Layout {...props} screen={<ReportsByBenefits {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/reportsbydepartment/:id', '/reportsbydepartment']}
          component={(props) => (
            <Layout {...props} screen={<ReportsByDepartment {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/reportsbyusers/:id', '/reportsbyusers']}
          component={(props) => (
            <Layout {...props} screen={<ReportsByUsers {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/reportsbyaddress/:id', '/reportsbyaddress']}
          component={(props) => (
            <Layout {...props} screen={<ReportsByAddress {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/reportsbystatusperusers/:id', '/reportsbystatusperusers']}
          component={(props) => (
            <Layout
              {...props}
              screen={<ReportsByStatusPerUsers {...props} />}
            />
          )}
        />
        <PrivateRoute
          path={['/reportsbystatus/:id', '/reportsbystatus']}
          component={(props) => (
            <Layout {...props} screen={<ReportsByStatus {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/purchaseHistoric/:id', '/purchaseHistoric']}
          component={(props) => (
            <Layout {...props} screen={<PurchaseHistoric {...props} />} />
          )}
        />
        <Route
          path={['/historyResult/:id', '/historyResult']}
          component={(props) => (
            <ReportPurchaseHistoricWithoutAuth {...props} />
          )}
        />
        <PrivateRoute
          path={[
            '/reportsbydepartmentperusers/:id',
            '/reportsbydepartmentperusers',
          ]}
          component={(props) => (
            <Layout
              {...props}
              screen={<ReportsByDepartmentPerUsers {...props} />}
            />
          )}
        />
        <PrivateRoute
          path={[
            '/reportsbydepartmentperbenefits/:id',
            '/reportsbydepartmentperbenefits',
          ]}
          component={(props) => (
            <Layout
              {...props}
              screen={<ReportsByDepartmentPerBenefits {...props} />}
            />
          )}
        />
        <PrivateRoute
          path={['/report/selector/:type']}
          component={(props) => (
            <Layout {...props} screen={<ReportSelector {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/createOrdersByImport/:id', '/createOrdersByImport']}
          component={(props) => (
            <Layout {...props} screen={<CreateOrdersByImport {...props} />} />
          )}
        />
        <PrivateRoute
          path={['/changePassword']}
          component={(props) => (
            <Layout {...props} screen={<ChangePassword {...props} />} />
          )}
        />
        <PrivateRoute
          path="/optionBenefit/:employeeId"
          component={(props) => <OptionBenefit {...props} />}
        />
        <Route
          path="/historyByCpf"
          component={(props) => <HistoryCPF {...props} />}
        />

        {/* <Route
          path={['/historyByCpfResult/:id', '/historyByCpfResult']}
          component={(props) => (
            <Layout {...props} screen={<HistoryCPFResult {...props} />} />
          )}
        /> */}
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
