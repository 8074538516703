import styled from 'styled-components';
import constants from '~/system/constants';

export const Container = styled.div`
  max-width: ${constants.containerSize.xxl};
  margin: 0 auto;
  padding: 15px 0;

  @media (max-width: 1400px) {
    max-width: ${constants.containerSize.xl};
  }
  @media (max-width: 1200px) {
    max-width: ${constants.containerSize.lg};
  }
  @media (max-width: 992px) {
    max-width: ${constants.containerSize.md};
  }
  @media (max-width: 768px) {
    max-width: ${constants.containerSize.sm};
  }
  @media (max-width: 576px) {
    max-width: ${constants.containerSize.xs};
  }
`;
export const Header = styled.header`
  background: #fff;
`;
export const EnterpriseLogo = styled.img``;
