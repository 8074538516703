import { useState } from 'react';
import { ORDER_ENVELOPE } from '~/system/enum';

const useEnvelope = () => {
  const [orderEnvelope] = useState({
    loading: false,
    options: [
      { title: 'Nome / Departamento', value: ORDER_ENVELOPE.NOME_DEPARTAMENTO },
      { title: 'Nome / Matricula', value: ORDER_ENVELOPE.NOME_MATRICULA },
      { title: 'Apenas Nome', value: ORDER_ENVELOPE.NOME },
    ],
  });
  return {
    orderEnvelope,
  };
};

export default useEnvelope;
