import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  components: {
    lastLogin: false,
  },
};

function home(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_HOME: {
      return produce(state, (draft) => {
        draft = { ...draft, ...action.payload };
        return draft;
      });
    }
    default:
      return state;
  }
}

export default home;
