/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable import/order */
/* eslint-disable no-param-reassign */

import { RenderStatusIconButton } from '~/system/helper';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import uuid from 'uuid-random';
import BvsButton from '~/components/BvsButton';
import BvsButtonGroup from '~/components/BvsButtonGroup';
import BvsContainer from '~/components/BvsContainer';
import BvsDropdown from '~/components/BvsDropdown';
import BvsForm from '~/components/BvsForm';
import BvsInput from '~/components/BvsInput';
import BvsPanel from '~/components/BvsPanel';
import { FontAwesome } from 'react-web-vector-icons';
import SubHeader from '~/components/BvsSubHeader';
import { useHistory, useLocation } from 'react-router-dom';
import { STATUS, USER_TYPE } from '~/system/enum';
import { useSelector, useDispatch } from 'react-redux';
import { useSelectedItems } from '../../hooks/useSelectedItems';
import useFilter from './hooks/useFilter';
import { Item, Menu, useContextMenu } from 'react-contexify';
import useController from './hooks/useController';
import useShippingAddress from '~/hooks/useShippingAddress';
import BvsAdmButton from '~/components/BvsAdmButton';
import useWorkdays from '~/hooks/useWorkday';
import useDepartments from '~/hooks/useDepartments';
import useStatus from '~/hooks/useStatus';
import ShowBenefitsModal from './modal/ShowBenefits';
import {
  updateListEmployee,
  historicEmployee,
} from '~/store/modules/app/actions';
import HistoricEmployee from './modal/Historic';
import BvsTableV4 from '../../components/BvsTableV4';
import { resetEmployeeFilter } from '~/store/modules/filter/FilterEmployees/actions';

const Employees = () => {
  const menuId = uuid();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    listEmployee: { components },
    historicEmployee: {
      components: historicEmployeeComponents,
      state: historicEmployeeState,
    },
  } = useSelector((state) => state.app);
  const { query, filter } = useSelector((state) => state.filterEmployees);
  const { departments, fetch: getDepartments } = useDepartments({
    loading: false,
  });
  const { user } = useSelector((state) => state.auth);
  const { workdays, fetch: getWorkdays } = useWorkdays();
  const { shippingAddress, fetch: getShippingAddress } = useShippingAddress();
  const { enterpriseActive } = useSelector((state) => state.enterprises);
  const history = useHistory();
  const { status } = useStatus();
  const { show } = useContextMenu({});
  const [menuData, setMenuData] = useState(null);

  const setComponents = (component, state) => {
    dispatch(
      updateListEmployee({
        components: { ...components, [component]: state },
      })
    );
  };

  const setBenefitInfo = (name, value) => {
    dispatch(
      updateListEmployee({
        [name]: value,
      })
    );
  };

  const {
    getColumns,
    onDelete,
    changeMultipleStatus,
    changeStatus,
    fetch: getEmployees,
    onEdit,
    employees,
    applyFilter,
  } = useController();

  const { onFieldChange } = useFilter();

  const { selectedItems, onSelect } = useSelectedItems({
    rows: employees.options,
    key: 'id',
  });

  useEffect(() => {
    // fetchEmployees();
    if (enterpriseActive?.info?.id) {
      getDepartments();
      getWorkdays();
      getShippingAddress();
    }
    getEmployees();
  }, []);

  useEffect(() => {
    const cleanup = () => {
      // Lógica para identificar se a rota mudou antes do componente ser desmontado
      dispatch(resetEmployeeFilter());
    };

    // Cleanup será chamado quando o componente for desmontado
    return cleanup;
  }, [location]);

  return (
    <>
      <SubHeader
        title="Cadastro de Funcionarios"
        subtitle="que utilizarão os beneficio da empresa"
      />
      <BvsContainer>
        {user?.type === USER_TYPE.ADMIN && enterpriseActive?.info?.id && (
          <BvsAdmButton />
        )}
        <div className="col-md-12">
          <BvsPanel title="Filtros">
            <BvsForm onSubmit={applyFilter}>
              <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="registry"
                    label="Matrícula"
                    value={query.registry}
                    onChange={(value) => onFieldChange('registry', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="name"
                    label="Nome"
                    value={query.name}
                    onChange={(value) => onFieldChange('name', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="cpf"
                    label="CPF"
                    mask="cpf"
                    value={query.cpf}
                    onChange={(value) => onFieldChange('cpf', value)}
                  />
                </div>
                {enterpriseActive?.info?.id && (
                  <>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                      <BvsDropdown
                        title="Selecione um Departamento..."
                        name="department_id"
                        label="Departamento"
                        options={departments.options}
                        loading={departments.loading}
                        value={query.department_id}
                        onSelectedItem={(value) =>
                          onFieldChange('department_id', value)
                        }
                        style={{
                          marginTop: '-5px',
                          float: 'right',
                        }}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                      <BvsDropdown
                        title="Selecione um Período..."
                        name="workday_id"
                        label="Período"
                        options={workdays.options}
                        loading={workdays.loading}
                        value={query.workday_id}
                        onSelectedItem={(value) =>
                          onFieldChange('workday_id', value)
                        }
                        style={{
                          marginTop: '-5px',
                          float: 'right',
                        }}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                      <BvsDropdown
                        title="Selecione um Endereço..."
                        name="shipping_address_id"
                        label="Endereços"
                        options={shippingAddress.options}
                        loading={shippingAddress.loading}
                        value={query.shipping_address_id}
                        onSelectedItem={(value) =>
                          onFieldChange('shipping_address_id', value)
                        }
                        style={{
                          marginTop: '-5px',
                          float: 'right',
                        }}
                      />
                    </div>
                  </>
                )}
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <label>Contratado em:</label>
                  <input
                    name="admission_date"
                    type="date"
                    value={query.admission_date}
                    onChange={(e) => {
                      let value = e.target.value || '';
                      onFieldChange('admission_date', value);
                    }}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <label>Contratado até:</label>
                  <input
                    name="demission_date"
                    type="date"
                    value={query.demission_date}
                    onChange={(e) => {
                      let value = e.target.value || '';
                      onFieldChange('demission_date', value);
                    }}
                  />
                </div>
                <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 p-3">
                  <BvsButtonGroup
                    name="status"
                    label="Filtrados Por"
                    value={query.status}
                    onChange={onFieldChange}
                    options={status.options}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsButton
                    type="submit"
                    title="Filtrar"
                    icon="fa fa-filter"
                    disabled={filter.filtering}
                  />
                </div>
              </div>
            </BvsForm>
          </BvsPanel>
        </div>

        <div className="col-md-12">
          <BvsPanel title="Resultado do Filtro">
            <div className="col-md-12">
              <BvsTableV4
                tableKey="employees"
                columns={getColumns()}
                rows={employees.options}
                onSelect={onSelect}
                selectedItems={selectedItems}
                rowKey={(r) => r.id}
                onRefresh={() => {
                  getEmployees();
                }}
                actionOptions={{ width: '225px' }}
                actions={[
                  (row) => (
                    <span data-tip="Ver Detalhes">
                      <BvsButton
                        type="button"
                        customStyle={{ marginRight: '5px' }}
                        className="btn btn-xs"
                        icon={
                          <FontAwesome name="edit" color="#fff" size={12} />
                        }
                        onClick={() => onEdit(row)}
                      />
                    </span>
                  ),
                  (row) => (
                    <span data-tip="Excluir">
                      <BvsButton
                        type="button"
                        className="btn btn-xs"
                        customStyle={{ marginRight: '5px' }}
                        icon={
                          <FontAwesome name="trash" color="#fff" size={12} />
                        }
                        onClick={() => onDelete(row.id)}
                      />
                    </span>
                  ),
                  (row) => {
                    return (
                      <>
                        <span data-tip="Mais Opções">
                          <BvsButton
                            type="button"
                            className="btn btn-xs"
                            customStyle={{ marginRight: '5px' }}
                            icon={
                              <FontAwesome
                                name="ellipsis-h"
                                color="#fff"
                                size={12}
                              />
                            }
                            onClick={(e) => {
                              setMenuData(row);
                              show(e, { id: menuId, props: row });
                            }}
                          />
                        </span>
                      </>
                    );
                  },
                ]}
              />
            </div>
            <div className="d-flex flex-wrap">
              <div className="px-1 py-1 text-nowrap">
                <BvsButton
                  type="button"
                  title="Inativos"
                  icon="fa fa-thumbs-down"
                  onClick={() => changeMultipleStatus(selectedItems, false)}
                  tooltip="Inativar Selecionados"
                />
              </div>
              <div className="px-1 py-1 text-nowrap">
                <BvsButton
                  type="button"
                  title="Ativos"
                  icon="fa fa-thumbs-up"
                  tooltip="Ativar Selecionados"
                  onClick={() => changeMultipleStatus(selectedItems, true)}
                />
              </div>
              {/* <div className="px-1 py-1 text-nowrap">
                <BvsButton type="button" title="Cancelar" icon="fa fa-ban" />
              </div> */}
              {/* <div className="px-1 py-1 text-nowrap">
                <BvsButton type="button" title="Excluir" icon="fa fa-trash" />
              </div> */}
              {/* <div className="px-1 py-1 text-nowrap">
                <BvsButton type="button" title="Imprimir" icon="fa fa-print" />
              </div> */}
              {/* <div className="px-1 py-1 text-nowrap">
                <BvsButton
                  type="button"
                  title="Excel"
                  icon="fa fa-file-excel-o"
                  onClick={async () => {
                    DownloadFile(
                      '/benefitsEmployees/export/excel',
                      'benvs_exportacao_departamento.xlsx'
                    );
                  }}
                />
              </div> */}

              <div className="px-1 py-1 text-nowrap">
                <BvsButton
                  type="button"
                  title="Incluir Funcionario"
                  icon="fa fa-plus"
                  onClick={() => history.push(`/createEmployees`)}
                />
              </div>
            </div>
          </BvsPanel>
        </div>
      </BvsContainer>
      <ShowBenefitsModal />
      <HistoricEmployee />
      <Menu id={menuId}>
        {menuData && (
          <>
            <Item key="status" onClick={() => changeStatus(menuData.id)}>
              {RenderStatusIconButton(menuData.status, '#a81e21')}
              &nbsp;
              {menuData.status === STATUS.ATIVO ? 'Inativar' : 'Ativar'}
            </Item>
            <Item
              key="showCards"
              onClick={() => {
                setBenefitInfo('detail', menuData);
                setComponents('showBenefit', true);
              }}
            >
              <FontAwesome name="list-alt" size={12} color="#a81e21" />
              &nbsp; Ver Benefícios
            </Item>
            <Item
              key="historic"
              onClick={() => {
                dispatch(
                  historicEmployee('update', {
                    components: {
                      ...historicEmployeeComponents,
                      modalVisible: true,
                    },
                    state: {
                      ...historicEmployeeState,
                      employeeId: menuData.id,
                      loading: true,
                    },
                  })
                );
                dispatch(historicEmployee('fetch'));
              }}
            >
              <FontAwesome name="history" color="#a81e21" size={12} />
              &nbsp; Historico de Mudanças
            </Item>
          </>
        )}
      </Menu>
    </>
  );
};

export default Employees;
