import React from 'react';
import { useSelector } from 'react-redux';
import { WallCardBody, WallCardTitle, WallCardWrapper } from './style';

const WallCard = () => {
  const { user } = useSelector((state) => state.auth);
  return (
    <WallCardWrapper>
      <WallCardTitle>Olá {user?.name}</WallCardTitle>
      <WallCardBody>
        <p>
          Seja bem-vindo ao portal de pedidos do Serviço de&nbsp;
          <strong>Gestão de Vale-Transporte</strong>
        </p>
        <br />
        <p>
          Nosso objetivo é atendê-lo com mais eficiência e agilidade. Por isso,
          disponibilizamos um canal de atendimento para suporte a pedidos de
          vale-transporte, onde você acessa informações sobre realização de
          pedidos, cadastramento de unidade de entrega e outras dúvidas sobre o
          sistema de pedidos.
        </p>
        <br />

        <p>
          <strong>
            Central de Atendimento e Suporte a Pedidos de Vale-Transporte:
            Atendimento de segunda à sexta-feira, das 08h às 19h.
          </strong>
        </p>
      </WallCardBody>
    </WallCardWrapper>
  );
};

export default WallCard;
