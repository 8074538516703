/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const BvsButton = styled.button`
  padding: ${({ small }) =>
    small ? '5px !important' : '8.5px 20px !important'};
  border-radius: 0 !important;
  background-color: ${({ disabled }) => (disabled ? '#D9D9D9' : '#beddcd')};
  text-shadow: none;
  box-shadow: none;
  color: #fff;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ disabled }) => (disabled ? '#D9D9D9' : '#beddcd')};
  border-image: initial;
  background-image: none;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transform: scale(1);
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

  :active,
  :focus,
  :visited {
    outline: 0;
  }
  &:hover,
  &:hover * {
    outline: 0;
    color: #fff !important;
    border-color: ${({ disabled }) =>
      disabled ? '#D9D9D9' : 'rgb(168, 30, 33)'};
    /* border: solid 2px #a81e21; */
    background-color: ${({ disabled }) =>
      disabled ? '#D9D9D9' : 'rgb(168, 30, 33)'};
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transform: scale(0.98);
  }
`;
