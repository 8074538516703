/* eslint-disable no-return-assign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toMoney } from '~/system/helper';
import { STATUS_FINANCIAL, STATUS_OPERATIONAL, USER_TYPE } from '~/system/enum';
import SubHeader from '~/components/BvsSubHeader';
import BvsContainer from '~/components/BvsContainer';
import BvsPanel from '~/components/BvsPanel';
import BvsButton from '~/components/BvsButton';
import BvsDropdown from '~/components/BvsDropdown';
import BvsAdmButton from '~/components/BvsAdmButton';
import BvsForm from '~/components/BvsForm';
import BvsInput from '~/components/BvsInput';
import ButtomPaginate from './buttomPaginate';
import * as UI from '../style';
import { updateOrder } from '../../../store/modules/order/actions';
import { mask } from 'remask';
import * as actions from '~/store/modules/order/detail/actions';
import useOrderBy from './hooks/useOrderBy';
import usePagination from './hooks/usePagination';
import useFilter from './hooks/useFilter';
import useChangeDays from './hooks/useChangeDays';
import useHelper from './hooks/useHelper';
import useController from './hooks/useController';
import produce from 'immer';

const VerifyPlaceOrders = ({ match }) => {
  const { id } = match.params;
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);

  // #region Redux
  const { user } = useSelector((state) => state.auth);
  const { order } = useSelector((state) => state.order);
  const { orderBy, pagination } = useSelector((state) => state.orderDetail);
  // #endregion

  // #region hooks
  const { orderBy: optOrderBy, applyOrderBy } = useOrderBy();
  const { pagination: optPagination, applyPagination } = usePagination();
  const { filter, applyFilter } = useFilter();
  const { onPopulate } = useController({
    orderId: id,
  });
  const {
    changeDays,
    onChange: onChangeDays,
    updateMonthDaysSelected,
  } = useChangeDays({ selecteds: selectedItems, setSelectedItems });
  const { checkout, groupBy, getSubtotal, getTransferAmount } = useHelper({
    id,
  });

  // #endregion
  const history = useHistory();

  useEffect(() => {
    onPopulate();
  }, [id]);

  return (
    <>
      <SubHeader
        title="Fazer Pedido Integral"
        subtitle="Personalizado por usuario"
      />
      <BvsContainer>
        {user?.type === USER_TYPE.ADMIN && <BvsAdmButton />}
        <div className="col-md-12">
          <BvsPanel title="Listagem do colaboradores selecionados">
            <BvsForm onSubmit={false}>
              <div className="row">
                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                  <BvsDropdown
                    title="Selecione a quantidade..."
                    name="paginate"
                    label="Visualização"
                    loading={optPagination.loading}
                    options={optPagination.options}
                    onSelectedItem={(value) =>
                      dispatch(
                        actions.updatePaginate({
                          ...pagination,
                          limit: value,
                        })
                      )
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                  <BvsDropdown
                    title="Selecione a ordem..."
                    name="orderList"
                    label="Ordem de Listagem"
                    loading={optOrderBy.loading}
                    options={optOrderBy.options}
                    onSelectedItem={(value) =>
                      dispatch(
                        actions.updateOrderBy({
                          ...orderBy,
                          type: value,
                        })
                      )
                    }
                  />
                </div>
                <div
                  className="col-xs-12 col-sm-3 col-md-3 col-lg-2"
                  style={{ marginTop: 'auto' }}
                >
                  <BvsInput
                    name="search"
                    label="Buscar"
                    value={filter.query}
                    onChange={(value) => {
                      dispatch(
                        actions.updateFilter({
                          ...filter,
                          query: value,
                        })
                      );
                    }}
                  />
                </div>
                <div
                  className=" col-xs-12 col-sm-3 col-md-2 col-lg-2"
                  style={{ marginTop: 'auto' }}
                >
                  <BvsInput
                    name="name"
                    label="Alterar dias"
                    onChange={onChangeDays}
                    value={changeDays}
                  />
                </div>
                <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2 align-items-end justify-content-start d-flex">
                  <BvsButton
                    type="button"
                    title="Alterar"
                    icon="fa fa-edit"
                    onClick={updateMonthDaysSelected}
                  />
                </div>
              </div>
            </BvsForm>
          </BvsPanel>
        </div>

        <div className="col-md-12">
          {applyPagination(applyOrderBy(applyFilter(order?.employees))).map(
            (employee, idx) => (
              <BvsPanel
                key={employee?.id}
                title={() => (
                  <form action="" style={{ display: 'flex' }}>
                    <label
                      htmlFor={employee?.registry}
                      style={{ color: '#a81e21' }}
                    >
                      {employee?.registry} - {employee?.name}
                    </label>
                    <input
                      type="checkbox"
                      id={employee?.registry}
                      checked={selectedItems.includes(employee?.id)}
                      onChange={() => {
                        if (selectedItems.includes(employee?.id)) {
                          setSelectedItems(
                            selectedItems.filter((i) => i !== employee?.id)
                          );
                        } else
                          setSelectedItems([...selectedItems, employee?.id]);
                      }}
                      style={{ width: '15px', marginLeft: '10px' }}
                    />
                  </form>
                )}
              >
                <div className="row">
                  <UI.Table>
                    <UI.TableHead>
                      <tr>
                        <UI.TableTH style={{ width: '10%' }}>Codigo</UI.TableTH>
                        <UI.TableTH>Departamento</UI.TableTH>
                        <UI.TableTH style={{ width: '10%' }}>Codigo</UI.TableTH>
                        <UI.TableTH style={{ textAlign: 'center' }}>
                          Cargo
                        </UI.TableTH>
                        <UI.TableTH style={{ width: '15%' }}>CPF</UI.TableTH>
                        <UI.TableTH style={{ width: '200px' }}>RG</UI.TableTH>
                      </tr>
                    </UI.TableHead>
                    <tbody>
                      <tr>
                        <UI.TableTD>{employee?.department?.code}</UI.TableTD>
                        <UI.TableTD>{employee?.department?.name}</UI.TableTD>
                        <UI.TableTD>{employee?.job?.code}</UI.TableTD>
                        <UI.TableTD>{employee?.job?.name}</UI.TableTD>
                        <UI.TableTD style={{ width: '15%' }}>
                          {mask(employee?.cpf_cnpj, '999.999.999-99')}
                        </UI.TableTD>
                        <UI.TableTD style={{ width: '200px' }}>
                          {mask(employee?.rg, '99.999.999-99')}
                        </UI.TableTD>
                      </tr>
                    </tbody>
                  </UI.Table>
                  <UI.Table>
                    <UI.TableHead>
                      <tr>
                        <UI.TableTH style={{ width: '10%' }}>Codigo</UI.TableTH>
                        <UI.TableTH>Descriminacao dos Beneficios</UI.TableTH>
                        <UI.TableTH />
                        <UI.TableTH style={{ width: '10%' }}>
                          Unitario
                        </UI.TableTH>
                        <UI.TableTH style={{ width: '10%' }}>
                          Qtde. por Dia
                        </UI.TableTH>
                        <UI.TableTH style={{ width: '10%' }}>
                          Qtde. Dias
                        </UI.TableTH>
                        <UI.TableTH style={{ width: '10%' }}>Total</UI.TableTH>
                        <UI.TableTH>Repasse</UI.TableTH>
                      </tr>
                    </UI.TableHead>
                    {employee?.benefits.map((employeeBenefit, benefitIdx) => (
                      <tbody key={employeeBenefit?.id}>
                        <tr>
                          <UI.TableTD>
                            {employeeBenefit?.benefit?.code}
                          </UI.TableTD>
                          <UI.TableTD colSpan="2">
                            {employeeBenefit?.benefit?.name}
                          </UI.TableTD>
                          <UI.TableTD>
                            {toMoney(employeeBenefit?.benefit?.unit_cost)}
                          </UI.TableTD>
                          <UI.TableTD>
                            {employeeBenefit?.daily_quantity}
                          </UI.TableTD>
                          <UI.TableTD style={{ padding: '0px' }}>
                            <BvsInput
                              name="month_quantity"
                              label="Qtde. Dias"
                              value={employeeBenefit?.month_quantity}
                              onChange={(value) => {
                                let newValues = produce(
                                  order.employees,
                                  (draft) => {
                                    let thisEmployee = draft.find(
                                      (i) => i.id === employee.id
                                    );
                                    if (!thisEmployee) return draft;

                                    thisEmployee.benefits.find(
                                      (eb) => eb.id === employeeBenefit.id
                                    ).month_quantity = value;
                                    return draft;
                                  }
                                );
                                dispatch(
                                  updateOrder({
                                    ...order,
                                    employees: newValues,
                                  })
                                );
                              }}
                            />
                          </UI.TableTD>
                          <UI.TableTD>
                            {toMoney(getSubtotal(employeeBenefit))}
                          </UI.TableTD>
                          <UI.TableTD>
                            {toMoney(getTransferAmount(employeeBenefit))}
                          </UI.TableTD>
                        </tr>
                      </tbody>
                    ))}
                    <tfoot>
                      {groupBy(employee?.benefits).map((benefits) => (
                        <>
                          <tr>
                            <UI.TableTDResumo2 colSpan="6">
                              TOTAL&nbsp;
                              {benefits?.name}
                            </UI.TableTDResumo2>
                            <UI.TableTDResumo
                              colSpan="1"
                              style={{ textAlign: 'center' }}
                            >
                              {toMoney(
                                benefits?.data
                                  .map(
                                    (e) =>
                                      (Number(e?.benefit?.unit_cost) ?? 0) *
                                      (e?.month_quantity ?? 0) *
                                      (e?.daily_quantity ?? 0)
                                  )
                                  .reduce((prev, cur) => prev + cur, 0)
                              )}
                            </UI.TableTDResumo>
                            <UI.TableTDResumo
                              colSpan="1"
                              style={{ textAlign: 'center' }}
                            >
                              {toMoney(
                                benefits?.data
                                  .map((v) => getTransferAmount(v))
                                  .reduce((prev, cur) => prev + cur, 0)
                              )}
                            </UI.TableTDResumo>
                          </tr>
                        </>
                      ))}
                      <tr>
                        <UI.TableTDResumo2 colSpan="6">
                          Total de Beneficios
                        </UI.TableTDResumo2>
                        <UI.TableTDResumo2
                          colSpan="1"
                          style={{ textAlign: 'center' }}
                        >
                          {toMoney(
                            employee?.benefits
                              .map(
                                (e) =>
                                  (Number(e?.benefit?.unit_cost) ?? 0) *
                                  (e?.month_quantity ?? 0) *
                                  (e?.daily_quantity ?? 0)
                              )
                              .flat(1)
                              .reduce((c, o) => c + o, 0)
                          )}
                        </UI.TableTDResumo2>

                        <UI.TableTDResumo2
                          colSpan="1"
                          style={{ textAlign: 'center' }}
                        >
                          {toMoney(
                            employee?.benefits
                              .map((b) => getTransferAmount(b))
                              .flat(1)
                              .reduce((c, o) => c + o, 0)
                          )}
                        </UI.TableTDResumo2>
                      </tr>
                      <tr>
                        <UI.TableTDResumo2 colSpan="6">
                          Total deste Colaborador
                        </UI.TableTDResumo2>
                        <UI.TableTDResumo2
                          colSpan="2"
                          style={{ textAlign: 'center' }}
                        >
                          {toMoney(
                            employee?.benefits
                              .map(
                                (e) =>
                                  (Number(e?.benefit?.unit_cost) ?? 0) *
                                    (e?.month_quantity ?? 0) *
                                    (e?.daily_quantity ?? 0) +
                                  getTransferAmount(e)
                              )
                              .flat(1)
                              .reduce((c, o) => c + o, 0)
                          )}
                        </UI.TableTDResumo2>
                      </tr>
                    </tfoot>
                  </UI.Table>
                </div>
              </BvsPanel>
            )
          )}
          <div
            className="d-flex justify-content-between"
            style={{
              position: 'sticky',
              padding: 20,
              bottom: 10,
              zIndex: 1,
              backgroundColor: '#fff',
              borderRadius: '0.5em',
              boxShadow: '0px 0px 3px rgb(0 0 0 / 40%)',
            }}
          >
            <BvsButton
              type="button"
              title="Voltar"
              icon="fa fa-undo"
              onClick={async () => {
                history.goBack();
              }}
            />
            <ButtomPaginate />
            <BvsButton
              type="button"
              title={id ? 'Prosseguir' : 'Finalizar Pedido'}
              icon="fa fa-check"
              onClick={checkout}
              disabled={
                !(
                  order.financial_status !== STATUS_FINANCIAL.PAYED &&
                  order.operation_status !==
                    STATUS_OPERATIONAL.BUYED_TO_OPERATOR &&
                  order.operation_status !==
                    STATUS_OPERATIONAL.PAYED_TO_OPERATOR &&
                  order.financial_status !== STATUS_FINANCIAL.CANCELLED &&
                  order.operational_status !== STATUS_OPERATIONAL.CANCELLED
                )
              }
            />
          </div>
        </div>
      </BvsContainer>
    </>
  );
};

export default VerifyPlaceOrders;
