import produce from 'immer';
import { useDispatch, useSelector } from 'react-redux';
import { updateUsers } from '~/store/modules/forms/FormUsers/actions';

const useForm = () => {
  const { users, form } = useSelector((state) => state.formUsers);
  const dispatch = useDispatch();
  const onFieldChange = (name, value) => {
    dispatch(
      updateUsers({
        form,
        users: produce(users, (draft) => {
          draft[name] = value;
          return draft;
        }),
      })
    );
  };
  return { onFieldChange };
};

export default useForm;
