/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */

import React, { useEffect, useState } from 'react';

import { FontAwesome } from 'react-web-vector-icons';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { getStatus } from '~/system/convert';
import * as actions from '~/store/modules/forms/FormEmployeeBenefits/actions';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';
import { RenderStatusIconButton, toMoney } from '~/system/helper';
import { STATUS } from '~/system/enum';
import webservice from '~/services/api';
import BvsButton from '~/components/BvsButton';
import BvsContainer from '~/components/BvsContainer';
import BvsDropdown from '~/components/BvsDropdown';
import BvsForm from '~/components/BvsForm';
import BvsInput from '~/components/BvsInput';
import BvsPanel from '~/components/BvsPanel';
import SubHeader from '~/components/BvsSubHeader';
import BvsInputCheck from '~/components/BvsInputCheck';
import BvsToastResult from '~/components/BvsToastResult';
import BvsSearchBenefits from '~/components/BvsSearchBenefits';
import BvsInputCurrency from '~/components/BvsInputCurrency';
import ModalBenefitDetail from '../Benefit/ModalDetail';

import useController from './hooks/useController';
import { Item, Menu, useContextMenu } from 'react-contexify';
import Historic from '~/pages/CreateEmployees/modal/Historic';
import {
  bvsJustification,
  employeeHistoricOfBenefits,
} from '../../store/modules/app/actions';
import BvsJustification from '../../components/BvsJustification';
import * as Yup from 'yup';
import { mask } from 'remask';
import BvsTableV4 from '../../components/BvsTableV4';
import uuid from 'uuid-random';

const EmployeeBenefits = ({ match }) => {
  const { enterpriseActive } = useSelector((state) => state.enterprises);
  const { employeeBenefits } = useSelector(
    (state) => state.formEmployeeBenefits
  );
  const {
    bvsJustification: {
      components: justificationComponents,
      state: justificationState,
    },
  } = useSelector((state) => state.app);
  const {
    employeeHistoricOfBenefit: { components: historicComponents },
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const history = useHistory();
  const [list, setList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [optionSelected, setOptionSelected] = useState([]);
  let { employeeId, benefitId } = match.params;

  const { setEmployeeCurrent, employeeCurrent } = useController({
    employeeId,
    benefitId,
  });
  const { show } = useContextMenu({});

  const setEmployeeBenefits = (key, value) => {
    dispatch(
      actions.updateEmployeeBenefits({
        employeeBenefits: { ...employeeBenefits, [key]: value },
      })
    );
  };

  const [benefit, setBenefit] = useState({
    options: [],
    loading: false,
  });

  const [workday, setWorkday] = useState({
    options: [],
    loading: false,
  });

  const status = {
    options: [
      { title: 'Ativos', value: STATUS.ATIVO },
      { title: 'Inativos', value: STATUS.INATIVO },
      { title: 'Cancelado', value: STATUS.CANCELADO },
    ],
    loading: false,
  };

  const columns = [
    {
      key: 'status',
      label: 'Status',
      sort: true,
      content: (row) => getStatus(row.status),
    },
    {
      key: 'code',
      label: 'Código',
      sort: true,
    },
    {
      key: 'name',
      label: 'Descrição',
      sort: true,
      content: (row) => row.benefit?.name?.substring(0, 35),
    },
    {
      key: 'card',
      label: 'Cartão',
      sort: true,
    },
    {
      key: 'month_quantity',
      label: 'Dias',
      sort: true,
    },
    {
      key: 'daily_quantity',
      label: 'Quantidade',
      sort: true,
    },
    {
      key: 'unit_cost',
      label: 'Unitário',
      sort: true,
      content: (row) => toMoney(Number(row.benefit?.unit_cost)),
    },
    {
      key: 'subtotal',
      label: 'Subtotal',
      sort: true,
      width: '100%',
      content: (row) =>
        toMoney(
          Number(
            row.month_quantity * row.daily_quantity * row.benefit?.unit_cost
          )
        ),
    },
  ];

  const menuId = uuid();
  const [menuData, setMenuData] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [benefitInfo, setBenefitInfo] = useState(null);

  /**
   * @description Responsável por carregar os dados da listagem.
   */
  const fetchEmployeeBenefits = async () => {
    dispatch(showLoading());
    try {
      const { data } = await webservice.get(
        `/employees/${employeeId}/benefits`
      );
      if (data.error || data.message) throw data;
      setList(data);
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Não foi possivel atualizar a lista neste momento."
        />
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const getUnitCost = async () => {
    try {
      const { data } = await webservice.get(
        `/benefits/${employeeBenefits.benefit_id}`
      );
      if (data.error || data.message) throw data;
      setEmployeeBenefits('value_unity', data.unit_cost);
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message={
            err?.message ||
            err?.error?.message ||
            'Não foi possivel definir o Valor unitário no momento.'
          }
        />
      );
    }
  };

  const getBenefit = async () => {
    setBenefit({ ...benefit, loading: true });
    let options = [];
    try {
      const { data } = await webservice.get('/benefitOperators');
      if (data.error || data.message) throw data;
      options = data.map((d) => ({ title: d.name, value: d.id }));
      setBenefit({ ...benefit, options });
    } catch (error) {
      setBenefit({ ...benefit, options });
    } finally {
      setBenefit({ ...benefit, options, loading: false });
    }
  };

  const getWorkday = async () => {
    setWorkday({ ...workday, loading: true });
    let options = [];
    try {
      const { data } = await webservice.get('/workdays');
      if (data.error || data.message) throw data;
      options = data.map((d) => ({ title: d.description, value: d.id }));
      setWorkday({ ...workday, options });
    } catch (error) {
      setWorkday({ ...workday, options });
    } finally {
      setWorkday({ ...workday, options, loading: false });
    }
  };

  const onSave = async (reason) => {
    dispatch(showLoading());
    try {
      dispatch(
        actions.updateEmployeeBenefits({
          filter: { filtering: true },
        })
      );
      const { data } = await webservice[benefitId ? 'put' : 'post'](
        `/employees/${employeeId}${
          benefitId
            ? `/benefits/${benefitId}`
            : `/benefits/${employeeBenefits.benefit_id}`
        }`,
        {
          ...employeeBenefits,
          reason,
        }
      );
      if (data.error || data.message) throw data;
      toast.success(<BvsToastResult message="Beneficio salvo com sucesso." />);
      onCancel();
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message={
            err?.message ||
            err?.error?.message ||
            'Não foi possivel salvar o Beneficio neste momento.'
          }
        />
      );
    } finally {
      dispatch(hideLoading());
      dispatch(
        actions.updateEmployeeBenefits({
          filter: { filtering: false },
        })
      );
      fetchEmployeeBenefits();
    }
  };

  const onEdit = async (paramsId) => {
    history.push(`/employee/${employeeId}/benefits/${paramsId}`);
  };

  const changeStatus = async (params) => {
    dispatch(showLoading());
    try {
      dispatch(
        actions.updateEmployeeBenefits({
          filter: { filtering: true },
        })
      );
      const { data } = await webservice.put(
        `/employees/${employeeId}/benefits/${params.benefit.id}/status`
      );
      if (data.error || data.message) throw data;
      toast.success(
        <BvsToastResult
          message={
            params.status_enum === STATUS.ATIVO
              ? "Alterado para 'Inativo' com sucesso"
              : "Alterado para 'Ativo' com sucesso."
          }
        />
      );
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message={
            err?.message ||
            err?.error?.message ||
            'Não foi possivel salvar o Beneficio neste momento.'
          }
        />
      );
    } finally {
      dispatch(hideLoading());
      dispatch(
        actions.updateEmployeeBenefits({
          filter: { filtering: false },
        })
      );
      fetchEmployeeBenefits();
    }
  };

  const onDelete = async (paramsId) => {
    dispatch(showLoading());
    try {
      dispatch(
        actions.updateEmployeeBenefits({
          filter: { filtering: true },
        })
      );
      const { data } = await webservice.delete(
        `/employees/${employeeId}/benefits/${paramsId}`
      );
      if (data.error || data.message) throw data;
      toast.success(
        <BvsToastResult message="Beneficio excluido com sucesso." />
      );
      onCancel();
      fetchEmployeeBenefits();
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Não foi possivel excluir o Beneficio neste momento."
        />
      );
    } finally {
      dispatch(hideLoading());
      dispatch(
        actions.updateEmployeeBenefits({
          filter: { filtering: false },
        })
      );
    }
  };

  const onCancel = () => {
    dispatch(actions.resetEmployeeBenefits());
    populateEmployee();
    history.replace(`/employee/${employeeId}/benefits`);
  };
  /**
   * @description Responsável por popular um employeeBenefit de acordo com o beneficio
   */
  const populate = async () => {
    dispatch(showLoading());
    try {
      const { data } = await webservice.get(
        `/employees/${employeeId}${benefitId ? `/benefits/${benefitId}` : ''}`
      );
      if (data.error || data.message) throw data;
      dispatch(
        actions.updateEmployeeBenefits({
          employeeBenefits: {
            ...employeeBenefits,
            workday_id: data.workday_id,
            status: data.status,
            benefit_id: data?.benefit?.id,
            benefit_name: data?.benefit?.name,
            month_quantity: data.month_quantity,
            daily_quantity: data.daily_quantity,
            value_unity: data.value_unity,
            card: data.card,
          },
        })
      );
      setOptionSelected([
        {
          value: data?.benefit?.id,
          title: data?.benefit?.name,
        },
      ]);
    } catch (error) {
      toast.error(
        <BvsToastResult
          type="error"
          message={
            error?.message ||
            error?.error?.message ||
            'Falha ao atualizar dados do usuario'
          }
        />
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const populateEmployee = async () => {
    dispatch(showLoading());
    try {
      const { data } = await webservice.get(`/employees/${employeeId}`);
      if (data.error || data.message) throw data;
      setEmployeeCurrent({
        name: data?.name,
        registry: data?.registry,
        cpf: data?.cpf,
      });
    } catch (error) {
      toast.error(
        <BvsToastResult
          type="error"
          message={
            error?.message ||
            error?.error?.message ||
            'Falha ao atualizar dados do usuario'
          }
        />
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const schema = Yup.object({
      workday_id: Yup.string()
        .nullable()
        .required('Informe o Periodo de Trabalho'),
      benefit_id: Yup.string().nullable().required('Informe o beneficio'),
      status: Yup.string().nullable().required('Informe o status'),
      month_quantity: Yup.string()
        .nullable()
        .required('Informe a quantidade periodo'),
      daily_quantity: Yup.string()
        .nullable()
        .required('Informe a quantidade diária'),
    });

    try {
      await schema.validate(employeeBenefits);
      await dispatch(
        bvsJustification('update', {
          components: {
            ...justificationComponents,
            visible: true,
          },
          state: justificationState,
        })
      );
    } catch (err) {
      console.log(err);
      toast.info(<BvsToastResult message="Verifique os campos obrigatórios" />);
    }
  };

  // #region MoreOptions
  const handleMoreOptions = (e, row) => {
    setMenuData(row);
    show(e, {
      id: menuId,
    });
  };
  const handleInfo = async (row) => {
    // Busco os dados
    const { benefit_id } = row;
    try {
      const { data } = await api.get(`/benefits/${benefit_id}`);
      if (data.error || data.message) throw data;
      setModalVisible(true);
      setBenefitInfo({
        ...data,
        region: data?.region?.name,
        benefitGroup: data?.benefitGroup?.name,
      });
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Falha ao buscar dados do Beneficio."
        />
      );
    }
  };
  const handleHistoric = (row) => {
    dispatch(
      employeeHistoricOfBenefits('update', {
        components: {
          ...historicComponents,
          modalVisible: true,
        },
      })
    );
    dispatch(employeeHistoricOfBenefits('fetch', row.id));
  };
  // #endregion

  useEffect(() => {
    fetchEmployeeBenefits();
    getBenefit();
    getWorkday();
    populateEmployee();
  }, []);

  useEffect(() => {
    if (employeeId) populateEmployee();
  }, [employeeId]);

  useEffect(() => {
    if (benefitId) populate();
  }, [benefitId]);

  useEffect(() => {
    if (!['', null, undefined].includes(employeeBenefits.benefit_id)) {
      getUnitCost();
    }
    if (employeeBenefits.benefit_id === null) {
      setEmployeeBenefits('value_unity', '');
    }
  }, [employeeBenefits.benefit_id]);

  return (
    <>
      <SubHeader title="Benefícios" subtitle="da empresa" />
      <BvsContainer>
        <div className="col-md-12">
          <BvsPanel title="Grupo de Beneficios">
            <BvsForm onSubmit={onSubmit}>
              <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-2 col-lg-2">
                  <h1 style={{ fontSize: '1.5rem' }}>
                    Matr.:&nbsp;
                    {employeeCurrent.registry}
                  </h1>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-6 col-lg-4">
                  <h1 style={{ fontSize: '1.5rem' }}>
                    Nome:&nbsp;
                    {employeeCurrent.name}
                  </h1>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3">
                  <h1 style={{ fontSize: '1.5rem' }}>
                    CPF:&nbsp;
                    {mask(employeeCurrent.cpf, '999.999.999-99')}
                  </h1>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3">
                  <BvsDropdown
                    title="Selecione um periodo..."
                    name="workday_id"
                    label="Periodo de dias Trabalhados"
                    options={workday.options}
                    loading={workday.loading}
                    value={employeeBenefits.workday_id}
                    required
                    onSelectedItem={(value) =>
                      setEmployeeBenefits('workday_id', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsDropdown
                    title="Selecione um status..."
                    name="status"
                    label="Status"
                    options={status.options}
                    loading={status.loading}
                    value={employeeBenefits.status}
                    required
                    onSelectedItem={(value) =>
                      setEmployeeBenefits('status', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsDropdown
                    title="Selecione um beneficio..."
                    name="benefit_id"
                    label="Beneficios"
                    hasModal
                    onClick={() => setVisible(true)}
                    options={optionSelected}
                    disabled={benefitId}
                    value={employeeBenefits.benefit_id}
                    required
                  >
                    <BvsSearchBenefits
                      visible={visible}
                      onClose={(row) => {
                        setVisible(false);
                        setEmployeeBenefits('benefit_id', row?.id || null);
                        setOptionSelected([
                          { title: row?.name, value: row?.id },
                        ]);
                      }}
                    />
                  </BvsDropdown>
                </div>
                <div
                  className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3"
                  style={{ marginTop: 'auto' }}
                >
                  <BvsInput
                    name="month_quantity"
                    label="Quantidade Periodo"
                    value={employeeBenefits.month_quantity}
                    onChange={(value) =>
                      setEmployeeBenefits('month_quantity', value)
                    }
                    required
                  />
                </div>
                <div
                  className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3"
                  style={{ marginTop: 'auto' }}
                >
                  <BvsInput
                    name="daily_quantity"
                    label="Quantidade Diária:"
                    value={employeeBenefits.daily_quantity}
                    onChange={(value) =>
                      setEmployeeBenefits('daily_quantity', value)
                    }
                    required
                  />
                </div>
                <div
                  className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3"
                  style={{ marginTop: 'auto' }}
                >
                  <BvsInputCurrency
                    name="value_unity"
                    label="Valor Unitário"
                    value={employeeBenefits.value_unity}
                    onChange={(value) =>
                      setEmployeeBenefits('value_unity', value)
                    }
                    disabled
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsInputCheck
                    name="card"
                    label="Cartão"
                    value={employeeBenefits.card}
                    onChange={setEmployeeBenefits}
                  />
                </div>
              </div>
              <div className="row align-items-end justify-content-end">
                <div className="justify-content-end align-self-end mx-3">
                  <BvsButton
                    type="button"
                    title="Voltar"
                    icon="fa fa-undo"
                    className="mr-3"
                    onClick={() => {
                      dispatch(actions.resetEmployeeBenefits());
                      history.push(`/createEmployees/${employeeId}`);
                    }}
                  />
                  {employeeId && benefitId && (
                    <BvsButton
                      type="button"
                      title="Cancelar"
                      icon="fa fa-close"
                      onClick={onCancel}
                      className="mr-3"
                    />
                  )}
                  <BvsButton type="submit" title="Salvar" icon="fa fa-save" />
                </div>
              </div>
            </BvsForm>
          </BvsPanel>
        </div>

        <div className="col-md-12">
          <BvsPanel title="Informações dos Benefícios">
            <div className="col-md-12">
              <BvsTableV4
                columns={columns}
                rows={list}
                onRefresh={() => {
                  fetchEmployeeBenefits();
                }}
                rowKey={(e) => e.id}
                actions={[
                  (row) => (
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={<FontAwesome name="edit" color="#fff" size={12} />}
                      onClick={() => onEdit(row.benefit.id)}
                    />
                  ),
                  (row) => (
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={<FontAwesome name="trash" color="#fff" size={12} />}
                      onClick={() => onDelete(row.benefit.id)}
                    />
                  ),
                  (row) => {
                    return (
                      <>
                        <span data-tip="Mais Opções">
                          <BvsButton
                            type="button"
                            className="btn btn-xs"
                            icon={
                              <FontAwesome
                                name="ellipsis-h"
                                color="#fff"
                                size={12}
                              />
                            }
                            onClick={(e) => handleMoreOptions(e, row)}
                          />
                        </span>
                      </>
                    );
                  },
                ]}
              />
            </div>
          </BvsPanel>
        </div>

        <div className="container-fluid">
          <div className="row justify-content-end">
            <div className="col-md-6">
              <div className="card">
                <div className="row align-items-center">
                  <div className="card-body">
                    <h5 className="card-title px-3 m-0">
                      Resumo dos Benefícios
                    </h5>
                    <ul className="list-group list-group-flush">
                      {enterpriseActive?.info?.fees?.map((fee) => (
                        <li className="list-group-item d-flex justify-content-between align-items-end">
                          <strong>Total {fee.benefitGroupName}</strong>
                          <i>
                            {toMoney(
                              list
                                .filter(
                                  (eb) =>
                                    eb?.benefit?.benefit_group_id ===
                                      fee.benefitGroupId &&
                                    eb.status === STATUS.ATIVO
                                )
                                .map(
                                  (eb) =>
                                    Number(eb.daily_quantity) *
                                    Number(eb.month_quantity) *
                                    Number(eb.benefit?.unit_cost)
                                )
                                .reduce((prev, cur) => prev + cur, 0)
                            )}
                          </i>
                        </li>
                      ))}
                      <li className="list-group-item list-group-item-info d-flex justify-content-between align-items-end">
                        <strong>Total de Benefício</strong>
                        <i>
                          {toMoney(
                            list
                              .filter((eb) => eb.status === STATUS.ATIVO)
                              .map(
                                (eb) =>
                                  Number(eb.daily_quantity) *
                                  Number(eb.month_quantity) *
                                  Number(eb.benefit?.unit_cost)
                              )
                              .reduce((prev, cur) => prev + cur, 0)
                          )}
                        </i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BvsContainer>
      <BvsJustification
        onSave={(reason) => {
          onSave(reason);
        }}
        visible={justificationComponents.visible}
      />
      <Historic />
      <Menu id={menuId}>
        {menuData && (
          <>
            <Item key="status" onClick={() => changeStatus(menuData)}>
              {RenderStatusIconButton(menuData.status, '#000')}&nbsp; Alterar
              Status
            </Item>
            <Item key="info" onClick={() => handleInfo(menuData)}>
              <FontAwesome name="credit-card-alt" color="#000" size={12} />
              &nbsp; Mais Informações
            </Item>
            <Item key="historic" onClick={() => handleHistoric(menuData)}>
              <FontAwesome name="history" color="#000" size={12} />
              &nbsp; Últimas alterações
            </Item>
          </>
        )}
      </Menu>
      {modalVisible && (
        <ModalBenefitDetail
          visible
          benefitInfo={benefitInfo}
          onClose={() => {
            setModalVisible(false);
          }}
        />
      )}
    </>
  );
};

export default EmployeeBenefits;
