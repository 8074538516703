import { useEffect, useRef, useState } from 'react';
import * as UI from './style';
import { debounce } from 'lodash';
import api from '~/services/api';
import ReactTooltip from 'react-tooltip';

const BvsDropdownSearch = ({
  style = {},
  disabled = false,
  route,
  method = 'get',
  render = (row) => ({ title: `[${row.id}] ${row.name}`, value: row.id }),
  onSelect,
  selected,
}) => {
  const [text, setText] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  const debouncedSave = useRef(
    debounce(async (nextValue) => {
      const { data } = await api[method](`${route}?query=${nextValue}`);

      setOptions(data.map((i) => render(i)));
    }, 1000)
  ).current;

  const select = (value) => {
    setText(value.title);
    setSelectedValue(value.value);
    setOptions([]);
    onSelect && onSelect(value);
  };
  const reset = () => {
    setText('');
    setOptions([]);
    setSelectedValue(null);
  };
  const edtClick = (e) => {
    e.preventDefault();
    reset();
  };

  const edtSearch = (e) => {
    const { value } = e?.target;
    setText(value);
    // Mesmo que handleChange seja criada a cada renderização e executada
    // ela fará referência à mesma debouncedSave criada inicialmente
    debouncedSave(value);
    // console.log(name, value);
  };

  const dropdownItemClick = (value) => {
    select(value);
  };

  useEffect(() => {
    if (selected) {
      setText(selected.title);
    }
  }, [selected]);

  return (
    <UI.Dropdown style={{ ...style }} disabled={disabled}>
      <span data-tip={text}>
        <UI.Input onChange={edtSearch} onClick={edtClick} value={text} />
      </span>
      {options?.length > 0 && (
        <UI.DropdownList>
          {options.map((opt) => (
            <UI.DropdownListItem
              key={opt.value}
              onClick={() => dropdownItemClick(opt)}
            >
              {opt.title}
            </UI.DropdownListItem>
          ))}
        </UI.DropdownList>
      )}
      <ReactTooltip place="bottom" />
    </UI.Dropdown>
  );
};

export default BvsDropdownSearch;
