/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
import React, { useMemo, useState, useEffect } from 'react';
import {
  string,
  func,
  number,
  bool,
  shape,
  node,
  oneOfType,
  object,
} from 'prop-types';
import uuid from 'uuid-random';
import { formatCurrency } from '../../system/helper';
import { Input, InputContainer, Label } from './style';

const defaultConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const BvsInputCurrency = ({
  name,
  label,
  value,
  required = false,
  defaultValue,
  config = defaultConfig,
  currency,
  max,
  autoFocus,
  autoSelect,
  autoReset,
  onChange,
  onBlur,
  onFocus,
  onKeyPress,
  register,
  error,
  disabled,
}) => {
  const id = `${uuid()}_${name}`;

  const floatingEffect = () => {
    let el = document.getElementById(id);
    if (el) {
      el.value === '' &&
        el.classList.contains('hasFilled') &&
        el.classList.remove('hasFilled');
      el.value &&
        !el.classList.contains('hasFilled') &&
        el.classList.add('hasFilled');
    }
  };

  const [maskedValue, setMaskedValue] = useState('0');

  // to prevent a malformed config object
  const safeConfig = useMemo(
    () => () => {
      const {
        formats: {
          number: {
            [currency]: { maximumFractionDigits },
          },
        },
      } = config;

      const finalConfig = {
        ...defaultConfig,
        ...config,
      };

      // at the moment this prevents problems when converting numbers
      // with zeroes in-between, otherwise 205 would convert to 25.
      finalConfig.formats.number[currency].minimumFractionDigits =
        maximumFractionDigits;

      return finalConfig;
    },
    [defaultConfig, config]
  );

  const clean = (number) => {
    if (typeof number === 'number') {
      return number;
    }

    // strips everything that is not a number (positive or negative)
    return Number(number.toString().replace(/[^0-9-]/g, ''));
  };

  const normalizeValue = (number) => {
    const {
      formats: {
        number: {
          [currency]: { maximumFractionDigits },
        },
      },
    } = safeConfig();
    let safeNumber = number;

    if (typeof number === 'string') {
      safeNumber = clean(number);

      if (safeNumber % 1 !== 0) {
        safeNumber = safeNumber.toFixed(maximumFractionDigits);
      }
    } else {
      // all input numbers must be a float point (for the cents portion). This is a fallback in case of integer ones.
      safeNumber = Number.isInteger(number)
        ? Number(number) * 10 ** maximumFractionDigits
        : number.toFixed(maximumFractionDigits);
    }

    // divide it by 10 power the maximum fraction digits.
    return clean(safeNumber) / 10 ** maximumFractionDigits;
  };

  const calculateValues = (inputFieldValue) => {
    const value = normalizeValue(inputFieldValue);
    const maskedValue = formatCurrency(value, safeConfig(), currency);

    return [value, maskedValue];
  };

  const updateValues = (value) => {
    const [calculatedValue, calculatedMaskedValue] = calculateValues(value);

    if (!max || calculatedValue <= max) {
      // if (calculatedValue === 0) setMaskedValue('');
      setMaskedValue(calculatedMaskedValue);

      return [calculatedValue, calculatedMaskedValue];
    }
    return [normalizeValue(maskedValue), maskedValue];
  };

  const handleChange = (event) => {
    event.preventDefault();

    const [value, maskedValue] = updateValues(event.target.value);
    floatingEffect();
    if (maskedValue) {
      onChange(event, value, maskedValue);
    }
  };

  const handleBlur = (event) => {
    const [value, maskedValue] = updateValues(event.target.value);
    floatingEffect();

    if (autoReset) {
      calculateValues(0);
    }

    if (maskedValue) {
      onBlur(event, value, maskedValue);
    }
  };

  const handleFocus = (event) => {
    if (autoSelect) {
      event.target.select();
    }

    const [value, maskedValue] = updateValues(event.target.value);

    if (maskedValue) {
      onFocus(event, value, maskedValue);
    }
  };

  const handleKeyUp = (event) => onKeyPress(event, event.key, event.keyCode);

  useEffect(() => {
    const currentValue = value || defaultValue || 0;

    const [, maskedValue] = calculateValues(currentValue);
    // if (currentValue === 0) setMaskedValue('');
    setMaskedValue(maskedValue);
    floatingEffect();
  }, [currency, value, defaultValue, config, disabled]);

  return (
    <InputContainer>
      <Input
        id={id}
        name={name}
        label={label}
        autoComplete="off"
        value={maskedValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyUp={handleKeyUp}
        isRequired={disabled ? false : required}
        disabled={disabled}
      />
      {label && (
        <Label htmlFor={id} isRequired={required} disabled={disabled}>
          {label}
          {!disabled && required && '*'}
        </Label>
      )}
      {!disabled && error && (
        <Label isRequired={required} error>
          Campo obrigatório
          {required && '*'}
        </Label>
      )}
    </InputContainer>
  );
};

BvsInputCurrency.propTypes = {
  defaultValue: number,
  value: number,
  max: number,
  name: string,
  label: string,
  required: bool,
  disabled: bool,
  component: node.isRequired,
  currency: string.isRequired,
  config: shape().isRequired,
  autoFocus: bool.isRequired,
  autoSelect: bool.isRequired,
  autoReset: bool.isRequired,
  onChange: func.isRequired,
  onBlur: func.isRequired,
  onFocus: func.isRequired,
  onKeyPress: func.isRequired,
  register: oneOfType([func, object]),
  unregister: oneOfType([func, object]),
};

BvsInputCurrency.defaultProps = {
  component: 'input',
  currency: 'BRL',
  value: 0,
  config: defaultConfig,
  autoFocus: false,
  autoSelect: false,
  autoReset: false,
  register: null,
  unregister: null,
  required: false,
  disabled: false,
  max: 999999999,
  onChange: (f) => f,
  onBlur: (f) => f,
  onFocus: (f) => f,
  onKeyPress: (f) => f,
};

export default BvsInputCurrency;
