import types from './types';

export function updateSocket(payload) {
  return {
    type: types.UPDATE_SOCKET,
    payload,
  };
}

export function resetSocket() {
  return {
    type: types.RESET_SOCKET,
  };
}

export function updateListEmployee(payload) {
  return {
    type: types.UPDATE_LIST_EMPLOYEE,
    payload,
  };
}

export function updateListOrder(payload) {
  return {
    type: types.UPDATE_LIST_ORDER,
    payload,
  };
}

export function updateHistoricEmployee(payload) {
  return {
    type: types.HISTORIC_EMPLOYEE.UPDATE,
    payload,
  };
}

export function historicEmployee(type, payload, callback = null) {
  let action = {
    fetch: { type: types.HISTORIC_EMPLOYEE.FETCH },
    update: { type: types.HISTORIC_EMPLOYEE.UPDATE },
  };
  if (payload) action[type].payload = payload;
  if (callback) action[type].callback = callback;

  return action[type];
}

export function employeeHistoricOfBenefits(type, payload, callback) {
  let action = {
    fetch: { type: types.EMPLOYEE_HISTORIC_OF_BENEFITS.FETCH },
    update: { type: types.EMPLOYEE_HISTORIC_OF_BENEFITS.UPDATE },
  };

  if (payload) action[type].payload = payload;
  if (callback) action[type].callback = callback;

  return action[type];
}

export function bvsJustification(type, payload, callback) {
  let action = {
    update: { type: types.BVS_JUSTIFICATION.UPDATE },
    reset: { type: types.BVS_JUSTIFICATION.RESET },
  };

  if (payload) action[type].payload = payload;
  if (callback) action[type].callback = callback;

  return action[type];
}

export function announcements(type, payload, callback) {
  let action = {
    update: { type: types.ANNOUNCEMENTS.UPDATE },
  };

  if (payload) action[type].payload = payload;
  if (callback) action[type].callback = callback;

  return action[type];
}
