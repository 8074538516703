import React, { useEffect, useState } from 'react';
import SubHeader from '~/components/BvsSubHeader';
import BvsContainer from '~/components/BvsContainer';
import BvsPanel from '~/components/BvsPanel';
import { toMoney } from '~/system/helper';
import BvsInputFile from '~/components/BvsInputFile';
import BvsDropdown from '~/components/BvsDropdown';
import BvsInputCurrency from '~/components/BvsInputCurrency';
import api from '~/services/api';

const ReceiptPurchase = ({ match }) => {
  let { id } = match.params;
  const [info, useInfo] = useState();
  const [typePaymentSlip, useTypePaymentSlip] = useState([{ title: 'Comprovante de Compra', value: 0 }, { title: 'Boleto', value: 1 }, { title: 'Comprovante de Pagamento', value: 2 }]);
  const getInfo = async () => {
    const { data } = await api.get(`/order/${id}`);
    console.log('nhapo2', data)
    useInfo(data);
  };
  useEffect(() => {
    getInfo();
  }, []);
  return (
    <>
      <SubHeader
        title="Comprovante de Compra"
        subtitle="Veja os comprovantes do seu pedido."
      />
      <BvsContainer>
        <div className="col-md-12">
          <BvsPanel
            title="Detalhamento do pedido"
            style={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Numero do Pedido</th>
                  <th scope="col">Valor do Pedido sem Repasse</th>
                  <th scope="col">Valor do Repasse</th>
                  <th scope="col">Valor total do Pedido</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>#{id}</td>
                  <td>{toMoney(info?.total - info?.total_transfer)}</td>
                  <td>{toMoney(info?.total_transfer)}</td>
                  <td>{toMoney(info?.total)}</td>
                </tr>
              </tbody>
            </table>
          </BvsPanel>
        </div>
        <div className="col-md-12">
          <BvsPanel title="Inserindo os Comprovantes">
            <div className="row">
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                <BvsDropdown
                  title="Escolha a Operadora ..."
                  name="receipt_operator"
                  label="Operadora"
                  options={[]}
                />
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                <BvsDropdown
                  title="Tipo do Comprovante ..."
                  name="receipt_ticket"
                  label="Boleto"
                  options={typePaymentSlip}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3 ">
                <BvsInputFile name="inputFile" />
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3 ">
                <BvsInputCurrency
                  name="receipt_total_ticket"
                  label="Valor do Boleto (R$)"
                  currency="BRL"
                />
              </div>
            </div>
          </BvsPanel>
          <BvsPanel title="Comprovantes Anexados">
            <div>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Operadora</th>
                    <th scope="col">Tipo do Boleto</th>
                    <th scope="col">Valor do Boleto</th>
                    <th scope="col">Arquivo</th>
                    <th scope="col">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>#{id}</td>
                    <td>{toMoney(info?.total - info?.total_transfer)}</td>
                    <td>{toMoney(info?.total_transfer)}</td>
                    <td>{toMoney(info?.total)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default ReceiptPurchase;
