/* eslint-disable import/prefer-default-export */
import BvsButton from '../BvsButton';
import BvsDropdown from '../BvsDropdown';
import BvsInput from '../BvsInput';
import { TableHeaderActionsWrapper } from './style';

export const TableHeaderOptions = ({
  itemsPerPage = 10,
  itemsPerPageOptions = [10, 25, 50, 100, 1000],
  onPageSizeChange,
  onSearch,
  searchValue,
  onRefresh,
}) => {
  return (
    <TableHeaderActionsWrapper>
      <div className="bvs-table-sizepicker">
        <BvsDropdown
          title={`${itemsPerPage} por página`}
          style={{ width: '165px' }}
          onSelectedItem={onPageSizeChange}
          options={itemsPerPageOptions.map((i) => ({
            value: i,
            title: `${i} por página`,
          }))}
        />
      </div>
      <div className="d-flex justify-content-end">
        <div className="text-nowrap col-12">
          <BvsInput
            onChangeNative={onSearch}
            value={searchValue}
            label="Pesquisar"
          />
        </div>
        <div className="text-nowrap ml-3">
          <BvsButton
            type="button"
            title="Atualizar"
            icon="fa fa-undo"
            onClick={onRefresh}
          />
        </div>
      </div>
    </TableHeaderActionsWrapper>
  );
};
