import React from 'react';
import { SubHeaderContainer, SubHeaderWrapper, Title, SubTitle } from './style';

const SubHeader = ({ title, subtitle }) => {
  return (
    <SubHeaderContainer>
      <SubHeaderWrapper>
        <Title>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
      </SubHeaderWrapper>
    </SubHeaderContainer>
  );
};

export default SubHeader;
