import BvsModal from '~/components/BvsModal';
import BvsButton from '~/components/BvsButton';
import { useSelector, useDispatch } from 'react-redux';
import { updateEmployees } from '~/store/modules/forms/FormEmployees/actions';
import {
  updateWorkday,
  resetWorkday,
  addWorkday,
} from '~/store/modules/forms/FormWorkday/actions';
import BvsInput from '~/components/BvsInput';

const CreateWorkdays = ({ onSaved }) => {
  const dispatch = useDispatch();
  const { components, employees } = useSelector((state) => state.formEmployees);
  const { workday, form } = useSelector((state) => state.formWorkday);
  const setComponents = (component, state) => {
    dispatch(
      updateEmployees({ components: { ...components, [component]: state } })
    );
  };

  const onSave = () => {
    dispatch(
      addWorkday(async (newWorkday) => {
        setComponents('createWorkday', false);
        onSaved && (await onSaved());
        dispatch(
          updateEmployees({
            employees: {
              ...employees,
              workday_id: newWorkday?.id,
            },
          })
        );
      })
    );
    dispatch(resetWorkday());
  };

  const onFieldChange = (name, value) => {
    dispatch(
      updateWorkday({
        workday: { ...workday, [name]: value },
      })
    );
  };
  return (
    <BvsModal
      visible={components.createWorkday}
      onClose={() => setComponents('createWorkday', false)}
      footer={() => (
        <div className="row d-flex justify-content-end">
          <div className="mr-2 text-nowrap">
            <BvsButton
              title="Cancelar"
              onClick={() => {
                setComponents('createWorkday', false);
                dispatch(resetWorkday());
              }}
            />
          </div>
          <div className=" text-nowrap">
            <BvsButton
              title="Salvar"
              onClick={() => {
                onSave();
              }}
            />
          </div>
        </div>
      )}
    >
      <div className="container">
        <form onSubmit={onSave} autoComplete="off">
          <div className="row">
            <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2 p-3">
              <BvsInput
                name="code"
                label="Código"
                value={workday.code}
                onChange={(value) => onFieldChange('code', value)}
                required
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-5 col-lg-5 p-3">
              <BvsInput
                name="description"
                label="Descrição Periodo"
                value={workday.description}
                onChange={(value) => onFieldChange('description', value)}
                required
              />
            </div>
            <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2 p-3">
              <BvsInput
                name="quantity"
                label="Dias"
                value={workday.quantity}
                onChange={(value) => onFieldChange('quantity', value)}
              />
            </div>
          </div>
        </form>
      </div>
    </BvsModal>
  );
};

export default CreateWorkdays;
