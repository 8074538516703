/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';

export const useSelectedItems = ({ rows, key }) => {
  const [selectedItems, setSelected] = useState([]);

  const onSelect = (value) => {
    if (value === -1) {
      setSelected(rows);
      return;
    }
    if (value === 0) {
      setSelected([]);
      return;
    }

    let tmp = [...selectedItems];
    let search = tmp.findIndex((i) => i[key] === value[key]);
    if (search > -1) tmp.splice(search, 1);
    else tmp.push(rows.find((i) => i[key] === value[key]));
    setSelected(tmp);
    // let tmp = [...selectedItems];
    // if(tmp.find(i => key === i))
    // setSelected(selectedItems.map((_, idx) => (idx === index ? !checked : _)));
  };

  return {
    selectedItems,
    onSelect,
  };
};
