/* eslint-disable no-throw-literal */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useRef } from 'react';

import { DownloadFile, toMoney } from '~/system/helper';
import { FontAwesome } from 'react-web-vector-icons';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BvsButton from '~/components/BvsButton';
import BvsContainer from '~/components/BvsContainer';
import BvsDropdown from '~/components/BvsDropdown';
import BvsForm from '~/components/BvsForm';
import BvsInput from '~/components/BvsInput';
import BvsPanel from '~/components/BvsPanel';
import BvsToggle from '~/components/BvsToggle';
import SubHeader from '~/components/BvsSubHeader';
import BvsInputPercentage from '~/components/BvsInputPercentage';
import BvsImport from '~/components/BvsImport';
// import columns from './columns.json';
import ModalBenefitDetail from './ModalDetail';
import useForm from './hooks/useForm';
import useController from './hooks/useController';
import BvsInputCurrency from '~/components/BvsInputCurrency';
import BvsToggleGroup from '~/components/BvsToggleGroup';
import BvsTableV4 from '../../components/BvsTableV4';
import moment from 'moment';
import { ModalLinkBenefitExternal } from './modal-link-benefit-external';
import {
  updateState as benefitGenericUpdate,
  resetState as benefitGenericReset,
} from '~/store/modules/benefit/actions';

const Benefit = ({ match }) => {
  const dispatch = useDispatch();
  const { operator_benefit } = useSelector(
    (state) => state.formOperatorBenefit
  );
  const { socket } = useSelector((state) => state.app);
  const containerForm = useRef();
  const history = useHistory();
  let { id } = match.params;
  const { onFieldChange } = useForm();
  const { '@module/generic': benefitGenericState } = useSelector(
    (state) => state.benefit
  );

  const {
    onCancel,
    onDelete,
    onDetails,
    benefits,
    onSave,
    fetch: fetchBenefits,
    modalVisible,
    setModalVisible,
    visible,
    onEdit,
    benefitInfo,
    getOperators,
    getRegion,
    getGroupBenefits,
    operator,
    benefitGroup,
    region,
  } = useController({
    id,
    containerForm,
  });

  // #region Methods
  const btnGenericImport = () => {
    dispatch(
      benefitGenericUpdate({
        module: '@module/generic',
        key: '@modalVisible',
        value: true,
      })
    );
  };
  const btnCancelBenefitGeneric = () => {
    dispatch(
      benefitGenericReset({
        module: '@module/generic',
      })
    );
  };
  // #endregion

  useEffect(() => {
    getOperators();
    getRegion();
    getGroupBenefits();
    fetchBenefits();

    const onBenefitImportSuccess = () => {
      fetchBenefits();
    };
    // Listener do socket para caso tenha terminado a importação e a pessoa continue na mesma pagina
    socket.on('BenefitImport.Success', onBenefitImportSuccess);

    return () => {
      socket.off('BenefitImport.Success');
    };
  }, []);

  const columns = [
    {
      key: 'code',
      label: 'Código',
      sort: true,
    },
    {
      key: 'name',
      label: 'Nome do Benefício',
      sort: true,
      content: (row) => row.name.substring(0, 40),
    },
    {
      key: 'benefitOperator',
      label: 'Operadora',
      sort: true,
      content: (row) => row?.benefitOperator?.name || '',
    },
    {
      key: 'region',
      label: 'Região',
      sort: true,
      content: (row) => row?.region?.name || '',
    },
    {
      key: 'unit_cost',
      label: 'Valor da Tarifa',
      sort: true,
      width: '100%',
      content: (row) => toMoney(Number(row?.unit_cost)),
    },
  ];

  return (
    <>
      <SubHeader title="Benefícios" />
      <BvsContainer>
        <div className="col-md-12" ref={containerForm}>
          <BvsPanel title="Novo Benefício" visible={visible}>
            <BvsForm onSubmit={onSave}>
              <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-6">
                  <BvsDropdown
                    title="Escolha a Operadora ..."
                    name="benefit_operator_id"
                    label="Operadora"
                    value={operator_benefit.benefit_operator_id}
                    onSelectedItem={(value) =>
                      onFieldChange('benefit_operator_id', value)
                    }
                    options={operator.options}
                    loading={operator.loading}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-2 p-3">
                  <BvsInput
                    name="code"
                    label="Codigo"
                    value={operator_benefit.code}
                    onChange={(value) => onFieldChange('code', value)}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="name"
                    label="Descrição"
                    value={operator_benefit.name}
                    onChange={(value) => onFieldChange('name', value)}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsDropdown
                    title="Escolha o Tipo ..."
                    name="benefit_group_id"
                    label="Tipo"
                    value={operator_benefit.benefit_group_id}
                    onSelectedItem={(value) =>
                      onFieldChange('benefit_group_id', value)
                    }
                    options={benefitGroup.options}
                    loading={benefitGroup.loading}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsDropdown
                    title="Escolha a Região ..."
                    name="region_id"
                    label="Região"
                    value={operator_benefit.region_id}
                    onSelectedItem={(value) =>
                      onFieldChange('region_id', value)
                    }
                    options={region.options}
                    loading={region.loading}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsInput
                    name="line"
                    label="Linha"
                    value={operator_benefit.line}
                    onChange={(value) => onFieldChange('line', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsInput
                    name="sub_description"
                    label="Sub Descrição"
                    value={operator_benefit.sub_description}
                    onChange={(value) =>
                      onFieldChange('sub_description', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsInputCurrency
                    name="unit_cost"
                    label="Valor Unitário"
                    currency="BRL"
                    value={operator_benefit.unit_cost}
                    onChange={(_, value) => onFieldChange('unit_cost', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsInputPercentage
                    name="percentage_total"
                    label="% do Total"
                    value={operator_benefit.percentage_total}
                    onChange={(_, value) =>
                      onFieldChange('percentage_total', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsInputCurrency
                    name="cost_per_employee"
                    label="R$ por Usuário"
                    currency="BRL"
                    value={operator_benefit.cost_per_employee}
                    onChange={(_, value) =>
                      onFieldChange('cost_per_employee', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsInputCurrency
                    name="cost_per_order"
                    label="Valor por Pedido"
                    currency="BRL"
                    value={operator_benefit.cost_per_order}
                    onChange={(_, value) =>
                      onFieldChange('cost_per_order', value)
                    }
                  />
                </div>
                <BvsToggleGroup>
                  <BvsToggle
                    orientation="vertical"
                    title="Valor Variável"
                    name="variable_cost"
                    value={operator_benefit.variable_cost}
                    onChange={(value) => onFieldChange('variable_cost', value)}
                  />
                  <BvsToggle
                    orientation="vertical"
                    title="Bloco"
                    name="voucher_type_block"
                    value={operator_benefit.voucher_type_block}
                    onChange={(value) =>
                      onFieldChange('voucher_type_block', value)
                    }
                  />
                  <BvsToggle
                    orientation="vertical"
                    title="Cartão"
                    name="voucher_type_card"
                    value={operator_benefit.voucher_type_card}
                    onChange={(value) =>
                      onFieldChange('voucher_type_card', value)
                    }
                  />
                  <BvsToggle
                    orientation="vertical"
                    title="Multiplo"
                    name="voucher_type_multiple"
                    value={operator_benefit.voucher_type_multiple}
                    onChange={(value) =>
                      onFieldChange('voucher_type_multiple', value)
                    }
                  />
                </BvsToggleGroup>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsInputCurrency
                    name="cost_original"
                    label="Custo 1ª Via"
                    currency="BRL"
                    value={operator_benefit.cost_original}
                    onChange={(_, value) =>
                      onFieldChange('cost_original', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsInputCurrency
                    name="cost_duplicate"
                    label="Custo 2ª Via"
                    currency="BRL"
                    value={operator_benefit.cost_duplicate}
                    onChange={(_, value) =>
                      onFieldChange('cost_duplicate', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsInputCurrency
                    name="cost_per_credit"
                    label="Custo por Crédito"
                    currency="BRL"
                    value={operator_benefit.cost_per_credit}
                    onChange={(_, value) =>
                      onFieldChange('cost_per_credit', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsInputCurrency
                    name="minimal_original_cost"
                    label="Min. 1º Crédito"
                    currency="BRL"
                    value={operator_benefit.minimal_original_cost}
                    onChange={(_, value) =>
                      onFieldChange('minimal_original_cost', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsInputCurrency
                    name="minimal_others_cost"
                    label="Min. Demais Crédito"
                    currency="BRL"
                    value={operator_benefit.minimal_others_cost}
                    onChange={(_, value) =>
                      onFieldChange('minimal_others_cost', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsInputCurrency
                    name="maximum_others_cost"
                    label="Máx. Demais Crédito"
                    currency="BRL"
                    value={operator_benefit.maximum_others_cost}
                    onChange={(_, value) =>
                      onFieldChange('maximum_others_cost', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsInput
                    name="required_data"
                    label="Dados Obrigatórios"
                    value={operator_benefit.required_data}
                    onChange={(value) => onFieldChange('required_data', value)}
                  />
                </div>
                <BvsToggleGroup>
                  <BvsToggle
                    orientation="vertical"
                    title="Facial"
                    name="facial"
                    value={operator_benefit.facial}
                    onChange={(value) => onFieldChange('facial', value)}
                  />
                  <BvsToggle
                    orientation="vertical"
                    title="Unitário"
                    name="unit"
                    value={operator_benefit.unit}
                    onChange={(value) => onFieldChange('unit', value)}
                  />
                  <BvsToggle
                    orientation="vertical"
                    title="Período"
                    name="period"
                    value={operator_benefit.period}
                    onChange={(value) => onFieldChange('period', value)}
                  />
                </BvsToggleGroup>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="integration"
                    label="Integração"
                    value={operator_benefit.integration}
                    onChange={(value) => onFieldChange('integration', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="payment_method"
                    label="Tipo de Pagamento"
                    value={operator_benefit.payment_method}
                    onChange={(value) => onFieldChange('payment_method', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="deadline_credit"
                    label="Prazo para Liberação dos Créditos"
                    value={operator_benefit.deadline_credit}
                    onChange={(value) =>
                      onFieldChange('deadline_credit', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="deadline_card_operator"
                    label="Prazo para geração do Cartão na Operadora"
                    value={operator_benefit.deadline_card_operator}
                    onChange={(value) =>
                      onFieldChange('deadline_card_operator', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="card_status"
                    label="Status dos Cartões"
                    value={operator_benefit.card_status}
                    onChange={(value) => onFieldChange('card_status', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInputCurrency
                    name="cost_card_generation"
                    label="Custo/Condições para geração do Cartão"
                    currency="BRL"
                    value={operator_benefit.cost_card_generation}
                    onChange={(_, value) =>
                      onFieldChange('cost_card_generation', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="descriptive"
                    label="Discritivo"
                    value={operator_benefit.descriptive}
                    onChange={(value) => onFieldChange('descriptive', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <BvsInput
                    name=""
                    label="Website"
                    value={operator_benefit.website}
                    onChange={(value) => onFieldChange('website', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="phone"
                    label="Telefone"
                    mask="phone"
                    value={operator_benefit.phone}
                    onChange={(value) => onFieldChange('phone', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="email"
                    label="Email"
                    value={operator_benefit.email}
                    onChange={(value) => onFieldChange('email', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="login"
                    label="Login"
                    value={operator_benefit.login}
                    onChange={(value) => onFieldChange('login', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="password"
                    label="Senha"
                    value={operator_benefit.password}
                    onChange={(value) => onFieldChange('password', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <label>Data do Último Reajuste:</label>
                  <input
                    name="date_last_adjustment"
                    type="date"
                    value={operator_benefit.date_last_adjustment}
                    onChange={(e) => {
                      let value = e.target.value || '';
                      onFieldChange('date_last_adjustment', value);
                    }}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="type_letter_card"
                    label="Tipo de Carta para Retirada do Cartão/Passes."
                    value={operator_benefit.type_letter_card}
                    onChange={(value) =>
                      onFieldChange('type_letter_card', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="type_order_generation"
                    label="Geração do Pedido"
                    value={operator_benefit.type_order_generation}
                    onChange={(value) =>
                      onFieldChange('type_order_generation', value)
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="text-nowrap m-3">
                  <BvsButton type="submit" title="Salvar" icon="fa fa-save" />
                </div>
                <div className="text-nowrap  m-3">
                  {id && (
                    <BvsButton
                      type="button"
                      title="Cancelar"
                      icon="fa fa-save"
                      onClick={onCancel}
                    />
                  )}
                </div>
              </div>
            </BvsForm>
          </BvsPanel>

          <BvsPanel title="Relação de Benefícios">
            <div className="col-md-12">
              <BvsTableV4
                columns={columns}
                tableKey="operatorBenefit"
                rowKey="id"
                rows={benefits}
                onRefresh={() => {
                  fetchBenefits();
                }}
                actionOptions={{ width: '225px' }}
                actions={[
                  (row) => (
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={
                        <FontAwesome
                          name="credit-card"
                          color="#fff"
                          size={10}
                        />
                      }
                      onClick={() => onDetails(row.id)}
                    />
                  ),
                  (row) => (
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={<FontAwesome name="edit" color="#fff" size={10} />}
                      onClick={() => onEdit(row.id)}
                    />
                  ),
                  (row) => (
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={<FontAwesome name="close" color="#fff" size={10} />}
                      onClick={() => onDelete(row.id)}
                    />
                  ),
                ]}
              />
              <div>
                {/* <BvsButton
                type="button"
                title="Imprimir"
                icon=""
                customStyle={{ margin: '5px' }}
              /> */}
                <BvsButton
                  type="button"
                  title="Exportar Excel"
                  icon=""
                  onClick={async () => {
                    DownloadFile(
                      'benefits/excel/export',
                      `Operadora_e_Beneficio-${moment().format(
                        'DD.MM.YYYY HH:mm:ss'
                      )}.xlsx`
                    );
                  }}
                />
                <BvsImport
                  title="Importar Tarifas"
                  uri="benefits/excel/import"
                />
                <BvsButton
                  type="button"
                  title="Operadora"
                  icon=""
                  onClick={() => history.push(`/operator`)}
                />
                <BvsButton
                  title="Configurar Benefícios Externos"
                  type="button"
                  icon=""
                  customStyle={{ margin: '5px' }}
                  onClick={btnGenericImport}
                />
              </div>
            </div>
          </BvsPanel>
        </div>
      </BvsContainer>
      {modalVisible && (
        <ModalBenefitDetail
          visible
          benefitInfo={benefitInfo}
          onClose={() => {
            setModalVisible(false);
          }}
        />
      )}

      {benefitGenericState?.['@modalVisible'] && (
        <ModalLinkBenefitExternal
          visible
          size="xl"
          onClose={btnCancelBenefitGeneric}
        />
      )}
    </>
  );
};

export default Benefit;
