/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  form: {
    saving: false,
    disabled: false,
  },
  job: {
    code: '',
    name: '',
  },
};

function formJob(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_JOB: {
      return produce(state, (draft) => {
        draft = { ...draft, ...action.payload };
        return draft;
      });
    }
    case types.RESET_JOB: {
      return produce(state, (draft) => {
        draft.job = INITIAL_STATE.job;
        return draft;
      });
    }
    default:
      return state;
  }
}

export default formJob;
