import React from 'react';

const BvsForm = ({ children, onSubmit }) => {
  return (
    <form onSubmit={onSubmit} autoComplete="off">
      {children}
    </form>
  );
};
export default BvsForm;
