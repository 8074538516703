/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
import types from './types';

export function updateOperator(payload) {
  return {
    type: types.UPDATE_OPERATOR,
    payload,
  };
}

export function resetOperator() {
  return {
    type: types.RESET_OPERATOR,
  };
}
