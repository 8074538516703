import styled from 'styled-components';
import '~/assets/fonts/Century Gothic.ttf';

const Calendar = styled.div`
  padding: 15px 25px;
  background-color: #fdfdfd;
  border-radius: 3px;
  width: 100%;
  font-family: 'Century Gothic', Helvetica, sans-serif;
  display: block;
  user-select: none;
  margin-bottom: 20px;

  &:before,
  &:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
`;

const CalendarHeader = styled.div`
  color: white;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  margin-bottom: 30px;
  &:before,
  &:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
`;

const CalendarControl = styled.button`
  color: #c9c9c9;
  font-weight: bold;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0 5px;
  transition: all 0.5s;
  &:hover {
    color: #393939;
    transform: scale(1.4);
    transition: all 0.5s;
  }
  &:before,
  &:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
`;

const GridTemplate = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  &:before,
  &:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
`;

const GridHeader = styled(GridTemplate)`
  color: #393939;
  font-weight: bold;
  margin-bottom: 5px;
  &:before,
  &:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
`;

const GridBody = styled(GridTemplate)`
  color: #393939;
  &:before,
  &:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
`;

const GridCell = styled.span`
  justify-content: center;
  align-items: center;
  display: flex;
  &:before,
  &:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  &.selected {
    color: #393939;
    font-weight: bold;
    background-color: #f0f0f0;
    border-bottom: 2px solid #333333;
  }
`;

const GridCellGh = styled(GridCell)`
  border-bottom: 2px solid #f0f0f0;
  margin: 3px;
  box-sizing: border-box;
  height: 40px;
  &:before,
  &:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
`;

const GridCellGd = styled(GridCell)`
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.1s;
  margin: 3px;
  position: relative;
  min-height: 50px;
  max-width: 80px;
  overflow: hidden;

  &:hover {
    background-color: #f9f9f9;
  }
`;

const MonthName = styled.span`
  color: #393939;
  font-weight: bold;
  font-size: 1.3em;
  &:before,
  &:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
`;

const DayName = styled.span`
  display: table;
  font-size: 0.98em;
  margin-top: 5px;
  margin-left: 5px;
  font-style: italic;
  &:before,
  &:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
`;
const CalendarBody = styled.div``;
const Grid = styled.div``;
export {
  Calendar,
  CalendarHeader,
  CalendarBody,
  CalendarControl,
  Grid,
  GridHeader,
  GridBody,
  GridCell,
  GridCellGd,
  GridCellGh,
  MonthName,
  DayName,
};
