/* eslint-disable no-shadow */
import BvsModal from '~/components/BvsModal';
import BvsButton from '~/components/BvsButton';
import { useDispatch, useSelector } from 'react-redux';
import { employeeHistoricOfBenefits } from '~/store/modules/app/actions';
import moment from 'moment';

const HistoricItems = () => {
  const {
    employeeHistoricOfBenefit: { state },
  } = useSelector((state) => state.app);
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="row justify-content-around">
            <div className="text-center">
              <span className="font-weight-bold mb-1">Gerado Por:</span>
              <p className="pt-2">{state?.data?.createdBy}</p>
            </div>
            <div className="text-center">
              <span className="font-weight-bold">Gerado Em:</span>
              <p className="pt-2">
                {state?.data?.createdAt &&
                  moment(state?.data?.createdAt)?.format('DD/MM/YYYY HH:mm:ss')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <table className="table">
            <thead>
              <tr>
                <th width="33%" style={{ textAlign: 'center' }}>
                  Data - Hora
                </th>
                <th width="33%" style={{ textAlign: 'center' }}>
                  Motivo
                </th>
                <th width="33%" style={{ textAlign: 'center' }}>
                  Alterado Por
                </th>
              </tr>
            </thead>
            <tbody>
              {state?.data?.historic?.map((historic) => (
                <tr key={historic.id}>
                  <td style={{ textAlign: 'center' }}>
                    {historic?.createdAt &&
                      moment(historic.createdAt)?.format('DD/MM/YYYY HH:mm:ss')}
                  </td>
                  <td style={{ textAlign: 'center' }}>{historic?.reason}</td>
                  <td style={{ textAlign: 'center' }}>{historic?.createdBy}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const Historic = () => {
  const dispatch = useDispatch();
  const {
    employeeHistoricOfBenefit: { components, state },
  } = useSelector((state) => state.app);

  const setComponents = (component, value) => {
    dispatch(
      employeeHistoricOfBenefits('update', {
        components: {
          ...components,
          [component]: value,
        },
      })
    );
  };

  return (
    <BvsModal
      visible={components.modalVisible}
      title="Registro de Alterações"
      onClose={() => {
        setComponents('modalVisible', false);
      }}
      footer={() => (
        <div>
          <BvsButton
            title="Fechar"
            onClick={() => {
              setComponents('modalVisible', false);
            }}
          />
        </div>
      )}
    >
      {state?.loading && (
        <div className="p-3 row justify-content-center align-items-center">
          <div className="col-12 text-center">
            <h4>Carregando...</h4>
          </div>
          <div className="col-4">
            <div className="progress">
              <div
                className="progress-bar bg-warning progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </div>
      )}
      {!state?.loading && state && <HistoricItems />}
    </BvsModal>
  );
};

export default Historic;
