import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import BvsToastResult from '~/components/BvsToastResult';
import { toast } from 'react-toastify';
import { updateJob, resetJob } from './actions';
import types from './types';
import api from '~/services/api';

export function* addJob({ callback }) {
  const { job, form } = yield select((state) => state.formJob);
  try {
    yield put(
      updateJob({
        form: { ...form, saving: true, disabled: true },
      })
    );

    const { data } = yield call(api.post, '/jobs', {
      code: job.code,
      name: job.name,
    });

    if (data.error || data.message) {
      throw data;
    }

    yield put(resetJob());

    toast.success(
      <BvsToastResult message={`'${data.name}' salvo com sucesso!`} />
    );
    callback && callback(data);
  } catch (error) {
    toast.error(
      <BvsToastResult
        type="error"
        err={error}
        message="Falha ao salvar registro"
      />
    );
  } finally {
    yield put(updateJob({ form: { ...form, saving: false, disabled: false } }));
  }
}
export default all([takeLatest(types.ADD_JOB, addJob)]);
