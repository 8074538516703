import styled from 'styled-components';

export const BvsShortcut = styled.div`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  display: flex;
`;

export const BvsShortcutItem = styled.a`
  padding: 10px 45px;
  background-color: #7f83b6;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  margin: 15px;
  min-height: 85px;
  font-size: 0.95em;
  h4 {
    font-size: 1.2em;
  }
  &:link {
    text-decoration: none;
  }
  > * {
    display: inline-block;
    color: #fff;
  }
  > i {
    padding-right: 10px;
    padding-top: 3px;
  }
`;
