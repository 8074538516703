/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import BvsContainer from '~/components/BvsContainer';
import { Header, EnterpriseLogo } from './style';
import Logo from '../../../assets/logo.png';

export default () => {
  return (
    <Header>
      <BvsContainer>
        <a href="/">
          <EnterpriseLogo src={Logo} />
        </a>
      </BvsContainer>
    </Header>
  );
};
