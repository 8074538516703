import BvsModal from '~/components/BvsModal';
import BvsButton from '~/components/BvsButton';
import { useSelector, useDispatch } from 'react-redux';
import { updateEmployees } from '~/store/modules/forms/FormEmployees/actions';
import {
  updateJob,
  resetJob,
  addJob,
} from '~/store/modules/forms/FormJob/actions';
import BvsInput from '~/components/BvsInput';

const CreateJob = ({ onSaved }) => {
  const dispatch = useDispatch();
  const { components, employees } = useSelector((state) => state.formEmployees);
  const { job, form } = useSelector((state) => state.formJob);
  const setComponents = (component, state) => {
    dispatch(
      updateEmployees({ components: { ...components, [component]: state } })
    );
  };

  const onSave = () => {
    dispatch(
      addJob(async (newJob) => {
        setComponents('createJob', false);
        onSaved && (await onSaved());
        dispatch(
          updateEmployees({
            employees: {
              ...employees,
              job_id: newJob?.id,
            },
          })
        );
      })
    );
    dispatch(resetJob());
  };

  const onFieldChange = (name, value) => {
    dispatch(updateJob({ job: { ...job, [name]: value } }));
  };

  return (
    <BvsModal
      visible={components.createJob}
      onClose={() => setComponents('createJob', false)}
      footer={() => (
        <div className="row d-flex justify-content-end">
          <div className="mr-2 text-nowrap">
            <BvsButton
              title="Cancelar"
              onClick={() => {
                setComponents('createJob', false);
                dispatch(resetJob());
              }}
            />
          </div>
          <div className=" text-nowrap">
            <BvsButton
              title="Salvar"
              onClick={() => {
                onSave();
              }}
            />
          </div>
        </div>
      )}
    >
      <div className="container">
        <form onSubmit={onSave} autoComplete="off">
          <div className="row">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
              <BvsInput
                name="code"
                label="Código"
                value={job.code}
                onChange={(value) => onFieldChange('code', value)}
                required
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
              <BvsInput
                name="name"
                label="Nome do Cargo"
                value={job.name}
                onChange={(value) => onFieldChange('name', value)}
                required
              />
            </div>
          </div>
        </form>
      </div>
    </BvsModal>
  );
};

export default CreateJob;
