import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrder } from '~/store/modules/order/actions';

const useForm = () => {
  const dispatch = useDispatch();
  const { order } = useSelector((state) => state.order);

  const onFieldChange = (name, value) => {
    let form = { ...order, [name]: value };
    if (name === 'start_period_use') {
      form.end_period_use = moment(value).add(30, 'days').format('YYYY-MM-DD');
    }
    dispatch(updateOrder({ ...form }));
  };
  return { onFieldChange };
};

export default useForm;
