/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';

export const DataReview = styled.p`
  font-size: 0.75rem;
`;

export const ContainerImg = styled.div`
  display: flex;
`;

export const Img = styled.img`
  margin-left: auto;
`;

export const Text = styled.h1`
  margin: 5px;
  color: #b6a771;
  font-weight: bold;
  font-size: 1.7rem;
`;

export const Text2 = styled.h1`
  margin-left: 5px;
  color: #a81e21;
  font-weight: bold;
  font-size: 1.7rem;
  text-align: right;
`;
