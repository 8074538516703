/* eslint-disable no-unused-expressions */
/* eslint-disable import/order */
/* eslint-disable no-param-reassign */

import React, { useEffect, useRef } from 'react';

import BvsButton from '~/components/BvsButton';
import BvsContainer from '~/components/BvsContainer';
import BvsForm from '~/components/BvsForm';
import BvsInputFile from '~/components/BvsInputFile';
import BvsPanel from '~/components/BvsPanel';
import BvsTableV4 from '../../components/BvsTableV4';
import { FontAwesome } from 'react-web-vector-icons';
import { RenderStatusIconButton } from '~/system/helper';
import SubHeader from '~/components/BvsSubHeader';
// import columns from './columns.json';
import { getStatus } from '~/system/convert';
import { useSelector } from 'react-redux';
import BvsInput from '~/components/BvsInput';
import useController from './hooks/useController';
import useForm from './hooks/useForm';

const CreateUsers = ({ match }) => {
  let { id } = match.params;
  const { users, form } = useSelector((state) => state.formUsers);
  const containerForm = useRef();
  const { onFieldChange } = useForm();
  const {
    users: list,
    getUsers,
    onCancel,
    onResetPassword,
    onSave,
    onEdit,
    onDelete,
    changeStatus,
  } = useController({
    id,
    containerForm,
  });

  const columns = [
    {
      key: 'name',
      label: 'Nome de Exibição',
      width: '50%',
      sort: true,
    },
    {
      key: 'email',
      label: 'E-mail',
      sort: true,
      width: '40%',
    },
    {
      key: 'status',
      label: 'Status',
      sort: true,
      width: '10%',
      content: (row) => {
        return getStatus(row.status);
      },
    },
  ];

  useEffect(() => {
    getUsers();
  }, []);
  useEffect(() => {
    id && onEdit(id);
  }, [id]);

  return (
    <>
      <SubHeader title="Usuarios" subtitle="da empresa" />
      <BvsContainer>
        <div className="col-md-12" ref={containerForm}>
          <BvsPanel title="Incluir">
            <BvsForm onSubmit={onSave}>
              <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2 p-3">
                  <BvsInput
                    name="name"
                    label="Nome de Exibição"
                    value={users.name}
                    onChange={(value) => onFieldChange('name', value)}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2 p-3">
                  <BvsInput
                    name="email"
                    label="E-mail"
                    value={users.email}
                    onChange={(value) => onFieldChange('email', value)}
                    required
                  />
                </div>
                {!id && (
                  <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2 p-3">
                    <BvsInput
                      name="password"
                      label="Senha"
                      value={users.password}
                      onChange={(value) => onFieldChange('password', value)}
                      required
                    />
                  </div>
                )}
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-4 p-3">
                  <BvsInputFile
                    name="avatar"
                    value={users.avatar}
                    onChange={onFieldChange}
                  />
                </div>
              </div>
              <div className="row d-flex align-items-end">
                <div className="col">
                  <BvsButton
                    type="submit"
                    title="Salvar"
                    icon="fa fa-save"
                    disabled={form.saving}
                    className="mr-3"
                  />
                  {id && (
                    <>
                      <BvsButton
                        type="button"
                        title="Cancelar"
                        icon="fa fa-save"
                        onClick={onCancel}
                        disabled={form.saving}
                        className="mr-3"
                      />
                      <BvsButton
                        type="button"
                        title="Resetar Senha"
                        icon="fa fa-key"
                        onClick={onResetPassword}
                        disabled={form.saving}
                      />
                    </>
                  )}
                </div>
              </div>
            </BvsForm>
          </BvsPanel>
        </div>

        <div className="col-md-12">
          <BvsPanel title="Relação de Usuários">
            <div className="col-md-12">
              <BvsTableV4
                tableKey="users"
                columns={columns}
                rows={list.options}
                rowKey="id"
                onRefresh={() => getUsers()}
                actionOptions={{ width: '225px' }}
                actions={[
                  (row) => (
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={<FontAwesome name="edit" color="#fff" size={12} />}
                      onClick={() => onEdit(row.id)}
                    />
                  ),
                  (row) => (
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={<FontAwesome name="close" color="#fff" size={12} />}
                      onClick={() => onDelete(row.id)}
                    />
                  ),
                  (row) => (
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={RenderStatusIconButton(row.status)}
                      onClick={() => changeStatus(row.id)}
                    />
                  ),
                ]}
              />
            </div>
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default CreateUsers;
