const DAYS_WEEK = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

const STATUS = {
  ATIVO: 1,
  INATIVO: 2,
  CANCELADO: 3,
};

const STATUS_CARD = {
  ATIVO: 1,
  INATIVO: 2,
  CANCELADO: 3,
  BLOQUEADO: 4,
  TODOS: null,
};

const STATUS_EMPLOYEES = {
  ATIVO: 1,
  INATIVO: 2,
  CANCELADO: 3,
  EXCLUIDO: 4,
  DEMITIDO: 5,
};

const USER_TYPE = {
  ADMIN: 1,
  USER: 2,
};

const TYPE_ACCOUNT_BANK = {
  CORRENTE: 1,
  POUPANCA: 2,
};

const IMPORT_STATUS = {
  SUCCESS: 1,
  FAILED: 2,
  PENDING: 3,
};

const GENDER_EMPLOYEES = {
  MALE: 0,
  FEMALE: 1,
  OTHER: 2,
};

const STATUS_FINANCIAL = {
  PENDING: 1,
  PAYED: 2,
  CANCELLED: 3,
};

const STATUS_OPERATIONAL = {
  PENDING: 1,
  AVAILABLE_BUY: 2,
  PROBLEM_TO_BUY: 3,
  BUYED_TO_OPERATOR: 4,
  PAYED_TO_OPERATOR: 5,
  CREDIT_AVAILABLE_OPERATOR: 6,
  CANCELLED: 7,
  BENEFIT_AVAILABLE: 8,
};

const URL_SHORTCUTS = {
  PEDIDO_NOVO: '/createOrders',
  MUDAR_SENHA: '/changePassword',
  IMPORTAR: '/importGeneralRegisters',
  EXPORTAR: '/exportDownload',
  EMPRESA_NOVO: '/enterprises',
  CARTAO: '/administrativeCard',
  OCCORENCIA: '/occurrence',
  DEPARTAMENTO: '/departments',
  CARGO: '/job',
};

const POSITION_SHORTCUTS = {
  POSICAO_UM: 0,
  POSICAO_DOIS: 1,
  POSICAO_TRES: 2,
  POSICAO_QUATRO: 3,
};

const STATUS_OCCURRENCE = {
  ABERTO: 0,
  ANDAMENTO: 1,
  FINALIZADO: 2,
  CANCELADO: 3,
};

const ORDER_LIST = {
  ALFABETICO: 0,
  MATRICULA: 1,
  DEPARTAMENTO: 2,
  CARGO: 3,
};

const ORDER_ENVELOPE = {
  NOME_DEPARTAMENTO: 0,
  NOME_MATRICULA: 1,
  NOME: 2,
};

const PAGINATE = {
  PER_PAGE_10: 10,
  PER_PAGE_25: 25,
  PER_PAGE_50: 50,
  PER_PAGE_100: 100,
  PER_PAGE_1000: 1000,
};

const ADDRESS_TYPE = {
  ENTREGA: 1,
  FATURAMENTO: 2,
  COBRANCA: 3,
};

const DEFAULT_BENEFIT_GROUP = {
  VT: 1,
  BR: 2,
  BC: 3,
  BA: 4,
};

const EMPLOYEE_STATUS = {
  ATIVO: 1,
  INATIVO: 2,
  CANCELADO: 3,
  DEMITIDO: 4,
};

const EMISSOR_RG = {
  SSP_AC: 'SSP/AC',
  SSP_AL: 'SSP/AL',
  SSP_AP: 'SSP/AP',
  SSP_AM: 'SSP/AM',
  SSP_BA: 'SSP/BA',
  SSP_CE: 'SSP/CE',
  SSP_DF: 'SSP/DF',
  SSP_ES: 'SSP/ES',
  SSP_GO: 'SSP/GO',
  SSP_MA: 'SSP/MA',
  SSP_MT: 'SSP/MT',
  SSP_MS: 'SSP/MS',
  SSP_MG: 'SSP/MG',
  SSP_PA: 'SSP/PA',
  SSP_PB: 'SSP/PB',
  SSP_PR: 'SSP/PR',
  SSP_PE: 'SSP/PE',
  SSP_PI: 'SSP/PI',
  SSP_RJ: 'SSP/RJ',
  SSP_RN: 'SSP/RN',
  SSP_RS: 'SSP/RS',
  SSP_RO: 'SSP/RO',
  SSP_RR: 'SSP/RR',
  SSP_SC: 'SSP/SC',
  SSP_SP: 'SSP/SP',
  SSP_SE: 'SSP/SE',
  SSP_TO: 'SSP/TO',
};
export {
  DAYS_WEEK,
  STATUS,
  STATUS_CARD,
  STATUS_EMPLOYEES,
  URL_SHORTCUTS,
  USER_TYPE,
  IMPORT_STATUS,
  ORDER_LIST,
  GENDER_EMPLOYEES,
  PAGINATE,
  ORDER_ENVELOPE,
  STATUS_FINANCIAL,
  STATUS_OCCURRENCE,
  POSITION_SHORTCUTS,
  TYPE_ACCOUNT_BANK,
  STATUS_OPERATIONAL,
  ADDRESS_TYPE,
  DEFAULT_BENEFIT_GROUP,
  EMISSOR_RG,
};

export const BENEFIT_GENERIC_STATUS = {
  INITIAL: 0,
  IMPORTING: 1,
  MAPPING: 2,
};
