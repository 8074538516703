import styled from 'styled-components';

export const BvsToggle = styled.div`
  cursor: pointer;
  user-select: none;
  ${(props) =>
    props.orientation === 'horizontal'
      ? `
        padding:15px;
        display:flex;
        box-sizing:border-box;
        float: left;
        flex-direction:row;
        
    `
      : `
        float: left;
        margin: auto; 
        display: --webkit-box;
        display:flex;
        align-items: center;
        justify-content: center;
        flex-direction:column;
        height: 50px;
        & > span{
            display:flex;
            flex-wrap: wrap;
            flex-direction: row;
        }
        & > span:nth-of-type(1){
        }
        & > span:nth-of-type(2){
            margin-top:5px;
            
        }
    `};
`;

export const BvsDivToggle = styled.div`
  margin: 5px;
`;

export const BvsToggleTitle = styled.span`
  font-weight: bold;
  padding-left: 15px;
  padding-right: 15px;
`;

export const BvsContainer = styled.span`
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;
`;
export const BvsToggleState = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: #a81e21;
  }

  &:focus + span {
    box-shadow: 0 0 1px #2196f3;
  }

  &:checked + span:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }
`;
export const BvsToggleInterface = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #beddcd;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 23px;
  &:before {
    border-radius: 50%;
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
`;
