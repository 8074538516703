/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesome } from 'react-web-vector-icons';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { getStatusOccurrence } from '~/system/convert';
import { STATUS_OCCURRENCE } from '~/system/enum';
import webservice from '~/services/api';
import BvsPanel from '~/components/BvsPanel';
import SubHeader from '~/components/BvsSubHeader';
import BvsInputV2 from '~/components/BvsInputV2';
import BvsButton from '~/components/BvsButton';
import BvsContainer from '~/components/BvsContainer';
import BvsForm from '~/components/BvsForm';
import BvsTableV4 from '../../components/BvsTableV4';
import BvsDropdown from '~/components/BvsDropdown';
// import columns from './columns.json';

const Occurrence = ({ match }) => {
  const history = useHistory();
  const [occurrence, setOccurrence] = useState();
  const [statusOccurrence, setStatusOccurrence] = useState({
    loading: false,
    options: [
      { title: 'Aberto', value: STATUS_OCCURRENCE.ABERTO },
      { title: 'Andamento', value: STATUS_OCCURRENCE.ANDAMENTO },
      { title: 'Finalizado', value: STATUS_OCCURRENCE.FINALIZADO },
      { title: 'Cancelado', value: STATUS_OCCURRENCE.CANCELADO },
    ],
    value: null,
  });
  const { register, handleSubmit, errors, setValue } = useForm();
  const { addToast } = useToasts();
  let { id } = match.params;

  const fetchOccurrence = async () => {
    const { data } = await webservice.get(`/occurrences`);
    if (data.message || data.error) {
      addToast(`${data.message || data.error.message}`, {
        appearance: 'warning',
        autoDismiss: true,
      });
      return;
    }
    setOccurrence(data);
  };

  const columns = [
    {
      key: 'code',
      label: 'Código',
      sort: true,
      content: (row) => row.id,
    },
    {
      key: 'status',
      label: 'Status',
      sort: true,
      content: (row) => getStatusOccurrence(row.status),
    },
    {
      key: 'name',
      label: 'Responsável',
      sort: true,
      content: (row) => row?.requester?.name,
    },
    {
      key: 'title',
      label: 'Titulo de Ocorrência',
      sort: true,
    },
    {
      key: 'date',
      label: 'Data de Criação',
      sort: true,
      width: '100%',
      content: (row) => moment(row.created_at).format('DD/MM/YYYY HH:mm'),
    },
  ];

  const save = async (values) => {
    const { data } = await webservice[id ? 'put' : 'post'](
      `/occurrences${id ? `/${id}` : ''}`,
      id ? { ...values, status: statusOccurrence.value } : values
    );
    if (!data.message || data.error) {
      addToast(`'${values.title}' salvo com sucesso!`, {
        appearance: 'success',
        autoDismiss: true,
      });
      onCancel();
    } else {
      addToast(data.error.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    await fetchOccurrence();
  };

  const onEdit = async (occurrenceRow) => {
    history.push(`/occurrence/${occurrenceRow.id}`);
    setValue('title', occurrenceRow.title);
    setStatusOccurrence({
      ...statusOccurrence,
      value: occurrenceRow._original.status,
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onCancel = () => {
    setValue('title', '');
    setValue('message', '');
    setStatusOccurrence({ ...statusOccurrence, value: '' });
    history.replace(`/occurrence`);
  };

  useEffect(() => {
    fetchOccurrence();
  }, []);

  return (
    <>
      <SubHeader title="Ocorrências" subtitle="da empresa" />
      <BvsContainer>
        <div className="col-md-12">
          <BvsPanel title="Incluir">
            <BvsForm onSubmit={handleSubmit(save)}>
              <div className="row">
                <div
                  className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
                  style={{ marginTop: 'auto' }}
                >
                  <BvsInputV2
                    ref={register({ required: true })}
                    name="title"
                    label="Titulo da Ocorrência"
                    required
                    error={errors.title}
                  />
                </div>
                {id && (
                  <div
                    className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
                    style={{ marginTop: 'auto' }}
                  >
                    <BvsDropdown
                      name="statusOccurrence"
                      label="Status da Ocorrência"
                      value={statusOccurrence.value}
                      onSelectedItem={(value) =>
                        setStatusOccurrence({ ...statusOccurrence, value })
                      }
                      loading={statusOccurrence.loading}
                      options={statusOccurrence.options}
                    />
                  </div>
                )}
                {!id && (
                  <div
                    className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
                    style={{ marginTop: 'auto' }}
                  >
                    <BvsInputV2
                      ref={register({ required: true })}
                      name="message"
                      label="Mensagem"
                      required
                      error={errors.message}
                    />
                  </div>
                )}
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <BvsButton
                    type="submit"
                    title="Salvar"
                    icon="fa fa-save"
                    customStyle={{ marginTop: '40px', float: 'right' }}
                  />
                  {id && (
                    <BvsButton
                      type="button"
                      title="Cancelar"
                      icon="fa fa-save"
                      onClick={onCancel}
                      customStyle={{
                        marginTop: '40px',
                        marginRight: '10px',
                        float: 'right',
                      }}
                    />
                  )}
                </div>
              </div>
            </BvsForm>
          </BvsPanel>
        </div>

        <div className="col-md-12">
          <BvsPanel title="Relação de Ocorrências">
            <div className="col-md-12">
              <BvsTableV4
                tableKey="occurrence"
                columns={columns}
                rows={occurrence}
                rowKey="id"
                onRefresh={() => {
                  fetchOccurrence();
                }}
                actionOptions={{
                  width: '170px',
                }}
                actions={[
                  (row) => (
                    <>
                      <BvsButton
                        type="button"
                        className="btn btn-xs"
                        customStyle={{ marginRight: '5px' }}
                        icon={
                          <FontAwesome name="comment" color="#fff" size={12} />
                        }
                        onClick={() =>
                          history.push(`/occurrenceDetails/${row.id}`)
                        }
                      />
                      <BvsButton
                        type="button"
                        className="btn btn-xs"
                        customStyle={{ marginRight: '5px' }}
                        icon={
                          <FontAwesome name="edit" color="#fff" size={12} />
                        }
                        onClick={() => onEdit(row)}
                      />
                    </>
                  ),
                ]}
              />
            </div>
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default Occurrence;
