/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */

import React, { useEffect, useState } from 'react';

import { FontAwesome } from 'react-web-vector-icons';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { getStatus } from '~/system/convert';
import { DownloadFile, RenderStatusIconButton } from '~/system/helper';
import api from '~/services/api';
import SubHeader from '~/components/BvsSubHeader';
import BvsTableV4 from '../../components/BvsTableV4';
import BvsPanel from '~/components/BvsPanel';
import BvsContainer from '~/components/BvsContainer';
import BvsButton from '~/components/BvsButton';

const ProtocolsReceipts = ({ match }) => {
  const history = useHistory();
  const [enterprises, setEnterprises] = useState([]);
  const { setValue } = useForm();
  const { addToast } = useToasts();
  let { id } = match.params;

  const fetchProtocolsReceipts = async () => {
    const { data } = await api.get(`/protocolsReceipts`);
    if (data.message || data.error) {
      addToast(`${data.message || data.error.message}`, {
        appearance: 'warning',
        autoDismiss: true,
      });
    }

    setEnterprises(data);
  };

  const columns = [
    {
      key: 'status',
      label: 'Status',
      sort: true,
      content: (row) => {
        row.status_enum = row.status;
        row.status = getStatus(row.status);
      },
    },
    {
      key: 'cnpj',
      label: 'CNPJ Empresa',
      sort: true,
    },
    {
      key: 'address',
      label: 'Endereço de Entrega',
      sort: true,
    },
    {
      key: 'registration',
      label: 'Matrícula',
      sort: true,
    },
    {
      key: 'user',
      label: 'Usuário',
      sort: true,
    },
    {
      key: 'cpf',
      label: 'CPF',
      sort: true,
    },
    {
      key: 'codeBenefit',
      label: 'Codigo',
      sort: true,
    },
    {
      key: 'department',
      label: 'Departamento',
      sort: true,
    },
    {
      key: 'codeDepartment',
      label: 'Codigo',
      sort: true,
    },
    {
      key: 'description',
      label: 'Descrição do Benefício',
      sort: true,
    },
    {
      key: 'value',
      label: 'Valor',
      sort: true,
    },
    {
      key: 'unitary',
      label: 'Unitário',
      sort: true,
    },
    {
      key: 'hiredin',
      label: 'Contratado em',
      sort: true,
    },
    {
      key: 'card',
      label: 'Cartão',
      sort: true,
      type: 'input',
      width: '100%',
    },
  ];

  const save = async (values, { target: form }) => {
    const { data } = await api[id ? 'put' : 'post'](
      `/protocolsReceipts${id ? `/${id}` : ''}`,
      values
    );
    if (!data.message || data.error) {
      addToast(`'${values.name}' salvo com sucesso!`, {
        appearance: 'success',
        autoDismiss: true,
      });

      onCancel();
      form.reset();
    } else {
      addToast(data.error.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    await fetchProtocolsReceipts();
  };

  const changeStatus = async (protocolsReceiptsId) => {
    const { data } = await api.put(
      `/protocolsReceipts/${protocolsReceiptsId}/status`
    );
    if (!data.message || data.error) {
      addToast(`Status atualizado com sucesso`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } else {
      addToast(data.error.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    await fetchProtocolsReceipts();
  };

  const onDelete = async (protocolsReceiptsId) => {
    const { data } = await api.delete(
      `/protocolsReceipts/${protocolsReceiptsId}`
    );
    if (!data.message || data.error) {
      addToast(`'${data.name}' excluido com sucesso!`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } else {
      addToast(data.error.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    await fetchProtocolsReceipts();
  };

  const onEdit = async (protocolsReceiptsId) => {
    const { data } = await api.get(`/protocolsReceipts/${protocolsReceiptsId}`);
    if (!data.message || data.error) {
      history.push(`/protocolsReceipts/${protocolsReceiptsId}`);
      setValue('code', data.code);
      setValue('name', data.name);
    } else {
      addToast(data.error.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onCancel = () => {
    setValue('code', '');
    setValue('name', '');
    history.replace(`/protocolsReceipts`);
  };

  useEffect(() => {
    fetchProtocolsReceipts();
  }, []);

  return (
    <>
      <SubHeader title="Protocolos e Recibos de Entrega" />
      <BvsContainer>
        <div className="col-md-12">
          <BvsPanel title="Resulta do Filtro">
            <div className="col-md-12">
              <BvsTableV4
                tableKey="enterprises"
                columns={columns}
                rows={enterprises}
                onRefresh={() => {
                  fetchProtocolsReceipts();
                }}
                rowKey="id"
                actions={[
                  (row) => (
                    <>
                      <BvsButton
                        type="button"
                        className="btn btn-xs"
                        customStyle={{ marginRight: '5px' }}
                        icon={
                          <FontAwesome name="edit" color="#fff" size={12} />
                        }
                        onClick={() => onEdit(row.id)}
                      />
                      <BvsButton
                        type="button"
                        className="btn btn-xs"
                        customStyle={{ marginRight: '5px' }}
                        icon={
                          <FontAwesome name="close" color="#fff" size={12} />
                        }
                        onClick={() => onDelete(row.id)}
                      />
                      <BvsButton
                        type="button"
                        className="btn btn-xs"
                        customStyle={{ marginRight: '5px' }}
                        icon={RenderStatusIconButton(row.status)}
                        onClick={() => changeStatus(row.id)}
                      />
                    </>
                  ),
                ]}
              />
            </div>
            <div>
              <BvsButton
                type="button"
                title="Selecionar Todos"
                icon="fa fa-print"
                customStyle={{ margin: '15px 5px' }}
              />
              <BvsButton
                type="button"
                title="Gerar Protocolo"
                icon=""
                customStyle={{ margin: '15px 5px' }}
              />
              {/* <BvsButton
                type="button"
                title="Excel"
                icon="fa fa-file-excel-o"
                customStyle={{ margin: '15px 5px' }}
                onClick={async () => {
                  DownloadFile(
                    '/departments/export/excel',
                    'benvs_exportacao_departamento.xlsx'
                    `benvs_exportacao_orders-${enterpriseActive?.info?.social_name
                    }-${moment().format(
                      'DD.MM.YYYY HH:mm:ss'
                    )}.xlsx`
                  );
                }}
              /> */}
              <BvsButton
                type="button"
                title="Subir Arquivo de Cartões"
                icon=""
                customStyle={{ margin: '15px 5px' }}
              />
              <BvsButton
                type="button"
                title="Já possui cartão - Todos Selecionados"
                icon=""
                customStyle={{ margin: '15px 5px' }}
              />
            </div>
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default ProtocolsReceipts;
