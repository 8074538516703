/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
import types from './types';

export function updateAdministrativeCard(payload) {
  return {
    type: types.UPDATE_ADMINISTRATIVE_CARD,
    payload,
  };
}

export function resetAdministrativeCard() {
  return {
    type: types.RESET_ADMINISTRATIVE_CARD,
  };
}
