/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import { useHistory } from 'react-router';
import { AntDesign } from 'react-web-vector-icons';
import { toMoney } from '~/system/helper';
import { getStatusOperational } from '~/system/convert';
import BvsContainer from '~/components/BvsContainer';
import BvsPanel from '~/components/BvsPanel';
import SubHeader from '~/components/BvsSubHeader';
import BvsButton from '~/components/BvsButton';
import FooterReports from '~/pages/Reports/FooterReports';
import HeaderReports from '~/pages/Reports/HeaderReports';
import * as UI from './style';
import useController from './hooks/useController';
import { mask } from 'remask';

const ReportsByStatus = ({ match }) => {
  const history = useHistory();
  const { reportData, getReportData } = useController();
  const { id } = match.params;

  const groupBy = () => {
    let tmp = reportData?.data?.benefits.reduce((result, currentValue) => {
      if (result) {
        let searched = result.findIndex(
          (i) => i.benefitGroupId === currentValue.benefitGroupId
        );
        if (searched > -1) {
          result[searched].data.push(currentValue);
        } else
          result.push({
            benefitGroupId: currentValue.benefitGroupId,
            name: currentValue.benefitGroupName,
            data: [currentValue],
          });
      } else {
        result = [
          {
            benefitGroupId: currentValue.benefitGroupId,
            name: currentValue.benefitGroupName,
            data: [currentValue],
          },
        ];
      }
      return result;
    }, null);

    return tmp;
  };

  const getSubTotal = (arr) => {
    const getValues = (benefitGroup, isRepasse = false) =>
      benefitGroup.employees.map((employee) =>
        isRepasse
          ? employee.transferAmount
          : employee.quantity * employee.unitCost
      );
    const calc = (isRepasse) =>
      arr
        .map((i) => getValues(i, isRepasse))
        .flat(1)
        .reduce((cur, old) => cur + old, 0);

    return {
      subTotal: calc(),
      subTotalRepasse: calc(true),
    };
  };

  const getFinalValue = () => {
    let benefitTotal = groupBy()
      .map((i) =>
        i.data.map((e) =>
          e.employees
            .map((h) => h.quantity * h.unitCost)
            .reduce((cur, old) => cur + old, 0)
        )
      )
      .flat(1)
      .reduce((cur, old) => cur + old, 0);

    let repasseTotal = groupBy()
      .map((r) =>
        r.data
          .map((e) =>
            e.employees
              .map((t) => t.transferAmount)
              .reduce((cur, old) => cur + old, 0)
          )
          .reduce((cur, old) => cur + old, 0)
      )
      .reduce((cur, old) => cur + old, 0);
    return {
      b: benefitTotal,
      r: repasseTotal,
      br: benefitTotal + repasseTotal,
    };
  };

  useEffect(() => {
    getReportData(id);
  }, []);
  return (
    <>
      <SubHeader
        title="Relatório de Status & Benefícios"
        subtitle="Por Status - Status por Benefícios"
      />
      <BvsContainer>
        <div>
          <BvsPanel title="Usuarios & Benefícios Status por Benefícios">
            <BvsButton
              type="button"
              title="Voltar"
              onClick={() => {
                history.goBack();
              }}
            />
            <HeaderReports />
            {reportData.loading && (
              <div className="p-3 row justify-content-center align-items-center">
                <div className="col-12 text-center">
                  <h4>Carregando...</h4>
                </div>
                <div className="col-4">
                  <div className="progress">
                    <div
                      className="progress-bar bg-warning progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
              </div>
            )}
            {!reportData?.loading && !reportData?.data && (
              <div
                className="row middle-xs center-xs"
                style={{ padding: 60, textAlign: 'center' }}
              >
                <div className="col-md-12">
                  <h2>Não foi possivel encontrar registros</h2>
                  <h2 style={{ marginTop: '10px' }}>
                    <AntDesign name="frowno" />
                  </h2>
                </div>
              </div>
            )}
            {!reportData?.loading && reportData?.data && (
              <>
                <BvsContainer
                  style={{
                    display: 'flex',
                    margin: '40px 20px 5px',
                    borderTop: '1.4px solid rgba(0, 0, 0, 0.1)',
                    borderBottom: '1.4px solid rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <UI.Titulo> Data de Emissão </UI.Titulo>
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'flex',
                    margin: '5px 20px 5px',
                    borderBottom: '1.4px solid rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <UI.Data>{reportData?.data?.dateEmission}</UI.Data>
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'block',
                    marginLeft: '40px',
                    marginRight: '40px',
                    marginBottom: '40px',
                    padding: '0px',
                  }}
                >
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Razão Social</th>
                        <th scope="col" />
                        <th scope="col">Código</th>
                        <th scope="col">CNPJ</th>
                        <th scope="col">Inscrição Estadual</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row" colSpan="2">
                          {reportData?.data?.socialName}
                        </td>
                        <td>{reportData?.data?.id}</td>
                        <td>{reportData?.data?.cnpj}</td>
                        <td>{reportData?.data?.stateRegistration}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Endereço</th>
                        <th scope="col">CEP</th>
                        <th scope="col">Bairro</th>
                        <th scope="col">Municipio</th>
                        <th scope="col">Estado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row">{reportData?.data?.street}</td>
                        <td>{reportData?.data?.zipcode}</td>
                        <td>{reportData?.data?.district}</td>
                        <td>{reportData?.data?.city}</td>
                        <td>{reportData?.data?.state}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Contato</th>
                        <th scope="col" />
                        <th scope="col">Telefone</th>
                        <th scope="col">Email</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row" colSpan="2">
                          {reportData?.data?.ownerName}
                        </td>
                        <td>{reportData?.data?.ownerPhone}</td>
                        <td colSpan="2">{reportData?.data?.ownerEmail}</td>
                      </tr>
                    </tbody>
                  </table>
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'block',
                    marginLeft: '40px',
                    marginRight: '40px',
                    marginBottom: '20px',
                  }}
                >
                  {reportData?.data?.benefits.map((benefit) => (
                    <>
                      <UI.Titulo2>
                        Usuario & Beneficios Status por Beneficios
                      </UI.Titulo2>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Codigo</th>
                            <th scope="col">Discriminição dos Beneficios</th>
                            <th scope="col" />
                            <th scope="col" />
                            <th scope="col" style={{ width: '10%' }}>
                              Mês Referência
                            </th>
                            <th scope="col">N.º de Pedido</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td scope="row">{benefit?.benefitCode}</td>
                            <td>{benefit?.subDescription}</td>
                            <td />
                            <td />
                            <td>{benefit?.benefitReference}</td>
                            <td>{reportData?.data?.orderId}</td>
                          </tr>
                        </tbody>
                      </table>

                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Matricula</th>
                            <th scope="col">Colaborador</th>
                            <th scope="col">CPF</th>
                            <th scope="col" style={{ width: '5%' }}>
                              Cod.
                            </th>
                            <th scope="col">Discriminição dos Beneficios</th>
                            <th scope="col" />
                            <th scope="col">Unitário (R$)</th>
                            <th scope="col" style={{ width: '4%' }}>
                              Qta.
                            </th>
                            <th scope="col">Valor Total</th>
                            <th scope="col">Cartão</th>
                            <th scope="col" style={{ textAlign: 'center' }}>
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {benefit?.employees.map((employee) => (
                            <tr>
                              <td scope="row">{employee?.registry}</td>
                              <td>{employee?.name}</td>
                              <td>{mask(employee?.cpf, '999.999.999-99')}</td>
                              <td>{benefit?.benefitCode}</td>
                              <td colSpan="2">{benefit?.subDescription}</td>
                              <td>{toMoney(employee?.unitCost)}</td>
                              <td>{employee?.quantity}</td>
                              <td>
                                {toMoney(
                                  employee?.unitCost * employee?.quantity
                                )}
                              </td>
                              <td>{employee?.benefitCard}</td>
                              <td>
                                <div
                                  data-tip={
                                    getStatusOperational(employee?.statusCode)
                                      .title
                                  }
                                >
                                  <UI.InfoStatus
                                    color={
                                      getStatusOperational(employee?.statusCode)
                                        .color
                                    }
                                  />
                                </div>
                                <ReactTooltip />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan="9" />
                            <td>
                              <strong>Benefícios</strong>
                            </td>
                            <td>
                              <strong>Repasse</strong>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="8" />
                            <td style={{ width: 130 }}>
                              <strong>Valor Total</strong>
                            </td>
                            <td>
                              {toMoney(
                                benefit?.employees
                                  ?.map((i) => i.unitCost * i.quantity)
                                  .reduce((r, a) => r + a, 0)
                              )}
                            </td>
                            <td>
                              {toMoney(
                                benefit?.employees
                                  ?.map((i) => i.transferAmount)
                                  .reduce((r, a) => r + a),
                                0
                              )}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </>
                  ))}
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'block',
                    marginLeft: '40px',
                    marginRight: '40px',
                    marginBottom: '40px',
                  }}
                >
                  <table className="table table-striped">
                    <thead
                      style={{
                        borderTop: '1.4px solid rgba(0, 0, 0, 0.1)',
                        borderBottom: '1.4px solid rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      <tr>
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" style={{ textAlign: 'center' }}>
                          Benefício
                        </th>
                        <th scope="col" style={{ textAlign: 'center' }}>
                          Repasse
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupBy().map((benefit) => {
                        return (
                          <tr
                            style={{
                              borderTop: '1.4px solid rgba(0, 0, 0, 0.1)',
                              borderBottom: '1.4px solid rgba(0, 0, 0, 0.1)',
                              backgroundColor: 'rgba(169, 156, 156, 0.05)',
                            }}
                          >
                            <td
                              colSpan="4"
                              scope="row"
                              style={{
                                textAlign: 'right',
                                fontWeight: 'normal',
                              }}
                            >
                              {benefit?.name}
                            </td>
                            <td
                              style={{
                                textAlign: 'center',
                                fontWeight: 'normal',
                              }}
                            >
                              {toMoney(getSubTotal(benefit.data).subTotal)}
                            </td>
                            <td
                              style={{
                                textAlign: 'center',
                                fontWeight: 'normal',
                              }}
                            >
                              {toMoney(
                                getSubTotal(benefit.data).subTotalRepasse
                              )}
                            </td>
                          </tr>
                        );
                      })}

                      <tr
                        style={{
                          borderBottom: '1.4px solid rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        <td
                          scope="row"
                          colSpan="4"
                          style={{
                            textAlign: 'right',
                          }}
                        >
                          Valor Total
                        </td>
                        <td
                          style={{ textAlign: 'center', fontWeight: 'normal' }}
                        >
                          {toMoney(getFinalValue().b)}
                        </td>
                        <td
                          style={{ textAlign: 'center', fontWeight: 'normal' }}
                        >
                          {toMoney(getFinalValue().r)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan="5"
                          scope="row"
                          style={{ textAlign: 'right', fontWeight: 'bold' }}
                        >
                          Valor Total:
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {toMoney(getFinalValue().br)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </BvsContainer>
              </>
            )}
            <FooterReports periodUse={reportData?.data?.periodUse} />
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default ReportsByStatus;
