/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateUser, resetUser } from '~/store/modules/forms/FormLogin/actions';
import { logIn } from '~/store/modules/auth/actions';
import webservice from '~/services/api';
import BvsContainer from '~/components/BvsContainer';
import BvsForm from '~/components/BvsForm/index';
import BvsInput from '~/components/BvsInput';
import BvsInputPassword from '~/components/BvsInputPassword';
import BvsButton from '~/components/BvsButton/index';
import BvsToastResult from '~/components/BvsToastResult';
import {
  LoginWrapper,
  LoginTitle,
  LoginContent,
  LoginSubTitle,
  FormInline,
  InputGroup,
  Divider,
  ForgetPassword,
} from './style';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';

const FormLogin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, reset, form } = useSelector((state) => state.formLogin);
  const [preview, setPreview] = useState(true);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(logIn(user));
  };

  const setUser = (key, value) => {
    dispatch(
      updateUser({
        user: {
          ...user,
          [key]: value,
        },
      })
    );
  };

  const setReset = (key, value) => {
    dispatch(
      updateUser({
        reset: {
          ...reset,
          [key]: value,
        },
      })
    );
  };

  const onRecovery = async (e) => {
    e && e.preventDefault();
    try {
      dispatch(showLoading());
      dispatch(
        updateUser({
          form: {
            saving: true,
          },
        })
      );
      const { data } = await webservice.post('forgotPassword', {
        email: reset.recovery,
      });
      if (data.error || data.message) throw data;
      toast.success(
        <BvsToastResult message="Você receberá no seu e-mail a nova senha" />
      );
      dispatch(resetUser());
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Falha ao resetar senha"
        />
      );
    } finally {
      dispatch(
        updateUser({
          form: {
            saving: false,
          },
        })
      );
      dispatch(hideLoading());
    }
  };

  return (
    <BvsContainer style={{}}>
      {preview ? (
        <LoginWrapper>
          <LoginContent>
            <div style={{ width: '100%' }}>
              <LoginTitle>
                <i className="icon-login" style={{ color: '#7E898F' }} />
                Login
              </LoginTitle>
              <LoginSubTitle>
                Entre com seu e-mail de usuário e sua senha para acessar o
                sistema
              </LoginSubTitle>
              <BvsForm onSubmit={onSubmit}>
                <FormInline className="d-none d-sm-flex">
                  <InputGroup>
                    <div className="col-xs-12 col-sm-12 col-md-6 middle-xs">
                      <BvsInput
                        name="username"
                        label="E-mail"
                        required
                        value={user.username}
                        onChange={(value) => setUser('username', value)}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 middle-xs">
                      <BvsInputPassword
                        label="Senha"
                        name="password"
                        required
                        value={user.password}
                        onChange={(value) => setUser('password', value)}
                      />
                    </div>
                  </InputGroup>
                  <div>
                    <BvsButton
                      title="Entrar"
                      customStyle={{ alignSelf: 'center' }}
                      type="submit"
                    />
                  </div>
                </FormInline>
                <div className="row d-block d-sm-none">
                  <div className="col-xs-12 col-sm-12 middle-xs p-1">
                    <BvsInput
                      name="username"
                      label="E-mail"
                      required
                      value={user.username}
                      onChange={(value) => setUser('username', value)}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 middle-xs p-1">
                    <BvsInputPassword
                      label="Senha"
                      name="password"
                      required
                      value={user.password}
                      onChange={(value) => setUser('password', value)}
                    />
                  </div>
                  <div className="d-block w-100 pt-3">
                    <BvsButton title="Entrar" fullWidth type="submit" />
                  </div>
                </div>
              </BvsForm>
              <Divider />
              <ForgetPassword>
                <div onClick={() => setPreview(false)}>
                  <i className="icon-lightbulb-line" />
                  Esqueceu a senha ?
                </div>
              </ForgetPassword>
              <div style={{ marginTop: '30px' }}>
                <p style={{ fontWeight: 'bold' }}>
                  Sou colaborador e{' '}
                  <a
                    style={{
                      cursor: 'pointer',
                    }}
                    className="link"
                    onClick={() => history.push(`/historyByCpf`)}
                  >
                    quero visualizar meu historico de compra
                  </a>
                </p>
              </div>
            </div>
          </LoginContent>
        </LoginWrapper>
      ) : (
        <BvsContainer>
          <LoginWrapper>
            <LoginContent>
              <div style={{ width: '100%' }}>
                <LoginTitle>Recupere sua senha</LoginTitle>
                <LoginSubTitle>
                  Entre com seu e-mail de usuário para receber uma nova senha de
                  acesso.
                </LoginSubTitle>
                <BvsForm onSubmit={onRecovery}>
                  <FormInline>
                    <InputGroup>
                      <div className="col-xs-12 col-sm-12 col-md-12">
                        <BvsInput
                          name="recovery"
                          label="E-mail"
                          required
                          value={reset.recovery}
                          onChange={(value) => setReset('recovery', value)}
                        />
                      </div>
                    </InputGroup>
                    <div>
                      <BvsButton
                        title="Enviar"
                        type="submit"
                        customStyle={{}}
                        disabled={form.saving}
                      />
                    </div>
                  </FormInline>
                </BvsForm>
                <Divider />
                <div
                  onClick={() => setPreview(true)}
                  style={{ cursor: 'pointer' }}
                >
                  <i className="fa fa-undo" />
                  &nbsp;&nbsp;Voltar ao Login...
                </div>
              </div>
            </LoginContent>
          </LoginWrapper>
        </BvsContainer>
      )}
    </BvsContainer>
  );
};

export default FormLogin;
