/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  form: {
    saving: false,
    disabled: false,
  },
  user: {
    username: null,
    password: null,
  },
  reset: {
    recovery: '',
  },
};

function formLogin(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_USER: {
      return produce(state, (draft) => {
        draft = { ...draft, ...action.payload };
        return draft;
      });
    }
    case types.RESET_USER: {
      return produce(state, (draft) => {
        draft.user = INITIAL_STATE.user;
        return draft;
      });
    }
    case types.RESET_FORGOT_PASSWORD: {
      return produce(state, (draft) => {
        draft.reset = INITIAL_STATE.reset;
        return draft;
      });
    }
    default:
      return state;
  }
}

export default formLogin;
