import { useDispatch, useSelector } from 'react-redux';
import { updateEnterprise } from '~/store/modules/forms/FormEnterprise/actions';

const useForm = () => {
  const { enterprise, form } = useSelector((state) => state.formEnterprise);
  const dispatch = useDispatch();

  const onFieldChange = (key, value) => {
    dispatch(
      updateEnterprise({
        form: { ...form },
        enterprise: { ...enterprise, [key]: value },
      })
    );
  };
  return {
    onFieldChange,
  };
};

export default useForm;
