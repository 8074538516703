/* eslint-disable no-console */
import md5 from 'md5';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import webservice from '~/services/api';
import { USER_TYPE } from '~/system/enum';
import { updateEnterpriseActive, updateEnterprises } from './actions';
import types from './types';

export function* getMyEnterprises() {
  // const enterprises = yield select(state => state.enterprises);
  const { user } = yield select((state) => state.auth);
  try {
    // yield
    const { data } = yield call(webservice.get, '/enterprises');

    if (data.message || data.error) {
      throw data.message;
    }

    yield put(updateEnterprises(data));
    if (user.type === USER_TYPE.USER) {
      if (data.length > 0) {
        let enterprise = data[data.length - 1];
        let tmp = {
          hash: md5(process.env.REACT_APP_SECRET_ENCRYPT + enterprise.id),
          info: enterprise,
        };
        yield put(updateEnterpriseActive(tmp));
        yield call(setEnterpriseActive, { enterpriseId: enterprise.id });
      } else yield put(updateEnterpriseActive(null));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* setEnterpriseActive({ enterpriseId, callback }) {
  try {
    // procuro os dados da empresas
    const { data } = yield call(webservice.get, `/enterprises/${enterpriseId}`);
    if (data.error || data.message) throw data;
    let hash = md5(process.env.REACT_APP_SECRET_ENCRYPT + data.id);
    webservice.defaults.headers['X-Enterprise'] = `${hash}`;
    let enterpriseData = {
      hash,
      info: { ...data },
    };

    yield put(updateEnterpriseActive(enterpriseData));
    callback && callback(enterpriseData);
  } catch (err) {
    console.log(err);
  }
}

export default all([
  takeLatest(types.GET_MY_ENTERPRISES, getMyEnterprises),
  takeLatest(types.SET_ENTERPRISE_ACTIVE, setEnterpriseActive),
]);
