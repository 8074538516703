import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { mask } from 'remask';
import BvsToastResult from '~/components/BvsToastResult';
import api from '~/services/api';
import * as actions from '~/store/modules/forms/FormEmployees/actions';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';

const useController = ({ employeeId = null, onSaved = () => {} }) => {
  const { employees } = useSelector((state) => state.formEmployees);
  const {
    justification: { form },
  } = useSelector((state) => state.employee);
  const dispatch = useDispatch();
  const history = useHistory();

  const populate = async (callback) => {
    dispatch(
      actions.updateEmployees({
        form: { saving: true },
      })
    );
    try {
      const { data } = await api.get(
        `/employees${employeeId ? `/${employeeId}` : ``}`
      );
      if (data.error || data.message) throw data;
      dispatch(
        actions.updateEmployees({
          employees: {
            ...data,
            admission_date: moment(data.admission_date).format('YYYY-MM-DD'),
            birthday: moment(data.birthday).format('YYYY-MM-DD'),
            cpf: mask(data?.cpf, '999.999.999-99'),
            rg: mask(data?.rg, '99.999.999-9'),
          },
        })
      );

      callback && callback();
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Não foi possivel localizar o colaborador"
        />
      );
    } finally {
      dispatch(
        actions.updateEmployees({
          form: { saving: false },
        })
      );
    }
  };
  const onSave = async () => {
    dispatch(showLoading());
    try {
      dispatch(
        actions.updateEmployees({
          form: { saving: true },
        })
      );
      let params = {
        ...employees,
        cpf: employees.cpf.replace(/[.]|[/]|[,]|[-]/g, ''),
        rg: employees.rg.replace(/[.]|[/]|[,]|[-]/g, ''),
      };

      if (employeeId) params.justification = form.justification;

      const { data } = await api[employeeId ? 'put' : 'post'](
        `/employees${employeeId ? `/${employeeId}` : ``}`,
        params
      );
      if (data.error || data.message) throw data;
      toast.success(<BvsToastResult message="Salvo com sucesso" />);
      onSaved && onSaved(data);
    } catch (err) {
      if (err?.errors)
        err?.errors.forEach((error) =>
          toast.error(
            <BvsToastResult
              type="error"
              err={error}
              message="Não foi possivel salvar esse registro no momento."
            />
          )
        );
      else
        toast.error(
          <BvsToastResult
            type="error"
            err={err}
            message="Não foi possivel salvar esse registro no momento."
          />
        );
    } finally {
      dispatch(hideLoading());
      dispatch(
        actions.updateEmployees({
          form: { saving: false },
        })
      );
    }
  };

  const reset = () => {
    dispatch(actions.resetEmployees());
    history.replace('/employees');
  };
  return { populate, onSave, reset };
};

export default useController;
