/* eslint-disable no-continue */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';
import api from '~/services/api';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';

const useAvailables = () => {
  const [availables, setAvailables] = useState([]);
  const { query } = useSelector((state) => state.filterCreateOrder);
  const dispatch = useDispatch();

  const fetch = async () => {
    dispatch(showLoading());
    let options = [];
    try {
      const { data } = await api.get(`/available`);
      if (data.error || data.message) throw data;
      options = data;
      /**
       * Filtro
       */
      // Se algum filtro tiver preenchido
      if (Object.values(query).some((i) => i !== null && i !== '')) {
        options = options.filter((item) => {
          let isValid = [];
          for (let key in query) {
            if ([null, '', undefined].includes(query[key])) continue;
            if (key === 'department_id')
              isValid.push(String(item?.department?.id) === String(query[key]));
            if (key === 'workday_id')
              isValid.push(String(item?.workday?.id) === String(query[key]));
            if (key === 'shipping_address_id')
              isValid.push(
                String(item?.shippingAddress?.id) === String(query[key])
              );
            if (key === 'employee_id')
              isValid.push(String(item?.id) === String(query[key]));
          }
          return isValid.every((i) => i);
        });
      }
      setAvailables(options);
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          message={
            err?.message ||
            err?.error?.message ||
            'Não foi possivel atualizar a lista neste momento.'
          }
          err={err}
        />
      );
      setAvailables(options);
    } finally {
      dispatch(hideLoading());
    }
  };

  const applyFilter = (e) => {
    e.preventDefault();

    fetch();
  };

  return {
    availables,
    fetch,
    applyFilter,
  };
};

export default useAvailables;
