/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const WrapperLoader = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  z-index: 110;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    pointer-events: none;
  }
`;
