/* eslint-disable no-param-reassign */
import produce from 'immer';
import { maxBy } from 'lodash';
import { STATUS } from '~/system/enum';
import types from './types';

const INITIAL_STATE = {
  form: {
    saving: false,
    disabled: false,
  },
  shipping_address: {
    title: '',
    state_uf: '',
    city: '',
    district: '',
    street: '',
    number: '',
    zipcode: '',
    complement: '',
    reference_point: '',
    address_type: null,
    status: STATUS.ATIVO,
    code: '',
  },
  responsibles: [
    {
      key: 1,
      id: null,
      name: '',
      phone: '',
      email: '',
      job_id: null,
      department_id: null,
    },
  ],
};

function formShippingAddress(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_SHIPPING_ADDRESS: {
      return produce(state, (draft) => {
        draft = { ...draft, ...action.payload };
        return draft;
      });
    }
    case types.ADD_RESPONSIBLE: {
      return produce(state, (draft) => {
        draft.responsibles.push({
          key: maxBy(draft.responsibles, 'key').key + 1,
          id: null,
          name: action?.payload?.name || '',
          phone: action?.payload?.phone || '',
          email: action?.payload?.email || '',
          job_id: action?.payload?.job_id || null,
          department_id: action?.payload?.department_id || null,
        });
        return draft;
      });
    }
    case types.REMOVE_RESPONSIBLE: {
      return produce(state, (draft) => {
        draft.responsibles = draft.responsibles.filter(
          (responsible) => responsible.key !== action.payload
        );
        return draft;
      });
    }
    case types.UPDATE_RESPONSIBLE: {
      return produce(state, (draft) => {
        draft = { ...draft, responsibles: action.payload };
        return draft;
      });
    }
    case types.RESET_RESPONSIBLE: {
      return produce(state, (draft) => {
        draft.responsibles = INITIAL_STATE.responsibles;
        return draft;
      });
    }
    case types.RESET_SHIPPING_ADDRESS: {
      return produce(state, (draft) => {
        draft.shipping_address = INITIAL_STATE.shipping_address;
        draft.responsibles = INITIAL_STATE.responsibles;
        return draft;
      });
    }
    default:
      return state;
  }
}

export default formShippingAddress;
