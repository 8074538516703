import { useState } from 'react';
import api from '~/services/api';

const useBenefitGroup = ({ loading = false, dropdown = true }) => {
  const [benefitGroup, setBenefitGroup] = useState({
    options: [],
    loading: false,
  });

  const fetch = async () => {
    setBenefitGroup({ ...benefitGroup, loading: true });
    let options = [];
    try {
      const { data } = await api.get('/benefitGroup');
      if (data.error || data.message) throw data;
      options = data.map((d) => ({ title: d.name, value: d.id, _original: d }));
      if (dropdown) options.unshift({ title: 'Nenhum', value: null });
      setBenefitGroup({ ...benefitGroup, options, loading: false });
    } catch (error) {
      setBenefitGroup({ ...benefitGroup, options, loading: false });
    }
  };
  return {
    benefitGroup,
    fetch,
  };
};

export default useBenefitGroup;
