import { useState } from 'react';
import api from '~/services/api';

const useBranchActivity = () => {
  const [branchActivity, setBranchActivity] = useState({
    options: [],
    loading: false,
  });

  const fetch = async () => {
    setBranchActivity({ ...branchActivity, loading: true });
    let options = [];
    try {
      const { data } = await api.get('/branchActivity');
      if (data.error || data.message) throw data;
      options = data.map((d) => ({ title: d.name, value: d.id }));
      setBranchActivity({ ...branchActivity, options });
    } catch (error) {
      setBranchActivity({ ...branchActivity, options });
    } finally {
      setBranchActivity({ ...branchActivity, options, loading: false });
    }
  };
  return {
    branchActivity,
    fetch,
  };
};

export default useBranchActivity;
