import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FontAwesome } from 'react-web-vector-icons';
import BvsAdmButton from '~/components/BvsAdmButton';
import BvsButton from '~/components/BvsButton';
import BvsContainer from '~/components/BvsContainer';
import BvsDropdown from '~/components/BvsDropdown';
import BvsForm from '~/components/BvsForm';
import BvsPanel from '~/components/BvsPanel';
import { toast } from 'react-toastify';
import SubHeader from '~/components/BvsSubHeader';
import useBenefitGroup from '~/hooks/useBenefitGroup';
import useDepartments from '~/hooks/useDepartments';
import useEmployees from '~/hooks/useEmployees';
import { useSelectedItems } from '~/hooks/useSelectedItems';
import useShippingAddress from '~/hooks/useShippingAddress';
import BvsToastResult from '~/components/BvsToastResult';
import useWorkdays from '~/hooks/useWorkday';
import useAvailables from '~/pages/Order/CreateOrder/hooks/useAvailables';
import useFilter from '~/pages/Order/CreateOrder/hooks/useFilter';
import { updateOrder, resetOrder } from '~/store/modules/order/actions';
import { USER_TYPE } from '~/system/enum';
import BvsTableV4 from '../../../components/BvsTableV4';

const CreateOrder = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { shippingAddress, fetch: getShippingAddress } = useShippingAddress();
  const { departments, fetch: getDepartments } = useDepartments({});
  const { workdays, fetch: getWorkdays } = useWorkdays();
  const { benefitGroup, fetch: getBenefitGroup } = useBenefitGroup({
    loading: false,
  });
  const { employees, fetch: getEmployees } = useEmployees();
  const { availables, fetch: getAvailables, applyFilter } = useAvailables();
  const { onFieldChange } = useFilter();
  const { query } = useSelector((state) => state.filterCreateOrder);
  const columns = [
    {
      key: 'name',
      label: 'Nome do Beneficiario',
      sort: true,
    },
    {
      key: 'cpf_cnpj',
      label: 'CPF/CNPJ',
      sort: true,
    },
    {
      key: 'card_number',
      label: 'Cartão Administrativo',
      sort: true,
    },
    {
      key: 'department',
      label: 'Departamento',
      sort: true,
      content: (row) => row?.department?.name,
    },
    {
      key: 'job',
      label: 'Cargo',
      sort: true,
      content: (row) => row?.job?.name,
    },
    {
      key: 'type',
      label: 'Tipo',
      sort: true,
      width: '100%',
    },
  ];

  // #region Redux - States
  const { order } = useSelector((state) => state.order);
  const { user } = useSelector((state) => state.auth);
  // #endregion

  // #region State Local
  // responsavel pelo datagrid
  const { selectedItems, onSelect } = useSelectedItems({
    rows: availables,
    key: 'id',
  });

  // #endregion
  // #region Methods

  /**
   * @description Responsável por direcionar para tela de editar colaborador
   * @param {*} employeeId
   */
  const onEdit = (employeeId) => {};

  const proceed = () => {
    if (selectedItems.length === 0) {
      toast.warn(
        <BvsToastResult
          type="warn"
          message="Escolha ao menos um Funcionario para prosseguir"
        />
      );
    } else {
      dispatch(
        updateOrder({
          ...order,
          employees: selectedItems,
        })
      );
      history.push('/order/detail');
    }
  };
  // #endregion

  useEffect(() => {
    getAvailables();
    getShippingAddress();
    getDepartments();
    getWorkdays();
    getBenefitGroup();
    getEmployees();
  }, []);

  return (
    <>
      <SubHeader
        title="Fazer Pedido Integral"
        subtitle="Personalizado por Usuário"
      />
      <BvsContainer>
        {user?.type === USER_TYPE.ADMIN && <BvsAdmButton />}
        <div className="row">
          <div className="col-md-12">
            <BvsPanel title="Filtrar Pedido">
              <BvsForm onSubmit={applyFilter}>
                <div className="row">
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                    <BvsDropdown
                      title="Selecione um endereço..."
                      name="shippingAddress"
                      label="Endereços"
                      value={query.shipping_address_id}
                      onSelectedItem={(value) =>
                        onFieldChange('shipping_address_id', value)
                      }
                      loading={shippingAddress.loading}
                      options={shippingAddress.options}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                    <BvsDropdown
                      title="Selecione um departamento..."
                      name="department"
                      label="Departamento"
                      value={query.department_id}
                      onSelectedItem={(value) =>
                        onFieldChange('department_id', value)
                      }
                      loading={departments.loading}
                      options={departments.options}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                    <BvsDropdown
                      title="Selecione um período..."
                      name="workday"
                      label="Período"
                      value={query.workday_id}
                      onSelectedItem={(value) =>
                        onFieldChange('workday_id', value)
                      }
                      loading={workdays.loading}
                      options={workdays.options}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                    <BvsDropdown
                      title="Selecione um grupo..."
                      name="benefitGroup"
                      label="Grupo de Beneficios"
                      value={query.benefit_group_id}
                      onSelectedItem={(value) =>
                        onFieldChange('benefit_group_id', value)
                      }
                      loading={benefitGroup.loading}
                      options={benefitGroup.options}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                    <BvsDropdown
                      title="Selecione um colaborador..."
                      name="employees"
                      label="Usuário"
                      search
                      value={query.employee_id}
                      onSelectedItem={(value) =>
                        onFieldChange('employee_id', value)
                      }
                      loading={employees.loading}
                      options={employees.options}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                    <BvsButton
                      type="submit"
                      title="Filtrar"
                      icon="fa fa-filter"
                    />
                  </div>
                </div>
              </BvsForm>
            </BvsPanel>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <BvsPanel title="Resultado do Filtro">
              <div className="col-md-12">
                <BvsTableV4
                  columns={columns}
                  rows={availables}
                  onRefresh={() => getAvailables()}
                  onSelect={onSelect}
                  selectedItems={selectedItems}
                  rowKey={(r) => r.id}
                  actionOptions={{ width: '110px' }}
                  actions={[
                    (row) => (
                      <>
                        <BvsButton
                          type="button"
                          className="btn btn-xs"
                          customStyle={{ marginRight: '5px' }}
                          icon={
                            <FontAwesome name="edit" color="#fff" size={12} />
                          }
                          onClick={() => onEdit(row.id)}
                        />
                      </>
                    ),
                  ]}
                />
                <div>
                  <BvsButton
                    type="button"
                    title="Selecionar todos"
                    icon=""
                    customStyle={{ marginTop: '30px' }}
                  />
                  <BvsButton
                    type="button"
                    title="Avançar"
                    icon=""
                    customStyle={{ marginTop: '30px', float: 'right' }}
                    onClick={() => proceed()}
                  />
                </div>
              </div>
            </BvsPanel>
          </div>
        </div>
      </BvsContainer>
    </>
  );
};

export default CreateOrder;
