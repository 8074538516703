import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import api from '~/services/api';
import BvsToastResult from '~/components/BvsToastResult';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';
import { toast } from 'react-toastify';

const useController = ({ whenImportComplete: pWhenImportComplete = null }) => {
  const [showModal, setShowModal] = useState({
    show: false,
    id: null,
  });
  const [imports, setImport] = useState({
    options: [],
    loading: false,
  });

  const { import_registers } = useSelector((state) => state.formImport);
  const [cursorLog, setCursorLog] = useState(null);

  // const [file, setFile] = useState(null);
  const { socket } = useSelector((state) => state.app);

  const dispatch = useDispatch();
  const onSave = async (e) => {
    e.preventDefault();

    dispatch(showLoading());
    try {
      let formData = new FormData();
      formData.append('file', import_registers.file);
      formData.append('socketId', socket?.id);
      formData.append('identification', import_registers.identification);
      const { data } = await api.post('/register/excel/import', formData, {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      });

      pWhenImportComplete && pWhenImportComplete(data);
      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
      toast.error(
        <BvsToastResult
          type="error"
          err={error}
          message="Falha ao consultar pedidos."
        />
      );
    } finally {
      fetch();
    }
  };
  const fetch = async () => {
    setImport({ ...imports, loading: true });
    let options = [];
    try {
      const { data } = await api.get('/imports/register');
      if (data.error || data.message) throw data;
      options = data;
      setImport({ ...imports, options, loading: false });
    } catch (error) {
      setImport({ ...imports, options, loading: false });
    }
  };

  const chooseModal = (show, id) => {
    setShowModal({ ...showModal, show, id });
  };

  const getLog = async (logId) => {
    const { data } = await api.get(`/registers/imports/log/${logId}`);
    setCursorLog(data);
  };

  return {
    fetch,
    imports,
    onSave,
    showModal,
    chooseModal,
    getLog,
    setCursorLog,
    cursorLog,
  };
};

export default useController;
