/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { FontAwesome } from 'react-web-vector-icons';

import * as UI from './style';

const BvsDropdown = ({
  title = '',
  options = [],
  onSelectedItem,
  label = '',
  style = {},
  value,
  disabled = false,
  search = false,
  loading = false,
  children,
  hasModal = false,
  onClick: pOnClick,
  required = false,
}) => {
  const dropdownRef = React.createRef();
  const [listOpen, setListOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState(title);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const toggleList = (e) => {
    e.stopPropagation();
    setListOpen(!listOpen, options);
  };

  const handleSelectedItem = (selectedValue, option) => {
    setHeaderTitle(option.title);
    setListOpen(false);
    setSelectedItem(option);
    if (onSelectedItem) onSelectedItem(selectedValue, option);
  };
  let lostFocus = true;

  const lostFocusEvent = () => {
    lostFocus = true;
  };
  const enterFocusEvent = () => {
    lostFocus = false;
  };

  const clickEvent = (e) => {
    setListOpen(false);
    window.removeEventListener('click', clickEvent);
  };

  useEffect(() => {
    let dropdown = null;
    if (dropdownRef.current) {
      dropdown = dropdownRef.current;
      dropdown.addEventListener('mouseleave', lostFocusEvent);
      dropdown.addEventListener('mouseenter', enterFocusEvent);
      // window.addEventListener('click', clickEvent);
    }
    return () => {
      if (dropdown) {
        dropdown.removeEventListener('mouseleave', lostFocusEvent);
        dropdown.removeEventListener('mouseenter', enterFocusEvent);
        window.removeEventListener('click', clickEvent);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);
  useEffect(() => {
    if (listOpen) window.addEventListener('click', clickEvent);
  }, [listOpen]);
  useEffect(() => {
    if (value !== null && options.length >= 0) {
      let searched = options.find((i) => i.value === value);
      if (searched) {
        setHeaderTitle(searched.title);
        setListOpen(false);
        setSelectedItem(searched);
      }
    } else {
      setHeaderTitle(title);
      setListOpen(false);
      setSelectedItem(null);
    }
  }, [options, value, loading]);

  return (
    <UI.Dropdown style={{ ...style }} disabled={disabled}>
      {label && (
        <UI.DropdownLabel>
          {label} {required && <span>*</span>}
        </UI.DropdownLabel>
      )}
      <UI.DropdownHeader
        loading={loading}
        disabled={disabled}
        onClick={
          !disabled
            ? !loading && !hasModal
              ? (e) => toggleList(e)
              : hasModal
              ? () => pOnClick()
              : () => {}
            : () => {}
        }
      >
        <UI.DropdownHeaderTitle disabled={disabled}>
          {loading ? 'Carregando...' : headerTitle}
        </UI.DropdownHeaderTitle>
        {listOpen ? (
          <FontAwesome name="angle-up" color="#beddcd" size={20} />
        ) : (
          <FontAwesome name="angle-down" color="#beddcd'" size={20} />
        )}
      </UI.DropdownHeader>
      {loading && (
        <UI.DropdownLoading>
          <i className="fa fa-circle-o-notch fa-spin fa-fw" />
        </UI.DropdownLoading>
      )}
      {!hasModal && listOpen && (
        <UI.DropdownList
          ref={dropdownRef}
          hasLabel={!!label}
          onClick={(e) => e.stopPropagation()}
        >
          {search && (
            <UI.DropdownListItem
              search
              style={{
                position: 'sticky !important',
                paddingBottom: '5px',
                top: 0,
                zIndex: 10,
              }}
            >
              <input
                placeholder="Pesquisar"
                style={{
                  fontSize: '0.78rem',
                  padding: 5,
                  position: 'relative',
                  top: 0,
                }}
                value={searchQuery}
                onChange={({ target: { value: searchValue = '' } }) =>
                  setSearchQuery(searchValue)
                }
              />
            </UI.DropdownListItem>
          )}
          {options
            .filter(
              searchQuery !== ''
                ? (opt) =>
                    !String(opt.title)
                      .toLowerCase()
                      .indexOf(searchQuery.toLowerCase()) > 0
                : (i) => true
            )
            .map((option) => (
              <UI.DropdownListItem
                key={JSON.stringify(option)}
                onClick={() => handleSelectedItem(option.value, option)}
                className={
                  selectedItem &&
                  selectedItem.value === option.value &&
                  'selected'
                }
              >
                {option.title}
              </UI.DropdownListItem>
            ))}
        </UI.DropdownList>
      )}
      {hasModal && <> {children}</>}
    </UI.Dropdown>
  );
};

export default BvsDropdown;
