import { number } from 'prop-types';
import React, { useEffect } from 'react';
import BvsButton from '~/components/BvsButton';

const BvsButtonGroup = ({ value = 1, name, onChange, options = [], label }) => {
  return (
    <>
      <span style={{ fontWeight: 'bold', color: '#616161' }}>{label}</span>
      <div style={{ display: 'flex' }}>
        {options.map((item) => {
          return (
            <div key={item.value}>
              <BvsButton
                name={name}
                type="button"
                title={item.title}
                icon=""
                customStyle={{
                  margin: '15px 5px',
                  backgroundColor: item.value === value ? 'rgb(168,30,33)' : '',
                }}
                onClick={() => {
                  onChange && onChange(name, item.value);
                }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default BvsButtonGroup;
