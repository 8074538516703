import produce from 'immer';
import types from './types';
import { BENEFIT_GENERIC_STATUS } from '~/system/enum';

const INITIAL_STATE = {
  '@module/generic': {
    '@form/file': null,
    '@components/loading': null,
    '@modalVisible': false,
    '@status': BENEFIT_GENERIC_STATUS.INITIAL,
    '@data': null,
    '@dropdownBenefits': [],
  },
};

function benefit(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_STATE: {
      return produce(state, (draft) => {
        draft[action.payload.module][action.payload.key] = action.payload.value;
        return draft;
      });
    }

    case types.RESET_STATE: {
      return produce(state, (draft) => {
        const { module, key } = action.payload;
        if (key) {
          draft[module][key] = INITIAL_STATE[module][key];
        } else draft[module] = INITIAL_STATE[module];

        return draft;
      });
    }

    default:
      return state;
  }
}

export default benefit;
