import moment from 'moment';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';
import { DownloadFile } from '~/system/helper';

const useController = () => {
  const { query } = useSelector((state) => state.filterExport);
  const { enterpriseActive } = useSelector((state) => state.enterprises);
  const exportExcel = () => {
    const urlParams = new URLSearchParams(query).toString();
    toast.info(
      <BvsToastResult message="Seu download iniciará em breve, já recebemos sua solicitação" />
    );
    DownloadFile(
      `/register/excel/export?${urlParams}`,
      `exportacao de cadastros-${
        enterpriseActive?.info?.social_name
      }-${moment().format('DD.MM.YYYY HH:mm:ss')}.xlsx`
    );
  };
  return { exportExcel };
};

export default useController;
