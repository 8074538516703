/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
/* eslint-disable no-empty-pattern */
/* eslint-disable no-empty */

import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { MaterialIcons } from 'react-web-vector-icons';
import { USER_TYPE } from '~/system/enum';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';
import * as actions from '~/store/modules/forms/FormEnterprise/actions';
import viacep from '~/services/viacep';
import BvsAdmButton from '~/components/BvsAdmButton';
import BvsButton from '~/components/BvsButton';
import BvsContainer from '~/components/BvsContainer';
import BvsDropdown from '~/components/BvsDropdown';
import BvsForm from '~/components/BvsForm';
import BvsInput from '~/components/BvsInput';
import BvsPanel from '~/components/BvsPanel';
import SubHeader from '~/components/BvsSubHeader';
import BvsInputCurrency from '~/components/BvsInputCurrency';
import BvsInputPercentage from '~/components/BvsInputPercentage';
import BvsDivider from '~/components/BvsDivider';
import Termo from './termo';
import useEnterprise from '~/pages/Enterprise/hooks/useEnterprise';
import useBranchActivity from '~/hooks/useBranchActivity';
import useDepartments from '~/hooks/useDepartments';
import useJobs from '~/hooks/useJobs';
import useForm from '~/pages/Enterprise/hooks/useForm';
import useFees from '~/pages/Enterprise/hooks/useFees';
import useUsers from '~/hooks/useUser';

const Enterprise = ({ match }) => {
  let { id } = match.params;

  const {
    fees,
    loadFees: getFees,
    updateFees,
    togglePercent,
    onChangeFee,
  } = useFees();

  const {
    agree,
    onPopulate,
    toggleAgree,
    toggleTerms,
    termsVisible,
    goToAddress,
    onSave,
  } = useEnterprise({
    enterpriseId: id,
    updateFees,
    fees,
  });
  const { onFieldChange } = useForm();
  const { enterpriseActive } = useSelector((state) => state.enterprises);
  const { user } = useSelector((state) => state.auth);
  const { branchActivity, fetch: getBranchActivity } = useBranchActivity();
  const { departments, fetch: getDepartments } = useDepartments({});
  const { users, fetch: getUsers } = useUsers({});

  const { jobs, fetch: getJobs } = useJobs({});

  const history = useHistory();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const { enterprise, form } = useSelector((state) => state.formEnterprise);

  useEffect(() => {
    const fetch = async () => {
      // Carrego os dropdowns necessários
      dispatch(showLoading());
      getBranchActivity();
      dispatch(hideLoading());
      getFees();
      getUsers();
      if (user.type === USER_TYPE.USER && enterpriseActive?.info?.id) {
        history.replace(`/enterprises/${enterpriseActive?.info?.id}`);
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    (async () => {
      if (id) {
        onPopulate();
        getDepartments();
        getJobs();
      }
    })();
  }, [id]);

  /**
   * ANCHOR se mudou de tela
   */
  useEffect(() => {
    dispatch(actions.resetEnterprise());
  }, [path]);

  return (
    <>
      <SubHeader
        title="Dados da Empresa"
        subtitle="Mantenha os dados atualizados"
      />
      <BvsContainer>
        {user?.type === USER_TYPE.ADMIN && <BvsAdmButton />}
        <BvsForm onSubmit={onSave}>
          <div style={{ display: !termsVisible ? 'block' : 'none' }}>
            {/* Dados da Empresa */}
            <BvsPanel
              title="Dados da Empresa"
              style={{ display: 'flex', flexWrap: 'wrap' }}
            >
              <div className="row m-2">
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4  p-3">
                  <BvsInput
                    value={enterprise.email}
                    onChange={(value) => {
                      onFieldChange('email', value);
                    }}
                    name="email"
                    label="E-mail"
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="cnpj"
                    label="CNPJ"
                    mask="cpf/cnpj"
                    required
                    value={enterprise.cnpj}
                    onChange={(value) => {
                      onFieldChange('cnpj', value);
                    }}
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="social_name"
                    label="Razão Social"
                    required
                    value={enterprise.social_name}
                    onChange={(value) => onFieldChange('social_name', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="fantasy_name"
                    label="Nome Fantasia"
                    required
                    value={enterprise.fantasy_name}
                    onChange={(value) => onFieldChange('fantasy_name', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="state_registration"
                    label="Inscrição Estatual"
                    value={enterprise.state_registration}
                    onChange={(value) =>
                      onFieldChange('state_registration', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="municipal_registration"
                    label="Inscrição Municipal"
                    value={enterprise.municipal_registration}
                    onChange={(value) =>
                      onFieldChange('municipal_registration', value)
                    }
                  />
                </div>
                {user.type === USER_TYPE.ADMIN && (
                  <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 p-3">
                    <BvsDropdown
                      title="Selecione um Responsável"
                      name="responsible_enterprise_id"
                      label="Responsável pela Empresa"
                      search
                      value={enterprise.responsible_enterprise_id}
                      onSelectedItem={(value) =>
                        onFieldChange('responsible_enterprise_id', value)
                      }
                      loading={users.loading}
                      options={users.options}
                    />
                  </div>
                )}
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 p-3">
                  <BvsDropdown
                    title="Selecione um Ramo..."
                    name="branch_activity_id"
                    label="Ramo de Atividade"
                    value={enterprise.branch_activity_id}
                    search
                    onSelectedItem={(value) =>
                      onFieldChange('branch_activity_id', value)
                    }
                    loading={branchActivity.loading}
                    options={branchActivity.options}
                  />
                </div>
              </div>
            </BvsPanel>
            {/* Financeiro */}
            <BvsPanel title="Financeiro">
              <div className="row m-2">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInputPercentage
                    name="tax_general_manager"
                    label="Taxa de Gestão Geral (%)"
                    disabled={user?.type === USER_TYPE.USER}
                    value={enterprise.tax_general_manager}
                    onChange={(_, value) =>
                      onFieldChange('tax_general_manager', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInputCurrency
                    name="other_cost"
                    label="Outros Valores"
                    currency="BRL"
                    disabled={user?.type === USER_TYPE.USER}
                    value={enterprise.other_cost}
                    onChange={(_, value) => onFieldChange('other_cost', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInputCurrency
                    name="delivery_cost"
                    label="Entrega (R$)"
                    currency="BRL"
                    disabled={user?.type === USER_TYPE.USER}
                    value={enterprise.delivery_cost}
                    onChange={(_, value) =>
                      onFieldChange('delivery_cost', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInputCurrency
                    name="discount"
                    label="Desconto"
                    currency="BRL"
                    disabled={user?.type === USER_TYPE.USER}
                    value={enterprise.discount}
                    onChange={(_, value) => onFieldChange('discount', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="delivery_day"
                    label="Prazo de entregas (dias)"
                    disabled={user?.type === USER_TYPE.USER}
                    value={enterprise.delivery_day}
                    onChange={(value) => onFieldChange('delivery_day', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="manager_account"
                    label="Gestor da Conta"
                    value={enterprise.manager_account}
                    onChange={(value) =>
                      onFieldChange('manager_account', value)
                    }
                    disabled={user?.type === USER_TYPE.USER}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="new_business"
                    label="Novos Negócios"
                    value={enterprise.new_business}
                    onChange={(value) => onFieldChange('new_business', value)}
                    disabled={user?.type === USER_TYPE.USER}
                  />
                </div>
              </div>
              <div className="row m-2">
                <div
                  className="col-12"
                  style={{
                    fontSize: '20px',
                    color: '#A81E21',
                    alignItens: 'center',
                    marginTop: '20px',
                  }}
                >
                  Custo Gestão
                </div>
              </div>
              <BvsDivider />
              <div className="row m-2">
                {fees.map((fee) => (
                  <>
                    <div
                      key={fee.id}
                      className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3"
                      style={{ position: 'relative' }}
                    >
                      {!fee.percent && (
                        <BvsInputCurrency
                          name={`enterprise_fees_${fee.id}_money`}
                          label={`${fee.name} (R$)`}
                          currency="BRL"
                          disabled={user?.type === USER_TYPE.USER}
                          onChange={(_, value) => {
                            onChangeFee(fee.id, value, false);
                          }}
                          value={fee.cost}
                        />
                      )}
                      {fee.percent && (
                        <BvsInputPercentage
                          name={`enterprise_fees_${fee.id}_money`}
                          label={`${fee.name} (%)`}
                          disabled={user?.type === USER_TYPE.USER}
                          onChange={(_, value) => {
                            onChangeFee(fee.id, value, true);
                          }}
                          value={fee.percentage}
                        />
                      )}
                      <i
                        className={`fa fa-${
                          fee.percent ? 'percent' : 'dollar'
                        }`}
                        onClick={() => {
                          user?.type !== USER_TYPE.USER &&
                            togglePercent(fee.id);
                          // changeFeeType(fee.id);
                        }}
                        style={{
                          position: 'absolute',
                          top: '22px',
                          right: '18px',
                          cursor: 'pointer',
                        }}
                      />
                      <div />
                    </div>
                  </>
                ))}
              </div>
            </BvsPanel>
            {/* Endereço da Empresa */}
            <BvsPanel
              title="Endereço da Empresa"
              style={{ display: 'flex', flexWrap: 'wrap' }}
            >
              <div className="row m-2">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="zipcode"
                    label="CEP"
                    mask="cep"
                    value={enterprise.zipcode}
                    onChange={(value) => onFieldChange('zipcode', value)}
                    onBlur={async (v) => {
                      let cep = v?.target?.value?.replace(/[^\d]/g, '');
                      if (cep.length >= 8) {
                        try {
                          const { data } = await viacep.get(`/${cep}/json/`);
                          dispatch(
                            actions.updateEnterprise({
                              enterprise: {
                                ...enterprise,
                                state: data?.uf,
                                ibge: data?.ibge,
                                city: data?.localidade,
                                street: data?.logradouro,
                                district: data?.bairro,
                              },
                            })
                          );
                        } catch (err) {
                          return false;
                        }
                      }
                    }}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-2 p-3">
                  <BvsInput
                    name="ibge"
                    label="IBGE"
                    required
                    value={enterprise.ibge}
                    onChange={(value) => onFieldChange('ibge', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-2 p-3">
                  <BvsInput
                    name="state"
                    label="Estado"
                    maxLength={2}
                    required
                    value={enterprise.state}
                    onChange={(value) => onFieldChange('state', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col lg-4 p-3">
                  <BvsInput
                    name="city"
                    label="Cidade"
                    required
                    value={enterprise.city}
                    onChange={(value) => onFieldChange('city', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-5 p-3">
                  <BvsInput
                    name="street"
                    label="Endereço"
                    required
                    value={enterprise.street}
                    onChange={(value) => onFieldChange('street', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-2 p-3">
                  <BvsInput
                    name="number"
                    label="Número"
                    required
                    value={enterprise.number}
                    onChange={(value) => onFieldChange('number', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-5 p-3">
                  <BvsInput
                    name="district"
                    label="Bairro"
                    required
                    value={enterprise.district}
                    onChange={(value) => onFieldChange('district', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-6 p-3">
                  <BvsInput
                    name="complement"
                    label="Complemento"
                    value={enterprise.complement}
                    onChange={(value) => onFieldChange('complement', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-6 p-3">
                  <BvsInput
                    name="reference"
                    label="Referência"
                    value={enterprise.reference}
                    onChange={(value) => onFieldChange('reference', value)}
                  />
                </div>
              </div>
              {id && (
                <BvsButton
                  type="button"
                  title="Endereço de entrega"
                  icon={() => (
                    <MaterialIcons
                      name="local-shipping"
                      color="#fff"
                      size={18}
                    />
                  )}
                  onClick={goToAddress}
                  customStyle={{ margin: '5px', float: 'right' }}
                />
              )}
            </BvsPanel>
            {/* Contato */}
            <BvsPanel title="Contato">
              <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-5 p-3">
                  <BvsInput
                    name="owner_name"
                    label="Nome"
                    required
                    value={enterprise.owner_name}
                    onChange={(value) => onFieldChange('owner_name', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsInput
                    name="owner_phone"
                    label="Telefone"
                    mask="phone"
                    value={enterprise.owner_phone}
                    onChange={(value) => onFieldChange('owner_phone', value)}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsInput
                    name="owner_email"
                    label="E-mail"
                    required
                    value={enterprise.owner_email}
                    onChange={(value) => onFieldChange('owner_email', value)}
                  />
                </div>
                {id && (
                  <>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 p-3">
                      <BvsDropdown
                        title="Selecione um Cargo..."
                        name="owner_job_id"
                        label="Cargo"
                        options={jobs.options}
                        value={enterprise.owner_job_id}
                        loading={jobs.loading}
                        onSelectedItem={(value) =>
                          onFieldChange('owner_job_id', value)
                        }
                        style={{
                          marginTop: '-5px',
                          float: 'right',
                        }}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 p-3">
                      <BvsDropdown
                        title="Selecione um Departamento..."
                        name="owner_department_id"
                        label="Departamento"
                        value={enterprise.owner_department_id}
                        onSelectedItem={(value) =>
                          onFieldChange('owner_department_id', value)
                        }
                        options={departments.options}
                        loading={departments.loading}
                        style={{
                          marginTop: '-5px',
                          float: 'right',
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </BvsPanel>
          </div>

          {termsVisible && (
            <>
              <BvsPanel title="Termo de Utilização">
                <Termo />
                <div className="d-flex justify-content-end">
                  <div className="row">
                    <div className="col text-nowrap">
                      <BvsButton
                        type="button"
                        title="Não concordo"
                        icon="fa fa-times"
                        fullWidth
                        onClick={() => toggleAgree(false)}
                      />
                    </div>
                    <div className="col text-nowrap">
                      <BvsButton
                        type="button"
                        title="Concordo"
                        icon="fa fa-check"
                        fullWidth
                        onClick={() => toggleAgree(true)}
                      />
                    </div>
                  </div>
                </div>
              </BvsPanel>
            </>
          )}

          {!id && !termsVisible && agree && (
            <div className=" d-flex justify-content-end">
              <div className="row">
                <div className="col">
                  <strong>
                    Você concordou com os Termos
                    <i className="fa fa-check" />
                  </strong>
                </div>
              </div>
            </div>
          )}
          {id && (
            <div className="d-flex justify-content-end">
              <div className="row">
                <div className="col text-nowrap">
                  <BvsButton
                    type="submit"
                    title="Salvar"
                    icon="fa fa-save"
                    disabled={form.saving}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="container-fluid my-3 p-0">
            {!id && (
              <div className="d-flex justify-content-end">
                <div className="row">
                  <div className="col text-nowrap">
                    <BvsButton
                      type="button"
                      title={!termsVisible ? 'Ler Termos.' : 'Ocultar Termos.'}
                      icon="fa fa-eye"
                      onClick={() => toggleTerms(!termsVisible)}
                    />
                  </div>
                  {agree && (
                    <div className="col text-nowrap">
                      <BvsButton
                        type="submit"
                        title="Salvar"
                        icon="fa fa-save"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </BvsForm>
      </BvsContainer>
    </>
  );
};
export default Enterprise;
