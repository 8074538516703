/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  filter: {
    filtering: false,
    disabled: false,
  },
  query: {
    registry: null,
    name: null,
    cpf: null,
    department_id: null,
    workday_id: null,
    shipping_address_id: null,
    status: null,
    demission_date: null,
    admission_date: null,
  },
};

function filterEmployees(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_EMPLOYEES: {
      return produce(state, (draft) => {
        draft = { ...draft, ...action.payload };
        return draft;
      });
    }
    case types.RESET_EMPLOYEES: {
      return produce(state, (draft) => {
        draft = INITIAL_STATE;
        return draft;
      });
    }
    default:
      return state;
  }
}

export default filterEmployees;
