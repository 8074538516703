const types = {
  GET_MY_ENTERPRISES: '@enterprise/GET_MY_ENTERPRISES',
  UPDATE_ENTERPRISES: '@enterprise/UPDATE_ENTERPRISES',
  SET_ENTERPRISE_ACTIVE: '@enterprise/SET_ENTERPRISE_ACTIVE',
  UPDATE_ENTERPRISE_ACTIVE: '@enterprise/UPDATE_ENTERPRISE_ACTIVE',
  RESET_ENTERPRISE: '@enterprise/RESET_ENTERPRISE',
  CLEAR_ENTERPRISE_ACTIVE: '@enterprise/CLEAR_ENTERPRISE_ACTIVE',
};

export default types;
