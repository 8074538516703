import { useEffect, useState } from 'react';
import { getStatusCard } from '~/system/convert';
import { STATUS_CARD } from '~/system/enum';

const useStatusCard = () => {
  const [statusCard, setStatusCard] = useState({
    options: [],
    loading: false,
  });

  useEffect(() => {
    setStatusCard({
      options: Object.values(STATUS_CARD).map((status) => ({
        title: getStatusCard(status),
        value: status,
      })),
      loading: false,
    });
  }, []);
  return {
    statusCard,
  };
};

export default useStatusCard;
