import BvsModal from '~/components/BvsModal';
import BvsButton from '~/components/BvsButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetFormJustification,
  updateFormJustification,
} from '~/store/modules/employee/actions';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';

const JustificationEditing = ({ onSave }) => {
  const dispatch = useDispatch();
  const {
    justification: { components, form },
  } = useSelector((state) => state.employee);
  const setComponents = (component, state) => {
    dispatch(
      updateFormJustification({
        components: {
          ...components,
          [component]: state,
        },
      })
    );
  };
  const onFieldChange = (name, value) => {
    dispatch(
      updateFormJustification({
        form: {
          ...form,
          [name]: value,
        },
      })
    );
  };
  return (
    <BvsModal
      visible={components.visible}
      onClose={() => {
        setComponents('visible', false);
        dispatch(resetFormJustification());
      }}
      footer={() => (
        <div className="row d-flex justify-content-end">
          <div className="mr-2 text-nowrap">
            <BvsButton
              title="Cancelar"
              onClick={() => {
                setComponents('visible', false);
              }}
            />
          </div>
          <div className=" text-nowrap">
            <BvsButton
              title="Salvar"
              type="submit"
              form="create-shipping-address"
              onClick={() => {
                if (form.justification.trim().length === 0)
                  toast.info(
                    <BvsToastResult message="Informe a justificativa" />
                  );
                else {
                  setComponents('visible', false);
                  dispatch(resetFormJustification());
                  onSave && onSave();
                }
              }}
            />
          </div>
        </div>
      )}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 pt-3">
            <p className="font-weight-bold">Qual é o motivo da alteração?</p>
          </div>
          <div className="col-12 px-3">
            <textarea
              name="justificativa"
              label="Justificativa"
              value={form.justification}
              onChange={(e) => onFieldChange('justification', e.target.value)}
              rows="5"
              style={{ width: '100%' }}
              maxLength="500"
              required
            />
          </div>
        </div>
      </div>
    </BvsModal>
  );
};

export default JustificationEditing;
