import React from 'react';
import BvsCalendar from '~/components/BvsCalendar/index';
import BvsContainer from '~/components/BvsContainer';
import BvsSubHeader from '~/components/BvsSubHeader/index';
import LastRequest from './LastRequest';
import OrderChart from './OrderChart';
import WallCard from './WallCard';

export default function DashboardUser() {
  return (
    <>
      <BvsSubHeader title="Painel de Controle" />
      <BvsContainer>
        <div className="row">
          <div className="col-xs-12 col-md-8">
            <WallCard />
            <OrderChart />
          </div>
          <div className="col-xs-12 col-md-4">
            <BvsCalendar />
          </div>
        </div>
        <div className="row my-3">
          <div className="col">
            <LastRequest />
          </div>
        </div>
      </BvsContainer>
    </>
  );
}
