/* eslint-disable no-param-reassign */
import produce from 'immer';
import api from '~/services/api';
import types from './types';

const INITIAL_VALUE = {
  enterprises: [],
  enterpriseActive: null,
};

function enterprises(state = INITIAL_VALUE, action) {
  switch (action.type) {
    case types.UPDATE_ENTERPRISES:
      return produce(state, (draft) => {
        draft.enterprises = action.enterprises;
        return draft;
      });
    case types.UPDATE_ENTERPRISE_ACTIVE:
      return produce(state, (draft) => {
        draft.enterpriseActive = action.enterprise;
        return draft;
      });
    case types.RESET_ENTERPRISE:
      return produce(state, (draft) => {
        draft.enterprises = INITIAL_VALUE.enterprises;
        draft.enterpriseActive = INITIAL_VALUE.enterpriseActive;
        return draft;
      });
    case types.CLEAR_ENTERPRISE_ACTIVE:
      return produce(state, (draft) => {
        draft.enterpriseActive = INITIAL_VALUE.enterpriseActive;
        delete api.defaults.headers['X-Enterprise'];

        return draft;
      });

    default:
      return state;
  }
}

export default enterprises;
