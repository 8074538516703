/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import { useHistory } from 'react-router';
import { AntDesign } from 'react-web-vector-icons';
import { toMoney } from '~/system/helper';
import { getStatusOperational } from '~/system/convert';
import BvsContainer from '~/components/BvsContainer';
import BvsPanel from '~/components/BvsPanel';
import SubHeader from '~/components/BvsSubHeader';
import BvsButton from '~/components/BvsButton';
import FooterReports from '~/pages/Reports/FooterReports';
import HeaderReports from '~/pages/Reports/HeaderReports';
import * as UI from './style';
import useController from './hooks/useController';
import { mask } from 'remask';

const ReportsByStatusPerUsers = ({ match }) => {
  const history = useHistory();
  const { reportData, getReportData } = useController();
  const { id } = match.params;

  const groupBy = (benefits) => {
    return benefits.reduce((result, currentValue) => {
      if (result) {
        let searched = result.findIndex(
          (i) => i.benefitGroupId === currentValue.benefitGroupId
        );
        if (searched > -1) {
          result[searched].data.push(currentValue);
        } else
          result.push({
            benefitGroupId: currentValue.benefitGroupId,
            name: currentValue.benefitGroupName,
            data: [currentValue],
          });
      } else {
        result = [
          {
            benefitGroupId: currentValue.benefitGroupId,
            name: currentValue.benefitGroupName,
            data: [currentValue],
          },
        ];
      }
      return result;
    }, null);
  };

  const getSubTotal = (arr) => {
    const getValues = (benefitGroup) =>
      benefitGroup.quantity *
      benefitGroup.unitCost *
      benefitGroup.quantityMonth;
    const calc = () =>
      arr.map((i) => getValues(i)).reduce((cur, old) => cur + old, 0);

    return {
      subTotal: calc(),
    };
  };

  const resume = (arr) => {
    let totalBenefit = groupBy(arr)
      .map((items) =>
        items.data
          .map((i) => i.unitCost * i.quantity * i.quantityMonth)
          .reduce((cur, old) => cur + old, 0)
      )
      .reduce((cur, old) => cur + old, 0);
    return { totalBenefit, total: totalBenefit };
  };

  useEffect(() => {
    getReportData(id);
  }, []);
  return (
    <>
      <SubHeader
        title="Relatório de Status por Usuario"
        subtitle="Status por usuario "
      />
      <BvsContainer>
        <div>
          <BvsPanel title="Relatório de Status Usuarios & Benefícios Por Usuarios ">
            <BvsButton
              type="button"
              title="Voltar"
              onClick={() => {
                history.goBack();
              }}
            />
            <HeaderReports />
            {reportData.loading && (
              <div className="p-3 row justify-content-center align-items-center">
                <div className="col-12 text-center">
                  <h4>Carregando...</h4>
                </div>
                <div className="col-4">
                  <div className="progress">
                    <div
                      className="progress-bar bg-warning progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
              </div>
            )}
            {!reportData?.loading && !reportData?.data && (
              <div
                className="row middle-xs center-xs"
                style={{ padding: 60, textAlign: 'center' }}
              >
                <div className="col-md-12">
                  <h2>Não foi possivel encontrar registros</h2>
                  <h2 style={{ marginTop: '10px' }}>
                    <AntDesign name="frowno" />
                  </h2>
                </div>
              </div>
            )}
            {!reportData?.loading && reportData?.data && (
              <>
                <BvsContainer
                  style={{
                    display: 'flex',
                    margin: '40px 20px 5px',
                    borderTop: '1.4px solid rgba(0, 0, 0, 0.1)',
                    borderBottom: '1.4px solid rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <UI.Titulo> Data de Emissão </UI.Titulo>
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'flex',
                    margin: '5px 20px 5px',
                    borderBottom: '1.4px solid rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <UI.Data>{reportData?.data?.dateEmission}</UI.Data>
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'block',
                    marginLeft: '40px',
                    marginRight: '40px',
                    marginBottom: '40px',
                    padding: '0px',
                  }}
                >
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Razão Social</th>
                        <th scope="col" />
                        <th scope="col">Código</th>
                        <th scope="col">CNPJ</th>
                        <th scope="col">Inscrição Estadual</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row" colSpan="2">
                          {reportData?.data?.socialName}
                        </td>
                        <td>{reportData?.data?.id}</td>
                        <td>{reportData?.data?.cnpj}</td>
                        <td>{reportData?.data?.stateRegistration}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Endereço</th>
                        <th scope="col">CEP</th>
                        <th scope="col">Bairro</th>
                        <th scope="col">Municipio</th>
                        <th scope="col">Estado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row">{reportData?.data?.street}</td>
                        <td>{reportData?.data?.zipcode}</td>
                        <td>{reportData?.data?.district}</td>
                        <td>{reportData?.data?.city}</td>
                        <td>{reportData?.data?.state}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Contato</th>
                        <th scope="col" />
                        <th scope="col">Telefone</th>
                        <th scope="col">Email</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row" colSpan="2">
                          {reportData?.data?.ownerName}
                        </td>
                        <td>{reportData?.data?.ownerPhone}</td>
                        <td colSpan="2">{reportData?.data?.ownerEmail}</td>
                      </tr>
                    </tbody>
                  </table>
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'block',
                    marginLeft: '40px',
                    marginRight: '40px',
                    marginBottom: '40px',
                    padding: '0px',
                  }}
                >
                  {reportData?.data?.employees.map((employee) => (
                    <>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Matricula</th>
                            <th scope="col">Colaborador</th>
                            <th scope="col" />
                            <th scope="col">CPF</th>
                            <th scope="col">RG</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td scope="row">{employee?.registry}</td>
                            <td colSpan="2">{employee?.name}</td>
                            <td>{mask(employee?.cpf, '999.999.999-99')}</td>
                            <td>{mask(employee?.rg, '99.999.999-9')}</td>
                          </tr>
                        </tbody>
                        <thead>
                          <tr>
                            <th scope="col">Código</th>
                            <th scope="col">Departamento</th>
                            <th scope="col" />
                            <th scope="col">Código</th>
                            <th scope="col">Cargo</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td scope="row">{employee?.codeDepartment}</td>
                            <td colSpan="2">{employee?.nameDepartment}</td>
                            <td>{employee?.codeJob}</td>
                            <td>{employee?.nameJob}</td>
                          </tr>
                        </tbody>
                        <thead>
                          <tr>
                            <th scope="col">Mes Referencia</th>
                            <th scope="col">N. de Pedido</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td scope="row">{reportData?.data?.reference}</td>
                            <td colSpan="2">{reportData?.data?.orderId}</td>
                            <td />
                            <td />
                          </tr>
                        </tbody>
                      </table>
                      <BvsContainer style={{ marginBottom: '50px' }}>
                        <table className="table table-striped">
                          <thead>
                            <tr style={{ textAlign: 'center' }}>
                              <th scope="col" style={{ width: '5%' }}>
                                Codigo
                              </th>
                              <th scope="col">Discriminação dos Benefícios</th>
                              <th scope="col" />
                              <th scope="col" style={{ width: '10%' }}>
                                Qta. Dia
                              </th>
                              <th scope="col">Unitario(R$)</th>
                              <th scope="col" style={{ width: '10%' }}>
                                Qta. Dias
                              </th>
                              <th scope="col">Valor total</th>
                              <th scope="col">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {employee?.benefits.map((b) => (
                              <tr key={b?.benefitGroupId}>
                                <td scope="row">{b?.benefitCode}</td>
                                <td colSpan="2">{b?.subDescription}</td>
                                <td>{b?.quantity}</td>
                                <td>{toMoney(b?.unitCost)}</td>
                                <td>{b?.quantityMonth}</td>
                                <td>
                                  {toMoney(
                                    b?.unitCost * b?.quantity * b?.quantityMonth
                                  )}
                                </td>
                                <td>
                                  <div
                                    data-tip={
                                      getStatusOperational(b?.statusCode).title
                                    }
                                  >
                                    <UI.InfoStatus
                                      color={
                                        getStatusOperational(b?.statusCode)
                                          .color
                                      }
                                    />
                                    <ReactTooltip />
                                  </div>
                                </td>
                              </tr>
                            ))}

                            {groupBy(employee.benefits).map((items) => {
                              return (
                                <tr key={items?.benefitGroupId}>
                                  <td
                                    scope="row"
                                    colSpan="7"
                                    style={{
                                      fontWeight: 'bold',
                                      textAlign: 'right',
                                    }}
                                  >
                                    {items?.name}
                                  </td>
                                  <td>
                                    {toMoney(getSubTotal(items.data).subTotal)}
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td
                                scope="row"
                                colSpan="7"
                                style={{
                                  fontWeight: 'bold',
                                  textAlign: 'right',
                                }}
                              >
                                Valor Total dos Beneficios
                              </td>
                              <td>
                                {toMoney(
                                  resume(
                                    groupBy(employee.benefits)
                                      .map((i) => i.data)
                                      .flat(1)
                                  ).total
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </BvsContainer>
                    </>
                  ))}
                </BvsContainer>
                <BvsContainer
                  style={{
                    marginLeft: '40px',
                    marginRight: '40px',
                  }}
                >
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          scope="row"
                          colSpan="5"
                          style={{ textAlign: 'right', fontWeight: 'bold' }}
                        >
                          Valor Total dos Beneficios
                        </td>
                        <td style={{ textAlign: 'center', fontWeight: 'bold' }}>
                          {toMoney(
                            resume(
                              reportData?.data?.employees
                                .map((employee) => employee.benefits)
                                .flat(1)
                            ).total
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </BvsContainer>
              </>
            )}
            <FooterReports periodUse={reportData?.data?.periodUse} />
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default ReportsByStatusPerUsers;
