/* eslint-disable no-shadow */
import BvsModal from '~/components/BvsModal';
import BvsButton from '~/components/BvsButton';
import { useDispatch, useSelector } from 'react-redux';
import { bvsJustification } from '~/store/modules/app/actions';
import BvsToastResult from '../BvsToastResult';
import { toast } from 'react-toastify';

const BvsJustification = ({ onClose, onSave, onCancel }) => {
  const {
    bvsJustification: { components, state },
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const setComponents = (component, value) => {
    dispatch(
      bvsJustification('update', {
        components: { ...components, [component]: value },
        state,
      })
    );
  };
  const setJustification = (name, value) => {
    dispatch(
      bvsJustification('update', {
        components,
        state: {
          ...state,
          [name]: value,
        },
      })
    );
  };
  return (
    <BvsModal
      visible={components?.visible}
      onClose={() => {
        console.log('onClose');
        dispatch(bvsJustification('reset'));
        onClose && onClose();
      }}
      footer={() => (
        <div className="row d-flex justify-content-end">
          <div className="mr-2 text-nowrap">
            <BvsButton
              title="Cancelar"
              onClick={() => {
                dispatch(bvsJustification('reset'));
                onCancel && onCancel();
              }}
            />
          </div>
          <div className=" text-nowrap">
            <BvsButton
              title="Salvar"
              type="submit"
              form="create-shipping-address"
              onClick={() => {
                if (state?.justification?.trim()?.length === 0) {
                  toast.info(
                    <BvsToastResult message="Informe uma justificativa" />
                  );
                } else {
                  const justification = state?.justification;
                  dispatch(bvsJustification('reset'));
                  onSave && onSave(justification);
                }
              }}
            />
          </div>
        </div>
      )}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 pt-3">
            <p className="font-weight-bold">Qual é o motivo da alteração?</p>
          </div>
          <div className="col-12 px-3">
            <textarea
              name="justificativa"
              label="Justificativa"
              value={state?.justification}
              onChange={(e) => {
                setJustification('justification', e.target.value);
              }}
              rows="5"
              style={{ width: '100%' }}
              maxLength="500"
              required
            />
          </div>
        </div>
      </div>
    </BvsModal>
  );
};

export default BvsJustification;
