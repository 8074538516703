import styled from 'styled-components';

export const Dropdown = styled.div`
  display: inline-block;
  position: relative;

  & button:hover + div {
    max-width: 150px;
    position: absolute;
    visibility: visible;
    opacity: 1;
  }

  :hover button {
    background-color: #a81e21;
  }
`;

export const ListButton = styled.div`
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 99;
  visibility: hidden;
  position: absolute;
  min-width: 100%;
  opacity: 0;
  transition: 0.3s;
  :hover {
    visibility: visible;
    opacity: 1;
  }
`;

export const ItemList = styled.a`
  display: block;
  text-decoration: none;
  padding: 8px;
  color: #000;
  transition: 0.1s;
  max-width: 150px;

  white-space: wrap;
  /* white-space: nowrap; */

  :hover {
    background-color: #a81e21;
    color: #fff;
  }
`;
