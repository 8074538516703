import styled from 'styled-components';

export const TextValue = styled.h3`
  font-weight: bold;
  font-size: 2.55em;
  text-align: center;
`;

export const Subtitle = styled.h4`
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  font-weight: 500;
  font-size: 2em;
`;
