/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import {
  clearEnterpriseActive,
  setEnterpriseActive,
} from '~/store/modules/enterpriseActive/actions';
import { logOut } from '~/store/modules/auth/actions';
import api from '~/services/api';
// import BvsTableV4 from '../../components/BvsTableV4';
import BvsContainer from '~/components/BvsContainer';
import BvsModal from '~/components/BvsModal';
import BvsButton from '~/components/BvsButton';
import {
  Actions,
  Container,
  EnterpriseWrapper,
  InfoEnterprise,
  Title,
} from './style';
import { USER_TYPE } from '~/system/enum';
import BvsTableV4 from '../BvsTableV4';

const BvsEnterpriseSelector = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [enterpriseSelector, setEnterpriseSelector] = useState([]);
  const { enterpriseActive } = useSelector((state) => state.enterprises);
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  // const enterpriseChange = () => {
  //   let event = new CustomEvent('EnterpriseChanged', {});
  //   window.dispatchEvent(event);
  // };
  const columns = [
    {
      key: 'id',
      label: '#',
      sort: true,
    },
    {
      key: 'cnpj',
      label: 'CNPJ Empresa',
      sort: true,
    },
    {
      key: 'social_name',
      label: 'Razão Social',
      sort: true,
      width: '100%',
    },
  ];
  const onSelect = (values) => {
    dispatch({ type: 'CHANGE_ENTERPRISE', payload: null });
    dispatch(setEnterpriseActive(values.id));
    setVisible(false);
    history.push(`/home`);
    // history.push(`/enterprises/${values.id}`);
    // if (path === '/home') history.push(`/enterprises/${values.id}`);
  };
  const fetchEnterprises = async () => {
    const { data } = await api.get(`/enterprises`);
    if (data.message || data.error) {
      addToast(`${data.message || data.error.message}`, {
        appearance: 'warning',
        autoDismiss: true,
      });

      return;
    }
    setEnterpriseSelector(data);
  };

  const showDropdown = () => {
    setVisible(true);
  };

  useEffect(() => {
    visible && fetchEnterprises();
  }, [visible]);

  useEffect(() => {
    // enterpriseChange();
  }, [enterpriseActive?.info]);

  return (
    <>
      <Container>
        <EnterpriseWrapper>
          <span onClick={showDropdown}>
            <Title className="p-0 m-0">Você está visualizando o CNPJ</Title>
            <InfoEnterprise className="p-0 m-0">
              {enterpriseActive
                ? `${enterpriseActive?.info?.fantasy_name} - ${enterpriseActive?.info?.cnpj}`
                : `Nenhuma empresa selecionada`}
            </InfoEnterprise>
          </span>
          {user?.type === USER_TYPE.ADMIN && (
            <u
              className="text-muted small p-1"
              onClick={() => {
                dispatch(clearEnterpriseActive());
                if (path !== '/orders') history.push('/home');
              }}
            >
              Limpar Seleção
            </u>
          )}
        </EnterpriseWrapper>
        <Actions>
          <BvsButton
            title="Voltar ao Site"
            customStyle={{ whiteSpace: 'nowrap', marginRight: '5px' }}
          />
          <BvsButton title="Sair" onClick={() => dispatch(logOut())} />
        </Actions>
      </Container>

      <BvsModal
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        footer={() => (
          <div className="row d-flex justify-content-end">
            <div className="col text-nowrap">
              <BvsButton
                title="Nova Empresa"
                onClick={() => {
                  history.replace('/enterprises');
                  setVisible(false);
                }}
              />
            </div>
            <div className="col text-nowrap">
              <BvsButton title="Fechar" onClick={() => setVisible(false)} />
            </div>
          </div>
        )}
      >
        <BvsContainer>
          <BvsTableV4
            tableKey="enterprise"
            columns={columns}
            rows={enterpriseSelector}
            onClickRow={(row) => onSelect(row)}
            rowKey="id"
          />
        </BvsContainer>
      </BvsModal>
    </>
  );
};

export default BvsEnterpriseSelector;
