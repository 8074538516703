import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  form: {
    saving: false,
    disabled: false,
  },
  permission: {
    user_id: undefined,
    privilege: undefined,
    master: false,
    financial: false,
    register: false,
    order: false,
    operational: false,
  },
};

function formPermission(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_PERMISSION: {
      return produce(state, (draft) => {
        draft = { ...draft, ...action.payload };
        return draft;
      });
    }
    case types.RESET_PERMISSION: {
      return produce(state, (draft) => {
        draft.permission = INITIAL_STATE.permission;
        return draft;
      });
    }
    default:
      return state;
  }
}

export default formPermission;
