import styled from 'styled-components';

const Divider = styled.hr`
  background-color: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.08);
  border: 0;
  height: 1px;
  background-image: -webkit-linear-gradient(
    left,
    transparent,
    rgba(0, 0, 0, 0.2),
    transparent
  );
  background-image: -moz-linear-gradient(
    left,
    transparent,
    rgba(0, 0, 0, 0.2),
    transparent
  );
  background-image: -ms-linear-gradient(
    left,
    transparent,
    rgba(0, 0, 0, 0.2),
    transparent
  );
  background-image: -o-linear-gradient(
    left,
    transparent,
    rgba(0, 0, 0, 0.2),
    transparent
  );
  margin: 8px 0 20px 0;
`;

export default Divider;
