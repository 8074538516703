import types from './types';

export function updateFormJustification(payload) {
  return {
    type: types.UPDATE_FORM_JUSTIFICATION,
    payload,
  };
}

export function resetFormJustification() {
  return {
    type: types.RESET_FORM_JUSTIFICATION,
  };
}
