/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from 'react';
import produce from 'immer';
import { FontAwesome, AntDesign } from 'react-web-vector-icons';
import { useHistory } from 'react-router-dom';

import { DownloadFile } from '~/system/helper';

import SubHeader from '~/components/BvsSubHeader';
import BvsPanel from '~/components/BvsPanel';
import BvsContainer from '~/components/BvsContainer';
import BvsButton from '~/components/BvsButton';
// import columns from './columns.json';
import useController from './hooks/useController';
import moment from 'moment';
import { useSelectedItems } from '~/hooks/useSelectedItems';
import { Item, Menu, Separator, useContextMenu } from 'react-contexify';
import { setEnterpriseActive } from '~/store/modules/enterpriseActive/actions';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS_FINANCIAL, STATUS_OPERATIONAL, USER_TYPE } from '~/system/enum';
import BvsAdmButton from '~/components/BvsAdmButton';
import BvsModal from '~/components/BvsModal';
import { navigateTo } from '~/system/rootNavigation';
import ModalCancelOrder from './modal/modal-cancel-order';
import {
  resetForm,
  selectOrder,
} from '../../store/modules/order/cancel/actions';
import ModalConfirmNfeUpload from './modal/modal-confirm-nfe-upload';
import { updateListOrder } from '../../store/modules/app/actions';
import { toast } from 'react-toastify';
import BvsToastResult from '../../components/BvsToastResult';
import api from '../../services/api';
import BvsTableV4 from '../../components/BvsTableV4';
import uuid from 'uuid-random';
import BvsInput from '~/components/BvsInput';

const ListOrders = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [menuData, setMenuData] = useState(null);
  const { enterpriseActive } = useSelector((state) => state.enterprises);
  const { user, token } = useSelector((state) => state.auth);
  const {
    listOrder: { components },
  } = useSelector((state) => state.app);
  const [modalHistoric, setModalHistoric] = useState(false);
  const [infoHistoric, setInfoHistoric] = useState();
  const [modalExport, setModalExport] = useState();
  const [initDate, setInitDate] = useState('');
  const [finishDate, setFinishDate] = useState('');
  const uploadRef = useRef();
  const [nfeFile, setNfeFile] = useState({
    file: null,
    orderId: null,
  });

  const {
    orders,
    fetch: getOrders,
    getColumns,
    generateReceipt,
    reportByOrder,
    emitBolet,
    modalVisible,
    onCloseModal,
    curOrder,
    modalState,
    setModalState,
  } = useController({});
  const { selectedItems, onSelect } = useSelectedItems({
    rows: orders.options,
    key: 'id',
  });

  const menuId = uuid();
  const { show } = useContextMenu({});

  const onEdit = async ({ id: orderId, enterprise_id }) => {
    dispatch(setEnterpriseActive(enterprise_id));
    history.push(`/orders/checkout/${orderId}`);
  };

  const setComponentsByListOrder = (component, state) => {
    dispatch(
      updateListOrder({
        components: {
          ...components,
          [component]: state,
        },
      })
    );
  };

  const handleUploadNfe = async () => {
    try {
      let formData = new FormData();
      formData.append('nfe', nfeFile?.file);
      const { data } = await api.post(
        `/order/upload/nfe/${nfeFile.orderId}`,
        formData,
        {
          headers: {
            'Content-Type': `multipart/form-data`,
          },
        }
      );
      modalNfeOnClose();
      if (data) {
        toast.success(
          <BvsToastResult message="Arquivo enviado com sucesso." />
        );
      }
    } catch (error) {
      toast.error(
        <BvsToastResult
          type="error"
          err={error}
          message="Não foi possivel consultar os registros"
        />
      );
    }
  };

  const modalNfeOnClose = () => {
    setNfeFile({
      file: null,
      orderId: null,
    });
    setComponentsByListOrder('showConfirmUploadNfe', false);
    if (uploadRef.current) {
      uploadRef.current.value = null;
    }
  };

  const seeNfe = async () => {
    if (selectedItems.length === 0) {
      toast.info(<BvsToastResult message="Selecione ao menos um pedido" />);
    } else {
      try {
        await api.get(`/order/download/nfe/${selectedItems[0]?.id}`);

        window.open(
          `${process.env.REACT_APP_ENDPOINT}/order/download/nfe/${selectedItems[0]?.id}?token=${token}`,
          '_blank'
        );
      } catch (error) {
        toast.error(<BvsToastResult message="Nfe não encontrado" />);
      }
    }
  };

  const getActions = () => {
    return [
      (row) =>
        BvsButton({
          className: 'btn btn-xs',
          customStyle: { marginRight: '5px' },
          icon: <FontAwesome name="eye" color="#fff" size={12} />,
          onClick: () => onEdit(row),
        }),
      (row) =>
        row.financial_status === STATUS_FINANCIAL.CANCELLED ||
        row.operational_status === STATUS_OPERATIONAL.CANCELLED
          ? undefined
          : BvsButton({
              className: 'btn btn-xs',
              customStyle: { marginRight: '5px' },
              icon: <FontAwesome name="close" color="#fff" size={12} />,
              onClick: () => dispatch(selectOrder(row)),
            }),
      (row) => {
        return (
          <>
            <span data-tip="Mais Opções">
              <BvsButton
                type="button"
                className="btn btn-xs"
                customStyle={{ marginRight: '5px' }}
                icon={<FontAwesome name="ellipsis-h" color="#fff" size={12} />}
                onClick={(e) => {
                  setMenuData(row);
                  show(e, {
                    id: menuId,
                    props: row,
                  });
                }}
              />
            </span>
          </>
        );
      },
    ];
  };

  const repeatOrder = async (x) => {
    try {
      await api.post(`/repeatOrder/${x.id}`);
    } catch (e) {
      toast.error(
        <BvsToastResult type="warn" message="Erro ao buscar ultimo pedido" />
      );
    }
    toast.success(
      <BvsToastResult type="success" message="Sucesso ao replicar pedido" />
    );
  };

  const handleReceiptPurchase = (params) => {
    let {id} = params[0]
    navigateTo(`/orders/receiptPurchase/${id}`)
  };

  useEffect(() => {
    getOrders();
  }, []);

  return (
    <>
      <SubHeader title="Listar Pedidos" subtitle="Acompanhe seu pedido feito" />
      <BvsContainer>
        {user?.type === USER_TYPE.ADMIN && enterpriseActive?.info?.id && (
          <BvsAdmButton />
        )}

        <div className="col-md-12">
          <BvsPanel title="Relação de Pedidos">
            <div className="col-md-12">
              <BvsTableV4
                columns={getColumns()}
                rows={orders.options}
                onSelect={onSelect}
                selectedItems={selectedItems}
                rowKey={(r) => r.id}
                onRefresh={() => getOrders()}
                actions={getActions()}
              />
              <div>
                {/* <BvsButton
                  className="mx-1"
                  type="button"
                  title="Visualizar Pedido"
                  icon=""
                />
                <BvsButton
                  className="mx-1"
                  type="button"
                  title="Alterar data Pagto"
                  icon=""
                /> */}
                <BvsButton
                  className="mx-1"
                  type="button"
                  title="Imprimir Recibo"
                  icon=""
                  onClick={() => generateReceipt(selectedItems)}
                />
                <BvsButton
                  className="mx-1"
                  type="button"
                  title="Ver NF-e"
                  icon=""
                  onClick={() => seeNfe()}
                />
                <BvsButton
                  className="mx-1"
                  type="button"
                  title="RPS"
                  icon=""
                  disabled
                />
                <BvsButton
                  className="mx-1"
                  type="button"
                  title="Comprovante de Compra"
                  icon=""
                  onClick={() => handleReceiptPurchase(selectedItems)}
                />
                <BvsButton
                  className="mx-1"
                  type="button"
                  title="Exportar"
                  icon=""
                  onClick={() => setModalExport(true)}
                />
              </div>
            </div>
          </BvsPanel>
        </div>
      </BvsContainer>

      <input
        type="file"
        ref={uploadRef}
        style={{ display: 'none' }}
        onChange={(e) => {
          const file = e?.target?.files?.[0];
          setNfeFile(
            produce(nfeFile, (draft) => {
              draft.file = file;
              return draft;
            })
          );
          setComponentsByListOrder('showConfirmUploadNfe', true);
        }}
      />

      <ModalCancelOrder
        visible={modalState.cancel}
        toggle={setModalState}
        onCancelledSuccessful={() => {
          getOrders();
          dispatch(resetForm());
        }}
        onCloseModal={() => {
          setModalState({ ...modalState, cancel: false });
          dispatch(resetForm());
        }}
      />

      <BvsModal
        visible={modalHistoric}
        title="Registro de Alterações"
        onClose={() => setModalHistoric(false)}
        footer={() => (
          <div>
            <BvsButton title="Fechar" onClick={() => setModalHistoric(false)} />
          </div>
        )}
      >
        <div
          style={{
            display: 'flex',
            margin: '30px',
            justifyContent: 'space-around',
          }}
        >
          <div
            style={{
              width: '100%',
            }}
          >
            <strong>N° do pedido:</strong> {infoHistoric?.id}
          </div>
          <div
            style={{
              width: '100%',
            }}
          >
            <strong>Razão Social:</strong>{' '}
            {infoHistoric?.enterprise.fantasy_name}
          </div>
          <div
            style={{
              width: '100%',
            }}
          >
            <strong>CNPJ:</strong> {infoHistoric?.enterprise.cnpj}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            margin: '30px',
            justifyContent: 'space-around',
          }}
        >
          <div style={{ width: '100%' }}>
            <strong>Gerado Por:</strong> {infoHistoric?.createdBy?.name}
          </div>

          <div style={{ width: '100%' }}>
            <strong>Gerado Em:</strong> {infoHistoric?.createdAt}
          </div>
        </div>
        {infoHistoric?.historic.map((item) => {
          return (
            <>
              <div
                style={{
                  display: 'flex',
                  margin: '30px',
                  justifyContent: 'space-around',
                }}
              >
                <div style={{ width: '100%', flexDirection: 'column' }}>
                  <div>
                    <strong>Data -- Hora: </strong>
                  </div>
                  <div>
                    {moment(item.createdAt).format('DD/MM/YYYY -- HH:mm') ||
                      ' - '}
                  </div>
                </div>

                <div style={{ width: '100%' }}>
                  <strong>Motivo: </strong>
                  {item.reason}
                </div>
                <div style={{ width: '100%' }}>
                  <strong>Alterado Por: </strong>
                  {item.createdBy.name}
                </div>
              </div>
            </>
          );
        })}
      </BvsModal>

      <BvsModal
        visible={modalVisible}
        title="Confirmação de Processamento do Pedido"
        onClose={onCloseModal}
        footer={() => (
          <div>
            <BvsButton title="Fechar" onClick={onCloseModal} />
          </div>
        )}
      >
        <p className="lead">
          Seu pedido de número <strong>{curOrder?.id}</strong> já foi processado
          e está previsto para ser pago em{' '}
          <strong>
            {moment(curOrder?.payday, 'DD/MM/YYYY').format('DD/MM/YYYY')}
          </strong>
        </p>
        {curOrder?.bolet?.status && (
          <>
            <form
              action="https://shopline.itau.com.br/shopline/shopline.asp"
              method="post"
              name="form1"
              target="SHOPLINE"
              onSubmit={() => {
                window.open(
                  '',
                  'SHOPLINE',
                  'toolbar=yes,menubar=yes,resizable=yes,status=no,scrollbars=yes,width=675,height=485'
                );
              }}
            >
              <input type="hidden" name="DC" value={curOrder?.bolet?.token} />
              <br />
              Para visualizar seu Boleto,{' '}
              <input
                type="submit"
                className="link"
                name="Shopline"
                value="Clique aqui"
              />
            </form>
          </>
        )}
        {!curOrder?.bolet?.status && (
          <div className="alert alert-info">
            Ocorreu um problema ao tentar gerar o boleto, verifique novamente
            mais tarde.
          </div>
        )}
        <br />
        <p className="big">
          <strong>
            A entrega do seu pedido será realizada no dia &nbsp;
            <strong>
              {curOrder?.delivery_date &&
                moment(curOrder?.delivery_date)?.format('DD/MM/YYYY')}
            </strong>
            , caso seja confirmado o pagamento na data prevista, acima
            informada.
          </strong>
        </p>
        <p className="big">
          Lembramos que a realização do pagamento em data anterior ou posterior
          à <strong>informada</strong> no pedido implicará na &nbsp;
          <strong>antecipação</strong> ou &nbsp;
          <strong>prorrogação automática</strong> da entrega na mesma quantidade
          de dias úteis.
        </p>
        <p className="big">
          Caso necessite alterar o pedido, data de pagamento, incluir ou excluir
          informações e usuários, basta voltar e processar as alterações.
        </p>
        <p className="big">
          Após o pagamento do boleto seu pedido não poderá mais ser alterado. Se
          houver a necessidade de alguma alteração após o pagamento, entre em
          contato com seu <strong>Gestor de Contas</strong>
          que ele poderá te ajudar.
        </p>
        <p className="big">
          Obrigado por escolher nosso{' '}
          <strong>
            Serviço de Gestão de Vale Transporte e demais Benefícios
          </strong>
          .
        </p>
      </BvsModal>
      <ModalConfirmNfeUpload
        onCloseModal={() => {
          modalNfeOnClose();
        }}
        onConfirmed={handleUploadNfe}
      />
      <Menu id={menuId}>
        {menuData && (
          <>
            {((menuData.financial_status === STATUS_FINANCIAL.CANCELLED ||
              menuData.operational_status === STATUS_FINANCIAL.CANCELLED) && (
              <Item
                onClick={() => {
                  setInfoHistoric(menuData);
                  setModalHistoric(true);
                }}
              >
                <FontAwesome name="plus" color="#a81e21" size={12} />
                &nbsp; Historico de Mudanças
              </Item>
            )) || (
              <>
                <Item key="gerar relatorio">
                  <FontAwesome name="list-alt" color="#a81e21" size={12} />
                  &nbsp; Gerar Relatório
                </Item>
                <Item
                  key="info"
                  onClick={() => {
                    setInfoHistoric(menuData);
                    setModalHistoric(true);
                  }}
                >
                  <FontAwesome name="plus" color="#a81e21" size={12} />
                  &nbsp; Historico de Mudanças
                </Item>
                <Item
                  key="report"
                  onClick={() => {
                    reportByOrder(menuData);
                  }}
                >
                  <FontAwesome name="archive" color="#a81e21" size={12} />
                  &nbsp; Relatório do Pedido
                </Item>

                <Item
                  key="repeat"
                  onClick={() => {
                    repeatOrder(menuData);
                  }}
                >
                  <FontAwesome name="repeat" color="#a81e21" size={12} />
                  &nbsp; Repetir Pedido
                </Item>

                <Item key="emit_bolet" onClick={() => emitBolet(menuData)}>
                  <AntDesign name="filetext1" color="#a81e21" size={12} />
                  &nbsp; Imprimir Boleto
                </Item>

                {user.type === USER_TYPE.ADMIN && (
                  <Item
                    key="upload_nfe"
                    onClick={() => {
                      setNfeFile(
                        produce(nfeFile, (draft) => {
                          draft.orderId = menuData.id;
                          return draft;
                        })
                      );
                      if (uploadRef?.current) {
                        uploadRef.current.value = null;
                        uploadRef?.current?.click();
                      }
                    }}
                  >
                    <FontAwesome name="upload" color="#a81e21" size={12} />
                    &nbsp; Subir Nf-e
                  </Item>
                )}
                <Separator />
                <Item disabled>Relatórios</Item>
                <Item
                  key="report_by_benefit"
                  onClick={() =>
                    history.push(`reportsbybenefits/${menuData.id}`)
                  }
                >
                  Por Benefícios
                </Item>
                <Item
                  key="report_by_user"
                  onClick={() => history.push(`reportsbyusers/${menuData.id}`)}
                >
                  Por Usuários
                </Item>
                <Item
                  key="report_by_address"
                  onClick={() =>
                    history.push(`reportsbyaddress/${menuData.id}`)
                  }
                >
                  Por Endereços
                </Item>
                <Item
                  key="detalhado_por_beneficio"
                  onClick={() =>
                    history.push(
                      `reportsbydepartmentperbenefits/${menuData.id}`
                    )
                  }
                >
                  Depto - Detalhado por Benefícios
                </Item>
                <Item
                  key="detalhado_por_departamento"
                  onClick={() =>
                    history.push(`reportsbydepartment/${menuData.id}`)
                  }
                >
                  Depto - Detalhado por Departamento
                </Item>
                <Item
                  key="detalhado_por_usuario"
                  onClick={() =>
                    history.push(`reportsbydepartmentperusers/${menuData.id}`)
                  }
                >
                  Depto - Detalhado por Usuário
                </Item>
                <Item
                  key="status_por_beneficio"
                  onClick={() => history.push(`reportsbystatus/${menuData.id}`)}
                >
                  Status - Por Benefício
                </Item>
                <Item
                  key="status_por_usuarios"
                  onClick={() =>
                    history.push(`reportsbystatusperusers/${menuData.id}`)
                  }
                >
                  Status - Por Usuários
                </Item>
              </>
            )}
          </>
        )}
      </Menu>
      <BvsModal
        visible={modalExport}
        onClose={() => {
          setModalExport(false);
        }}
        footer={() => (
          <div className="row d-flex justify-content-end">
            <div className="text-nowrap px-2">
              <BvsButton
                title="Gerar"
                onClick={() => {
                  DownloadFile(
                    '/order/export',
                    `orders-${
                      enterpriseActive?.info?.social_name
                    }-${moment().format('DD.MM.YYYY HH:mm:ss')}.xlsx`,
                    {
                      dataini: initDate,
                      datafinish: finishDate,
                    }
                  );
                }}
              />
            </div>
          </div>
        )}
      >
        <div className="row d-flex px-2">
          <div style={{ margin: '0 5px' }}>
            <label htmlFor="initDate">Data Inicial</label>
            <BvsInput
              name="initDate"
              type="date"
              value={initDate}
              onChange={(value) => setInitDate(value)}
            />
          </div>
          <div style={{ margin: '0 5px' }}>
            <label htmlFor="finishDate">Data Final</label>
            <BvsInput
              name="finishDate"
              type="date"
              value={finishDate}
              onChange={(value) => setFinishDate(value)}
            />
          </div>
        </div>
      </BvsModal>
    </>
  );
};

export default ListOrders;
