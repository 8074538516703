import React, { useContext, useEffect } from 'react';
import io from 'socket.io-client';

// import { SocketContext } from 'context/socket';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Header from './Header/index';
import Content from './Content/index';
import { LayoutWrapper } from './style';
import Footer from './Footer/index';
import SuperFooter from './SuperFooter';
import SuperHeader from './SuperHeader';
import { updateSocket } from '../../store/modules/app/actions';
import BvsToastResult from '../../components/BvsToastResult';

const Layout = ({ screen }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    // const onBenefitImport = () => {
    //   toast.success(
    //     <BvsToastResult
    //       type="success"
    //       message="Importação de Beneficios concluido com sucesso."
    //     />
    //   );
    // };
    // socket.on('connect', client => {
    //   dispatch(
    //     updateSocket({
    //       socket: {
    //         instance: socket,
    //         id: socket.id,
    //       },
    //     })
    //   );
    //   socket.on('BenefitImport.Success', onBenefitImport);
    // });
    // return () => {
    //   socket.off('BenefitImport.Success', onBenefitImport);
    // };
  }, []);
  return (
    <LayoutWrapper>
      <SuperHeader />
      <Header />
      <Content style={{ minHeight: '90vh' }}>{screen}</Content>
      <SuperFooter />
      <Footer />
    </LayoutWrapper>
  );
};

export default Layout;
