import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';
import api from '~/services/api';
import * as actions from '~/store/modules/forms/FormChangePassword/actions';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';

const useChangePassword = () => {
  const dispatch = useDispatch();
  const { change_password } = useSelector((state) => state.formChangePassword);

  const clear = () => {
    dispatch(actions.resetChangePassword());
  };
  const onSave = async (e) => {
    e.preventDefault();
    dispatch(showLoading());
    try {
      dispatch(
        actions.updateChangePassword({
          form: { saving: true },
        })
      );
      const { data } = await api.put(`/users/change/password`, change_password);
      if (data.error || data.message) throw data;
      toast.success(<BvsToastResult message="Salvo com sucesso" />);
      clear();
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          message="Falha ao atualizar multiplos status"
          err={err}
        />
      );
    } finally {
      dispatch(hideLoading());
      dispatch(
        actions.updateChangePassword({
          form: { saving: false },
        })
      );
    }
  };
  return {
    clear,
    onSave,
  };
};

export default useChangePassword;
