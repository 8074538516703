import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  background: #2c2c2c;
`;

export const CopyTitle = styled.p`
  color: #a6a6a6;
  padding-top: 15px;
  padding-bottom: 15px;
  align-self: center;
`;
