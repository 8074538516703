import { useDispatch, useSelector } from 'react-redux';
import {
  updateImport,
  resetImport,
} from '~/store/modules/forms/FormImport/actions';

const useForm = () => {
  const { import_registers, form } = useSelector((state) => state.formImport);
  const dispatch = useDispatch();
  const reset = () => {
    dispatch(resetImport());
  };
  const onFieldChange = (key, value) => {
    dispatch(
      updateImport({
        form: { ...form },
        import_registers: {
          ...import_registers,
          [key]: value,
        },
      })
    );
  };
  return {
    onFieldChange,
    reset,
  };
};

export default useForm;
