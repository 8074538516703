/* eslint-disable consistent-return */
/* eslint-disable import/order */
/* eslint-disable no-param-reassign */

import React, { useEffect, useState } from 'react';

import BvsButton from '~/components/BvsButton';
import BvsContainer from '~/components/BvsContainer';
import BvsDropdown from '~/components/BvsDropdown';
import BvsForm from '~/components/BvsForm';
import BvsInput from '~/components/BvsInput';
import BvsInputFile from '~/components/BvsInputFile';
import BvsPanel from '~/components/BvsPanel';
import SubHeader from '~/components/BvsSubHeader';
import { useHistory, useLocation } from 'react-router-dom';
import api from '~/services/api';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/modules/forms/FormEmployees/actions';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';
import { toast } from 'react-toastify';
import { FontAwesome } from 'react-web-vector-icons';
import * as _enum from '~/system/enum';
import viacep from '~/services/viacep';
import BvsInputCurrency from '~/components/BvsInputCurrency';
import * as convert from '~/system/convert';
import { RenderStatusIconButton, toMoney } from '~/system/helper';
import { Item, Menu, useContextMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import BvsToastResult from '~/components/BvsToastResult';
import { useSelectedItems } from '~/hooks/useSelectedItems';
import useBenefits from './hooks/useBenefits';
import useDepartments from '~/hooks/useDepartments';
import useJobs from '~/hooks/useJobs';
import useWorkdays from '~/hooks/useWorkday';
import useShippingAddress from '~/hooks/useShippingAddress';
import useController from './hooks/useController';
import EmployeeBenefitStatus from '~/enums/EmployeeBenefitStatus';
import CreateDepartmentModal from './modal/CreateDepartment';
import CreateJobModal from './modal/CreateJob';
import CreateWorkdayModal from './modal/CreateWorkdays';
import { resetDepartment } from '~/store/modules/forms/FormDepartment/actions';
import { resetJob } from '~/store/modules/forms/FormJob/actions';
import { resetWorkday } from '~/store/modules/forms/FormWorkday/actions';
import CreateShippingAddress from './modal/CreateShippingAddress';
import { resetShippingAddress } from '~/store/modules/forms/FormShippingAddress/actions';
import JustificationEditing from './modal/JustificationEditing';
import { updateFormJustification } from '../../store/modules/employee/actions';
import Historic from './modal/Historic';
import { employeeHistoricOfBenefits } from '../../store/modules/app/actions';
import BvsTableV4 from '../../components/BvsTableV4';
import ReactTooltip from 'react-tooltip';
import uuid from 'uuid-random';
import ModalBenefitDetail from '../Benefit/ModalDetail';

const CreateBenefitEmployees = ({ match }) => {
  let { id } = match.params;
  const history = useHistory();
  const location = useLocation();
  const [menuData, setMenuData] = useState(null);
  const { employees, components } = useSelector((state) => state.formEmployees);
  const { enterpriseActive } = useSelector((state) => state.enterprises);
  const {
    employeeHistoricOfBenefit: { components: historicComponents },
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { show } = useContextMenu({});
  const { departments, fetch: getDepartments } = useDepartments({
    loading: false,
  });
  const { jobs, fetch: getJobs } = useJobs({ loading: false });
  const { workdays, fetch: getWorkdays } = useWorkdays();
  const { shippingAddress, fetch: getShippingAddress } = useShippingAddress();
  const { benefits, fetch: getEmployeeBenefits } = useBenefits({
    employeeId: id,
  });
  const { selectedItems, onSelect } = useSelectedItems({
    rows: benefits,
    key: 'id',
  });

  const columns = [
    {
      key: 'status',
      label: 'Status',
      sort: true,
      content: (row) => convert.getStatus(row.status),
    },
    {
      key: 'code',
      label: 'Código',
      sort: true,
    },
    {
      key: 'name',
      label: 'Descrição',
      sort: true,
      content: (row) => row?.benefit?.name?.substring(0, 35),
    },
    {
      key: 'card',
      label: 'Cartão',
      sort: true,
    },
    {
      key: 'month_quantity',
      label: 'Dias',
      sort: true,
    },
    {
      key: 'daily_quantity',
      label: 'Quantidade',
      sort: true,
    },
    {
      key: 'unit_cost',
      label: 'Unitário',
      sort: true,
      content: (row) => toMoney(Number(row.benefit?.unit_cost) || 0),
    },
    {
      key: 'subtotal',
      label: 'SubTotal',
      sort: true,
      width: '100%',
      content: (row) =>
        toMoney(
          Number(
            row.month_quantity * row.daily_quantity * row.benefit?.unit_cost
          )
        ),
    },
  ];

  const menuId = uuid();
  const [modalVisible, setModalVisible] = useState(false);
  const [benefitInfo, setBenefitInfo] = useState(null);

  const onFieldChange = (key, value) => {
    dispatch(
      actions.updateEmployees({
        employees: { ...employees, [key]: value },
      })
    );
  };

  const setComponents = (component, state) => {
    dispatch(
      actions.updateEmployees({
        components: {
          ...components,
          [component]: state,
        },
      })
    );
  };

  const { onSave, populate } = useController({
    employeeId: id,
    onSaved: (employee) => {
      history.push(`/createEmployees/${employee.id}`);
      // populate(() => getEmployeeBenefits());
    },
  });
  const emissor = {
    options: Object.values(_enum.EMISSOR_RG).map((s) => ({
      value: s,
      title: s,
    })),
    loading: false,
  };

  const status = {
    options: Object.values(_enum.STATUS_EMPLOYEES).map((s) => ({
      value: s,
      title: convert.getEmployeeStatus(s),
    })),
    loading: false,
  };

  const gender = {
    options: [
      { value: null, title: 'Nenhum' },
      ...Object.values(_enum.GENDER_EMPLOYEES)
        .slice(0, 2)
        .map((g) => ({
          value: g,
          title: convert.getGender(g),
        })),
    ],
    loading: false,
  };

  const account = {
    options: Object.values(_enum.TYPE_ACCOUNT_BANK).map((t) => ({
      value: t,
      title: convert.getTypeBankAccount(t),
    })),
    loading: false,
  };

  const onEdit = async (paramsId) => {
    history.push(`/employee/${id}/benefits/${paramsId}`);
  };

  const changeStatus = async (paramsId) => {
    dispatch(showLoading());
    try {
      dispatch(
        actions.updateEmployees({
          form: { saving: true },
        })
      );
      const { data } = await api.put(
        `/employees/${id}/benefits/${paramsId}/status`,
        employees
      );
      if (data.error || data.message) throw data;
      toast.success(
        <BvsToastResult message="Funcionário salvo com sucesso." />
      );
      getEmployeeBenefits();
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          message="Não foi possivel salvar o funcionário neste momento."
          err={err}
        />
      );
    } finally {
      dispatch(hideLoading());
      dispatch(
        actions.updateEmployees({
          form: { saving: false },
        })
      );
      getEmployeeBenefits();
    }
  };

  const onDelete = async (paramsId) => {
    dispatch(showLoading());
    try {
      dispatch(
        actions.updateEmployees({
          form: { saving: true },
        })
      );
      const { data } = await api.delete(
        `/employees/${id}/benefits/${paramsId}`,
        employees
      );
      if (data.error || data.message) throw data;
      toast.success(
        <BvsToastResult message="Beneficio exluido com sucesso." />
      );
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          message={
            err?.message ||
            err?.error?.message ||
            'Não foi possivel salvar o funcionário neste momento.'
          }
          err={err}
        />
      );
    } finally {
      dispatch(hideLoading());
      dispatch(
        actions.updateEmployees({
          form: { saving: false },
        })
      );
      getEmployeeBenefits();
    }
  };

  const voucherOptions = () => {
    if (selectedItems.length === 0) {
      toast.warn(
        <BvsToastResult
          type="warn"
          message="Escolha ao menos um benefício de vale transporte"
        />
      );
    } else {
      history.push(`/optionBenefit/${id}`, {
        benefits: selectedItems.map((i) => i.id),
      });
    }
  };

  const onCancel = () => {
    dispatch(actions.resetEmployees());
    history.replace('/employees');
  };

  const onInfo = (paramsId) => {
    dispatch(
      employeeHistoricOfBenefits('update', {
        components: {
          ...historicComponents,
          modalVisible: true,
        },
      })
    );
    dispatch(employeeHistoricOfBenefits('fetch', paramsId));
  };

  // const onDetail = paramsId => {};
  const onDetail = async (benefitId) => {
    // Busco os dados
    try {
      const { data } = await api.get(`/benefits/${benefitId}`);
      if (data.error || data.message) throw data;
      setModalVisible(true);
      setBenefitInfo({
        ...data,
        region: data?.region?.name,
        benefitGroup: data?.benefitGroup?.name,
      });
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Falha ao buscar dados do Beneficio."
        />
      );
    }
  };

  useEffect(() => {
    getShippingAddress();
    getWorkdays();
    getDepartments();
    getJobs();

    id && populate(() => getEmployeeBenefits());
  }, [enterpriseActive?.info?.id, id]);

  useEffect(() => {
    const cleanup = () => {
      // Lógica para identificar se a rota mudou antes do componente ser desmontado
      dispatch(actions.resetEmployees());
    };

    // Cleanup será chamado quando o componente for desmontado
    return cleanup;
  }, [location]);

  return (
    <>
      <SubHeader
        title="Cadastro de Funcionários"
        subtitle="que utilizarão os beneficio da empresa"
      />
      <BvsContainer>
        <div className="col-md-12">
          <BvsForm
            onSubmit={(e) => {
              e.preventDefault();
              if (!id) onSave(e);
              else
                dispatch(
                  updateFormJustification({
                    components: { visible: true },
                  })
                );
            }}
          >
            <BvsPanel title="Dados do Funcionário">
              <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="registry"
                    label="Matrícula"
                    value={employees.registry}
                    onChange={(value) => onFieldChange('registry', value)}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="name"
                    label="Nome"
                    value={employees.name}
                    onChange={(value) => onFieldChange('name', value)}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsDropdown
                    title="Selecione um Status..."
                    name="status"
                    label="Status"
                    options={status.options}
                    loading={status.loading}
                    value={employees.status}
                    onSelectedItem={(value) => onFieldChange('status', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <label>Data de Nascimento</label>
                  <input
                    name="birthday"
                    type="date"
                    value={employees.birthday}
                    onChange={(e) => {
                      let value = e.target.value || '';
                      onFieldChange('birthday', value);
                    }}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="mother_name"
                    label="Nome da Mãe"
                    value={employees.mother_name}
                    onChange={(value) => onFieldChange('mother_name', value)}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="cpf"
                    label="CPF"
                    mask="cpf"
                    value={employees.cpf}
                    onChange={(value) => onFieldChange('cpf', value)}
                    disabled={id}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="rg"
                    label="RG"
                    mask="rg"
                    value={employees.rg}
                    onChange={(value) => onFieldChange('rg', value)}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsDropdown
                    title="Selecione um Emissor..."
                    name="emissor"
                    label="Emissor"
                    search
                    options={emissor.options}
                    loading={emissor.loading}
                    value={employees.emissor}
                    onSelectedItem={(value) => onFieldChange('emissor', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInputFile name="inputFile" />
                </div>
              </div>
            </BvsPanel>
            <BvsPanel title="Informações Profissionais">
              <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <div className="input-group">
                    <div className="col-9 mr-2 p-0">
                      <BvsDropdown
                        title="Selecione um Departamento..."
                        name="department_id"
                        label="Departamento"
                        options={departments.options}
                        loading={departments.loading}
                        value={employees.department_id}
                        onSelectedItem={(value) =>
                          onFieldChange('department_id', value)
                        }
                      />
                    </div>
                    <div className="input-group-append align-items-end">
                      <BvsButton
                        type="button"
                        title=""
                        icon="fa fa-plus"
                        onClick={() => {
                          setComponents('createDepartment', true);
                          dispatch(resetDepartment());
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <div className="input-group">
                    <div className="col-9 mr-2 p-0">
                      <BvsDropdown
                        title="Selecione um Cargo..."
                        name="job_id"
                        label="Cargo"
                        options={jobs.options}
                        loading={jobs.loading}
                        value={employees.job_id}
                        onSelectedItem={(value) =>
                          onFieldChange('job_id', value)
                        }
                      />
                    </div>
                    <div className="input-group-append align-items-end">
                      <BvsButton
                        type="button"
                        title=""
                        icon="fa fa-plus"
                        onClick={() => {
                          setComponents('createJob', true);
                          dispatch(resetJob());
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <div className="input-group">
                    <div className="col-9 mr-2 p-0">
                      <BvsDropdown
                        title="Selecione um Período..."
                        name="workday_id"
                        label="Dias Trabalhados"
                        options={workdays.options}
                        loading={workdays.loading}
                        value={employees.workday_id}
                        onSelectedItem={(value) =>
                          onFieldChange('workday_id', value)
                        }
                      />
                    </div>
                    <div className="input-group-append align-items-end">
                      <BvsButton
                        type="button"
                        title=""
                        icon="fa fa-plus"
                        onClick={() => {
                          setComponents('createWorkday', true);
                          dispatch(resetWorkday());
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <div className="input-group">
                    <div className="col-9 mr-2 p-0">
                      <BvsDropdown
                        title="Selecione um Endereço..."
                        name="shipping_address_id"
                        label="Endereço"
                        options={shippingAddress.options}
                        loading={shippingAddress.loading}
                        value={employees.shipping_address_id}
                        onSelectedItem={(value) =>
                          onFieldChange('shipping_address_id', value)
                        }
                      />
                    </div>
                    <div className="input-group-append align-items-end">
                      <BvsButton
                        type="button"
                        title=""
                        icon="fa fa-plus"
                        onClick={() => {
                          setComponents('createShippingAddress', true);
                          dispatch(resetShippingAddress());
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </BvsPanel>
            <BvsPanel title="Informações complementares caso sua empresa queira utilizar nossa base tecnológica para controle de informações sem custos">
              <div className="row" style={{ marginBottom: '25px' }}>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="admission_date"
                    type="date"
                    label="Data de Admissão"
                    required
                    value={employees.admission_date}
                    onChange={(value) => onFieldChange('admission_date', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="demission_date"
                    type="date"
                    label="Data de Demissão"
                    value={employees.demission_date}
                    onChange={(value) => onFieldChange('demission_date', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInputCurrency
                    name="salary"
                    label="Salário Base"
                    currency="BRL"
                    value={employees.salary}
                    onChange={(_, value) => onFieldChange('salary', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="email"
                    label="E-mail"
                    value={employees.email}
                    onChange={(value) => onFieldChange('email', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="phone"
                    label="Telefone"
                    mask="phone"
                    value={employees.phone}
                    onChange={(value) => onFieldChange('phone', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsDropdown
                    title="Selecione um Genero..."
                    name="gender"
                    label="Sexo"
                    options={gender.options}
                    loading={gender.loading}
                    value={employees.gender}
                    required
                    onSelectedItem={(value) => onFieldChange('gender', value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p className="lead">Dados Bancários</p>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                      <BvsDropdown
                        title="Selecione um tipo..."
                        name="account_type"
                        label="Tipo de Conta"
                        options={account.options}
                        loading={account.loading}
                        value={employees.account_type}
                        onSelectedItem={(value) =>
                          onFieldChange('account_type', value)
                        }
                      />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                      <BvsInput
                        name="bank"
                        label="Banco"
                        value={employees.bank}
                        onChange={(value) => onFieldChange('bank', value)}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                      <BvsInput
                        name="agency"
                        label="Agência"
                        value={employees.agency}
                        onChange={(value) => onFieldChange('agency', value)}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                      <BvsInput
                        name="agency_digit"
                        label="Digito"
                        maxLength={1}
                        value={employees.agency_digit}
                        onChange={(value) =>
                          onFieldChange('agency_digit', value)
                        }
                      />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                      <BvsInput
                        name="account"
                        label="Nº da Conta"
                        value={employees.account}
                        onChange={(value) => onFieldChange('account', value)}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                      <BvsInput
                        name="account_digit"
                        label="Digito"
                        maxLength={1}
                        value={employees.account_digit}
                        onChange={(value) =>
                          onFieldChange('account_digit', value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-12">
                  <strong
                    style={{
                      color: 'red',
                      display: 'inline-block',
                      width: '100%',
                    }}
                  >
                    Endereço Residencial
                  </strong>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="home_zipcode"
                    label="CEP"
                    mask="cep"
                    value={employees.home_zipcode}
                    onChange={(value) => onFieldChange('home_zipcode', value)}
                    onBlur={async (v) => {
                      let cep = v?.target?.value?.replace(/[^\d]/g, '');
                      if (cep.length >= 8) {
                        try {
                          const { data } = await viacep.get(`/${cep}/json/`);
                          dispatch(
                            actions.updateEmployees({
                              employees: {
                                ...employees,
                                home_state: data?.uf,
                                home_ibge: data?.ibge,
                                home_city: data?.localidade,
                                home_street: data?.logradouro,
                                home_district: data?.bairro,
                              },
                            })
                          );
                        } catch (err) {
                          return false;
                        }
                      }
                    }}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="home_street"
                    label="Endereço"
                    value={employees.home_street}
                    onChange={(value) => onFieldChange('home_street', value)}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="home_number"
                    label="Número"
                    value={employees.home_number}
                    onChange={(value) => onFieldChange('home_number', value)}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="home_complement"
                    label="Complemento"
                    value={employees.home_complement}
                    onChange={(value) =>
                      onFieldChange('home_complement', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="home_district"
                    label="Bairro"
                    value={employees.home_district}
                    onChange={(value) => onFieldChange('home_district', value)}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="home_ibge"
                    label="IBGE"
                    value={employees.home_ibge}
                    onChange={(value) => onFieldChange('home_ibge', value)}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="home_city"
                    label="Cidade"
                    value={employees.home_city}
                    onChange={(value) => onFieldChange('home_city', value)}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="home_state"
                    label="Estado"
                    value={employees.home_state}
                    onChange={(value) => onFieldChange('home_state', value)}
                    required
                  />
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-12">
                  <strong
                    style={{
                      color: 'red',
                      display: 'inline-block',
                      width: '100%',
                    }}
                  >
                    Endereço Comercial
                  </strong>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="business_zipcode"
                    label="CEP"
                    mask="cep"
                    value={employees.business_zipcode}
                    onChange={(value) =>
                      onFieldChange('business_zipcode', value)
                    }
                    onBlur={async (v) => {
                      let cep = v?.target?.value?.replace(/[^\d]/g, '');
                      if (cep.length >= 8) {
                        try {
                          const { data } = await viacep.get(`/${cep}/json/`);
                          dispatch(
                            actions.updateEmployees({
                              employees: {
                                ...employees,
                                business_state: data?.uf,
                                business_ibge: data?.ibge,
                                business_city: data?.localidade,
                                business_street: data?.logradouro,
                                business_district: data?.bairro,
                              },
                            })
                          );
                        } catch (err) {
                          return false;
                        }
                      }
                    }}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="business_street"
                    label="Endereço"
                    value={employees.business_street}
                    onChange={(value) =>
                      onFieldChange('business_street', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="business_number"
                    label="Número"
                    value={employees.business_number}
                    onChange={(value) =>
                      onFieldChange('business_number', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="business_complement"
                    label="Complemento"
                    value={employees.business_complement}
                    onChange={(value) =>
                      onFieldChange('business_complement', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="business_district"
                    label="Bairro"
                    value={employees.business_district}
                    onChange={(value) =>
                      onFieldChange('business_district', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="business_ibge"
                    label="IBGE"
                    value={employees.business_ibge}
                    onChange={(value) => onFieldChange('business_ibge', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="business_city"
                    label="Cidade"
                    value={employees.business_city}
                    onChange={(value) => onFieldChange('business_city', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="business_state"
                    label="Estado"
                    value={employees.business_state}
                    onChange={(value) => onFieldChange('business_state', value)}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <div className="row p-3">
                  <BvsButton type="submit" title="Salvar" icon="fa fa-save" />
                  <BvsButton
                    className="ml-2"
                    type="button"
                    title="Voltar"
                    icon="fa fa-undo"
                    onClick={() => onCancel()}
                  />
                </div>
              </div>
            </BvsPanel>
          </BvsForm>
        </div>

        {id && (
          <div className="col-md-12">
            <BvsPanel title="Informações dos Benefícios">
              <div className="col-md-12">
                <BvsTableV4
                  columns={columns}
                  rows={benefits}
                  onRefresh={() => {
                    getEmployeeBenefits();
                  }}
                  onSelect={onSelect}
                  selectedItems={selectedItems}
                  rowKey={(r) => r.id}
                  actionOptions={{ width: '225px' }}
                  actions={[
                    (row) => (
                      <span data-tip="Detalhes">
                        <BvsButton
                          type="button"
                          className="btn btn-xs"
                          customStyle={{ marginRight: '5px' }}
                          icon={
                            <FontAwesome
                              name="credit-card-alt"
                              color="#fff"
                              size={12}
                            />
                          }
                          onClick={() => onDetail(row.benefit_id)}
                        />
                      </span>
                    ),
                    (row) => (
                      <span data-tip="Editar">
                        <BvsButton
                          type="button"
                          className="btn btn-xs"
                          customStyle={{ marginRight: '5px' }}
                          icon={
                            <FontAwesome name="edit" color="#fff" size={12} />
                          }
                          onClick={() => onEdit(row?.benefit?.id)}
                        />
                      </span>
                    ),
                    (row) => {
                      return (
                        <>
                          <span data-tip="Mais Opções">
                            <BvsButton
                              type="button"
                              className="btn btn-xs"
                              customStyle={{ marginRight: '5px' }}
                              icon={
                                <FontAwesome
                                  name="ellipsis-h"
                                  color="#fff"
                                  size={12}
                                />
                              }
                              onClick={(e) => {
                                setMenuData(row);
                                show(e, {
                                  id: menuId,
                                });
                              }}
                            />
                          </span>
                        </>
                      );
                    },
                  ]}
                />

                <div className="my-3" />
                <div className="container-fluid">
                  <div className="row justify-content-end">
                    <div className="col-md-3">
                      <li className="list-group-item list-group-item-info d-flex justify-content-between align-items-end">
                        <strong>Total de Benefício</strong>
                        <i>
                          {toMoney(
                            benefits
                              .filter(
                                (eb) =>
                                  eb.status === EmployeeBenefitStatus.ATIVO
                              )
                              .map(
                                (eb) =>
                                  Number(eb.daily_quantity) *
                                  Number(eb.month_quantity) *
                                  Number(eb.benefit?.unit_cost)
                              )
                              .reduce((prev, cur) => prev + cur, 0)
                          )}
                        </i>
                      </li>
                    </div>
                  </div>
                </div>
                <div>
                  <BvsButton
                    type="button"
                    title="Incluir Beneficios"
                    icon="fa fa-plus"
                    customStyle={{ margin: '15px 5px' }}
                    onClick={() => history.push(`/employee/${id}/benefits`)}
                  />
                  <BvsButton
                    type="button"
                    title="Opção de Vale Transporte"
                    icon="fa fa-credit-card"
                    customStyle={{ margin: '15px 5px' }}
                    onClick={() => voucherOptions()}
                  />
                  <BvsButton
                    type="button"
                    title="Histórico de Compras"
                    icon="fa fa-list-alt"
                    customStyle={{ margin: '15px 5px' }}
                    // disabled
                    onClick={() => history.push(`/purchaseHistoric/${id}`)}
                  />
                </div>
              </div>
            </BvsPanel>
          </div>
        )}
      </BvsContainer>
      <CreateDepartmentModal onSaved={() => getDepartments()} />
      <CreateJobModal onSaved={() => getJobs()} />
      <CreateWorkdayModal onSaved={() => getWorkdays()} />
      <CreateShippingAddress onSaved={() => getShippingAddress()} />
      {id && <JustificationEditing onSave={() => onSave()} />}
      <Historic />
      <Menu id={menuId}>
        {menuData && (
          <>
            <Item key="delete" onClick={() => onDelete(menuData.benefit_id)}>
              <FontAwesome name="close" color="#000" size={12} />
              &nbsp; Excluir
            </Item>
            <Item
              key="changeStatus"
              onClick={() => {
                changeStatus(menuData.benefit_id);
              }}
            >
              {RenderStatusIconButton(menuData.status, '#000')}
              &nbsp;
              {menuData.status === _enum.STATUS.ATIVO ? 'Inativar' : 'Ativar'}
            </Item>
            <Item key="info" onClick={() => onInfo(menuData.id)}>
              <FontAwesome name="info-circle" color="#000" size={12} />
              &nbsp; Mais Informações
            </Item>
          </>
        )}
      </Menu>
      {modalVisible && (
        <ModalBenefitDetail
          visible
          benefitInfo={benefitInfo}
          onClose={() => {
            setModalVisible(false);
          }}
        />
      )}
      <ReactTooltip place="bottom" />
    </>
  );
};

export default CreateBenefitEmployees;
