/* eslint-disable no-throw-literal */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';
import api from '~/services/api';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';

const useController = ({ whenImportComplete: pWhenImportComplete = null }) => {
  const [showModal, setShowModal] = useState({
    show: false,
    id: null,
  });
  const [imports, setImport] = useState({
    options: [],
    loading: false,
  });
  const [completeOrder, setCompleteOrder] = useState(null);
  const [cursorLog, setCursorLog] = useState(null);
  const { import_orders } = useSelector((state) => state.formImportOrders);
  const dispatch = useDispatch();
  const onSave = async (e) => {
    e.preventDefault();
    dispatch(showLoading());
    try {
      if (import_orders.file === null)
        throw { message: 'Selecione um arquivo para importar' };
      if (import_orders.type === null)
        throw { message: 'Informe o tipo de importação'};

      let formData = new FormData();
      formData.append('file', import_orders.file);
      // formData.append('socketId', socket?.id);
      formData.append('identification', import_orders.identification);
      formData.append('value', import_orders.value);
      formData.append('type', import_orders.type)
      const { data } = await api.post('/order/excel/import', formData, {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      });

      if (data?.status === 'success') setCompleteOrder(data?.order);

      pWhenImportComplete && pWhenImportComplete(data);
    } catch (error) {
      toast.error(
        <BvsToastResult
          type="error"
          err={error}
          message="Falha ao consultar pedidos."
        />
      );
    } finally {
      dispatch(hideLoading());
    }
  };
  const fetch = async () => {
    setImport({ ...imports, loading: true });
    let options = [];
    try {
      const { data } = await api.get('imports/orders');
      if (data.error || data.message) throw data;
      options = data;
      setImport({ ...imports, options, loading: false });
    } catch (error) {
      setImport({ ...imports, options, loading: false });
    }
  };

  const chooseModal = (show, id) => {
    setShowModal({ ...showModal, show, id });
  };

  const getLog = async (logId) => {
    const { data } = await api.get(`/order/imports/log/${logId}`);
    setCursorLog(data);
  };

  return {
    fetch,
    imports,
    onSave,
    showModal,
    chooseModal,
    getLog,
    setCursorLog,
    cursorLog,
    completeOrder,
    setCompleteOrder,
  };
};

export default useController;
