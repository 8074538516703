import types from './types';

export function updateEnterprises(enterprises) {
  return {
    type: types.UPDATE_ENTERPRISES,
    enterprises,
  };
}
export function getMyEnterprises() {
  return {
    type: types.GET_MY_ENTERPRISES,
  };
}

export function updateEnterpriseActive(enterprise) {
  return {
    type: types.UPDATE_ENTERPRISE_ACTIVE,
    enterprise,
  };
}

export function resetEnterprise() {
  return {
    type: types.RESET_ENTERPRISE,
  };
}

export function setEnterpriseActive(payload, callback) {
  return {
    type: types.SET_ENTERPRISE_ACTIVE,
    enterpriseId: payload,
    callback,
  };
}

export function clearEnterpriseActive() {
  return { type: types.CLEAR_ENTERPRISE_ACTIVE };
}
