import { useSelector } from 'react-redux';
import BvsButton from '~/components/BvsButton';
import BvsModal from '~/components/BvsModal';

const ModalConfirmNfeUpload = ({ onCloseModal, onConfirmed }) => {
  const {
    listOrder: { components },
  } = useSelector((state) => state.app);
  return (
    <BvsModal
      visible={components.showConfirmUploadNfe}
      title="Confirmação de Envio de Nf-e"
      onClose={onCloseModal}
      footer={() => (
        <div>
          <BvsButton className="mr-3" title="Não" onClick={onCloseModal} />
          <BvsButton
            className="bg-danger"
            title="Sim"
            onClick={() => onConfirmed()}
          />
        </div>
      )}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 pt-3">
            <p className="font-weight-bold">
              Deseja enviar o arquivo selecionado?
            </p>
          </div>
        </div>
      </div>
    </BvsModal>
  );
};

export default ModalConfirmNfeUpload;
