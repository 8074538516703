/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesome } from 'react-web-vector-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logOut } from '~/store/modules/auth/actions';
import { UserMenuWrapper, UserMenuItem } from './style';

function BvsUserMenu({ visible, onClose }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  return visible ? (
    <UserMenuWrapper>
      <ul>
        <UserMenuItem onClick={() => history.push(`/users/${user.id}`)}>
          <FontAwesome name="user" size={18} style={{ marginRight: 10 }} />
          Meus Dados
        </UserMenuItem>
        <UserMenuItem onClick={() => history.push(`/changePassword`)}>
          <FontAwesome name="lock" size={18} style={{ marginRight: 10 }} />
          Trocar Senha
        </UserMenuItem>
        <UserMenuItem
          onClick={() => {
            dispatch(logOut());
          }}
        >
          <FontAwesome name="power-off" size={18} style={{ marginRight: 10 }} />
          Sair
        </UserMenuItem>
      </ul>
    </UserMenuWrapper>
  ) : (
    false
  );
}

BvsUserMenu.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

BvsUserMenu.defaultProps = {
  visible: false,
  onClose: () => {},
};
export default BvsUserMenu;
