/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable import/order */

import * as ENUM from '~/system/enum';

import React, { useEffect } from 'react';

import BvsButton from '~/components/BvsButton';
import BvsContainer from '~/components/BvsContainer';
import BvsForm from '~/components/BvsForm';
import { useSelector } from 'react-redux';
import SubHeader from '~/components/BvsSubHeader';
import BvsAdmButton from '~/components/BvsAdmButton';
import BvsPanel from '~/components/BvsPanel';
import BvsInput from '~/components/BvsInput';
// import columns from './columns.json';
import BvsInputFile from '~/components/BvsInputFile';
import BvsTableV4 from '../../components/BvsTableV4';
import { DownloadFile } from '~/system/helper';
import useController from './hooks/useController';
import useForm from './hooks/useForm';
import { getImportStatus } from '~/system/convert';
import { FontAwesome } from 'react-web-vector-icons';
import moment from 'moment';
import { IMPORT_STATUS } from '~/system/enum';
import BvsModal from '~/components/BvsModal';
import api from '~/services/api';

const ImportGeneralRegisters = () => {
  const { import_registers, form } = useSelector((state) => state.formImport);
  const { user, token } = useSelector((state) => state.auth);
  const { onFieldChange, reset } = useForm();

  const {
    imports,
    fetch: getImportRegister,
    onSave,
    getLog,
    setCursorLog,
    cursorLog,
  } = useController({
    whenImportComplete: (data) => {
      reset();
      getImportRegister();
    },
  });

  const columns = [
    {
      key: 'id',
      label: 'Protocolo',
      sort: true,
    },
    {
      key: 'createdAt',
      label: 'Data e Hora',
      sort: true,
      content: (row) => moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      key: 'importedBy',
      label: 'Importado por',
      sort: true,
      content: (row) => row?.createdBy?.name ?? '',
    },
    {
      key: 'type',
      label: 'Tipo',
      sort: true,
    },
    {
      key: 'name',
      label: 'Identificado',
      sort: true,
      width: '100%',
    },
    {
      key: 'status',
      label: 'Status',
      sort: true,
      content: (row) => getImportStatus(row.status),
    },
  ];

  const download = (logId) => {
    let link = document.createElement('a');
    link.download = `${logId}.xlsx`;
    link.target = '_blank';
    link.href = `${api.defaults.baseURL}/registers/imports/${logId}/download?token=${token}`;
    link.click();
  };

  useEffect(() => {
    getImportRegister();
    return () => {
      reset();
    };
  }, []);

  return (
    <>
      <SubHeader title="Importar/Upload" subtitle="todos os dados" />
      <BvsContainer>
        {user?.type === ENUM.USER_TYPE.ADMIN && <BvsAdmButton />}
        <div className="col-md-12">
          <BvsPanel title="Selecione o arquivo para importação de dados e clique em importar">
            <BvsForm onSubmit={onSave}>
              <div className="row">
                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 p-3">
                  <BvsInput
                    name="identification"
                    label="Identificador"
                    value={import_registers.identification}
                    onChange={(value) => onFieldChange('identification', value)}
                  />
                </div>

                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 p-3">
                  <BvsInputFile
                    name="file"
                    onChange={onFieldChange}
                    value={import_registers?.file}
                    uri=""
                  />
                </div>

                <div className="row justify-content-end align-items-end">
                  <div className="p-3">
                    <BvsButton
                      type="submit"
                      title="Importar"
                      icon="fa fa-save"
                    />
                  </div>
                </div>
              </div>
            </BvsForm>
          </BvsPanel>
        </div>

        <div className="col-md-12">
          <BvsPanel title="Histórico de Importação">
            <div className="col-md-12">
              <BvsTableV4
                columns={columns}
                rows={imports.options}
                onRefresh={() => getImportRegister()}
                rowKey="id"
                actionOptions={{ width: '165px' }}
                actions={[
                  (row) => (
                    <>
                      {row.status !== IMPORT_STATUS.PENDING && (
                        <BvsButton
                          type="button"
                          className="btn btn-xs"
                          customStyle={{ marginRight: '5px' }}
                          onClick={() => download(row.id)}
                          icon={
                            <FontAwesome
                              name="download"
                              color="#fff"
                              size={12}
                            />
                          }
                        />
                      )}
                      {row.status === IMPORT_STATUS.FAILED && (
                        <BvsButton
                          type="button"
                          className="btn btn-xs"
                          customStyle={{ marginRight: '5px' }}
                          icon={
                            <FontAwesome
                              name="exclamation-circle"
                              color="#fff"
                              size={12}
                            />
                          }
                          onClick={(e) => getLog(row.id)}
                        />
                      )}
                    </>
                  ),
                ]}
              />
              <div>
                <BvsButton
                  type="button"
                  title="Modelo de Importação (XLSX)"
                  icon="fa fa-file-excel-o"
                  onClick={async () => {
                    DownloadFile(
                      '/model-import',
                      `modelo_importação-${moment().format(
                        'DD.MM.YYYY HH:mm:ss'
                      )}.xlsx`
                    );
                  }}
                />
              </div>
            </div>
          </BvsPanel>
        </div>
      </BvsContainer>
      <BvsModal
        visible={!!cursorLog}
        onClose={() => {
          setCursorLog(null);
        }}
        footer={() => (
          <div className="row d-flex justify-content-end">
            <div className="text-nowrap px-2">
              <BvsButton
                title="Fechar"
                onClick={() => {
                  setCursorLog(null);
                }}
              />
            </div>
          </div>
        )}
      >
        <div className="container">
          <div className="row">
            <span className="col-12 my-2">
              <strong>Criado em:</strong>&nbsp;
              {!!cursorLog &&
                moment(cursorLog?.createdAt, 'YYYY-MM-DD hh:mm:ss')?.format(
                  'DD/MM/YYYY hh:mm:ss'
                )}
            </span>

            <span className="col-12 my-2">
              <strong>Criado por:</strong>&nbsp;
              {cursorLog?.userImport?.createdBy?.name}
            </span>

            <span className="col-12 my-2">
              <strong>Arquivo Original:</strong>&nbsp;
              <a
                href="javascript:;"
                onClick={() => download(cursorLog?.user_import_id)}
                rel="noreferrer"
              >
                {cursorLog?.userImport?.name ||
                  `${cursorLog?.userImport.id}.${cursorLog?.userImport?.type}`}
              </a>
            </span>

            <p className="col-12">
              <strong>Erro:</strong>&nbsp;
              <br />
              {cursorLog?.error?.split(',').map((error) => (
                <p className="p-0 m-1">{error};</p>
              ))}
            </p>
          </div>
        </div>
      </BvsModal>
    </>
  );
};

export default ImportGeneralRegisters;
