/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import AvatarDefault from '~/assets/sample-user.jpg';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import webservice from '~/services/api';
import BvsPanel from '~/components/BvsPanel';
import SubHeader from '~/components/BvsSubHeader';
import BvsButton from '~/components/BvsButton';
import BvsForm from '~/components/BvsForm';
import BvsContainer from '~/components/BvsContainer';
import BvsInputV2 from '~/components/BvsInputV2';
import { Avatar, AvatarCircle } from '../../layouts/main/Header/style';
import { getAvatar } from '../../system/helper';

const OcorrenceDetails = ({ match }) => {
  const history = useHistory();
  const [occurrenceDetails, setOccurrenceDetails] = useState([]);
  const { register, handleSubmit, errors, setValue } = useForm();
  let { id } = match.params;

  const fetchOccurrenceDetails = async () => {
    const { data } = await webservice.get(`/occurrences/${id}/message`);
    setOccurrenceDetails(data);
  };

  const save = async (values) => {
    const { data } = await webservice.post(
      `/occurrences/${id}/message`,
      values
    );
    onCancel();
    await fetchOccurrenceDetails();
  };

  const onCancel = () => {
    setValue('text', '');
    history.replace(`/occurrenceDetails/${id}`);
  };

  useEffect(() => {
    fetchOccurrenceDetails();
  }, []);

  return (
    <>
      <SubHeader title="Ocorrências" subtitle="da empresa" />
      <BvsContainer>
        <div className="col-md-12">
          <BvsPanel title="Enviar nova mensagem">
            <BvsForm onSubmit={handleSubmit(save)}>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <BvsInputV2
                    ref={register({ required: true })}
                    name="text"
                    label="Mensagem"
                    required
                    error={errors.text}
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <BvsButton
                    type="submit"
                    title="Salvar"
                    icon="fa fa-save"
                    customStyle={{
                      marginLeft: 25,
                      marginTop: 25,
                      float: 'right',
                    }}
                  />
                  <BvsButton
                    type="button"
                    title="Voltar"
                    icon="fa fa-undo"
                    customStyle={{ marginTop: 25, float: 'right' }}
                    onClick={() => history.push('/occurrence')}
                  />
                </div>
              </div>
            </BvsForm>
          </BvsPanel>
        </div>

        <div className="col-md-12">
          <BvsPanel title="Relação de Mensagens">
            {occurrenceDetails.map((item) => {
              return (
                <div style={{ margin: '25px' }}>
                  <p>
                    <strong>#{item.id}</strong>
                    &nbsp; enviado as &nbsp;
                    <strong>
                      {moment(item.created_at).format('DD/MM/YYYY HH:mm')}
                    </strong>
                  </p>
                  <p className="d-flex align-items-center font-weight-bold">
                    <AvatarCircle size="xs">
                      <Avatar
                        src={getAvatar(item?.createdBy?.avatar)}
                        onError={() => {
                          this.onerror = null;
                          this.src = AvatarDefault;
                        }}
                      />
                    </AvatarCircle>
                    {item?.createdBy?.name}:
                  </p>
                  <p
                    className="alert alert-warning"
                    style={{ marginTop: '15px' }}
                  >
                    {item.message}
                  </p>
                </div>
              );
            })}
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default OcorrenceDetails;
