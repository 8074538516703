const types = {
  UPDATE_SHIPPING_ADDRESS: '@form/shippingAddress/UPDATE',
  RESET_SHIPPING_ADDRESS: '@form/shippingAddress/RESET_WORKDAY',
  ADD_RESPONSIBLE: '@form/shippingAddress/ADD_RESPONSIBLE',
  REMOVE_RESPONSIBLE: '@form/shippingAddress/REMOVE_RESPONSIBLE',
  UPDATE_RESPONSIBLE: '@form/shippingAddress/UPDATE_RESPONSIBLE',
  RESET_RESPONSIBLE: '@form/shippingAddress/RESET_RESPONSIBLE',
  ADD_SHIPPING_ADDRESS: '@form/shippingAddress/ADD_SHIPPING_ADDRESS',
};

export default types;
