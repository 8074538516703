/* eslint-disable no-throw-literal */
/* eslint-disable no-plusplus */
import moment from 'moment';
import * as convert from '~/system/convert';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';
import api from '~/services/api';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';
import { DownloadFile, toMoney } from '~/system/helper';
import BvsBadge from '../../../components/BvsBadge';

const useController = ({}) => {
  const [orders, setOrders] = useState({ options: [], loading: false });
  const [curOrder, setCurOrder] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const { cursorOrder } = useSelector((state) => state.orderCancel);
  const { enterpriseActive } = useSelector((state) => state.enterprises);
  const [modalState, setModalState] = useState({
    cancel: false,
  });
  const dispatch = useDispatch();
  const getColumns = () => {
    let columns = [
      {
        key: 'id',
        label: 'Pedido Gerado',
        sort: true,
      },
      {
        key: 'name',
        label: 'Razão Social',
        sort: true,
        content: (row) => row?.enterprise?.social_name,
      },
      {
        key: 'cnpj',
        label: 'CNPJ',
        sort: true,
        content: (row) => row?.enterprise?.cnpj,
      },
      {
        key: 'createdAt',
        label: 'Emissão',
        sort: true,
        content: (row) =>
          row?.createdAt ? moment(row?.createdAt).format('DD/MM/YYYY') : '',
      },
      {
        key: 'payday',
        label: 'Pagamento',
        sort: true,
        content: (row) =>
          row?.payday ? moment(row?.payday).format('DD/MM/YYYY') : '',
      },
      {
        key: 'delivery',
        label: 'Entrega',
        sort: true,
        content: (row) =>
          row?.delivery_date
            ? moment(row?.delivery_date).format('DD/MM/YYYY')
            : '',
      },
      {
        key: 'total',
        label: 'Valor Total',
        sort: true,
        content: (row) => toMoney(row?.total ?? 0),
      },
      {
        key: 'financial_status',
        label: 'Financeiro',
        sort: false,
        content: (row) => {
          const status = convert.getStatusFinancial(row?.financial_status);
          return (
            <BvsBadge tooltip={status.tooltip} color={status.color} noLabel />
          );
        },
      },
      {
        key: 'operation_status',
        label: 'Operacional',
        sort: false,
        content: (row) => {
          const status = convert.getStatusOperational(row?.operational_status);
          return (
            <BvsBadge tooltip={status.tooltip} color={status.color} noLabel />
          );
        },
      },
      {
        key: 'status',
        label: 'Status',
        type: 'badge',
        sort: true,
        content: (row) => {
          const status = convert.getOrderStatus(row?.status);
          return (
            <BvsBadge
              tooltip={status.tooltip}
              color={status.color}
              title={status.title}
            />
          );
        },
      },
    ];

    if (enterpriseActive?.info) columns.splice(1, 2);
    return columns;
  };
  const fetch = async () => {
    dispatch(showLoading());
    setOrders({ ...orders, loading: true });
    let options = [];
    try {
      const { data } = await api.get('/orders');
      if (data.error || data.message) throw data;
      options = data;
      setOrders({ ...orders, options, loading: false });
      dispatch(hideLoading());
    } catch (err) {
      dispatch(hideLoading());

      setOrders({ ...orders, options, loading: false });
      toast.error(
        <BvsToastResult
          type="error"
          message={
            err?.message || err?.error?.message || 'Falha ao consultar pedidos.'
          }
        />
      );
    }
  };

  const generateReceipt = async (selectedItems) => {
    if (selectedItems.length === 0) {
      toast.info(<BvsToastResult message="Selecione ao menos um pedido" />);
      return;
    }
    try {
      dispatch(showLoading());

      const response = await api.post(
        '/order/receipt/download',
        selectedItems.map((i) => i.id),
        {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
        encoding: 'UTF-8',
      });
      const a = document.createElement('a');
      document.body.appendChild(a);

      const blobUrl = window.URL.createObjectURL(blob);
      a.href = blobUrl;
      a.download = `Recibo-${moment().format('YYYY-MM-DD HH:mm:ss')}`;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
      }, 0);
      dispatch(hideLoading());
    } catch (err) {
      toast.error(
        <BvsToastResult
          typ="error"
          err={err}
          message="Falha ao realizar download dos recibos"
        />
      );
      dispatch(hideLoading());
    }
  };

  const emitBolet = async (order) => {
    dispatch(showLoading());
    try {
      if (!order.payday)
        throw {
          message:
            'Este pedido não possui data de pagamento para gerar boleto!',
        };
      const { data } = await api.get(`order/bolet/${order.id}/emit`);
      if (data.error || data.message) throw data;
      setCurOrder({
        id: order.id,
        payday: order.payday,
        delivery_date: moment(order.payday).add(
          order?.enterprise?.delivery_day,
          'day'
        ),
        bolet: data,
      });
      setModalVisible(true);
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          message="Falha ao gerar boleto"
          err={err}
        />
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const onCloseModal = () => {
    setCurOrder(null);
    setModalVisible(false);
  };

  const reportByOrder = async (order) => {
    try {
      await DownloadFile(
        `/order/${order.id}/report/download`,
        `relatório_pedido_${order.id}-${
          enterpriseActive?.info?.social_name
        }-${moment().format('DD.MM.YYYY HH:mm:ss')}.xlsx`
      );
      toast.info(
        <BvsToastResult message="Seu download iniciará em breve, já recebemos sua solicitação" />
      );
    } catch (err) {
      toast.error(
        <BvsToastResult message="Falha ao gerar relatório do pedido" />
      );
    }
  };

  useEffect(() => {
    if (cursorOrder) {
      dispatch(showLoading());
      try {
        setModalState({
          cancel: true,
        });
      } catch (err) {
        toast.error(
          <BvsToastResult
            type="error"
            message={
              err?.message || err?.error?.message || 'Falha ao cancelar pedido.'
            }
          />
        );
      } finally {
        dispatch(hideLoading());
      }
    }
  }, [cursorOrder]);

  return {
    fetch,
    orders,
    getColumns,
    generateReceipt,
    emitBolet,
    modalVisible,
    onCloseModal,
    setModalVisible,
    curOrder,
    reportByOrder,
    modalState,
    setModalState,
  };
};

export default useController;
