/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { AntDesign } from 'react-web-vector-icons';

export default function BvsToastResult({ type = 'success', message, err }) {
  return (
    <div>
      <AntDesign
        name={
          type === 'success'
            ? 'check'
            : type === 'warn'
            ? 'infocirlceo'
            : 'frowno'
        }
        size={18}
        color="#fff"
      />{' '}
      &nbsp;{' '}
      <span
        dangerouslySetInnerHTML={{
          __html:
            type === 'success'
              ? message
              : err?.message ||
                err?.error?.message ||
                err?.error?.original?.sqlMessage ||
                message,
        }}
      />
    </div>
  );
}
