import SubHeader from '~/components/BvsSubHeader';
import BvsContainer from '~/components/BvsContainer';
import BvsPanel from '~/components/BvsPanel';
import BvsTableV4 from '~/components/BvsTableV4';
import useController from './hooks/useController';
import moment from 'moment';
import { toMoney } from '../../../system/helper';
import * as convert from '~/system/convert';
import { FontAwesome } from 'react-web-vector-icons';
// import { useSelectedItems } from '~/hooks/useSelectedItems';
import { useEffect } from 'react';
import BvsButton from '~/components/BvsButton';

const ReportSelector = ({ match }) => {
  const { type } = match.params;
  const {
    orders,
    fetch: getOrders,
    generateReport,
    getColumns,
  } = useController({});

  useEffect(() => {
    getOrders();
  }, []);
  return (
    <>
      <SubHeader
        title="Relatórios"
        subtitle="Selecione um pedido para gerar o relatório."
      />
      <BvsContainer>
        <div className="col-md-12">
          <BvsPanel title="Relação de Pedidos">
            <div className="col-md-12">
              <BvsTableV4
                tableKey="listOrders"
                columns={getColumns()}
                rows={[]}
                rowKey={(r) => r.id}
                onRefresh={() => getOrders()}
                actionOptions={{ width: '100px' }}
                actions={[
                  (row) => (
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={<FontAwesome name="file" color="#fff" size={12} />}
                      onClick={() => generateReport(row.id, Number(type))}
                    />
                  ),
                ]}
              />
            </div>
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default ReportSelector;
