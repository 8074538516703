import styled from 'styled-components';

const MenuTop = styled.div`
  max-width: 992px;
  margin-top: 50px;
  margin-left: 2.2px;
  & .rc-menu-root {
    overflow: hidden;
    background-color: transparent;
    cursor: pointer;
  }
  & .rc-menu-vertical .rc-menu-item {
    cursor: pointer;
  }
`;

export default MenuTop;
