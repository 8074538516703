import ReactTooltip from 'react-tooltip';

const BvsBadge = ({ title, noLabel = false, color, tooltip }) => {
  return (
    <>
      <span data-tip={tooltip}>
        <span
          className="badge d-flex justify-content-center"
          style={{
            backgroundColor: noLabel ? color : `${color}33`,
            minHeight: 12,
            color,
          }}
        >
          {!noLabel && <strong>{title}</strong>}
        </span>
      </span>
      <ReactTooltip />
    </>
  );
};

export default BvsBadge;
