/* eslint-disable consistent-return */
/* eslint-disable no-fallthrough */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showLoading, hideLoading } from '~/store/modules/loader/actions';
import api from '~/services/api';
import BvsToastResult from '~/components/BvsToastResult';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const useController = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [orders, setOrders] = useState({ options: [], loading: false });
  const { enterpriseActive } = useSelector((state) => state.enterprises);

  const getColumns = () => {
    let columns = [
      {
        key: 'id',
        label: 'Pedido Gerado',
        sort: true,
      },
      {
        key: 'name',
        label: 'Razão Social',
        sort: true,
      },
      {
        key: 'cnpj',
        label: 'CNPJ',
        sort: true,
      },
      {
        key: 'createdAt',
        label: 'Emissão',
        sort: true,
      },
      {
        key: 'payday',
        label: 'Pagamento',
        sort: true,
      },
      {
        key: 'delivery',
        label: 'Entrega',
        sort: true,
      },
      {
        key: 'total',
        label: 'Valor Total',
        sort: true,
      },
      {
        key: 'financial_status',
        label: 'Financeiro',
        type: 'badge',
        sort: false,
      },
      {
        key: 'operation_status',
        label: 'Operacional',
        type: 'badge',
        sort: false,
      },
      {
        key: 'status',
        label: 'Status',
        type: 'badge',
        show: true,
        sort: true,
        width: '100%',
      },
    ];

    if (enterpriseActive?.info) columns.splice(1, 2);
    return columns;
  };

  const fetch = async () => {
    dispatch(showLoading());
    setOrders({ ...orders, loading: true });
    let options = [];
    try {
      const { data } = await api.get('/orders');
      if (data.error || data.message) throw data;
      options = data;
      setOrders({ ...orders, options, loading: false });
      dispatch(hideLoading());
    } catch (err) {
      dispatch(hideLoading());

      setOrders({ ...orders, options, loading: false });
      toast.error(
        <BvsToastResult
          type="error"
          message={
            err?.message || err?.error?.message || 'Falha ao consultar pedidos.'
          }
        />
      );
    }
  };

  const generateReport = (orderId, type) => {
    switch (type) {
      case 1:
        return history.push(`/reportsbybenefits/${orderId}`);
      case 2:
        return history.push(`/reportsbyusers/${orderId}`);
      case 3:
        return history.push(`/reportsbyaddress/${orderId}`);
      case 4:
        return history.push(`/reportsbydepartmentperbenefits/${orderId}`);
      case 5:
        return history.push(`/reportsbydepartment/${orderId}`);
      case 6:
        return history.push(`/reportsbydepartmentperusers/${orderId}`);
      case 7:
        return history.push(`/reportsbystatus/${orderId}`);
      case 8:
        return history.push(`/reportsbystatusperusers/${orderId}`);
      default:
    }
  };

  return {
    fetch,
    orders,
    getColumns,
    generateReport,
  };
};
export default useController;
