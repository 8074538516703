/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';

export const InputContainer = styled.div`
  position: relative;
  /* margin-top: 25px; */
`;

export const Input = styled.input`
  ${(props) =>
    props.disabled ? 'background: #f2f2f2; cursor: not-allowed' : ''}
`;

export const Label = styled.label`
  color: ${(props) => (props.isRequired ? '#c0392b' : '#616161')};
  font-size: ${(props) => (props.error ? '0.8em' : '1rem')};
  ${(props) => (props.disabled ? 'background: #f2f2f2;' : '')}
`;
