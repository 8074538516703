import { useDispatch, useSelector } from 'react-redux';
import {
  resetAdministrativeCardFilter,
  updateAdministrativeCardFilter,
} from '~/store/modules/filter/FilterAdministrativeCard/actions';

const useFilter = () => {
  const dispatch = useDispatch();
  const { query, filter } = useSelector(
    (state) => state.filterAdministrativeCard
  );

  const onFieldChange = (key, value) => {
    dispatch(
      updateAdministrativeCardFilter({
        filter: { ...filter },
        query: { ...query, [key]: value },
      })
    );
  };

  const clean = () => {
    dispatch(resetAdministrativeCardFilter());
  };

  const onList = (list) => {
    dispatch(
      updateAdministrativeCardFilter({
        list,
      })
    );
  };

  return { onFieldChange, clean, onList };
};

export default useFilter;
