import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  filter: {
    shippingAddress: null,
    department: null,
    workday: null,
    benefitGroup: null,
    employees: null,
  },
};

function orderCreate(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_FILTER:
      return produce(state, (draft) => {
        draft = { ...draft, ...action.payload };
        return draft;
      });
    case types.RESET_FILTER:
      return produce(state, (draft) => {
        draft.filter = INITIAL_STATE.filter;
        return draft;
      });
    default:
      return state;
  }
}
export default orderCreate;
