import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  cursorOrder: null,
  form: {
    justification: '',
  },
};

function orderCancel(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE:
      return produce(state, (draft) => {
        draft = { ...draft, ...action.payload };
        return draft;
      });
    case types.RESET:
      return produce(state, (draft) => {
        draft.form = INITIAL_STATE.form;
        draft.cursorOrder = null;
        return draft;
      });
    case types.SELECT_ORDER:
      return produce(state, (draft) => {
        draft.cursorOrder = action.payload;
        return draft;
      });
    case types.UNSELECT_ORDER:
      return produce(state, (draft) => {
        draft.cursorOrder = INITIAL_STATE.cursorOrder;
      });
    default:
      return state;
  }
}
export default orderCancel;
