import styled from 'styled-components';

export const Content = styled.div`
  min-width: 100%;
  padding-bottom: 15px;
  position: relative;
`;

export const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  img {
    position: absolute;
    right: -120px;
    bottom: -350px;
  }
`;
