import React, { useEffect, useState } from 'react';

import api from '~/services/api';
import BvsContainer from '~/components/BvsContainer';
import BvsPanel from '~/components/BvsPanel';
import BvsTableV4 from '../../components/BvsTableV4';
import SubHeader from '~/components/BvsSubHeader';

const City = () => {
  const [cities, setCities] = useState([]);

  const fetchCities = async () => {
    const { data } = await api.get(`/cities`);
    setCities(data);
  };

  const columns = [
    {
      key: 'code',
      label: 'Código',
      sort: true,
    },
    {
      key: 'name',
      label: 'Nome da Cidade',
      sort: true,
      width: '100%',
    },
  ];

  useEffect(() => {
    fetchCities();
  }, []);

  return (
    <>
      <SubHeader title="Cidades" />
      <BvsContainer>
        <div className="col-md-12">
          <BvsPanel title="Relação de Cidades">
            <div className="col-md-12">
              <BvsTableV4
                tableKey="cities"
                columns={columns}
                rows={cities}
                onRefresh={() => fetchCities()}
                rowKey="id"
              />
            </div>
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default City;
