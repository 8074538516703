import types from './types';

export function showLoading() {
  return {
    type: types.SHOW_LOADER,
  };
}

export function hideLoading() {
  return {
    type: types.HIDE_LOADER,
  };
}
