/* eslint-disable no-plusplus */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BvsButton from '~/components/BvsButton';
import { getPagingRange } from '~/components/BvsTableV2/util/helper';
import usePagination from '~/pages/Order/Detail/hooks/usePagination';
import { updatePaginate } from '~/store/modules/order/detail/actions';

const MAX_BUTTONS = 5;
const MAX_LEFT = (MAX_BUTTONS - 1) / 2;

const ButtomPaginate = () => {
  const dispatch = useDispatch();
  const { order } = useSelector((state) => state.order);
  const { pagination } = useSelector((state) => state.orderDetail);
  const { page, limit } = pagination;

  const totalPage = Math.ceil(order.employees.length / limit);

  return (
    <div style={{ display: 'flex' }}>
      <BvsButton
        type="button"
        icon="fa fa-step-backward"
        onClick={() =>
          dispatch(
            updatePaginate({
              ...pagination,
              page: 1,
            })
          )
        }
      />
      {getPagingRange(page, {
        min: 0,
        total: totalPage,
        length: 5,
      }).map((value, index) => {
        return (
          <BvsButton
            type="button"
            title={value + 1}
            onClick={() => {
              dispatch(
                updatePaginate({
                  ...pagination,
                  page: value + 1,
                })
              );
            }}
            customStyle={{
              backgroundColor: page === value + 1 ? 'rgb(168, 30, 33)' : '',
            }}
          />
        );
      })}

      <BvsButton
        type="button"
        icon="fa fa-step-forward"
        onClick={() =>
          dispatch(
            updatePaginate({
              ...pagination,
              page: Math.ceil(order.employees.length / limit),
            })
          )
        }
      />
    </div>
  );
};

export default ButtomPaginate;
