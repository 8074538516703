import { all } from 'redux-saga/effects';
import auth from './auth/sagas';
import app from './app/sagas';
import enterprises from './enterpriseActive/sagas';

import formLogin from './forms/FormLogin/sagas';
import formEnterprise from './forms/FormEnterprise/sagas';
import formDepartment from './forms/FormDepartment/sagas';
import formJob from './forms/FormJob/sagas';
import formOperator from './forms/FormOperator/sagas';
import formOperatorBenefit from './forms/FormOperatorBenefit/sagas';
import formImport from './forms/FormImport/sagas';
import formChangePassword from './forms/FormChangePassword/sagas';
import formUsers from './forms/FormUsers/sagas';
import formWorkday from './forms/FormWorkday/sagas';
import formEmployees from './forms/FormEmployees/sagas';
import formEmployeeBenefits from './forms/FormEmployeeBenefits/sagas';
import formAdministrativeCard from './forms/FormAdministrativeCard/sagas';
import formShippingAddress from './forms/FormShippingAddress/sagas';
import filterAdministrativeCard from './filter/FilterAdministrativeCard/sagas';
import filterEmployees from './filter/FilterEmployees/sagas';
import filterExport from './filter/FilterExport/sagas';
import filterOrders from './filter/FilterOrders/sagas';
import filterSearchBenefits from './filter/FilterSearchBenefits/sagas';
import orderCancel from './order/cancel/sagas';

export default function* rootSaga() {
  return yield all([
    app,
    auth,
    enterprises,
    formLogin,
    formEnterprise,
    formDepartment,
    formJob,
    formOperator,
    formOperatorBenefit,
    formEmployeeBenefits,
    formChangePassword,
    formUsers,
    formWorkday,
    formImport,
    formEmployees,
    formAdministrativeCard,
    formShippingAddress,
    filterAdministrativeCard,
    filterExport,
    filterEmployees,
    filterOrders,
    filterSearchBenefits,
    orderCancel,
  ]);
}
