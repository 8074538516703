/* eslint-disable no-empty */
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import api from '~/services/api';
import {
  updateFilter,
  resetFilter,
  // resetEmployeeFilter,
} from '~/store/modules/filter/FilterCreateOrders/actions';
// import { showLoading, hideLoading } from '~/store/modules/loader/actions';

const useFilter = () => {
  const dispatch = useDispatch();
  const { query } = useSelector((state) => state.filterCreateOrder);

  const onFieldChange = (key, value) => {
    dispatch(
      updateFilter({
        query: { ...query, [key]: value },
      })
    );
  };

  return {
    onFieldChange,
  };
};

export default useFilter;
