/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  form: {
    saving: false,
    disabled: false,
  },
  enterprise: {
    social_name: '',
    fantasy_name: '',
    cnpj: '',
    municipal_registration: '',
    state_registration: '',
    email: '',
    manager_account: '',
    new_business: '',
    zipcode: '',
    state: '',
    city: '',
    district: '',
    street: '',
    number: '',
    complement: '',
    ibge: '',
    tax_general_manager: 0,
    other_cost: 0,
    delivery_cost: 0,
    discount: 0,
    delivery_day: '',
    reference: '',
    owner_name: '',
    owner_phone: '',
    owner_email: '',
    owner_job_id: null,
    owner_department_id: null,
    branch_activity_id: null,
    fees: [],
    agree: false,
  },
};

function formEnterprise(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_ENTERPRISE: {
      return produce(state, (draft) => {
        draft = { ...draft, ...action.payload };
        return draft;
      });
    }
    case types.RESET_ENTERPRISE: {
      return produce(state, (draft) => {
        draft.enterprise = INITIAL_STATE.enterprise;
        return draft;
      });
    }
    default:
      return state;
  }
}

export default formEnterprise;
