/* eslint-disable prettier/prettier */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/order */
/* eslint-disable no-param-reassign */

import { DownloadFile, RenderStatusIconButton } from '~/system/helper';
import React, { useEffect, useRef } from 'react';

import BvsButton from '~/components/BvsButton';
import BvsContainer from '~/components/BvsContainer';
import BvsForm from '~/components/BvsForm';
import BvsInput from '~/components/BvsInput';
import BvsPanel from '~/components/BvsPanel';
import { FontAwesome } from 'react-web-vector-icons';
import SubHeader from '~/components/BvsSubHeader';
import { getStatus } from '~/system/convert';
import { useHistory } from 'react-router-dom';
import api from '~/services/api';
import BvsToastResult from '~/components/BvsToastResult';
import { USER_TYPE } from '~/system/enum';
import BvsAdmButton from '~/components/BvsAdmButton';
import { useDispatch, useSelector } from 'react-redux';
import { updateDepartment } from '~/store/modules/forms/FormDepartment/actions';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';
import useForm from './hooks/useForm';
import { toast } from 'react-toastify';
import useDepartments from '~/hooks/useDepartments';
import useController from './hooks/useController';
import BvsTableV4 from '../../components/BvsTableV4';
import moment from 'moment';

const Department = ({ match }) =>
{
  const dispatch = useDispatch();
  const { department, form } = useSelector((state) => state.formDepartment);
  const { enterpriseActive } = useSelector((state) => state.enterprises);
  const { onFieldChange, clean } = useForm();
  let { id } = match.params;
  const { departments, fetch: getDepartments } = useDepartments({
    loading: false,
    dropdown: false,
  });
  const { onSave, changeStatus, deleteDepartment } = useController({
    departmentId: id,
    onSaved: () => {
      getDepartments();
      history.push('/departments');
    },
  });
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const containerForm = useRef();
  const columns = [
    {
      key: 'status',
      label: 'Status',
      sort: true,
      content: (row) => getStatus(row.status),
    },
    {
      key: 'code',
      label: 'Código',
      sort: true,
    },
    {
      key: 'name',
      label: 'Nome do Departamento',
      sort: true,
      width: '100%',
    },
  ];

  const onEdit = async (departmentId) => {
    try {
      dispatch(showLoading());
      const { data } = await api.get(`/departments/${departmentId}`);
      if (data.message || data.error) throw data;
      dispatch(
        updateDepartment({
          form,
          department: {
            ...department,
            code: data.code,
            name: data.name,
          },
        })
      );
      history.replace(`/departments/${departmentId}`);
      window.scrollTo({
        top: containerForm?.current.scrollTop,
        behavior: 'smooth',
      });
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message={
            err?.message || err?.error?.message || 'Falha ao localizar registro'
          }
        />
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const onCancel = () => {
    clean();
    history.replace(`/departments`);
  };

  useEffect(() => {
    getDepartments();
    return () => {
      clean();
    };
  }, []);

  return (
    <>
      <SubHeader title="Departamentos" subtitle="da empresa" />
      <BvsContainer>
        {user?.type === USER_TYPE.ADMIN && <BvsAdmButton />}
        <div className="col-md-12">
          <BvsPanel title="Incluir">
            <BvsForm onSubmit={onSave}>
              <div className="row" ref={containerForm}>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsInput
                    name="code"
                    label="Código"
                    value={department.code}
                    onChange={(value) => onFieldChange('code', value)}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsInput
                    name="name"
                    label="Nome do Departamento"
                    value={department.name}
                    onChange={(value) => onFieldChange('name', value)}
                    required
                  />
                </div>
                <div className="col">
                  <div className="row align-self-end">
                    <BvsButton
                      type="submit"
                      title="Salvar"
                      icon="fa fa-save"
                      disabled={form.saving}
                    />
                    {id && (
                      <BvsButton
                        type="button"
                        title="Cancelar"
                        icon="fa fa-save"
                        onClick={() => {
                          onCancel();
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </BvsForm>
          </BvsPanel>
        </div>

        <div className="col-md-12">
          <BvsPanel title="Relação de Departamentos">
            <div className="col-md-12">
              <BvsTableV4
                columns={columns}
                rows={departments.options?.map((d) => ({ ...d._original }))}
                actions={[
                  (row) => (
                    <>
                      <BvsButton
                        type="button"
                        className="btn btn-xs"
                        customStyle={{ marginRight: '5px' }}
                        icon={
                          <FontAwesome name="edit" color="#fff" size={12} />
                        }
                        onClick={() => onEdit(row.id)}
                      />
                      <BvsButton
                        type="button"
                        className="btn btn-xs"
                        customStyle={{ marginRight: '5px' }}
                        icon={
                          <FontAwesome name="close" color="#fff" size={12} />
                        }
                        onClick={() =>
                          deleteDepartment(row, () => getDepartments())
                        }
                      />
                      <BvsButton
                        type="button"
                        className="btn btn-xs"
                        customStyle={{ marginRight: '5px' }}
                        icon={RenderStatusIconButton(row.status)}
                        onClick={() =>
                          changeStatus(row, () => {
                            getDepartments();
                          })
                        }
                      />
                    </>
                  ),
                ]}
              />

              <div className="row justify-content-start">
                {/* <BvsButton
                  type="button"
                  title="Imprimir"
                  icon="fa fa-print"
                  disabled
                /> */}
                <BvsButton
                  type="button"
                  title="Excel"
                  icon="fa fa-file-excel-o"
                  className="mx-3"
                  onClick={async () => {
                    DownloadFile(
                      '/departments/export/excel',
                      `departamento-${
                        enterpriseActive?.info?.social_name
                      }-${moment().format('DD.MM.YYYY HH:mm:ss')}.xlsx`
                    );
                  }}
                />
                <BvsButton
                  type="button"
                  title="Próxima Página"
                  icon=""
                  className=""
                  onClick={() => history.push('/jobs')}
                />
              </div>
            </div>
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default Department;
