import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormTitle = styled.h3`
  color: #8a9296;
  font-size: 1.35em;
  flex: 1;
  padding: 5px 0 20px;
  margin: 0;
`;

export const FormSubTitle = styled.p`
  font-size: 1em;
  padding-bottom: 15px;
`;

export const FormInline = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const InputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex: 2 1 100%;
`;
export const Wrapper = styled.div`
  background: #fdfdfd;
  border-radius: 3px;
  padding: 25px 0;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);

  margin: 10px auto;
`;
export const Button = styled.button`
  padding: ${({ small }) =>
    small ? '5px !important' : '8.5px 20px !important'};
  border-radius: 0 !important;
  background-color: ${({ disabled }) => (disabled ? '#D9D9D9' : '#beddcd')};
  text-shadow: none;
  box-shadow: none;
  color: #fff;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ disabled }) => (disabled ? '#D9D9D9' : '#beddcd')};
  border-image: initial;
  background-image: none;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transform: scale(1);
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

  :active,
  :focus,
  :visited {
    outline: 0;
  }
  &:hover,
  &:hover * {
    outline: 0;
    color: #fff !important;
    border-color: ${({ disabled }) =>
      disabled ? '#D9D9D9' : 'rgb(168, 30, 33)'};
    /* border: solid 2px #a81e21; */
    background-color: ${({ disabled }) =>
      disabled ? '#D9D9D9' : 'rgb(168, 30, 33)'};
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transform: scale(0.98);
  }
`;
