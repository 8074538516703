import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import BvsLoader from '~/components/BvsLoader';
import 'react-toastify/dist/ReactToastify.css';
// import { SocketContext, socket } from 'context/socket';
import Router from './system/router';
import GlobalStyle from './styles/global';
import store from './store';

function App() {
  return (
    <>
      {/* <SocketContext.Provider value={socket}> */}
      <Provider store={store}>
        <BvsLoader />
        <Router />
        <GlobalStyle />
        <ToastContainer
          autoClose={3000}
          draggable={false}
          position="bottom-right"
          pauseOnHover
          hideProgressBar={false}
        />
      </Provider>
      {/* </SocketContext.Provider> */}
    </>
  );
}

export default App;
