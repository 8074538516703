/* eslint-disable no-return-assign */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { updateShippingAddress } from '~/store/modules/forms/FormShippingAddress/actions';
import api from '~/services/api';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';
import types from './types';

export function* addShippingAddress({ callback }) {
  const { shipping_address, responsibles, form } = yield select(
    (state) => state.formShippingAddress
  );
  try {
    yield put(
      updateShippingAddress({
        form: { ...form, saving: true, disabled: true },
      })
    );

    let shippingAddress = { ...shipping_address };
    if (responsibles.length === 0)
      throw new Error('Necessário informar ao menos um responsável');
    responsibles?.[0] &&
      (() => {
        return (shippingAddress.firstResponsible = {
          ...responsibles?.[0],
          phone: responsibles?.[0].phone.replace(/[^\d]/g, ''),
        });
      })();
    responsibles?.[1] &&
      (() => {
        return (shippingAddress.secondResponsible = {
          ...responsibles?.[1],
          phone: responsibles?.[1].phone.replace(/[^\d]/g, ''),
        });
      })();
    responsibles?.[2] &&
      (() => {
        return (shippingAddress.thirdResponsible = {
          ...responsibles?.[2],
          phone: responsibles?.[2].phone.replace(/[^\d]/g, ''),
        });
      })();
    const { data } = yield call(api.post, '/shippingAddress', {
      ...shippingAddress,
    });

    if (data.error || data.message) throw data;

    callback && callback(data);
  } catch (error) {
    toast.error(
      <BvsToastResult
        type="error"
        err={error}
        message="Falha ao salvar registro"
      />
    );
  } finally {
    yield put(
      updateShippingAddress({
        form: { ...form, saving: false, disabled: false },
      })
    );
  }
}
export default all([
  takeLatest(types.ADD_SHIPPING_ADDRESS, addShippingAddress),
]);
