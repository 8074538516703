import React from 'react';
import BvsContainer from '~/components/BvsContainer';
import { CopyTitle, FooterWrapper } from './style';
// eslint-disable
export default function Footer() {
  const copyText = ` © ${`${new Date().getFullYear()} `} Benv's - Gestão de Benefícios. Todos os Direitos Reservados`;
  return (
    <FooterWrapper>
      <BvsContainer>
        <CopyTitle>{copyText}</CopyTitle>
      </BvsContainer>
    </FooterWrapper>
  );
}
