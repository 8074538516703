import styled from 'styled-components';

export const WallCardWrapper = styled.div`
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
`;

export const WallCardBody = styled.div`
  p {
    font-size: 1.05em;
    line-height: 1.4;
    text-align: center;
  }
`;

export const WallCardTitle = styled.h4`
  color: #7e898f;
  font-size: 1.45em;
  font-weight: 500;
  text-align: center;
  width: 100%;
  padding-bottom: 15px;
`;
