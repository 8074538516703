import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.div`
  margin: 0 0 20px 0;
  font-size: 18px;
  color: #7e898f;
  font-weight: normal;
`;

export const LoginWrapper = styled.div`
  background: #fdfdfd;
  border-radius: 3px;
  padding: 25px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
  max-width: 700px;
  margin: 5px auto;
`;

export const LoginTitle = styled.h3`
  color: #8a9296;
  font-size: 1.35em;
  flex: 1;
  padding: 5px 0 20px;
  margin: 0;
`;

export const LoginContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const LoginSubTitle = styled.p`
  font-size: 1em;
  padding-bottom: 15px;
`;

export const FormInline = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex: 2 1 100%;
`;

export const Divider = styled.hr`
  background-color: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.08);
  border: 0;
  height: 1px;
  background-image: -webkit-linear-gradient(
    left,
    transparent,
    rgba(0, 0, 0, 0.2),
    transparent
  );
  background-image: -moz-linear-gradient(
    left,
    transparent,
    rgba(0, 0, 0, 0.2),
    transparent
  );
  background-image: -ms-linear-gradient(
    left,
    transparent,
    rgba(0, 0, 0, 0.2),
    transparent
  );
  background-image: -o-linear-gradient(
    left,
    transparent,
    rgba(0, 0, 0, 0.2),
    transparent
  );
  margin: 8px 0 20px 0;
`;

export const ForgetPassword = styled.span`
  cursor: pointer;
`;
