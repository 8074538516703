import { useDispatch, useSelector } from 'react-redux';
import BvsModal from '~/components/BvsModal';
import BvsButton from '~/components/BvsButton';
import { updateListEmployee } from '~/store/modules/app/actions';
import { toMoney } from '../../../../system/helper';
import { getStatus } from '../../../../system/convert';
import { useHistory } from 'react-router-dom';

const ShowBenefitsModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    listEmployee: { components, detail },
  } = useSelector((state) => state.app);

  const setComponents = (component, state) => {
    dispatch(
      updateListEmployee({
        components: {
          ...components,
          [component]: state,
        },
      })
    );
  };
  return (
    <BvsModal
      size="xl"
      visible={components.showBenefit}
      onClose={() => setComponents('showBenefit', false)}
      footer={() => (
        <div className="row d-flex justify-content-end">
          <div className="mr-2 text-nowrap">
            <BvsButton
              title="Fechar"
              onClick={() => {
                setComponents('showBenefit', false);
              }}
            />
          </div>
        </div>
      )}
    >
      <div className="container">
        <div className="row">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>Código</th>
                <th>Descrição</th>
                <th>Cartão</th>
                <th>Dias</th>
                <th>Qtde</th>
                <th>Unit</th>
                <th>Status</th>
                <th>Sub-total</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
              {detail?.employeeBenefits?.map((eb) => (
                <tr>
                  <td>{eb?.benefit?.code}</td>
                  <td>{eb?.benefit?.name}</td>
                  <td>{eb?.card ?? 'Não'}</td>
                  <td>{eb?.month_quantity}</td>
                  <td>{eb?.daily_quantity}</td>
                  <td>{toMoney(eb?.benefit?.unit_cost)}</td>
                  <td>{getStatus(eb?.status)}</td>
                  <td>
                    {toMoney(
                      Number(eb?.month_quantity) *
                        Number(eb?.daily_quantity) *
                        Number(eb?.benefit?.unit_cost)
                    )}
                  </td>
                  <td>
                    <BvsButton
                      icon="fa fa-pencil"
                      onClick={() => {
                        history.push(`createEmployees/${detail?.id}`);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </BvsModal>
  );
};

export default ShowBenefitsModal;
