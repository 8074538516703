import React from 'react';
import ReactDOM from 'react-dom';
import { ToastProvider } from 'react-toast-notifications';
import 'react-web-vector-icons/fonts';
import App from './App';
import './ReactotronConfig';

ReactDOM.render(
  <ToastProvider placement="bottom-right">
    <App />
  </ToastProvider>,
  document.getElementById('root')
);
