import { useDispatch, useSelector } from 'react-redux';
import produce from 'immer';
import { useState } from 'react';
import api from '~/services/api';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';
import * as actions from '~/store/modules/forms/FormUsers/actions';
import { useHistory } from 'react-router';
import { updateAuth } from '~/store/modules/auth/actions';

const useController = ({ id, containerForm }) => {
  const [users, setUsers] = useState({
    options: [],
    loading: false,
  });
  const { user } = useSelector((state) => state.auth);
  const { users: formUsers, form } = useSelector((state) => state.formUsers);
  const dispatch = useDispatch();
  const history = useHistory();

  const getUsers = async () => {
    setUsers({ ...users, loading: true });
    let options = [];
    dispatch(showLoading());
    try {
      const { data } = await api.get('/users');
      if (data.error || data.message) throw data;
      options = data;
      setUsers({ ...users, options, loading: false });
      dispatch(hideLoading());
    } catch (err) {
      dispatch(hideLoading());

      setUsers({ ...users, options, loading: false });
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Falha ao consultar usuários do sistema."
        />
      );
    }
  };

  const onCancel = () => {
    dispatch(actions.resetUsers());
    history.replace('/users');
  };
  const onSave = async (e) => {
    e.preventDefault();
    dispatch(showLoading());
    try {
      dispatch(
        actions.updateUsers({
          form: { saving: true },
        })
      );
      let params = {
        name: formUsers.name,
        email: formUsers.email,
      };
      if (!id) params.password = formUsers.password;
      const { data } = await api[id ? 'put' : 'post'](
        `/users${id ? `/${id}` : ''}`,
        params
      );
      if (data.error || data.message) throw data;

      if (formUsers.avatar) {
        let formData = new FormData();
        formData.append('avatar', formUsers.avatar);
        await api.post(`/uploads/avatar/${data.id}`, formData, {
          headers: {
            'Content-Type': `multipart/form-data`,
          },
        });
      }
      toast.success(<BvsToastResult message="Registro salvo com sucesso" />);
      if (Number(id) === Number(user.id)) {
        const { data: session } = await api.get('/verify');
        dispatch(
          updateAuth({
            user: session,
          })
        );
      }
      getUsers();
      onCancel();
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Falha ao salvar usuários do sistema."
        />
      );
    } finally {
      dispatch(hideLoading());
      dispatch(
        actions.updateUsers({
          form: { saving: false },
        })
      );
    }
  };

  const onEdit = async (userId) => {
    try {
      dispatch(showLoading());
      const { data } = await api.get(`/users/${userId}`);
      if (data.message || data.error) throw data;
      dispatch(
        actions.updateUsers({
          users: {
            ...users,
            name: data.name,
            email: data.email,
          },
        })
      );
      history.replace(`/users/${userId}`);
      window.scrollTo({
        top: containerForm?.current.scrollTop,
        behavior: 'smooth',
      });
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Falha ao localizar o usuário do sistema."
        />
      );
    } finally {
      dispatch(hideLoading());
    }
  };
  const onDelete = async (userId) => {
    dispatch(showLoading());
    try {
      dispatch(
        actions.updateUsers({
          form: { saving: true },
        })
      );
      const { data } = await api.delete(`/users/${userId}`);
      if (data.error || data.message) throw data;
      toast.success(<BvsToastResult message="Excluido com sucesso" />);
      onCancel();
      getUsers();
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Falha ao excluir o usuário do sistema."
        />
      );
    } finally {
      dispatch(hideLoading());
      dispatch(
        actions.updateUsers({
          form: { saving: false },
        })
      );
    }
  };
  const changeStatus = async (userId) => {
    dispatch(showLoading());
    try {
      dispatch(
        actions.updateUsers({
          form: { saving: true },
        })
      );
      const { data } = await api.put(`/users/${userId}/status`);
      if (data.error || data.message) throw data;
      toast.success(<BvsToastResult message="Status alterado com sucesso." />);
      onCancel();
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Não foi possivel salvar o funcionário neste momento."
        />
      );
    } finally {
      dispatch(hideLoading());
      dispatch(
        actions.updateUsers({
          form: { saving: false },
        })
      );
      await getUsers();
    }
  };
  const onResetPassword = async () => {
    dispatch(showLoading());

    try {
      const { data } = await api.post(`resetPassword`, {
        email: formUsers.email,
      });
      if (data.error || data.message) throw data;
      toast.success(
        <BvsToastResult message="Nova senha gerada e enviada para o e-mail cadastrado." />
      );
      onCancel();
      dispatch(hideLoading());
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Não foi possivel gerar uma nova senha."
        />
      );
      dispatch(hideLoading());
    }
  };

  return {
    users,
    getUsers,
    onCancel,
    onResetPassword,
    onSave,
    onEdit,
    onDelete,
    changeStatus,
  };
};

export default useController;
