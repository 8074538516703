import BvsButton from '~/components/BvsButton';
import BvsModal from '~/components/BvsModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  confirmCancel,
  resetForm,
  updateForm,
} from '~/store/modules/order/cancel/actions';
import BvsToastResult from '~/components/BvsToastResult';
import { useState } from 'react';
import { toast } from 'react-toastify';

const ModalCancelOrder = ({
  visible,
  onCloseModal,
  toggle,
  onCancelledSuccessful,
}) => {
  const { form } = useSelector((state) => state.orderCancel);
  const dispatch = useDispatch();
  const [confirmationDelete, setConfirmation] = useState(false);
  const onFieldChange = (name, value) => {
    dispatch(
      updateForm({
        form: {
          ...form,
          [name]: value,
        },
      })
    );
  };

  return (
    <>
      <BvsModal
        visible={visible}
        title="Confirmação de Cancelamento do Pedido"
        onClose={onCloseModal}
        footer={() => (
          <div>
            <BvsButton className="mr-3" title="Fechar" onClick={onCloseModal} />
            <BvsButton
              className="bg-danger"
              title="Confirmar"
              onClick={() => {
                if (form.justification.trim().length === 0) {
                  toast.info(
                    <BvsToastResult
                      type="info"
                      message="Informe a justificativa"
                    />
                  );
                  return;
                }
                setConfirmation(true);
                toggle(true);
              }}
            />
          </div>
        )}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 pt-3">
              <p className="font-weight-bold">
                Para prosseguir com o cancelamento do pedido, informe a
                justificativa da exclusão.
              </p>
            </div>
            <div className="col-12 px-3">
              <textarea
                name="justificativa"
                label="Justificativa"
                value={form.justification}
                onChange={(e) => onFieldChange('justification', e.target.value)}
                rows="5"
                style={{ width: '100%' }}
                maxLength="500"
                required
              />
            </div>
          </div>
        </div>
      </BvsModal>

      <BvsModal
        visible={confirmationDelete}
        title="Confirmação de Cancelamento do Pedido"
        onClose={() => setConfirmation(false)}
        footer={() => (
          <div>
            <BvsButton
              className="mr-3"
              title="Não"
              onClick={() => {
                dispatch(resetForm());
                setConfirmation(false);
              }}
            />
            <BvsButton
              className="bg-danger"
              title="Sim"
              onClick={() =>
                dispatch(
                  confirmCancel(() => {
                    setConfirmation(false);
                    onCancelledSuccessful();
                  })
                )
              }
            />
          </div>
        )}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 pt-3">
              <p>Tem certeza que deseja cancelar este pedido?</p>
              <p className="font-weight-bold">
                OBS: Essa operação não pode ser desfeita
              </p>
            </div>
          </div>
        </div>
      </BvsModal>
    </>
  );
};

export default ModalCancelOrder;
