/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
import types from './types';

export function updateUsers(payload) {
  return {
    type: types.UPDATE_USERS,
    payload,
  };
}

export function resetUsers() {
  return {
    type: types.RESET_USERS,
  };
}
