import React, { useEffect, useRef, useState } from 'react';

import { FontAwesome } from 'react-web-vector-icons';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { getUserType } from '~/system/convert';
import BvsNavBar from '~/components/BvsNavBar';
import BvsUserMenu from '~/components/BvsUserMenu';
import {
  Header as HeaderUI,
  Container,
  UserContent,
  UserWrapper,
  UserInfo,
  Avatar,
  AvatarCircle,
  MenuWrapper,
} from './style';
import Logo from '../../../assets/logo.png';
import { getAvatar } from '~/system/helper';

export default function Header() {
  const [visible, setVisible] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const ref = useRef();
  const history = useHistory();
  const closeDismiss = (e) => {
    let contain = ref.current.contains(e.target);
    if (!contain) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.body.addEventListener('click', closeDismiss);

    return () => {
      document.body.removeEventListener('click', closeDismiss);
    };
  }, []);

  return (
    <HeaderUI>
      <Container
        style={{
          position: 'relative',
          textAlign: 'center',
          zIndex: 1,
        }}
      >
        <MenuWrapper>
          <a
            href="#"
            title="Benv's - Gestão de Benefícios"
            onClick={() => history.replace('/Home')}
          >
            <img src={Logo} style={{ maxWidth: '100px', paddingBottom: 15 }} />
          </a>
          <div>
            <BvsNavBar />
          </div>
        </MenuWrapper>
        <UserContent
          style={{
            margin: '0px auto',
          }}
          className="user__content"
          ref={ref}
          onClick={() => {
            setVisible(!visible);
          }}
        >
          <UserWrapper>
            <AvatarCircle>
              <Avatar src={getAvatar(user?.avatar)} />
            </AvatarCircle>
            <UserInfo>
              <p className="p-0 m-0">{user?.name}</p>
              <small className="p-0 m-0">{getUserType(user?.type)}</small>
            </UserInfo>
            <BvsUserMenu visible={visible} />
          </UserWrapper>
          <div style={{ zIndex: 1 }}>
            <FontAwesome name="chevron-down" size={12} />
          </div>
        </UserContent>
      </Container>
    </HeaderUI>
  );
}
