import { useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { ORDER_LIST } from '~/system/enum';

const useOrderBy = () => {
  const { orderBy: orderByControl } = useSelector((state) => state.orderDetail);
  const [orderBy] = useState({
    loading: false,
    options: [
      { title: 'Alfabetica', value: ORDER_LIST.ALFABETICO },
      { title: 'Matricula', value: ORDER_LIST.MATRICULA },
      { title: 'Departamento', value: ORDER_LIST.DEPARTAMENTO },
      { title: 'Cargo', value: ORDER_LIST.CARGO },
    ],
  });

  const applyOrderBy = (data) => {
    switch (orderByControl.type) {
      case ORDER_LIST.ALFABETICO:
        return _.orderBy(data, ['name'], ['asc']);
      case ORDER_LIST.MATRICULA:
        return _.orderBy(data, ['registry'], ['asc']);
      case ORDER_LIST.DEPARTAMENTO:
        return _.orderBy(data, ['department.name'], ['asc']);
      case ORDER_LIST.CARGO:
        return _.orderBy(data, ['job.name'], ['asc']);
      default:
        return data;
    }
  };

  return {
    orderBy,
    applyOrderBy,
  };
};

export default useOrderBy;
