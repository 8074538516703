/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
import types from './types';

export function updateOperatorBenefit(payload) {
  return {
    type: types.UPDATE_OPERATOR_BENEFIT,
    payload,
  };
}

export function resetOperatorBenefit() {
  return {
    type: types.RESET_OPERATOR_BENEFIT,
  };
}
