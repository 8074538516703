import React, { useEffect, useState } from 'react';

import { FontAwesome } from 'react-web-vector-icons';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';
import { RenderStatusIconButton } from '~/system/helper';
import { getStatus } from '~/system/convert';
import { setEnterpriseActive } from '~/store/modules/enterpriseActive/actions';
import api from '~/services/api';
import SubHeader from '~/components/BvsSubHeader';
import BvsTableV4 from '../../components/BvsTableV4';
import BvsPanel from '~/components/BvsPanel';
import BvsContainer from '~/components/BvsContainer';
import BvsButton from '../../components/BvsButton';

const EnterpriseList = () => {
  const history = useHistory();
  const [enterpriseList, setEnterpriseList] = useState([]);
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const fetchEnterpriseList = async () => {
    try {
      const { data } = await api.get(`/enterprises`);
      if (data.error) throw data.error;
      setEnterpriseList(data);
    } catch (err) {
      addToast(`${err.message || err.error.message}`, {
        appearance: 'warning',
        autoDismiss: true,
      });
    }
  };

  const columns = [
    {
      key: 'status',
      label: 'Status',
      sort: true,
      content: (row) => {
        return getStatus(row.status);
      },
    },
    {
      key: 'cnpj',
      label: 'CNPJ',
      sort: true,
    },
    {
      key: 'fantasy_name',
      label: 'Nome Fantasia',
      sort: true,
    },
    {
      key: 'owner_phone',
      label: 'Contato',
      sort: true,
      content: (row) => {
        return row?.shippingAddress[0]?.firstResponsible?.phone;
      },
    },
    {
      key: 'email',
      label: 'E-mail',
      sort: true,
      width: '100%',
    },
  ];

  const changeStatus = async (enterpriseListId) => {
    const { data } = await api.put(`/enterprises/${enterpriseListId}/status`);
    if (!data.message) {
      addToast(`Status atualizado com sucesso`, {
        appearance: 'success',
        autoDismiss: true,
      });
    }
    await fetchEnterpriseList();
  };

  const onDelete = async (enterpriseListId) => {
    const { data } = await api.delete(`/enterprises/${enterpriseListId}`);
    if (!(data.message || data.error)) {
      addToast(`'${data.social_name}' excluido com sucesso!`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } else {
      addToast('Esta Empresa Não pode ser Deletada', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    await fetchEnterpriseList();
  };

  const onEdit = async (enterpriseId) => {
    dispatch(
      setEnterpriseActive(enterpriseId, () => {
        history.replace(`/enterprises/${enterpriseId}`);
      })
    );
  };

  useEffect(() => {
    fetchEnterpriseList();
  }, []);

  return (
    <>
      <SubHeader
        title="Empresas Clientes"
        subtitle="Mantenha os dados atualizados"
      />
      <BvsContainer>
        <div className="col-md-12">
          <BvsPanel title="Relação de Empresas">
            <div className="col-md-12">
              <BvsTableV4
                tableKey="enterpriseList"
                columns={columns}
                rows={enterpriseList}
                onRefresh={() => {
                  fetchEnterpriseList();
                }}
                rowKey="id"
                actionOptions={{ width: '225px' }}
                actions={[
                  (row) => (
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={<FontAwesome name="edit" color="#fff" size={12} />}
                      onClick={() => onEdit(row.id)}
                    />
                  ),
                  (row) => (
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={<FontAwesome name="close" color="#fff" size={12} />}
                      onClick={() => onDelete(row.id)}
                    />
                  ),
                  (row) => (
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={RenderStatusIconButton(row.status)}
                      onClick={() => changeStatus(row.id)}
                    />
                  ),
                ]}
              />
            </div>
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default EnterpriseList;
