import styled from 'styled-components';

export const Panel = styled.div`
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    border-color: #ddd;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

    &> .list-group,
    &> .panel-collapse > .list-group {
        margin-bottom: 0;
    }
    &> .list-group .list-group-item,
    &> .panel-collapse > .list-group .list-group-item {
        border-width: 1px 0;
        border-radius: 0;
    }
    &> .list-group:first-child .list-group-item:first-child,
    &> .panel-collapse > .list-group:first-child .list-group-item:first-child {
        border-top: 0;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }
    &> .list-group:last-child .list-group-item:last-child,
    &> .panel-collapse > .list-group:last-child .list-group-item:last-child {
        border-bottom: 0;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }
    &> .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    &> .panel-heading {
        color: #333333;
        background-color: #f5f5f5;
        border-color: #ddd;
    }
    &> .panel-heading + .panel-collapse > .panel-body {
        border-top-color: #ddd;
    }
    &> .panel-heading .badge {
        color: #f5f5f5;
        background-color: #333333;
    }
    &> .panel-footer + .panel-collapse > .panel-body {
        border-bottom-color: #ddd;
      
`;

export const PanelBody = styled.div`
  padding: ${({ visible }) => (visible ? '15px' : 0)};
  display: ${({ displaying }) => (displaying ? 'block' : 'none !important')};
  transition: all 0.5s;
  margin: 0 !important;
`;

export const PanelHeader = styled.header`
  color: #a81e21;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  font-family: 'Open Sans';
  text-transform: none;
  padding: 18px;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  & > .dropdown .dropdown-toggle {
    color: inherit;
  }
  & + .list-group .list-group-item:first-child {
    border-top-width: 0;
  }
`;
