import styled from 'styled-components';

export const EnterpriseLogo = styled.img``;

export const SuperFooterStyle = styled.div`
  background: #333333;
  display: flex;
  padding: 60px;
  width: 100%;
`;
