import produce from 'immer';
import { useState } from 'react';
import api from '~/services/api';

const useController = () => {
  const [reportData, setReportData] = useState({
    loading: true,
    data: null,
  });

  const getReportData = async (orderId) => {
    let data = null;
    setReportData(
      produce(reportData, (draft) => {
        draft.loading = true;
        return draft;
      })
    );
    try {
      const { data: res } = await api.get(`/reports/status/users/${orderId}`);
      data = res;
    } catch (err) {
      data = null;
    } finally {
      setReportData(
        produce(reportData, (draft) => {
          draft.loading = false;
          draft.data = data;
          return draft;
        })
      );
    }
  };
  return { reportData, getReportData };
};

export default useController;
