/* eslint-disable no-param-reassign */
import produce from 'immer';
import { STATUS } from '~/system/enum';
import types from './types';

const INITIAL_STATE = {
  form: {
    saving: false,
    disabled: false,
    loading: false,
  },
  workday: {
    id: null,
    code: '',
    description: '',
    status: STATUS.ATIVO,
    quantity: '',
  },
};

function formWorkday(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_WORKDAY: {
      return produce(state, (draft) => {
        draft = { ...draft, ...action.payload };
        return draft;
      });
    }
    case types.RESET_WORKDAY: {
      return produce(state, (draft) => {
        draft.workday = INITIAL_STATE.workday;
        return draft;
      });
    }
    default:
      return state;
  }
}

export default formWorkday;
