import { useState } from 'react';
import { useDispatch } from 'react-redux';
import api from '~/services/api';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';

const useJobs = ({ loading = false, dropdown = true }) => {
  const dispatch = useDispatch();
  const [jobs, setJobs] = useState({
    options: [],
    loading: false,
  });

  const fetch = async () => {
    if (loading) dispatch(showLoading());
    setJobs({ ...jobs, loading: true });
    let options = [];
    try {
      const { data } = await api.get('/jobs');
      if (data.message || data.error) throw data;

      options = data.map((d) => ({ title: d.name, value: d.id, _original: d }));
      if (dropdown) options.unshift({ title: 'Nenhum', value: null });
      setJobs({ ...jobs, options });
    } catch (error) {
      setJobs({ ...jobs, options });
    } finally {
      setJobs({ ...jobs, options, loading: false });
      if (loading) dispatch(hideLoading());
    }
  };

  return { jobs, fetch };
};

export default useJobs;
