/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
import types from './types';

export function updateEmployeeBenefits(payload) {
  return {
    type: types.UPDATE_EMPLOYEE_BENEFITS,
    payload,
  };
}

export function resetEmployeeBenefits() {
  return {
    type: types.RESET_EMPLOYEE_BENEFITS,
  };
}
