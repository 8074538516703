/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-bitwise */
/* eslint-disable import/order */

import { DownloadFile, RenderStatusIconButton } from '~/system/helper';
import React, { useEffect, useRef, useState } from 'react';

import BvsButton from '~/components/BvsButton';
import BvsContainer from '~/components/BvsContainer';
import BvsForm from '~/components/BvsForm';
import BvsInput from '~/components/BvsInput';
import BvsPanel from '~/components/BvsPanel';
import BvsTableV4 from '../../components/BvsTableV4';
import { FontAwesome, AntDesign } from 'react-web-vector-icons';
import SubHeader from '~/components/BvsSubHeader';
import { getStatus } from '~/system/convert';
import { useHistory } from 'react-router-dom';
import api from '~/services/api';
import { USER_TYPE } from '~/system/enum';
import BvsAdmButton from '~/components/BvsAdmButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetWorkday,
  updateWorkday,
} from '~/store/modules/forms/FormWorkday/actions';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';
import moment from 'moment';

const WorkedDay = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { enterpriseActive } = useSelector((state) => state.enterprises);
  const { workday, form } = useSelector((state) => state.formWorkday);
  const [workedDays, setWorkedDays] = useState([]);
  const containerForm = useRef();
  let { id } = match.params;

  const setWorkday = (key, value) => {
    dispatch(
      updateWorkday({
        form,
        workday: {
          ...workday,
          [key]: value,
        },
      })
    );
  };

  const columns = [
    {
      key: 'status',
      label: 'Status',
      sort: true,
      content: (row) => {
        return getStatus(row.status);
      },
    },
    {
      key: 'code',
      label: 'Código',
      sort: true,
    },
    {
      key: 'description',
      label: 'Periodo de Trabalho',
      sort: true,
    },
    {
      key: 'quantity',
      label: 'Quantidade de Dias',
      sort: true,
      width: '100%',
    },
  ];

  const fetchWorkedDays = async () => {
    dispatch(showLoading());
    try {
      const { data } = await api.get(`/workdays`);
      if (data.message || data.error) throw data;
      setWorkedDays(data);
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Falha ao atualizar registros"
        />
      );
      setWorkedDays([]);
    } finally {
      dispatch(hideLoading());
    }
  };

  const onSave = async (e) => {
    e.preventDefault();
    dispatch(showLoading());
    dispatch(
      updateWorkday({
        form: { saving: true },
        workday,
      })
    );
    try {
      const { data } = await api[id ? 'put' : 'post'](
        `/workdays${id ? `/${id}` : ''}`,
        workday
      );
      if (data.error || data.message) throw data;
      toast.success(
        <BvsToastResult
          message={`${data.description.substr(0, 20)}' salvo com sucesso!`}
        />
      );
      fetchWorkedDays();
      onCancel();
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message={
            err?.message || err?.error?.message || 'Falha ao salvar registro'
          }
        />
      );
    } finally {
      dispatch(hideLoading());
      dispatch(
        updateWorkday({
          form: { saving: false },
          ...workday,
        })
      );
    }
  };

  const changeStatus = async (workedDayID) => {
    try {
      const { data } = await api.put(`/workdays/${workedDayID}/status`);
      if (data.message || data.error) throw data;
      toast.success(<BvsToastResult message="Status atualizado com sucesso" />);
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message={
            err?.message ||
            err?.error?.message ||
            'Falha ao atualizar status do registro'
          }
        />
      );
    } finally {
      await fetchWorkedDays();
    }
  };

  const onDelete = async (workedDayID) => {
    try {
      dispatch(showLoading());
      const { data } = await api.delete(`/workdays/${workedDayID}`);
      if (data.message || data.error) throw data;
      toast.success(
        <BvsToastResult
          message="Excluido com
          sucesso!"
        />
      );
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message={
            err?.message || err?.error?.message || 'Falha ao excluir registro'
          }
        />
      );
    } finally {
      dispatch(hideLoading());
    }

    await fetchWorkedDays();
  };

  const onEdit = async (workedDayID) => {
    try {
      dispatch(showLoading());
      const { data } = await api.get(`/workdays/${workedDayID}`);
      if (data.message || data.error) throw data;
      dispatch(
        updateWorkday({
          form,
          workday: {
            ...workday,
            code: data.code,
            description: data.description,
            quantity: data.quantity,
          },
        })
      );
      history.replace(`/workedDays/${workedDayID}`);
      window.scrollTo({
        top: containerForm?.current.scrollTop,
        behavior: 'smooth',
      });
      fetchWorkedDays();
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message={
            err?.message || err?.error?.message || 'Falha ao localizar registro'
          }
        />
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const onCancel = () => {
    dispatch(resetWorkday());
    history.replace(`/workedDays`);
  };

  useEffect(() => {
    fetchWorkedDays();

    return () => {
      dispatch(resetWorkday());
    };
  }, []);

  return (
    <>
      <SubHeader
        title="Periodo de Dias Trabalhados"
        subtitle="dos usuários da empresa"
      />
      <BvsContainer>
        {user?.type === USER_TYPE.ADMIN && <BvsAdmButton />}

        <div className="col-md-12" ref={containerForm}>
          <BvsPanel title="Incluir">
            <BvsForm onSubmit={onSave}>
              <div className="row">
                <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2 p-3">
                  <BvsInput
                    name="code"
                    label="Código"
                    value={workday.code}
                    onChange={(value) => setWorkday('code', value)}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-5 col-lg-5 p-3">
                  <BvsInput
                    name="description"
                    label="Descrição Periodo"
                    value={workday.description}
                    onChange={(value) => setWorkday('description', value)}
                    required
                  />
                </div>
                <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2 p-3">
                  <BvsInput
                    name="quantity"
                    label="Dias"
                    value={workday.quantity}
                    onChange={(value) => setWorkday('quantity', value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                  <BvsButton
                    type="submit"
                    title="Salvar"
                    icon="fa fa-save"
                    customStyle={{ marginTop: '30px', float: 'right' }}
                  />
                  {id && (
                    <BvsButton
                      type="button"
                      title="Cancelar"
                      icon="fa fa-save"
                      onClick={onCancel}
                      customStyle={{
                        marginTop: '30px',
                        marginRight: '30px',
                        float: 'right',
                      }}
                    />
                  )}
                </div>
              </div>
            </BvsForm>
          </BvsPanel>
        </div>
        <div className="col-md-12">
          <BvsPanel title="Relação de Dias Trabalhados">
            <div className="col-md-12">
              <BvsTableV4
                tableKey="workedDays"
                columns={columns}
                rows={workedDays}
                onRefresh={() => {
                  fetchWorkedDays();
                }}
                rowKey="id"
                actionOptions={{
                  width: '225px',
                }}
                actions={[
                  (row) => (
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={<FontAwesome name="edit" color="#fff" size={12} />}
                      onClick={() => onEdit(row.id)}
                    />
                  ),
                  (row) => (
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={<FontAwesome name="close" color="#fff" size={12} />}
                      onClick={() => onDelete(row.id)}
                    />
                  ),
                  (row) => (
                    <BvsButton
                      type="button"
                      className="btn btn-xs"
                      customStyle={{ marginRight: '5px' }}
                      icon={RenderStatusIconButton(row.status)}
                      onClick={() => changeStatus(row.id)}
                    />
                  ),
                ]}
              />
              <div>
                {/* <BvsButton
                  type="button"
                  title="Imprimir"
                  icon="fa fa-print"
                  customStyle={{ margin: '15px 5px' }}
                /> */}
                <BvsButton
                  type="button"
                  title="Excel"
                  icon="fa fa-file-excel-o"
                  customStyle={{ margin: '15px 5px' }}
                  onClick={async () => {
                    DownloadFile(
                      '/workdays/export/excel',
                      `dias_trabalhados-${
                        enterpriseActive?.info?.social_name
                      }-${moment().format('DD.MM.YYYY HH:mm:ss')}.xlsx`
                    );
                  }}
                />
                <BvsButton
                  type="button"
                  title="Próxima Página"
                  icon=""
                  customStyle={{ margin: '15px 5px' }}
                  onClick={() => {
                    history.push('/employees');
                  }}
                />
              </div>
            </div>
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default WorkedDay;
