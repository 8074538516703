import { useContextMenu } from 'react-contexify';

const { useState } = require('react');

const useController = ({ employeeId, benefitId }) => {
  const [employeeCurrent, setEmployeeCurrent] = useState({
    name: '',
    registry: '',
    cpf: '',
  });

  const [components, setComponents] = useState({
    justification: {
      modalVisible: false,
    },
  });

  return {
    employeeCurrent,
    setEmployeeCurrent,
    justification: {
      components: components.justification,
    },
    setComponents,
  };
};

export default useController;
