/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';
import { useHistory } from 'react-router-dom';
import './menu.css';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { USER_TYPE } from '~/system/enum';
import BvsNavBarMobile from '~/components/BvsNavBarMobile';
import MenuTop from './style';
import DataAdm from '../../system/configs/menu.config.adm.json';
import DataUser from '../../system/configs/menu.config.user.json';

const BvsNavBar = () => {
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const { addToast } = useToasts();
  const { enterpriseActive } = useSelector((state) => state.enterprises);
  const pushLink = (router) => {
    if (router != null) {
      history.push(router);
    }
  };

  function GetTypeUser() {
    if (user?.type === USER_TYPE.ADMIN) {
      return DataAdm;
    }
    if (user?.type === USER_TYPE.USER) {
      return DataUser;
    }
  }

  function enterpriseRequired(item) {
    if (item.enterpriseActive && !enterpriseActive) {
      addToast(`Necessario selecionar uma empresa!`, {
        appearance: 'warning',
        autoDismiss: true,
      });
    } else pushLink(item.router);
  }
  const renderMenu = () =>
    GetTypeUser()
      .filter((item) => {
        return item.active === true;
      })
      .map((el) => {
        if (el.submenu.length === 0) {
          return (
            <MenuItem key={el.key} onClick={() => enterpriseRequired(el)}>
              {el.title}
            </MenuItem>
          );
        }
        return el.submenu.filter((submenu) => {
          return submenu.active === true;
        }).length === 0 ? (
          <MenuItem key={el.key} onClick={() => enterpriseRequired(el)}>
            {el.title}
          </MenuItem>
        ) : (
          <SubMenu key={el.key} title={el.title}>
            {el.submenu
              .filter((item) => {
                return item.active === true;
              })
              .map((submenu2) => {
                if (submenu2.submenu.length === 0) {
                  return (
                    <MenuItem
                      key={submenu2.key}
                      onClick={() => enterpriseRequired(submenu2)}
                    >
                      {submenu2.title}
                    </MenuItem>
                  );
                }
                return submenu2.submenu.filter((s2) => {
                  return s2.active === true;
                }).length === 0 ? (
                  <MenuItem
                    key={submenu2.key}
                    onClick={() => enterpriseRequired(submenu2)}
                  >
                    {submenu2.title}
                  </MenuItem>
                ) : (
                  <SubMenu key={submenu2.key} title={submenu2.title}>
                    {submenu2.submenu
                      .filter((_submenu) => {
                        return _submenu.active === true;
                      })
                      .map((submenu3) => {
                        return (
                          <MenuItem
                            key={submenu3.key}
                            onClick={() => enterpriseRequired(submenu3)}
                          >
                            {submenu3.title}
                          </MenuItem>
                        );
                      })}
                  </SubMenu>
                );
              })}
          </SubMenu>
        );
      });

  return (
    <>
      <MenuTop className="hidden-xs">
        <Menu mode="horizontal">{renderMenu()}</Menu>
      </MenuTop>
      <BvsNavBarMobile />
    </>
  );
};
export default BvsNavBar;
