/* eslint-disable no-param-reassign */

import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { FontAwesome } from 'react-web-vector-icons';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { getPositionShortcuts, getUrlShortcuts } from '~/system/convert';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';
import api from '~/services/api';
import SubHeader from '~/components/BvsSubHeader';
import BvsPanel from '~/components/BvsPanel';
import BvsInputV2 from '~/components/BvsInputV2';
import BvsForm from '~/components/BvsForm';
import BvsContainer from '~/components/BvsContainer';
import BvsButton from '~/components/BvsButton';
import BvsDropdown from '~/components/BvsDropdown';
import { POSITION_SHORTCUTS, URL_SHORTCUTS } from '../../system/enum';
import columns from './columns.json';
import BvsTableV4 from '../../components/BvsTableV4';

const Shortcuts = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [shortcuts, setShortcuts] = useState([]);
  const { register, handleSubmit, errors, setValue } = useForm();
  const { addToast } = useToasts();
  const { user } = useSelector((state) => state.auth);
  let { id } = match.params;

  const [urlShortcusts, setUrlShortcusts] = useState({
    loading: false,
    options: [
      { title: 'Pedido / Novo', value: URL_SHORTCUTS.PEDIDO_NOVO },
      { title: 'Mudar Senha', value: URL_SHORTCUTS.MUDAR_SENHA },
      { title: 'Importar Cadastro', value: URL_SHORTCUTS.IMPORTAR },
      { title: 'Exportar Cadastro', value: URL_SHORTCUTS.EXPORTAR },
      { title: 'Empresa Novo', value: URL_SHORTCUTS.EMPRESA_NOVO },
      {
        title: 'Cartão Administrativo',
        value: URL_SHORTCUTS.CARTAO,
      },
      { title: 'Ocorrências', value: URL_SHORTCUTS.OCCORENCIA },
      { title: 'Departamentos', value: URL_SHORTCUTS.DEPARTAMENTO },
      { title: 'Cargos', value: URL_SHORTCUTS.CARGO },
    ],
    value: null,
  });

  const [positionShortcuts, setPositionShortcuts] = useState({
    loading: false,
    options: [
      { title: 'Posição 1', value: POSITION_SHORTCUTS.POSICAO_UM },
      { title: 'Posição 2', value: POSITION_SHORTCUTS.POSICAO_DOIS },
      { title: 'Posição 3', value: POSITION_SHORTCUTS.POSICAO_TRES },
      { title: 'Posição 4', value: POSITION_SHORTCUTS.POSICAO_QUATRO },
    ],
    value: null,
  });

  const fetchShortcuts = async () => {
    dispatch(showLoading());
    const { data } = await api.get(`/shortcuts`);
    if (data.message || data.error) {
      addToast(`${data.message || data.error.message}`, {
        appearance: 'warning',
        autoDismiss: true,
      });
      return;
    }
    setShortcuts(
      data.map((item) => {
        return {
          ...item,
          route: getUrlShortcuts(item.route),
          order: getPositionShortcuts(item.order),
        };
      })
    );
    dispatch(hideLoading());
  };

  const save = async (values) => {
    const { data } = await api[id ? 'put' : 'post'](
      `/shortcuts${id ? `/${id}` : ''}`,
      {
        ...values,
        user_id: user.id,
        order: positionShortcuts.value,
        route: urlShortcusts.value,
      }
    );

    if (!data.message || data.error) {
      addToast(`'${values.name}' salvo com sucesso!`, {
        appearance: 'success',
        autoDismiss: true,
      });

      onCancel();
    } else {
      addToast(data.error.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    await fetchShortcuts();
  };

  const onDelete = async (shortcutsId) => {
    const { data } = await api.delete(`/shortcuts/${shortcutsId}`);
    if (!data.message || data.error) {
      addToast(`'${data.name}' excluido com sucesso!`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } else {
      addToast(data.error.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    await fetchShortcuts();
  };

  const onEdit = async (shortcutsId) => {
    const { data } = await api.get(`/shortcuts/${shortcutsId}`);
    if (!data.message || data.error) {
      history.push(`/shortcuts/${shortcutsId}`);
      setValue('name', data.name);
      setUrlShortcusts({ ...urlShortcusts, value: data.route });
      setPositionShortcuts({ ...positionShortcuts, value: data.order });
    } else {
      addToast(data.error.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onCancel = () => {
    setValue('name', '');
    setUrlShortcusts({ ...urlShortcusts, value: null });
    setPositionShortcuts({ ...positionShortcuts, value: null });
    history.replace(`/shortcuts`);
  };

  const columns = [
    {
      key: 'order',
      label: 'Posições',
      sort: true,
    },
    {
      key: 'name',
      label: 'Nome de Exibição',
      sort: true,
    },
    {
      key: 'route',
      label: 'URL',
      sort: true,
      width: '100%',
    },
  ];

  useEffect(() => {
    fetchShortcuts();
  }, []);

  return (
    <>
      <SubHeader title="Atalhos" />
      <BvsContainer>
        <div className="col-md-12">
          <BvsPanel title="Incluir">
            <BvsForm onSubmit={handleSubmit(save)}>
              <div className="row">
                <div
                  className="col-xs-12 col-sm-6 col-md-6 col-lg-3"
                  style={{ marginTop: 'auto' }}
                >
                  <BvsInputV2
                    ref={register({ required: true })}
                    name="name"
                    label="Nome de Exibição"
                    required
                    error={errors.name}
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                  <BvsDropdown
                    title="Selecione um atalho..."
                    name="urlShortcusts"
                    label="URL do Atalho"
                    value={urlShortcusts.value}
                    onSelectedItem={(value) =>
                      setUrlShortcusts({ ...urlShortcusts, value })
                    }
                    loading={urlShortcusts.loading}
                    options={urlShortcusts.options}
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                  <BvsDropdown
                    title="Escolha a posição..."
                    name="positionShortcuts"
                    label="Posição do Atalho"
                    value={positionShortcuts.value}
                    onSelectedItem={(value) =>
                      setPositionShortcuts({ ...positionShortcuts, value })
                    }
                    loading={positionShortcuts.loading}
                    options={positionShortcuts.options}
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                  <BvsButton
                    type="submit"
                    title="Salvar"
                    icon="fa fa-save"
                    customStyle={{ marginTop: '30px', float: 'right' }}
                  />
                  {id && (
                    <BvsButton
                      type="button"
                      title="Cancelar"
                      icon="fa fa-save"
                      onClick={onCancel}
                      customStyle={{
                        marginTop: '30px',
                        marginRight: '30px',
                        float: 'right',
                      }}
                    />
                  )}
                </div>
              </div>
            </BvsForm>
          </BvsPanel>
        </div>

        <div className="col-md-12">
          <BvsPanel title="Relação de Atalhos">
            <div className="col-md-12">
              <BvsTableV4
                rowKey="id"
                tableKey="shortcuts"
                columns={columns}
                rows={shortcuts}
                onRefresh={() => {
                  fetchShortcuts();
                }}
                actions={[
                  (row) => (
                    <>
                      <BvsButton
                        type="button"
                        className="btn btn-xs"
                        customStyle={{ marginRight: '5px' }}
                        icon={
                          <FontAwesome name="edit" color="#fff" size={12} />
                        }
                        onClick={() => onEdit(row.id)}
                      />
                      <BvsButton
                        type="button"
                        className="btn btn-xs"
                        customStyle={{ marginRight: '5px' }}
                        icon={
                          <FontAwesome name="close" color="#fff" size={12} />
                        }
                        onClick={() => onDelete(row.id)}
                      />
                    </>
                  ),
                ]}
              />
            </div>
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default Shortcuts;
