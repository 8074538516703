import React, { useState } from 'react';
import { FontAwesome } from 'react-web-vector-icons';
import * as UI from './style';

const BvsToggle = ({
  title = '',
  name,
  invert = false,
  dataTip = null,
  orientation = 'vertical',
  defaultValue = false,
  value = false,
  onChange,
  customStyle,
}) => {
  return (
    <UI.BvsToggle
      style={customStyle}
      orientation={orientation}
      onClick={(e) => {
        onChange && onChange(!value);
      }}
    >
      {!invert && title !== '' && (
        <UI.BvsDivToggle>
          <UI.BvsToggleTitle>{title}</UI.BvsToggleTitle>
        </UI.BvsDivToggle>
      )}
      <UI.BvsDivToggle>
        <UI.BvsContainer>
          <UI.BvsToggleState name={name} type="checkbox" checked={value} />
          <UI.BvsToggleInterface />
        </UI.BvsContainer>
      </UI.BvsDivToggle>
      {invert && title !== '' && (
        <UI.BvsDivToggle>
          <UI.BvsToggleTitle>{title}</UI.BvsToggleTitle>
        </UI.BvsDivToggle>
      )}
      {dataTip && (
        <div data-tip={dataTip}>
          <FontAwesome name="info-circle" size={14} style={{ marginTop: 5 }} />
        </div>
      )}
    </UI.BvsToggle>
  );
};

export default BvsToggle;
