import types from './types';

export function updateForm(payload) {
  return {
    type: types.UPDATE,
    payload,
  };
}

export function resetForm() {
  return {
    type: types.RESET,
  };
}

export function selectOrder(payload) {
  return {
    type: types.SELECT_ORDER,
    payload,
  };
}

export function unselectOrder() {
  return {
    type: types.UNSELECT_ORDER,
  };
}

export function confirmCancel(callback) {
  return {
    type: types.CONFIRM_CANCEL,
    callback,
  };
}
