/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
import types from './types';

export function updateAuth(payload) {
  return {
    type: types.UPDATE_AUTH,
    payload,
  };
}

export function logIn(payload) {
  return {
    type: types.LOGIN,
    payload,
  };
}

export function logOut() {
  return {
    type: types.LOGOUT,
  };
}

export function resetAuth() {
  return {
    type: types.CLEAR_SESSION,
  };
}

export function checkAuth() {
  return {
    type: types.CHECK_AUTH,
  };
}
