/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const Dropdown = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  background: #fff;
  position: relative;
  flex-wrap: wrap;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : '')};
`;

const Input = styled.input`
  border-radius: 0;
  background-color: ${(props) =>
    props.loading || props.disabled ? '#f9f9f9' : '#fff'};
  border: 2px solid ${(props) => (props.disabled ? '#c0392b' : '#beddcd')} !important;
  padding-left: 8px;
  width: 100%;
  position: relative;
`;

const DropdownList = styled.ul`
  position: absolute;
  background: #fff;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  color: #beddcd;
  box-sizing: border-box;
  border-left: 2px solid #beddcd;
  border-right: 2px solid #beddcd;
  border-bottom: 2px solid #beddcd;
  z-index: 2;
  height: auto;
  max-height: 200px;
  /* min-height: 50%; */
  /* height: 100%; */
  overflow-y: auto;
  overflow-x: hidden;
  top: ${({ hasLabel }) => (hasLabel ? '61px' : '40px')};
`;
const DropdownListItem = styled.li`
  background: #fff;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s;
  padding: 5px 15px;
  position: ${(props) => (props.search ? 'sticky' : 'relative')} !important;
  &.selected {
    background-color: #beddcd;
    color: #fff;
    transition: all 0.2s;
  }
  &:hover {
    background-color: #beddcd;
    color: #fff;
    transition: all 0.2s;
  }
`;

export { Dropdown, Input, DropdownList, DropdownListItem };
