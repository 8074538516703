const useHelper = () => {
  const getNameTax = (id, name) => {
    switch (id) {
      case 1:
        return 'Gestão VT';
      case 2:
        return 'Gestão BR';
      case 3:
        return 'Gestão BC';
      case 4:
        return 'Gestão BA';
      default:
        return `Gestão - ${name}`;
    }
  };

  return { getNameTax };
};

export default useHelper;
