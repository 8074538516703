import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BvsToastResult from '~/components/BvsToastResult';
import { toast } from 'react-toastify';
import api from '~/services/api';
import moment from 'moment';
import * as actions from '~/store/modules/forms/FormOperatorBenefit/actions';
import { showLoading, hideLoading } from '~/store/modules/loader/actions';

const useController = ({ id = null, containerForm = null }) => {
  const { operator_benefit, form } = useSelector(
    (state) => state.formOperatorBenefit
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [benefits, setBenefits] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [benefitInfo, setBenefitInfo] = useState(null);

  const [operator, setOperator] = useState({
    loading: false,
    options: [],
  });

  const [benefitGroup, setBenefitGroup] = useState({
    loading: false,
    options: [],
  });

  const [region, setRegion] = useState({
    loading: false,
    options: [],
  });

  const getRegion = async () => {
    try {
      setRegion({
        ...region,
        loading: true,
      });
      const { data } = await api.get('/regions');
      setRegion({
        ...region,
        loading: false,
        options: data.map((i) => ({ value: i.id, title: i.name })),
      });
    } catch (err) {
      setRegion({
        ...region,
        loading: false,
        options: [],
      });
    }
  };

  const getGroupBenefits = async () => {
    try {
      setBenefitGroup({
        ...benefitGroup,
        loading: true,
      });
      const { data } = await api.get('/benefitGroup');
      setBenefitGroup({
        ...benefitGroup,
        loading: false,
        options: data.map((i) => ({ value: i.id, title: i.name })),
      });
    } catch (err) {
      setBenefitGroup({
        ...benefitGroup,
        loading: false,
        options: [],
      });
    }
  };

  const getOperators = async () => {
    try {
      setOperator({ ...operator, loading: true });
      const { data } = await api.get('/benefitOperators');
      setOperator({
        ...operator,
        loading: false,
        options: data.map((i) => ({
          value: i.id,
          title: `[${i.code}] - ${i.name}`,
        })),
      });
    } catch (err) {
      setOperator({
        ...operator,
        loading: false,
        options: [],
      });
    }
  };

  const fetch = async () => {
    dispatch(showLoading());
    try {
      const { data } = await api.get(`/benefits`);
      if (data.message || data.error) throw data;
      setBenefits(data);
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Falha ao atualizar lista de Usuarios."
        />
      );
      setBenefits([]);
    } finally {
      dispatch(hideLoading());
    }
  };

  const onEdit = async (benefitId) => {
    history.replace(`/benefit/${benefitId}`);
    try {
      dispatch(
        actions.updateOperatorBenefit({
          form: { ...form, saving: true },
        })
      );
      const { data } = await api.get(`/benefits/${benefitId}`);
      if (data.error || data.message) throw data;
      dispatch(
        actions.updateOperatorBenefit({
          operator_benefit: {
            ...operator_benefit,
            id: data.id,
            code: data.code,
            name: data.name,
            line: data.line,
            sub_description: data.sub_description,
            unit_cost: data.unit_cost,
            percentage_total: data.percentage_total,
            cost_per_employee: data.cost_per_employee,
            cost_per_order: data.cost_per_order,
            variable_cost: data.variable_cost === 'S',
            voucher_type_block: data.voucher_type_block === 'S',
            voucher_type_card: data.voucher_type_card === 'S',
            voucher_type_multiple: data.voucher_type_multiple === 'S',
            cost_original: data.cost_original,
            cost_duplicate: data.cost_duplicate,
            cost_per_credit: data.cost_per_credit,
            minimal_original_cost: data.minimal_original_cost,
            minimal_others_cost: data.minimal_others_cost,
            maximum_others_cost: data.maximum_others_cost,
            required_data: data.required_data,
            facial: data.facial === 'S',
            unit: data.unit === 'S',
            period: data.period === 'S',
            integration: data.integration,
            payment_method: data.payment_method,
            deadline_credit: data.deadline_credit,
            deadline_card_operator: data.deadline_card_operator,
            card_status: data.card_status,
            cost_card_generation: data.cost_card_generation,
            descriptive: data.descriptive,
            website: data.website,
            phone: data.phone,
            email: data.email,
            login: data.login,
            password: data.password,
            date_last_adjustment: moment(data.date_last_adjustment).format(
              'YYYY-MM-DD'
            ),
            type_letter_card: data.type_letter_card,
            type_order_generation: data.type_order_generation,
            region_id: data.region_id,
            benefit_group_id: data.benefit_group_id,
            benefit_operator_id: data.benefit_operator_id,
          },
        })
      );
      setVisible(true);
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Falha ao atualizar Dados."
        />
      );
    } finally {
      dispatch(
        actions.updateOperatorBenefit({
          form: { ...form, saving: false },
        })
      );
    }
    window.scrollTo({
      top: containerForm?.current.scrollTop,
      behavior: 'smooth',
    });
  };

  const onSave = async (e) => {
    e.preventDefault();
    dispatch(showLoading());
    try {
      dispatch(
        actions.updateOperatorBenefit({
          form: { saving: true },
        })
      );
      const { data } = await api[id ? 'put' : 'post'](
        `/benefits${id ? `/${id}` : ''}`,
        {
          ...operator_benefit,
          date_last_adjustment:
            (moment(operator_benefit.date_last_adjustment).isValid() &&
              moment(operator_benefit.date_last_adjustment).format(
                'YYYY-MM-DD'
              )) ||
            null,
        }
      );
      if (data.error || data.message) throw data;
      toast.success(
        <BvsToastResult type="success" message="Salvo com sucesso" />
      );
      onCancel();
    } catch (err) {
      toast[err?.status === 'warn' ? 'warning' : 'error'](
        <BvsToastResult
          type={err?.status === 'warn' ? 'warn' : 'error'}
          message={
            err?.status === 'warn'
              ? err.message
              : 'Não foi possivel criar um benefício neste momento.'
          }
          error={err}
        />
      );
    } finally {
      dispatch(hideLoading());
      dispatch(
        actions.updateOperatorBenefit({
          form: { saving: false },
        })
      );
      fetch();
    }
  };

  const onDetails = async (benefitId) => {
    // Busco os dados
    try {
      const { data } = await api.get(`/benefits/${benefitId}`);
      if (data.error || data.message) throw data;
      setModalVisible(true);
      setBenefitInfo({
        ...data,
        region: data?.region?.name,
        benefitGroup: data?.benefitGroup?.name,
      });
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Falha ao buscar dados do Beneficio."
        />
      );
    }
  };

  const onDelete = async (paramsId) => {
    dispatch(showLoading());
    try {
      dispatch(
        actions.updateOperatorBenefit({
          form: { saving: true },
        })
      );
      const { data } = await api.delete(`/benefits/${paramsId}`);
      if (data.error || data.message) throw data;
      toast.success(<BvsToastResult message="Excluido com sucesso" />);
      onCancel();
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Não foi possivel Deletar usuario neste momento."
        />
      );
    } finally {
      dispatch(hideLoading());
      dispatch(
        actions.updateOperatorBenefit({
          form: { saving: false },
        })
      );
      fetch();
    }
  };

  const onCancel = () => {
    dispatch(actions.resetOperatorBenefit());
    history.replace('/benefit');
  };
  return {
    onCancel,
    onDelete,
    onDetails,
    benefits,
    onSave,
    fetch,
    modalVisible,
    setModalVisible,
    visible,
    onEdit,
    benefitInfo,
    getOperators,
    getRegion,
    getGroupBenefits,
    operator,
    benefitGroup,
    region,
  };
};

export default useController;
