/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useEffect } from 'react';

import Lottie from 'lottie-react-web';
import { WrapperLoader } from './style';
import animationData from '~/assets/animations/loadingBenvs.json';
import { useSelector } from 'react-redux';

export default function BvsLoader() {
  const { loading } = useSelector((state) => state.loader);
  useEffect(() => {
    if (loading) {
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('body').style.overflow = 'auto';
    }
  }, [loading]);
  return !loading ? (
    false
  ) : (
    <WrapperLoader>
      <Lottie
        direction={-1}
        forceSegments
        options={{
          loop: true,
          autoplay: true,
          animationData,
        }}
        height={100}
        width={100}
        isStopped={false}
        isPaused={false}
        speed={1.5}
        segments={[0, 65]}
      />
    </WrapperLoader>
  );
}
