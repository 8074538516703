/* eslint-disable no-console */
import Reactotron from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';

const reactotron = Reactotron.configure() // we can use plugins here -- more on this later
  .configure({ name: "Benv's Gestão de Benefícios" })
  .use(reactotronRedux())
  .connect(); // let's connect!

console.tron = reactotron;

export default reactotron;
