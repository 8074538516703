import React from 'react';
import Header from './Header/index';
import Content from './Content/index';
import { LayoutWrapper } from './style';
import FormLogin from './FormLogin/index';
import Footer from './Footer/index';
import SuperFooter from './SuperFooter/index';

export default function Login() {
  return (
    <LayoutWrapper>
      <Header />
      <Content
        style={{
          minHeight: '70vh',
        }}
      >
        <FormLogin />
      </Content>
      <SuperFooter />
      <Footer />
    </LayoutWrapper>
  );
}
