/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  form: {
    saving: false,
    disabled: false,
  },
  operator_benefit: {
    id: '',
    code: '',
    name: '',
    line: '',
    sub_description: '',
    unit_cost: '',
    percentage_total: '',
    cost_per_employee: '',
    cost_per_order: '',
    variable_cost: false,
    voucher_type_block: false,
    voucher_type_card: false,
    voucher_type_multiple: false,
    cost_original: '',
    cost_duplicate: '',
    cost_per_credit: '',
    minimal_original_cost: '',
    minimal_others_cost: '',
    maximum_others_cost: '',
    required_data: '',
    facial: false,
    unit: false,
    period: false,
    integration: '',
    payment_method: '',
    deadline_credit: '',
    deadline_card_operator: '',
    card_status: '',
    cost_card_generation: '',
    descriptive: '',
    website: '',
    phone: '',
    email: '',
    login: '',
    password: '',
    date_last_adjustment: undefined,
    type_letter_card: '',
    type_order_generation: '',
    region_id: '',
    benefit_group_id: '',
    benefit_operator_id: '',
  },
};

function formOperatorBenefit(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_OPERATOR_BENEFIT: {
      return produce(state, (draft) => {
        draft = { ...draft, ...action.payload };
        return draft;
      });
    }
    case types.RESET_OPERATOR_BENEFIT: {
      return produce(state, (draft) => {
        draft.operator_benefit = {
          ...INITIAL_STATE.operator_benefit,
          benefit_operator_id: null,
          benefit_group_id: null,
          region_id: null,
          date_last_adjustment: '',
        };
        return draft;
      });
    }
    default:
      return state;
  }
}

export default formOperatorBenefit;
