/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  form: {
    saving: false,
    disabled: false,
  },
  administrative_card: {
    code: '',
    identification: '',
    name: '',
    status: null,
    benefit_id: null,
    benefit_name: '',
    shipping_address_id: null,
    card_number: '',
    department_id: null,
    workday_id: null,
    daily_quantity: '',
    monthly_quantity: '',
  },
};

function formAdministrativeCard(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_ADMINISTRATIVE_CARD: {
      return produce(state, (draft) => {
        draft = { ...draft, ...action.payload };
        return draft;
      });
    }
    case types.RESET_ADMINISTRATIVE_CARD: {
      return produce(state, (draft) => {
        draft.administrative_card = INITIAL_STATE.administrative_card;
        return draft;
      });
    }
    default:
      return state;
  }
}

export default formAdministrativeCard;
