import Menu, { MenuItem } from 'rc-menu';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { USER_TYPE } from '~/system/enum';
import { BvsButton } from '~/components/BvsButton/style';
import * as UI from './style';
import DataAdm from '../../system/configs/menu.config.adm.json';
import DataUser from '../../system/configs/menu.config.user.json';

const BvsNavBarMobile = () => {
  const history = useHistory();
  const { addToast } = useToasts();

  const { user } = useSelector((state) => state.auth);
  const { enterpriseActive } = useSelector((state) => state.enterprises);
  const [menuVisible, setMenuVisible] = useState(false);
  const [managerVisible, setManagerVisible] = useState(null);

  const pushLink = (router) => {
    if (router != null) {
      history.push(router);
    }
  };

  function GetTypeUser() {
    if (user?.type === USER_TYPE.ADMIN) {
      return DataAdm;
    }
    if (user?.type === USER_TYPE.USER) {
      return DataUser;
    }
  }

  function enterpriseRequired(item) {
    if (item.enterpriseActive && !enterpriseActive) {
      addToast(`Necessario selecionar uma empresa!`, {
        appearance: 'warning',
        autoDismiss: true,
      });
    } else pushLink(item.router);
  }

  const getStates = () => {
    let objKey = {};
    const getSubmenu = (sm) => {
      objKey[sm?.key] = false;
      sm?.submenu?.forEach((i) => getSubmenu(i));
    };
    GetTypeUser().forEach((i) => i.submenu.map((v) => getSubmenu(v)));
    setManagerVisible(objKey);
  };
  const openMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const renderMobileMenu = (submenu) => {
    const renderItem = (item) => {
      return (
        <UI.MenuItem
          key={item.key}
          onClick={() => {
            enterpriseRequired(item);
          }}
        >
          <span>
            {item.submenu.length > 0 && (
              <i
                style={{ marginRight: '10px' }}
                className="fa fa-chevron-down"
                aria-hidden="true"
                onClick={() => {
                  updateItemState(item.key, !managerVisible[item.key]);
                }}
              />
            )}
          </span>
          {item.title}
        </UI.MenuItem>
      );
    };
    if (submenu?.submenu?.length === 0) {
      return renderItem();
    }
    return (
      <>
        <MenuItem key={submenu?.key}>
          {submenu?.map((i) => (
            <UI.MenuItem key={i.key}>
              {renderItem(i)}
              {managerVisible[i.key] && renderMobileMenu(i?.submenu)}
            </UI.MenuItem>
          ))}
        </MenuItem>
      </>
    );
  };

  const updateItemState = (key, value) => {
    setManagerVisible({
      ...managerVisible,
      [key]: value,
    });
  };

  useEffect(() => {
    getStates();
  }, []);
  return (
    <>
      <UI.MenuTop className="d-sm-none p-0 m-0 ml-3">
        <BvsButton type="button" onClick={openMenu}>
          <i className="fa fa-bars" aria-hidden="true" />
        </BvsButton>
        {menuVisible && (
          <Menu>
            {GetTypeUser().map((i) => (
              <MenuItem
                key={i.key}
                style={{
                  fontSize: '1rem',
                }}
              >
                <MenuItem
                  key={i.key}
                  onClick={() => {
                    updateItemState(i.key, !managerVisible[i.key]);
                  }}
                >
                  {i.title}
                </MenuItem>
                {managerVisible[i.key] &&
                  i.submenu &&
                  renderMobileMenu(i.submenu)}
              </MenuItem>
            ))}
          </Menu>
        )}
      </UI.MenuTop>
    </>
  );
};

export default BvsNavBarMobile;
