import BvsModal from '~/components/BvsModal';
import BvsButton from '~/components/BvsButton';
import { useSelector, useDispatch } from 'react-redux';
import { updateEmployees } from '~/store/modules/forms/FormEmployees/actions';
import {
  updateDepartment,
  resetDepartment,
  addDepartment,
} from '~/store/modules/forms/FormDepartment/actions';
import BvsInput from '~/components/BvsInput';

const CreateDepartment = ({ onSaved }) => {
  const dispatch = useDispatch();
  const { components, employees } = useSelector((state) => state.formEmployees);
  const { department, form } = useSelector((state) => state.formDepartment);
  const setComponents = (component, state) => {
    dispatch(
      updateEmployees({ components: { ...components, [component]: state } })
    );
  };
  const onSave = () => {
    dispatch(
      addDepartment(async (newDepartment) => {
        setComponents('createDepartment', false);
        onSaved && (await onSaved());
        dispatch(
          updateEmployees({
            employees: {
              ...employees,
              department_id: newDepartment?.id,
            },
          })
        );
      })
    );
    dispatch(resetDepartment());
  };

  const onFieldChange = (name, value) => {
    dispatch(
      updateDepartment({ department: { ...department, [name]: value } })
    );
  };
  return (
    <BvsModal
      visible={components.createDepartment}
      onClose={() => setComponents('createDepartment', false)}
      footer={() => (
        <div className="row d-flex justify-content-end">
          <div className="mr-2 text-nowrap">
            <BvsButton
              title="Cancelar"
              onClick={() => {
                setComponents('createDepartment', false);
                dispatch(resetDepartment());
              }}
            />
          </div>
          <div className=" text-nowrap">
            <BvsButton
              title="Salvar"
              onClick={() => {
                onSave();
              }}
            />
          </div>
        </div>
      )}
    >
      <div className="container">
        <form onSubmit={onSave} autoComplete="off">
          <div className="row">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
              <BvsInput
                name="code"
                label="Código"
                value={department.code}
                onChange={(value) => onFieldChange('code', value)}
                required
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
              <BvsInput
                name="name"
                label="Nome do Departamento"
                value={department.name}
                onChange={(value) => onFieldChange('name', value)}
                required
              />
            </div>
          </div>
        </form>
      </div>
    </BvsModal>
  );
};

export default CreateDepartment;
