/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
import types from './types';

export function updateEnterprise(payload) {
  return {
    type: types.UPDATE_ENTERPRISE,
    payload,
  };
}

export function resetEnterprise() {
  return {
    type: types.RESET_ENTERPRISE,
  };
}
