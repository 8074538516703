import { FontAwesome } from 'react-web-vector-icons';

/* eslint-disable import/prefer-default-export */
export const BvsTableHeader = ({
  onSelect,
  onSort,
  selectedItems = [],
  columns = [],
  rows,
  sort = null,
  actions,
  actionOptions,
}) => {
  // #region Methods
  const handleOnSelect = () => {
    onSelect(selectedItems.length < rows.length ? -1 : 0);
  };
  // #endregion

  return (
    <thead>
      <tr>
        {onSelect && (
          <th>
            <input
              type="checkbox"
              style={{ width: '25px', height: '15px', cursor: 'pointer' }}
              onChange={handleOnSelect}
              checked={
                selectedItems?.length === rows.length &&
                selectedItems?.length > 0
              }
            />
          </th>
        )}
        {columns?.map((c) => (
          <th
            key={c.key}
            onClick={c.sort ? (e) => onSort(e, c) : undefined}
            style={{
              cursor: c.sort ? 'pointer' : 'default',
              width: c.width ? c.width : '1px',
            }}
          >
            {c.label}
            &nbsp;
            {sort?.[c.key] !== null ? (
              <span style={{ fontSize: '0.6rem' }}>
                {sort?.[c.key] ? (
                  <FontAwesome name="sort-down" size={12} />
                ) : (
                  <FontAwesome name="sort-up" size={12} />
                )}
              </span>
            ) : (
              <span>
                <FontAwesome name="unsorted" size={12} />
              </span>
            )}
          </th>
        ))}
        {actions?.length > 0 && (
          <th
            className="column-fixed-right"
            style={{
              width: actionOptions?.width || '140px',
              minWidth: actionOptions?.width || '140px',
            }}
          >
            Ação
          </th>
        )}
      </tr>
    </thead>
  );
};
