/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Titulo = styled.p`
  font-size: 0.95rem;
  font-weight: bold;
  margin-left: auto;
  margin-top: 5px;
  margin-bottom: 5px;
`;
export const Data = styled.p`
  font-size: 0.95rem;
  margin-left: auto;
`;
