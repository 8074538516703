/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  user: {
    id: null,
    name: null,
    type: null,
    avatar: null,
    permissions: null,
  },
  token: null,
};

function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_AUTH: {
      return produce(state, (draft) => {
        draft = { ...draft, ...action.payload };
        return draft;
      });
    }
    case types.CLEAR_SESSION: {
      return produce(state, (draft) => {
        draft = { ...INITIAL_STATE };
        return draft;
      });
    }
    default:
      return state;
  }
}

export default auth;
