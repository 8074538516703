/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
import types from './types';

export function updateDepartment(payload) {
  return {
    type: types.UPDATE_DEPARTMENT,
    payload,
  };
}

export function resetDepartment() {
  return {
    type: types.RESET_DEPARTMENT,
  };
}

export function addDepartment(callback) {
  return {
    type: types.ADD_DEPARTMENT,
    callback,
  };
}
