/* eslint-disable no-param-reassign */
import React from 'react';
import { useHistory } from 'react-router';
import { AntDesign } from 'react-web-vector-icons';
import { toMoney } from '~/system/helper';
import BvsContainer from '~/components/BvsContainer';
import BvsPanel from '~/components/BvsPanel';
import SubHeader from '~/components/BvsSubHeader';
import BvsButton from '~/components/BvsButton';
import FooterReports from '~/pages/Reports/FooterReports';
import HeaderReports from '~/pages/Reports/HeaderReports';
import * as UI from './style';
import useController from './hooks/useController';
import { useEffect } from 'react';

const ReportsByDepartmentPerBenefits = ({ match }) => {
  const history = useHistory();
  const { reportData, getReportData } = useController();
  const { id } = match.params;

  const getValueTotal = () => {
    return reportData?.data?.departments
      .map((e) =>
        e.benefits
          .map((i) => i.quantity * i.unitCost + i.transferAmount)
          .reduce((cur, old) => cur + old, 0)
      )
      .reduce((cur, old) => cur + old, 0);
  };

  const groupBy = (benefits) => {
    return benefits.reduce((result, currentValue) => {
      if (result) {
        let searched = result.findIndex(
          (i) => i.benefitGroupId === currentValue.benefitGroupId
        );
        if (searched > -1) {
          result[searched].data.push(currentValue);
        } else
          result.push({
            benefitGroupId: currentValue.benefitGroupId,
            name: currentValue.benefitGroupName,
            data: [currentValue],
          });
      } else {
        result = [
          {
            benefitGroupId: currentValue.benefitGroupId,
            name: currentValue.benefitGroupName,
            data: [currentValue],
          },
        ];
      }
      return result;
    }, null);
  };

  const GetTotalByBenefits = () => {
    const benefits = reportData?.data?.departments
      ?.map((i) => i.benefits)
      .flat(1)
      ?.reduce((r, a) => {
        if (r?.map((i) => i.id).includes(a?.benefitGroupId)) {
          let benefitGroup = r.find((i) => i.id === a?.benefitGroupId);
          if (benefitGroup) {
            benefitGroup.total += a?.unitCost * a?.quantity;
            benefitGroup.tax += a?.transferAmount;
          }
        } else {
          r.push({
            id: a?.benefitGroupId,
            name: a?.benefitGroupName,
            total: a?.unitCost * a?.quantity,
            tax: a?.transferAmount,
          });
        }

        return r;
      }, []);

    return benefits?.map((benefit) => (
      <tr key={benefit?.id}>
        <td
          scope="row"
          colSpan="5"
          style={{
            textAlign: 'right',
            fontWeight: 'bold',
          }}
        >
          TOTAL&nbsp;
          {benefit?.name}
        </td>
        <td style={{ textAlign: 'center' }}>{toMoney(benefit?.total)}</td>
        <td style={{ textAlign: 'center' }}>{toMoney(benefit?.tax)}</td>
      </tr>
    ));
  };

  useEffect(() => {
    getReportData(id);
  }, []);
  return (
    <>
      <SubHeader
        title="Relatório de Aquisição de Benefícios"
        subtitle="Por Departamento - Detalhado por Benefícios"
      />
      <BvsContainer>
        <div>
          <BvsPanel title="Relatório de Aquisição de Benefícios Por Departamento - Detalhado por Benefício">
            <BvsButton
              type="button"
              title="Voltar"
              onClick={() => {
                history.goBack();
              }}
            />
            <HeaderReports />
            {reportData.loading && (
              <div className="p-3 row justify-content-center align-items-center">
                <div className="col-12 text-center">
                  <h4>Carregando...</h4>
                </div>
                <div className="col-4">
                  <div className="progress">
                    <div
                      className="progress-bar bg-warning progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
              </div>
            )}
            {!reportData.loading && !reportData?.data && (
              <div
                className="row middle-xs center-xs"
                style={{ padding: 60, textAlign: 'center' }}
              >
                <div className="col-md-12">
                  <h2>Não foi possivel encontrar registros</h2>
                  <h2 style={{ marginTop: '10px' }}>
                    <AntDesign name="frowno" />
                  </h2>
                </div>
              </div>
            )}
            {reportData?.data && (
              <>
                <BvsContainer
                  style={{
                    display: 'flex',
                    margin: '40px 20px 5px',
                    borderTop: '1.4px solid rgba(0, 0, 0, 0.1)',
                    borderBottom: '1.4px solid rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <UI.Titulo> Data de Emissão </UI.Titulo>
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'flex',
                    margin: '5px 20px 5px',
                    borderBottom: '1.4px solid rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <UI.Data>{reportData?.data?.dateEmission}</UI.Data>
                </BvsContainer>
                <BvsContainer
                  style={{
                    display: 'block',
                    marginLeft: '40px',
                    marginRight: '40px',
                    marginBottom: '40px',
                    padding: '0px',
                  }}
                >
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Razão Social</th>
                        <th scope="col" />
                        <th scope="col">Código</th>
                        <th scope="col">CNPJ</th>
                        <th scope="col">Inscrição Estadual</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row" colSpan="2">
                          {reportData?.data?.socialName}
                        </td>
                        <td>{reportData?.data?.id}</td>
                        <td>{reportData?.data?.cnpj}</td>
                        <td>{reportData?.data?.stateRegistration}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Endereço</th>
                        <th scope="col">CEP</th>
                        <th scope="col">Bairro</th>
                        <th scope="col">Municipio</th>
                        <th scope="col">Estado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row">{reportData?.data?.street}</td>
                        <td>{reportData?.data?.zipcode}</td>
                        <td>{reportData?.data?.district}</td>
                        <td>{reportData?.data?.city}</td>
                        <td>{reportData?.data?.state}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Contato</th>
                        <th scope="col" />
                        <th scope="col">Telefone</th>
                        <th scope="col">Email</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row" colSpan="2">
                          {reportData?.data?.ownerName}
                        </td>
                        <td>{reportData?.data?.ownerPhone}</td>
                        <td colSpan="2">{reportData?.data?.ownerEmail}</td>
                      </tr>
                    </tbody>
                  </table>
                </BvsContainer>
                {reportData?.data?.departments.map((department) => (
                  <>
                    <BvsContainer
                      style={{
                        display: 'block',
                        marginLeft: '40px',
                        marginRight: '40px',
                        padding: '0px',
                        borderTop: '1.4px solid rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col" style={{ textAlign: 'center' }}>
                              Código
                            </th>
                            <th scope="col" style={{ textAlign: 'center' }}>
                              Departamento
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td scope="row" style={{ textAlign: 'center' }}>
                              {department?.departmentCode}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {department?.departmentName}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </BvsContainer>
                    <BvsContainer
                      style={{
                        display: 'block',
                        marginLeft: '40px',
                        marginRight: '40px',
                        marginBottom: '40px',
                        padding: '0px',
                      }}
                    >
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Mes Referencia</th>
                            <th scope="col">
                              {department?.departmentReference}
                            </th>
                            <th scope="col">Nº do Pedido</th>
                            <th scope="col">{department?.departmentOrderId}</th>
                          </tr>
                        </thead>
                      </table>
                    </BvsContainer>
                    <BvsContainer
                      style={{
                        display: 'block',
                        marginLeft: '40px',
                        marginRight: '40px',
                        marginBottom: '40px',
                        padding: '0px',
                      }}
                    >
                      <table
                        className="table table-striped"
                        style={{ borderTop: '1.4px solid rgba(0, 0, 0, 0.1)' }}
                      >
                        <thead>
                          <tr>
                            <th scope="col">Codigo</th>
                            <th scope="col">Discriminacao dos beneficios</th>
                            <th scope="col" />
                            <th scope="col">Quantidade</th>
                            <th scope="col">Unitario</th>
                            <th scope="col">Total</th>
                            <th scope="col">Repasse</th>
                          </tr>
                        </thead>
                        {department?.benefits.map((benefit) => (
                          <>
                            <tbody>
                              <tr>
                                <td scope="row" style={{ paddingLeft: '5px' }}>
                                  {benefit?.benefitCode}
                                </td>
                                <td colSpan="2">{benefit?.subDescription}</td>
                                <td>{benefit?.quantity}</td>
                                <td>{toMoney(benefit?.unitCost)}</td>
                                <td
                                  style={{
                                    textAlign: 'center',
                                    paddingRight: '40px',
                                  }}
                                >
                                  {toMoney(
                                    benefit?.unitCost * benefit?.quantity
                                  )}
                                </td>
                                <td
                                  style={{
                                    textAlign: 'center',
                                    paddingRight: '40px',
                                  }}
                                >
                                  {toMoney(benefit?.transferAmount)}
                                </td>
                              </tr>
                            </tbody>
                          </>
                        ))}
                        <tfoot>
                          {groupBy(department?.benefits).map((benefits) => (
                            <>
                              <tr>
                                <td
                                  scope="row"
                                  colSpan="5"
                                  style={{
                                    fontWeight: 'bold',
                                    textAlign: 'right',
                                  }}
                                >
                                  TOTAL&nbsp;
                                  {benefits?.name}
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  {toMoney(
                                    benefits?.data
                                      .map((e) => e.unitCost * e.quantity)
                                      .reduce((cur, old) => cur + old, 0)
                                  )}
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  {toMoney(
                                    benefits?.data
                                      .map((e) => e.transferAmount)
                                      .reduce((cur, old) => cur + old, 0)
                                  )}
                                </td>
                              </tr>
                            </>
                          ))}
                        </tfoot>
                      </table>
                    </BvsContainer>
                  </>
                ))}
                <BvsContainer
                  style={{
                    display: 'block',
                    marginLeft: '40px',
                    marginRight: '40px',
                    marginBottom: '40px',
                    padding: '0px',
                  }}
                >
                  <UI.TituloResumo>RESUMO DO RELATORIO</UI.TituloResumo>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" style={{ width: '10%' }} />
                        <th scope="col" style={{ width: '10%' }} />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          scope="row"
                          colSpan="6"
                          style={{ textAlign: 'right' }}
                        >
                          Qta. De Beneficios:
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {reportData?.data?.departments
                            .map((b) => b.benefits.length)
                            .flat(1)
                            .reduce((cur, old) => cur + old, 0)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          scope="row"
                          colSpan="6"
                          style={{ textAlign: 'right' }}
                        >
                          Qta. Geral de beneficios:
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {reportData?.data?.departments
                            ?.map((b) => b.benefits.map((q) => q.quantity))
                            .flat(1)
                            .reduce((cur, old) => cur + old, 0)}
                        </td>
                      </tr>

                      <GetTotalByBenefits />
                    </tbody>
                    <tfoot>
                      <tr>
                        <td scope="row" />
                        <td />
                        <td />
                        <td />
                        <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                          Sub-Total
                        </td>
                        <td
                          colSpan="1"
                          style={{ fontSize: '1rem', textAlign: 'center' }}
                        >
                          {toMoney(
                            reportData?.data?.departments
                              .map((b) =>
                                b.benefits
                                  .map((i) => i.quantity * i.unitCost)
                                  .reduce((cur, old) => cur + old, 0)
                              )
                              .reduce((cur, old) => cur + old, 0)
                          )}
                        </td>
                        <td style={{ fontSize: '1rem', textAlign: 'center' }}>
                          {toMoney(
                            reportData?.data?.departments
                              .map((b) =>
                                b.benefits
                                  .map((i) => i.transferAmount)
                                  .reduce((cur, old) => cur + old, 0)
                              )
                              .reduce((cur, old) => cur + old, 0)
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td scope="row" />
                        <td />
                        <td />
                        <td />
                        <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                          Valor Total
                        </td>
                        <td
                          colSpan="2"
                          style={{ fontWeight: 'bold', textAlign: 'center' }}
                        >
                          {toMoney(getValueTotal())}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </BvsContainer>
              </>
            )}
            <FooterReports periodUse={reportData?.data?.periodUse} />
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default ReportsByDepartmentPerBenefits;
