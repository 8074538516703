import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import BvsModal from '~/components/BvsModal';
import BvsButton from '~/components/BvsButton';
import { updateHome } from '~/store/modules/home/actions';

const LastLogin = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { components } = useSelector((state) => state.home);
  const setComponent = (component, state) => {
    dispatch(
      updateHome({
        components: {
          ...components,
          [component]: state,
        },
      })
    );
  };
  return (
    <BvsModal
      visible={components.lastLogin}
      onClose={() => setComponent('lastLogin', false)}
      footer={() => (
        <div className="row d-flex justify-content-end">
          <div className=" text-nowrap">
            <BvsButton
              title="Fechar"
              onClick={() => {
                setComponent('lastLogin', false);
              }}
            />
          </div>
        </div>
      )}
    >
      <div className="container">
        <div className="row">
          Seu último acesso foi em{' '}
          {(user?.last_login &&
            moment(user?.last_login, 'YYYY-MM-DD HH:mm:ss')?.format(
              'DD/MM/YYYY HH:mm:ss'
            )) ||
            moment().format('DD/MM/YYYY HH:mm:ss')}
          . Caso não reconheça o acesso, troque sua senha.
        </div>
      </div>
    </BvsModal>
  );
};

export default LastLogin;
