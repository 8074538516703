/* eslint-disable consistent-return */
import BvsModal from '~/components/BvsModal';
import BvsButton from '~/components/BvsButton';
import { useSelector, useDispatch } from 'react-redux';
import { updateEmployees } from '~/store/modules/forms/FormEmployees/actions';
import {
  updateShippingAddress,
  updateResponsible,
  addShippingAddress,
  resetShippingAddress,
  addResponsible,
  removeResponsible,
} from '~/store/modules/forms/FormShippingAddress/actions';
import BvsInput from '~/components/BvsInput';
import { ADDRESS_TYPE } from '~/system/enum';
import { getAddressType } from '~/system/convert';
import BvsDropdown from '~/components/BvsDropdown';
import BvsPanel from '~/components/BvsPanel';
import viacep from '~/services/viacep';
import useDepartments from '~/hooks/useDepartments';
import useJobs from '~/hooks/useJobs';
import { useState, useEffect, useRef } from 'react';

const CreateShippingAddress = ({ onSaved }) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const { components, employees } = useSelector((state) => state.formEmployees);
  const { shipping_address, responsibles, form } = useSelector(
    (state) => state.formShippingAddress
  );
  const { departments, fetch: getDepartments } = useDepartments({
    loading: false,
  });
  const { jobs, fetch: getJobs } = useJobs({ loading: false });
  const setComponents = (component, state) => {
    dispatch(
      updateEmployees({ components: { ...components, [component]: state } })
    );
  };

  const onSave = (e) => {
    e.preventDefault();
    dispatch(
      addShippingAddress(async (newShippingAddress) => {
        setComponents('createShippingAddress', false);
        onSaved && (await onSaved());
        dispatch(
          updateEmployees({
            employees: {
              ...employees,
              shipping_address_id: newShippingAddress?.id,
            },
          })
        );
      })
    );

    dispatch(resetShippingAddress());
  };

  const onFieldChange = (name, value) => {
    dispatch(
      updateShippingAddress({
        shipping_address: { ...shipping_address, [name]: value },
      })
    );
  };

  const handleDelete = (pIndex) => {
    dispatch(removeResponsible(pIndex));
  };
  const handleAdd = () => {
    dispatch(addResponsible());
  };

  const setResponsible = (index, key, value) => {
    dispatch(
      updateResponsible(
        responsibles.map((responsible, idx) =>
          idx === index
            ? {
                ...responsible,
                [key]: value,
              }
            : responsible
        )
      )
    );
  };
  useEffect(() => {
    if (components.createShippingAddress) {
      getDepartments();
      getJobs();
    }
  }, [components.createShippingAddress]);

  return (
    <BvsModal
      size="xl"
      visible={components.createShippingAddress}
      onClose={() => setComponents('createShippingAddress', false)}
      footer={() => (
        <div className="row d-flex justify-content-end">
          <div className="mr-2 text-nowrap">
            <BvsButton
              title="Cancelar"
              onClick={() => {
                setComponents('createShippingAddress', false);
                dispatch(resetShippingAddress());
              }}
            />
          </div>
          <div className=" text-nowrap">
            <BvsButton
              title="Salvar"
              type="submit"
              form="create-shipping-address"
              onClick={() => {
                // formRef.submit();
              }}
            />
          </div>
        </div>
      )}
    >
      <div className="container">
        <form onSubmit={onSave} autoComplete="off" id="create-shipping-address">
          <BvsPanel title="Cadastro">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-1 p-3">
                <BvsInput
                  name="code"
                  label="Código"
                  value={shipping_address.code}
                  onChange={(value) => onFieldChange('code', value)}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 p-3">
                <BvsInput
                  name="title"
                  label="Titulo"
                  value={shipping_address.title}
                  onChange={(value) => onFieldChange('title', value)}
                />
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-2 p-3">
                <BvsInput
                  name="zipcode"
                  label="CEP"
                  mask="cep"
                  value={shipping_address.zipcode}
                  onChange={(value) => onFieldChange('zipcode', value)}
                  onBlur={async (v) => {
                    let cep = v?.target?.value?.replace(/[^\d]/g, '');
                    if (cep.length >= 8) {
                      try {
                        const { data } = await viacep.get(`/${cep}/json/`);
                        dispatch(
                          updateShippingAddress({
                            shipping_address: {
                              ...shipping_address,
                              state_uf: data?.uf,
                              ibge: data?.ibge,
                              city: data?.localidade,
                              street: data?.logradouro,
                              district: data?.bairro,
                            },
                          })
                        );
                      } catch (err) {
                        return false;
                      }
                    }
                  }}
                  required
                />
              </div>
              <div className="col-xs-12 col-sm-4 col-md-5 col-lg-4 p-3">
                <BvsInput
                  name="city"
                  label="Cidade"
                  value={shipping_address.city}
                  onChange={(value) => onFieldChange('city', value)}
                />
              </div>

              <div className="col-xs-12 col-sm-4 col-md-2 col-lg-2 p-3">
                <BvsInput
                  name="state_uf"
                  label="Estado"
                  value={shipping_address.state_uf}
                  onChange={(value) => onFieldChange('state_uf', value)}
                />
              </div>

              <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 p-3">
                <BvsInput
                  name="street"
                  label="Rua"
                  value={shipping_address.street}
                  onChange={(value) => onFieldChange('street', value)}
                />
              </div>

              <div className="col-xs-12 col-sm-2 col-md-4 col-lg-2 p-3">
                <BvsInput
                  name="number"
                  label="Nº"
                  value={shipping_address.number}
                  onChange={(value) => onFieldChange('number', value)}
                />
              </div>

              <div className="col-xs-12 col-sm-5 col-md-4 col-lg-5 p-3">
                <BvsInput
                  name="district"
                  label="Bairro"
                  value={shipping_address.district}
                  onChange={(value) => onFieldChange('district', value)}
                />
              </div>

              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3 ">
                <BvsInput
                  name="reference_point"
                  label="Ponto de Referencia"
                  value={shipping_address.reference_point}
                  onChange={(value) => onFieldChange('reference_point', value)}
                />
              </div>

              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                <BvsInput
                  name="complement"
                  label="Complemento"
                  value={shipping_address.complement}
                  onChange={(value) => onFieldChange('complement', value)}
                />
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                <BvsDropdown
                  title="Selecione um tipo..."
                  name="address_type"
                  label="Tipo de Endereço"
                  value={shipping_address.address_type}
                  onSelectedItem={(value) =>
                    onFieldChange('address_type', value)
                  }
                  options={Object.values(ADDRESS_TYPE).map((type) => ({
                    value: type,
                    title: getAddressType(type),
                  }))}
                />
              </div>
            </div>
          </BvsPanel>
          <BvsPanel title="Responsáveis de Entrega" style={{ marginBottom: 0 }}>
            {responsibles.map((responsible, index) => {
              return (
                <BvsPanel
                  key={`${responsible.id}`}
                  title="Nome do Responsavel"
                  style={{ display: responsible ? '' : 'none' }}
                >
                  <div className="row">
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-5 p-3">
                      <BvsInput
                        name={`name_${index}`}
                        label="Responsavel"
                        value={responsible.name}
                        onChange={(value) =>
                          setResponsible(index, 'name', value)
                        }
                      />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                      <BvsInput
                        name={`phone_${index}`}
                        label="Telefone"
                        mask="phone"
                        value={responsible.phone}
                        onChange={(value) =>
                          setResponsible(index, 'phone', value)
                        }
                        required
                      />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                      <BvsInput
                        name={`email_${index}`}
                        label="E-mail"
                        value={responsible.email}
                        onChange={(value) =>
                          setResponsible(index, 'email', value)
                        }
                      />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 p-3">
                      <BvsDropdown
                        title="Selecione um Cargo..."
                        name="owner_job_id"
                        label="Cargo"
                        value={responsible.job_id}
                        onSelectedItem={(value) => {
                          setResponsible(index, 'job_id', value);
                        }}
                        loading={jobs.loading}
                        options={jobs.options}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 p-3">
                      <BvsDropdown
                        title="Selecione um Departamento..."
                        name="owner_department_id"
                        label="Departamento"
                        value={responsible.department_id}
                        onSelectedItem={(value) => {
                          setResponsible(index, 'department_id', value);
                        }}
                        loading={departments.loading}
                        options={departments.options}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                      {responsibles.filter((d) => d).length > 1 && (
                        <BvsButton
                          type="button"
                          title="Excluir"
                          icon="fa fa-trash"
                          customStyle={{
                            margin: '20px 15px',
                            float: 'right',
                          }}
                          onClick={() => handleDelete(responsible.key)}
                        />
                      )}
                    </div>
                  </div>
                </BvsPanel>
              );
            })}
            <div className="row end-xs p-2">
              {responsibles.filter((d) => d).length < 3 && (
                <BvsButton
                  type="button"
                  title="Adicionar Responsável"
                  icon="fa fa-plus"
                  customStyle={{ margin: '15px 5px', float: 'right' }}
                  onClick={() => handleAdd()}
                />
              )}
            </div>
          </BvsPanel>
        </form>
      </div>
    </BvsModal>
  );
};

export default CreateShippingAddress;
