const types = {
  UPDATE_FILTER: '@order/filter/UPDATE',
  UPDATE_ORDERBY: '@order/orderby/UPDATE',
  UPDATE_PAGINATE: '@order/pagination/UPDATE',
  UPDATE_CHANGE_DAYS: '@order/changedays/UPDATE',
  RESET_ORDERBY: '@order/orderby/RESET',
  RESET_FILTER: '@order/filter/RESET',
  RESET_PAGINATE: '@order/pagination/RESET',
};

export default types;
