/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
import produce from 'immer';
import { useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { filterRows } from '../BvsTableV2/util/helper';
import { Table } from './style';
import { BvsTableFooter } from './table-footer';
import { BvsTableHeader } from './table-header';
import { TableHeaderOptions } from './table-header-options';
import { BvsTableRow } from './table-row';

/* eslint-disable import/prefer-default-export */
const BvsTableV4 = ({
  rows: pRows = [],
  columns = [],
  actions = [],
  rowKey = 'id',
  actionOptions,
  onClickRow,
  onRefresh,
  onSelect,
  selectedItems,
}) => {
  const [filteredRows, setFiltered] = useState([]);
  const createSortDefault = () =>
    columns
      .map((c) => c.key)
      .reduce((newSorting, current) => {
        newSorting[current] = null;
        return newSorting;
      }, {});
  const [sort, setSort] = useState(createSortDefault());
  const scheduleQueryChange = useRef(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 10,
  });

  const [searchValue, setSearch] = useState(null);

  // #region Methods
  const handleSort = (e, column) => {
    setSort(
      produce(sort, (draft) => {
        if (draft[column.key] === null) draft = createSortDefault();
        draft[column.key] = !draft[column.key];
        return draft;
      })
    );
  };

  const handlePagination = (nextPage) => {
    setPagination(
      produce(pagination, (draft) => {
        draft.currentPage = nextPage;
        return draft;
      })
    );
  };
  const handleSearchQuery = (e) => {
    const { value } = e.target;
    setSearch(value);

    if (scheduleQueryChange?.current) {
      clearTimeout(scheduleQueryChange?.current);
    }

    scheduleQueryChange.current = setTimeout(() => {
      const filterFunction = filterRows;
      const applyFilters =
        (value.length === 0 ? [] : filterFunction(pRows, columns, value)) || [];
      setFiltered(applyFilters);
    }, 200);
  };

  // #endregion

  const rows = pRows;
  const isFilterActive = searchValue?.trim()?.length > 0;
  return (
    <>
      <TableHeaderOptions
        {...{
          onSearch: handleSearchQuery,
          searchValue,
          onRefresh,
          onPageSizeChange: (pageSize) =>
            setPagination(
              produce(pagination, (draft) => {
                draft.itemsPerPage = pageSize;
                return draft;
              })
            ),
        }}
      />
      <div style={{ overflow: 'auto', width: '100%', position: 'relative' }}>
        <Table>
          <BvsTableHeader
            {...{
              columns,
              actions,
              onSort: handleSort,
              sort,
              actionOptions,
              selectedItems,
              onSelect,
              rows,
            }}
          />
          <tbody>
            <BvsTableRow
              {...{
                columns,
                actions,
                rows: isFilterActive ? filteredRows : rows,
                sort,
                pagination,
                onClickRow,
                actionOptions,
                selectedItems,
                onSelect,
              }}
            />
          </tbody>
        </Table>
      </div>
      <BvsTableFooter
        {...{
          currentPage: pagination.currentPage,
          itemsPerPage: pagination.itemsPerPage,
          total: isFilterActive ? filteredRows.length : rows?.length || 0,
          toEntries: pagination.itemsPerPage,
          onPage: handlePagination,
          fromEntries:
            (pagination.currentPage - 1) * pagination.itemsPerPage + 1,
        }}
      />
      <ReactTooltip />
    </>
  );
};

export default BvsTableV4;
