const types = {
  UPDATE_SOCKET: '@app/socket/update',
  RESET_SOCKET: '@app/socket/reset',
  UPDATE_LIST_EMPLOYEE: '@app/LIST_EMPLOYEE/UPDATE',
  UPDATE_LIST_ORDER: '@app/LIST_ORDER/UPDATE',
  HISTORIC_EMPLOYEE: {
    UPDATE: '@app/historic_employee/update',
    RESET: '@app/historic_employee/reset',
    FETCH: '@app/historic_employee/fetch',
  },
  EMPLOYEE_HISTORIC_OF_BENEFITS: {
    UPDATE: '@app/employee_historic_of_benefits/update',
    RESET: '@app/employee_historic_of_benefits/reset',
    FETCH: '@app/employee_historic_of_benefits/fetch',
  },
  BVS_JUSTIFICATION: {
    UPDATE: '@app/bvs_justification/update',
    RESET: '@app/bvs_justification/reset',
  },
  ANNOUNCEMENTS: {
    UPDATE: '@app/announcements/update',
  },
};

export default types;
