/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';
import {
  resetSearchBenefits,
  updateSearchBenefits,
} from '~/store/modules/filter/FilterSearchBenefits/actions';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';
import { toMoney } from '~/system/helper';
import api from '~/services/api';
import BvsTableV4 from '../BvsTableV4';
import BvsModal from '~/components/BvsModal';
import BvsDropdown from '~/components/BvsDropdown';
import BvsButton from '~/components/BvsButton';
import BvsInputCurrency from '../BvsInputCurrency';
import { FontAwesome } from 'react-web-vector-icons';
import ModalBenefitDetail from '~/pages/Benefit/ModalDetail';

export default function BvsSearchBenefits({ visible, onClose }) {
  const { search_benefits, filter } = useSelector(
    (state) => state.filterSearchBenefits
  );
  const dispatch = useDispatch();
  const [benefits, setBenefits] = useState([]);
  const [list, setList] = useState([]);

  const [benefitGroup, setBenefitGroup] = useState({
    loading: false,
    options: [],
  });

  const [region, setRegion] = useState({
    loading: false,
    options: [],
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [benefitInfo, setBenefitInfo] = useState(null);

  const columns = [
    {
      key: 'code',
      label: 'Código',
      sort: true,
    },
    {
      key: 'name',
      label: 'Descrição',
      sort: true,
      width: '100%',
      content: (row) => row.name.substring(0, 95),
    },
    {
      key: 'unit_cost',
      label: 'Unitário',
      sort: true,
      content: (row) => toMoney(Number(row?.unit_cost)),
    },
  ];

  const setSearch = (key, value) => {
    dispatch(
      updateSearchBenefits({
        filter,
        search_benefits: { ...search_benefits, [key]: value },
      })
    );
  };

  const getBenefit = async () => {
    dispatch(showLoading());
    try {
      const { data } = await api.get('/benefits');
      if (data.error || data.message) throw data;
      setBenefits(data);
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="houve um erro ao Requisitar dados para seleção."
          error={err}
        />
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const getBenefitGroup = async () => {
    setBenefitGroup({ ...benefitGroup, loading: true });
    let options = [];
    try {
      const { data } = await api.get('/benefitGroup');
      if (data.error || data.message) throw data;
      options = data.map((d) => ({ title: d.name, value: d.id }));
      setBenefitGroup({ ...benefitGroup, options });
    } catch (error) {
      setBenefitGroup({ ...benefitGroup, options });
    } finally {
      setBenefitGroup({ ...benefitGroup, options, loading: false });
    }
  };

  const getRegions = async () => {
    setRegion({ ...benefitGroup, loading: true });
    let options = [];
    try {
      const { data } = await api.get('/regions');
      if (data.error || data.message) throw data;
      options = data.map((d) => ({ title: d.name, value: d.id }));
      setRegion({ ...benefitGroup, options });
    } catch (error) {
      setRegion({ ...benefitGroup, options });
    } finally {
      setRegion({ ...benefitGroup, options, loading: false });
    }
  };

  const updateList = () => {
    let filtered = benefits;
    if (search_benefits?.region_id)
      filtered = filtered.filter(
        (i) => i.region_id === search_benefits?.region_id
      );
    if (search_benefits?.benefit_group_id)
      filtered = filtered.filter(
        (i) => i.benefit_group_id === search_benefits?.benefit_group_id
      );

    if (Number(search_benefits?.unit_cost) > 0) {
      filtered = filtered.filter(
        (i) => Number(i.unit_cost) === search_benefits?.unit_cost
      );
    }

    setList(filtered);
  };

  useEffect(() => {
    getBenefit();
    getRegions();
    getBenefitGroup();
  }, []);

  useEffect(() => {
    updateList();
  }, [
    search_benefits.region_id,
    search_benefits.benefit_group_id,
    benefits,
    search_benefits.unit_cost,
  ]);

  const onDetail = async (benefitId) => {
    // Busco os dados
    try {
      const { data } = await api.get(`/benefits/${benefitId}`);
      if (data.error || data.message) throw data;
      setModalVisible(true);
      setBenefitInfo({
        ...data,
        region: data?.region?.name,
        benefitGroup: data?.benefitGroup?.name,
      });
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Falha ao buscar dados do Beneficio."
        />
      );
    }
  };

  return (
    <>
      <BvsModal
        width="1000px"
        visible={visible}
        onClose={() => {
          onClose && onClose();
        }}
        footer={() => (
          <div className="row justify-content-end">
            <div>
              <BvsButton
                title="Fechar"
                onClick={() => onClose && onClose()}
                fullWidth
              />
            </div>
          </div>
        )}
      >
        <div className="row align-items-center justify-content-end">
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
            <BvsDropdown
              title="Selecione um grupo..."
              name="benefit_group_id"
              label="Grupo de Beneficios"
              value={search_benefits.benefit_group_id}
              onSelectedItem={(value) => setSearch('benefit_group_id', value)}
              loading={benefitGroup.loading}
              options={benefitGroup.options}
            />
          </div>
          <div className="col-xs-12 col-sm-5 col-md-4 col-lg-4 p-3">
            <BvsDropdown
              title="Selecione um grupo..."
              name="region_id"
              label="Região"
              value={search_benefits.region_id}
              onSelectedItem={(value) => setSearch('region_id', value)}
              loading={region.loading}
              options={region.options}
            />
          </div>
          <div className="col-xs-12 col-sm-5 col-md-3 col-lg-3 p-3 mt-3">
            <BvsInputCurrency
              name="fee"
              label="Tarifa (R$)"
              currency="BRL"
              value={search_benefits.unit_cost}
              onChange={(_, value) => setSearch('unit_cost', value)}
            />
          </div>
          <div className="col align-self-end">
            <div className="row justify-content-end p-3">
              <BvsButton
                className="p-3"
                icon="fa fa-filter"
                title="Limpar filtros"
                type="button"
                onClick={() => dispatch(resetSearchBenefits())}
              />
            </div>
          </div>
        </div>

        <BvsTableV4
          tableKey="benefits"
          columns={columns}
          rows={list}
          onRefresh={() => updateList()}
          onClickRow={(row) => {
            onClose && onClose(row);
          }}
          actions={[
            (row) => (
              <>
                <span data-tip="Detalhes">
                  <BvsButton
                    type="button"
                    className="btn btn-xs"
                    customStyle={{ marginRight: '5px' }}
                    icon={
                      <FontAwesome
                        name="credit-card-alt"
                        color="#fff"
                        size={12}
                      />
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      onDetail(row.id);
                    }}
                  />
                </span>
              </>
            ),
          ]}
        />
      </BvsModal>
      {modalVisible && (
        <ModalBenefitDetail
          visible
          benefitInfo={benefitInfo}
          onClose={() => {
            setModalVisible(false);
          }}
        />
      )}
    </>
  );
}
