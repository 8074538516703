/* eslint-disable no-underscore-dangle */
import produce from 'immer';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { mask as FnMask } from 'remask';

import BvsToastResult from '~/components/BvsToastResult';
import api from '~/services/api';
import { setEnterpriseActive } from '~/store/modules/enterpriseActive/actions';
import { updateEnterprise } from '~/store/modules/forms/FormEnterprise/actions';
import { STATUS } from '~/system/enum';

const useEnterprise = ({ enterpriseId = null, updateFees, fees }) => {
  const history = useHistory();
  const { form, enterprise } = useSelector((state) => state.formEnterprise);
  const dispatch = useDispatch();
  const [termsVisible, toggleTerms] = useState(false);
  const [agree, setAgree] = useState(false);

  const onPopulate = async () => {
    if (!enterpriseId) return;
    dispatch(
      updateEnterprise({
        form: { ...form },
      })
    );
    try {
      const { data } = await api.get(`/enterprises/${enterpriseId}`);
      if (data.error || data.message) throw data;
      let enterpriseFounded = {
        email: data?.email,
        social_name: data?.social_name,
        fantasy_name: data?.fantasy_name,
        cnpj: data?.cnpj,
        state_registration: data?.state_registration,
        municipal_registration: data?.municipal_registration,
        tax_general_manager: Number(data?.tax_general_manager),
        other_cost: Number(data?.other_cost),
        delivery_cost: Number(data?.delivery_cost),
        discount: Number(data?.discount),
        delivery_day: data?.delivery_day,
        manager_account: data?.manager_account,
        new_business: data?.new_business,
        zipcode: FnMask(data?.zipcode, ['99999-999']),
        state: data?.state,
        ibge: data?.ibge,
        city: data?.city,
        street: data?.street,
        number: data?.number,
        district: data?.district,
        complement: data?.complement,
        reference: data?.reference,
        owner_name: data?.shippingAddress?.[0]?.firstResponsible?.name,
        owner_phone: data?.shippingAddress?.[0]?.firstResponsible?.phone,
        owner_email: data?.shippingAddress?.[0]?.firstResponsible?.email,
        owner_job_id: data?.shippingAddress?.[0]?.firstResponsible?.job_id,
        owner_department_id:
          data.shippingAddress?.[0]?.firstResponsible?.department_id,
        branch_activity_id: data?.branchActivity?.id,
        fees: (data?.fees ?? []).map((fee) => ({
          id: fee.benefitGroupId,
          name: fee.benefitGroupName,
          cost: Number(fee.cost) || 0.0,
          percentage: Number(fee.percentage) || 0.0,
          percent: Number(fee.percentage) > 0,
        })),
        responsible_enterprise_id: data?.responsible?.id,
      };
      dispatch(updateEnterprise({ enterprise: enterpriseFounded }));
      updateFees &&
        updateFees(
          produce(fees, (draft) => {
            draft = enterpriseFounded.fees;
            return draft;
          })
        );
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          message={
            err?.message ||
            err?.error?.message ||
            'Não foi possivel atualizar a lista neste momento.'
          }
        />
      );
    } finally {
      dispatch(
        updateEnterprise({
          form: { ...form, saving: false },
        })
      );
    }
  };

  const onSave = async (e) => {
    e.preventDefault();
    try {
      dispatch(
        updateEnterprise({
          form: { ...form, saving: true },
          enterprise,
        })
      );

      const { data } = await api[enterpriseId ? 'put' : 'post'](
        `/enterprises${enterpriseId ? `/${enterpriseId}` : ``}`,
        {
          enterprise: {
            social_name: enterprise.social_name,
            fantasy_name: enterprise.fantasy_name,
            cnpj: enterprise.cnpj.replace(/[.]|[/]|[,]|[-]/g, ''),
            municipal_registration: enterprise.municipal_registration,
            state_registration: enterprise.state_registration,
            email: enterprise.email,
            manager_account: enterprise.manager_account,
            new_business: enterprise.new_business,
            zipcode: enterprise.zipcode.replace(/[.]|[/]|[,]|[-]/g, ''),
            state: enterprise.state,
            city: enterprise.city,
            district: enterprise.district,
            street: enterprise.street,
            number: enterprise.number,
            complement: enterprise.complement,
            ibge: enterprise.ibge,
            tax_general_manager: enterprise.tax_general_manager ?? 0,
            other_cost: enterprise.other_cost,
            delivery_cost: enterprise.delivery_cost,
            discount: enterprise.discount,
            delivery_day: enterprise.delivery_day,
            reference: enterprise.reference,
            branch_activity_id: enterprise.branch_activity_id,
            status: STATUS.ATIVO,
          },
          tax: fees.map((i) => ({
            benefit_group_id: i.id,
            cost: i.cost,
            percentage: i.percentage,
            percent: i.percent,
          })),
          owner: {
            name: enterprise.owner_name,
            email: enterprise.owner_email,
            phone: enterprise.owner_phone,
            job_id: enterprise.owner_job_id,
            department_id: enterprise.owner_department_id,
          },
          responsible_enterprise_id: enterprise.responsible_enterprise_id,
        }
      );

      if (data.error || data.message) throw data.error;
      !enterpriseId && dispatch(setEnterpriseActive(data.id));
      dispatch(
        updateEnterprise({
          form: { ...form, saving: false },
        })
      );
      toast.success(<BvsToastResult message="Alterado com sucesso." />);
      enterpriseId ? history.push(`/home`) : history.push('/departments');
    } catch (err) {
      dispatch(
        updateEnterprise({
          form: { ...form, saving: false },
        })
      );
      toast.error(
        <BvsToastResult
          type="error"
          message={
            err?.message ||
            err?.error?.message ||
            'Não foi possivel salvar o registro.'
          }
        />
      );
    }
  };

  const toggleAgree = (value) => {
    setAgree(value);
    toggleTerms(!termsVisible);
  };

  const goToAddress = () => {
    history.push(`/enterprise/${enterpriseId}/shippingAddress`);
  };

  return {
    agree,
    toggleAgree,
    toggleTerms,
    termsVisible,
    goToAddress,
    onPopulate,
    onSave,
  };
};

export default useEnterprise;
