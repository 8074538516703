/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import BvsEnterpriseSelector from '~/components/BvsEnterpriseSelector';
import { SuperHeaderWrapper } from './style';

export default function SuperHeader() {
  return (
    <SuperHeaderWrapper>
      <BvsEnterpriseSelector />
    </SuperHeaderWrapper>
  );
}
