/* eslint-disable import/order */
/* eslint-disable no-param-reassign */

import React from 'react';

import BvsButton from '~/components/BvsButton';
import BvsContainer from '~/components/BvsContainer';
import BvsForm from '~/components/BvsForm';
import BvsPanel from '~/components/BvsPanel';
import SubHeader from '~/components/BvsSubHeader';
import { USER_TYPE } from '~/system/enum';
import BvsAdmButton from '~/components/BvsAdmButton';
import useForm from './hooks/useForm';
import useChangePassword from '~/pages/ChangePassword/hooks/useChangePassword';
import BvsInputPassword from '~/components/BvsInputPassword';
import { useSelector } from 'react-redux';

const ChangePassword = ({ match }) => {
  const { change_password, form } = useSelector(
    (state) => state.formChangePassword
  );
  const { onFieldChange } = useForm();
  const { user } = useSelector((state) => state.auth);
  const { onSave } = useChangePassword();

  return (
    <>
      <SubHeader title="Usuário" subtitle="Alteração de senha" />
      <BvsContainer>
        {user?.type === USER_TYPE.ADMIN && <BvsAdmButton />}
        <BvsPanel title="Redefina sua senha">
          <BvsForm onSubmit={onSave}>
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                <BvsInputPassword
                  name="current"
                  label="Senha Atual"
                  value={change_password.current}
                  onChange={(value) => onFieldChange('current', value)}
                  required
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                <BvsInputPassword
                  name="newPassword"
                  label="Nova Senha"
                  value={change_password.newPassword}
                  onChange={(value) => onFieldChange('newPassword', value)}
                  required
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                <BvsInputPassword
                  name="confirmPassword"
                  label="Confirmar Nova Senha"
                  value={change_password.confirmPassword}
                  onChange={(value) => onFieldChange('confirmPassword', value)}
                  required
                />
              </div>
            </div>
            <div className="row justify-content-end p-3">
              <BvsButton
                type="submit"
                title="Alterar"
                icon="fa fa-save"
                customStyle={{ marginTop: '30px', float: 'right' }}
                disabled={form.saving}
              />
            </div>
          </BvsForm>
        </BvsPanel>
      </BvsContainer>
    </>
  );
};

export default ChangePassword;
