import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/modules/forms/FormDepartment/actions';

const useForm = () => {
  const dispatch = useDispatch();
  const { department, form } = useSelector((state) => state.formDepartment);

  const onFieldChange = (key, value) => {
    dispatch(
      actions.updateDepartment({
        form: { ...form },
        department: {
          ...department,
          [key]: value,
        },
      })
    );
  };

  const clean = () => {
    dispatch(actions.resetDepartment());
  };

  return { onFieldChange, clean };
};

export default useForm;
