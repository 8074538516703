import styled from 'styled-components';

const Dropdown = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  background: #fff;
  position: relative;
  flex-wrap: wrap;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : '')};
`;
const DropdownHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 36px;
  height: auto;
  background-color: ${(props) =>
    props.loading || props.disabled ? '#f9f9f9' : '#fff'};
  border: 2px solid ${(props) => (props.disabled ? '#c0392b' : '#beddcd')};
  border-radius: 0;
  justify-content: space-between;
  padding: 0 15px;
  align-items: center;
  clear: both;
`;
const DropdownHeaderTitle = styled.div`
  display: block;
  flex-direction: column;
  padding-left: 0;
  padding-right: 5px;
  color: ${(props) => (props.disabled ? '#767676' : '#323232')};
  font-weight: bold;
  flex-wrap: nowrap;
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  & + i {
    display: inline-flex;
    flex-direction: column;
  }
`;
const DropdownList = styled.ul`
  position: absolute;
  background: #fff;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  color: #beddcd;
  box-sizing: border-box;
  border-left: 2px solid #beddcd;
  border-right: 2px solid #beddcd;
  border-bottom: 2px solid #beddcd;
  z-index: 2;
  height: auto;
  max-height: 200px;
  /* min-height: 50%; */
  /* height: 100%; */
  overflow-y: auto;
  overflow-x: hidden;
  top: ${({ hasLabel }) => (hasLabel ? '61px' : '36px')};
`;
const DropdownListItem = styled.li`
  background: #fff;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s;
  padding: 5px 15px;
  position: ${(props) => (props.search ? 'sticky' : 'relative')} !important;
  &.selected {
    background-color: #beddcd;
    color: #fff;
    transition: all 0.2s;
  }
  &:hover {
    background-color: #beddcd;
    color: #fff;
    transition: all 0.2s;
  }
`;
const DropdownLabel = styled.div`
  width: 100%;
  height: 25px;
  margin-right: 15px;
  font-weight: bold;
  color: #616161;
`;

const DropdownLoading = styled.div`
  position: absolute;
  top: 36px;
  right: 35px;
`;

export {
  Dropdown,
  DropdownHeader,
  DropdownHeaderTitle,
  DropdownList,
  DropdownListItem,
  DropdownLabel,
  DropdownLoading,
};
