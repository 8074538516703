const { useState, useEffect } = require('react');
const { getStatus } = require('~/system/convert');
const { STATUS } = require('~/system/enum');

const useStatus = () => {
  const [status, setStatus] = useState({
    options: [],
    loading: false,
  });

  useEffect(() => {
    let options = Object.keys(STATUS).map((i) => ({
      value: STATUS[i],
      title: getStatus(STATUS[i]),
    }));
    options.unshift({ title: 'Nenhum', value: null });
    setStatus({
      options,
      loading: false,
    });
  }, []);
  return {
    status,
  };
};

export default useStatus;
