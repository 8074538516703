/* eslint-disable no-shadow */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BvsButton from '~/components/BvsButton';
import BvsModal from '~/components/BvsModal';
import { announcements } from '~/store/modules/app/actions';
import AnnouncementCartaoBom280322 from './@CartaoBom-28-03-2022';
import { Buffer } from 'buffer';

const Announcements = ({ announcement }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const {
    announcements: { components },
  } = useSelector((state) => state.app);
  const selector = {
    '@cartao-bom/28-03-2022': AnnouncementCartaoBom280322,
  };
  const setComponent = (component, state) => {
    dispatch(
      announcements('update', {
        components: {
          ...components,
          [component]: state,
        },
      })
    );
  };

  useEffect(() => {
    let announcements = localStorage.getItem(
      `@benvs/announcements/${Buffer.from(
        user.id + user.hash,
        'base64'
      ).toString('base64')}`
    );
    if (!announcements) {
      setComponent('visible', true);
    } else {
      announcements = JSON.parse(announcements);
      if (!announcements.find((item) => item === announcement)) {
        setComponent('visible', true);
      }
    }
  }, []);
  return (
    <BvsModal
      visible={components?.visible}
      onClose={() => {
        setComponent('visible', false);
      }}
      footer={() => (
        <div className="row d-flex justify-content-end">
          <div className=" text-nowrap">
            <BvsButton
              title="Fechar"
              onClick={() => {
                setComponent('visible', false);
              }}
            />
          </div>
          <div className=" text-nowrap ml-2">
            <BvsButton
              title="Marcar como lido"
              onClick={() => {
                setComponent('visible', false);
                let announcementsOld = [];
                let announcements = localStorage.getItem(
                  `@benvs/announcements/${Buffer.from(
                    user.id + user.hash,
                    'base64'
                  ).toString('base64')}`
                );
                if (announcements) {
                  announcements = JSON.parse(announcements);
                  announcementsOld = [...announcements, announcement];
                } else {
                  announcementsOld.push(announcement);
                }
                localStorage.setItem(
                  `@benvs/announcements/${Buffer.from(
                    user.id + user.hash,
                    'base64'
                  ).toString('base64')}`,
                  JSON.stringify(announcementsOld)
                );
              }}
            />
          </div>
        </div>
      )}
    >
      <div className="container">
        <div className="row">{selector[announcement]()}</div>
      </div>
    </BvsModal>
  );
};
export default Announcements;
