/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  components: {
    createDepartment: false,
  },
  form: {
    saving: false,
    disabled: false,
  },
  employees: {
    registry: undefined,
    name: undefined,
    cpf: undefined,
    rg: undefined,
    emissor: null,
    mother_name: undefined,
    birthday: null,
    job_id: null,
    department_id: null,
    home_state: undefined,
    home_city: undefined,
    home_district: undefined,
    home_street: undefined,
    home_number: undefined,
    home_complement: undefined,
    home_zipcode: undefined,
    business_state: undefined,
    business_city: undefined,
    business_district: undefined,
    business_street: undefined,
    business_number: undefined,
    business_complement: undefined,
    business_zipcode: undefined,
    shipping_address_id: null,
    admission_date: undefined,
    salary: undefined,
    email: undefined,
    phone: undefined,
    gender: null,
    bank: undefined,
    agency: undefined,
    agency_digit: undefined,
    account: undefined,
    account_digit: undefined,
    account_type: null,
    status: null,
    workday_id: null,
    home_ibge: undefined,
    business_ibge: undefined,
    demission_date: undefined,
    avatar: undefined,
  },
};

function formEmployees(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_EMPLOYEES: {
      return produce(state, (draft) => {
        draft = { ...draft, ...action.payload };
        return draft;
      });
    }
    case types.RESET_EMPLOYEES: {
      return produce(state, (draft) => {
        draft.employees = {
          ...INITIAL_STATE.employees,
          birthday: '',
          admission_date: '',
          demission_date: '',
        };
        return draft;
      });
    }
    default:
      return state;
  }
}

export default formEmployees;
