/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  form: {
    saving: false,
    disabled: false,
  },
  import_registers: {
    identification: '',
  },
};

function formImport(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_IMPORT: {
      return produce(state, (draft) => {
        draft = { ...draft, ...action.payload };
        return draft;
      });
    }
    case types.RESET_IMPORT: {
      return produce(state, (draft) => {
        draft.import_registers = INITIAL_STATE.import_registers;
        return draft;
      });
    }
    default:
      return state;
  }
}

export default formImport;
