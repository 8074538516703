/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
import types from './types';

export function updateEmployees(payload) {
  return {
    type: types.UPDATE_EMPLOYEES,
    payload,
  };
}

export function resetEmployees() {
  return {
    type: types.RESET_EMPLOYEES,
  };
}
