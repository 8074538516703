import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';
import api from '~/services/api';
import { updateJob } from '~/store/modules/forms/FormJob/actions';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';
import { STATUS } from '~/system/enum';
import useForm from './useForm';

const useJobController = ({ jobId: pJobId, onSaved = null }) => {
  const dispatch = useDispatch();
  const { clean } = useForm();
  const { job } = useSelector((state) => state.formJob);

  const onSave = async (e) => {
    e.preventDefault();
    dispatch(showLoading());
    try {
      dispatch(
        updateJob({
          ...job,
          form: {
            saving: true,
          },
        })
      );

      const { data } = await api[pJobId ? 'put' : 'post'](
        `/jobs${pJobId ? `/${pJobId}` : ''}`,
        {
          code: job.code,
          name: job.name,
        }
      );

      if (data.error || data.message) throw data.error;
      toast.success(
        <BvsToastResult message={`'${data.name}' salvo com sucesso!`} />
      );
      clean();
      onSaved && onSaved(data);
    } catch (error) {
      toast.error(
        <BvsToastResult
          type="error"
          err={error}
          message="Falha ao salvar registro"
        />
      );
    } finally {
      dispatch(
        updateJob({
          form: {
            saving: false,
          },
        })
      );
      dispatch(hideLoading());
    }
  };

  const changeStatus = async ({ id: jobId, status_enum: status }, cb) => {
    try {
      const { data } = await api.put(`/jobs/${jobId}/status`);
      if (data.error || data?.message) throw data;
      toast.success(
        <BvsToastResult
          message={
            status === STATUS.ATIVO
              ? "Alterado para 'Inativo' com sucesso"
              : "Alterado para 'Ativo' com sucesso."
          }
        />
      );
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Falha ao atualizar o status"
        />
      );
    } finally {
      cb && cb();
    }
  };

  const deleteJob = async ({ id: jobId }, cb) => {
    try {
      const { data } = await api.delete(`/jobs/${jobId}`);
      if (data.error || data.message) throw data;
      toast.success(
        <BvsToastResult message={`'${data.name}' excluido com sucesso!`} />
      );
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Falha ao excluir registro"
        />
      );
    } finally {
      cb && cb();
    }
  };

  return {
    onSave,
    changeStatus,
    deleteJob,
  };
};

export default useJobController;
