/* eslint-disable react/void-dom-elements-no-children */
import React, { useEffect, useState } from 'react';
import BvsInput from '~/components/BvsInput';
import BvsToggle from '~/components/BvsToggle';

const BvsInputCheck = ({ label, value, name, onChange }) => {
  const [block, setBlock] = useState(false);

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <BvsToggle
        orientation="horizontal"
        value={block}
        onChange={() => setBlock(!block)}
        customStyle={{ paddingBottom: '0px' }}
      />
      <BvsInput
        name={name}
        label={label}
        value={value}
        onChange={(val) => onChange(name, val)}
        disabled={!block}
        width
      />
    </div>
  );
};

export default BvsInputCheck;
