/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import uuid from 'uuid-random';
import { Input, InputContainer, Label } from './style';

const BvsInputPassword = ({
  placeholder,
  label,
  name,
  onChange,
  disabled = false,
  required = false,
  value = '',
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const id = `${uuid()}_${name}`;
  const floatingEffect = () => {
    let el = document.getElementById(id);
    if (el) {
      el.value === '' &&
        el.classList.contains('hasFilled') &&
        el.classList.remove('hasFilled');
      el.value &&
        !el.classList.contains('hasFilled') &&
        el.classList.add('hasFilled');
    }
  };
  const toggle = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    floatingEffect();
  }, [value]);
  return (
    <InputContainer>
      <Input
        id={id}
        name={name}
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          floatingEffect();
          if (onChange) onChange(e.target.value);
        }}
        required={required}
        disabled={disabled}
      />
      {label && (
        <Label htmlFor={id} isRequired={required} disabled={disabled}>
          {label}
          {!disabled && required && '*'}
        </Label>
      )}
      {!disabled && ['', null, undefined].includes(String(value).trim()) && (
        <Label isRequired={required} error>
          Campo obrigatório
          {required && '*'}
        </Label>
      )}
      <i
        style={{
          position: 'absolute',
          right: 10,
          top: 10,
          fontSize: '.98rem',
          cursor: 'pointer',
        }}
        className={`fa fa-eye${showPassword ? '-slash' : ''}`}
        onClick={toggle}
      />
    </InputContainer>
  );
};

export default BvsInputPassword;
