/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { Content, BackgroundImage } from './style';
import BGImage from '../../../assets/icon.png';
import BvsShortcut from '../../../components/BvsShortcut';

export default ({ children }) => {
  return (
    <Content className="">
      {children}
      {/* <BvsShortcut
        data={[
          { title: 'Ocorrencias', route: '/ocorrencia' },
          { title: 'Novos Pedidos', route: '/novos-pedidos' },
          { title: 'Exportar Cadastros', route: '/exportar-cadastro' },
          { title: 'Mudar Senha', route: '/mudar-senha' },
        ]}
      /> */}
    </Content>
  );
};
