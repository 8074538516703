/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import useBenefitGroup from '~/hooks/useBenefitGroup';
import produce from 'immer';

const useFees = () => {
  const [fees, setFees] = useState([]);
  const { benefitGroup, fetch } = useBenefitGroup({
    loading: false,
    dropdown: false,
  });

  const loadFees = () => {
    setFees(
      benefitGroup.options.map((bg) => ({
        ...bg._original,
        cost: 0,
        percentage: 0,
        percent: false,
      }))
    );
  };

  const updateFees = (newFees) => {
    setFees(newFees);
  };

  const onChangeFee = (benefitGroupId, value, percentage) => {
    setFees(
      produce(fees, (draft) => {
        const item = draft.find((f) => f.id === benefitGroupId);
        if (item) {
          if (percentage) item.percentage = value;
          else item.cost = value;
        }

        return draft;
      })
    );
  };

  const togglePercent = (fee) => {
    setFees(
      produce(fees, (draft) => {
        const item = draft.find((f) => f.id === fee);
        if (item) {
          item.percent = !item.percent;
          if (item.percent) item.cost = 0;
          else item.percentage = 0;
        }
        return draft;
      })
    );
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (benefitGroup.options.length > 0) loadFees();
  }, [benefitGroup.options]);

  return { fees, loadFees, updateFees, togglePercent, onChangeFee };
};

export default useFees;
