import styled from 'styled-components';

export const ModalWrapper = styled.div``;

export const ModalContainer = styled.div`
  background: #fff;
  max-width: ${(props) => (props.width ? props.width : '768px')};
  border-radius: 0px;
  margin: 0px auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  position: absolute;
  display: none;
  /* position: fixed; */
  color: black;
  left: 0;
  right: 0;
  overflow: hidden;
  height: auto !important;
  z-index: 110 !important;
`;

export const ModalHeader = styled.header`
  padding: 15px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 15px);
  margin: 0 auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
`;

export const HeaderTitle = styled.h3`
  font-weight: bold;
  font-size: 1.25em;
  padding: 0;
  margin: 0;
  vertical-align: middle;
`;

export const ModalBody = styled.div`
  padding: 15px 25px 0;
  overflow: auto;
  margin: 10px auto;
`;
export const ModalFooter = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
`;
export const HeaderAction = styled.div``;

export const ModalBg = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  left: 0;
  top: 0;
  z-index: 100;
`;
export const Modal = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 105;
`;
