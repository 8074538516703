/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';

export const BvsInputFile = styled.input``;

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

export const InputWraper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 12px 10px;
  user-select: none;
  cursor: pointer;
  border-bottom: 2px solid #9e9e9e;
  :hover {
    border-bottom: 2px solid #c0392b;
  }
  & i {
    padding-right: 15px;
  }
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  border-bottom: 2px solid #9e9e9e;
`;
