/* eslint-disable default-case */
import {
  IMPORT_STATUS,
  STATUS,
  USER_TYPE,
  STATUS_FINANCIAL,
  STATUS_OPERATIONAL,
  POSITION_SHORTCUTS,
  URL_SHORTCUTS,
  STATUS_OCCURRENCE,
  ORDER_LIST,
  ADDRESS_TYPE,
  STATUS_EMPLOYEES,
  GENDER_EMPLOYEES,
  TYPE_ACCOUNT_BANK,
  DEFAULT_BENEFIT_GROUP,
  STATUS_CARD,
} from './enum';

const getStatus = (status) => {
  let enumConvert = {
    [STATUS.ATIVO]: 'Ativo',
    [STATUS.CANCELADO]: 'Cancelado',
    [STATUS.INATIVO]: 'Inativo',
  };
  return enumConvert[status];
};

const getImportStatus = (status) => {
  let enumConvert = {
    [IMPORT_STATUS.PENDING]: 'Pendente',
    [IMPORT_STATUS.SUCCESS]: 'Importado',
    [IMPORT_STATUS.FAILED]: 'Arquivo Inválido',
  };
  return enumConvert[status];
};

const getUserType = (type) => {
  let enumConvert = {
    [USER_TYPE.ADMIN]: 'Administrador',
    [USER_TYPE.USER]: 'Usuário',
  };
  return enumConvert[type];
};

const getStatusFinancial = (type) => {
  let enumConvert = {
    [STATUS_FINANCIAL.PENDING]: {
      title: 'Aguardando pagamento',
      tooltip: 'Aguardando pagamento',
      color: '#e6e6e6',
    },
    [STATUS_FINANCIAL.PAYED]: {
      title: 'Pago',
      tooltip: 'Pago',
      color: '#27ae60',
    },
    [STATUS_FINANCIAL.CANCELLED]: {
      title: 'Cancelado',
      tooltip: 'Cancelado',
      color: '#7f8c8d',
    },
  };
  return enumConvert[type];
};

const getStatusOperational = (type) => {
  let enumConvert = {
    [STATUS_OPERATIONAL.PENDING]: {
      title: 'Aguardando pagamento',
      tooltip: 'Aguardando pagamento',
      color: '#e6e6e6',
    },
    [STATUS_OPERATIONAL.AVAILABLE_BUY]: {
      title: 'Liberado para a compra',
      tooltip: 'Liberado para a compra',
      color: '#3498db',
    },
    [STATUS_OPERATIONAL.PROBLEM_TO_BUY]: {
      title: 'Dificuldade com a compra. Estamos tratando o caso',
      tooltip: 'Dificuldade com a compra. Estamos tratando o caso',
      color: '#f1c40f',
    },
    [STATUS_OPERATIONAL.BUYED_TO_OPERATOR]: {
      title: 'Comprado na Operadora',
      tooltip: 'Comprado na Operadora',
      color: '#2980b9',
    },
    [STATUS_OPERATIONAL.PAYED_TO_OPERATOR]: {
      title: 'Pago para a Operadora',
      tooltip: 'Pago para a Operadora',
      color: '#2ecc71',
    },
    [STATUS_OPERATIONAL.CREDIT_AVAILABLE_OPERATOR]: {
      title: 'Créditos Liberados na Operadora',
      tooltip: 'Créditos Liberados na Operadora',
      color: '#27ae60',
    },
    [STATUS_OPERATIONAL.CANCELLED]: {
      title: 'Cancelado',
      tooltip: 'Cancelado',
      color: '#7f8c8d',
    },
    [STATUS_OPERATIONAL.BENEFIT_AVAILABLE]: {
      title: 'Benefício Liberado em Conta',
      tooltip: 'Benefício Liberado em Conta',
      color: '#8e44ad',
    },
  };
  return enumConvert[type];
};

const getPositionShortcuts = (type) => {
  let enumConvert = {
    [POSITION_SHORTCUTS.POSICAO_UM]: 'Posição 1',
    [POSITION_SHORTCUTS.POSICAO_DOIS]: 'Posição 2',
    [POSITION_SHORTCUTS.POSICAO_TRES]: 'Posição 3',
    [POSITION_SHORTCUTS.POSICAO_QUATRO]: 'Posição 4',
  };
  return enumConvert[type];
};

const getUrlShortcuts = (type) => {
  let enumConvert = {
    [URL_SHORTCUTS.PEDIDO_NOVO]: '/createOrders',
    [URL_SHORTCUTS.PEDIDO_NOVO]: '/changePassword',
    [URL_SHORTCUTS.PEDIDO_NOVO]: '/importGeneralRegisters',
    [URL_SHORTCUTS.PEDIDO_NOVO]: 'exportDownload',
    [URL_SHORTCUTS.PEDIDO_NOVO]: '/enterprises',
    [URL_SHORTCUTS.PEDIDO_NOVO]: '/administrativeCard',
    [URL_SHORTCUTS.PEDIDO_NOVO]: '/occurrence',
    [URL_SHORTCUTS.PEDIDO_NOVO]: '/departments',
    [URL_SHORTCUTS.PEDIDO_NOVO]: '/job',
  };
  return enumConvert[type];
};

const getStatusOccurrence = (status) => {
  let enumConvert = {
    [STATUS_OCCURRENCE.ABERTO]: 'Aberto',
    [STATUS_OCCURRENCE.ANDAMENTO]: 'Andamento',
    [STATUS_OCCURRENCE.FINALIZADO]: 'Finalizado',
    [STATUS_OCCURRENCE.CANCELADO]: 'Cancelado',
  };
  return enumConvert[status];
};

const getAddressType = (type) => {
  let enumAddressType = {
    [ADDRESS_TYPE.COBRANCA]: 'Cobrança',
    [ADDRESS_TYPE.ENTREGA]: 'Entrega',
    [ADDRESS_TYPE.FATURAMENTO]: 'Faturamento',
  };

  return enumAddressType[type] || 'Não Identificado';
};

const getEmployeeStatus = (status) => {
  let enumConvert = {
    [STATUS_EMPLOYEES.ATIVO]: 'Ativo',
    [STATUS_EMPLOYEES.CANCELADO]: 'Cancelado',
    [STATUS_EMPLOYEES.DEMITIDO]: 'Demitido',
    [STATUS_EMPLOYEES.EXCLUIDO]: 'Excluido',
    [STATUS_EMPLOYEES.INATIVO]: 'Inativo',
  };

  return enumConvert[status];
};

const getGender = (gender) => {
  let enumConvert = {
    [GENDER_EMPLOYEES.MALE]: 'Masculino',
    [GENDER_EMPLOYEES.FEMALE]: 'Feminino',
    [GENDER_EMPLOYEES.OTHER]: 'Prefiro não dizer',
  };

  return enumConvert[gender];
};

const getTypeBankAccount = (type) => {
  const enumConvert = {
    [TYPE_ACCOUNT_BANK.CORRENTE]: 'Conta Corrente',
    [TYPE_ACCOUNT_BANK.POUPANCA]: 'Poupança',
  };

  return enumConvert[type];
};

const getNameTax = (id, name) => {
  const enumConvert = {
    [DEFAULT_BENEFIT_GROUP.VT]: 'Gestão VT',
    [DEFAULT_BENEFIT_GROUP.BR]: 'Gestão BR',
    [DEFAULT_BENEFIT_GROUP.BC]: 'Gestão BC',
    [DEFAULT_BENEFIT_GROUP.BA]: 'Gestão BA',
  };
  return enumConvert[id] ?? `Gestão - ${name}`;
};

const getOrderStatus = (type) => {
  let enumConvert = {
    [STATUS.ATIVO]: {
      title: 'Ativo',
      tooltip: '',
      color: '#27ae60',
    },
    [STATUS.INATIVO]: {
      title: 'Inativo',
      tooltip: 'Inativo',
      color: '#e74c3c',
    },
    [STATUS.CANCELADO]: {
      title: 'Cancelado',
      tooltip: 'Cancelado',
      color: '#7f8c8d',
    },
  };
  return enumConvert[type];
};

const getStatusCard = (status) => {
  let enumConvert = {
    [STATUS_CARD.ATIVO]: 'Ativo',
    [STATUS_CARD.INATIVO]: 'Inativo',
    [STATUS_CARD.CANCELADO]: 'Cancelado',
    [STATUS_CARD.BLOQUEADO]: 'Bloqueado',
    [STATUS_CARD.TODOS]: 'Todos',
  };

  return enumConvert[status];
};

const getStatusEmployee = (status) => {
  let enumConvert = {
    [STATUS_EMPLOYEES.ATIVO]: 'Ativo',
    [STATUS_EMPLOYEES.INATIVO]: 'Inativo',
    [STATUS_EMPLOYEES.CANCELADO]: 'Cancelado',
    [STATUS_EMPLOYEES.DEMITIDO]: 'Demitido',
    [STATUS_EMPLOYEES.EXCLUIDO]: 'Excluido',
  };
  return enumConvert[status];
};

export const getMonth = (month) => {
  switch (month.toLowerCase()) {
    case 'jan':
      return 'Janeiro';
    case 'fev':
      return 'Fevereiro';
    case 'mar':
      return 'Março';
    case 'abr':
      return 'Abril';
    case 'mai':
      return 'Maio';
    case 'jun':
      return 'Junho';
    case 'jul':
      return 'Julho';
    case 'ago':
      return 'Agosto';
    case 'set':
      return 'Setembro';
    case 'out':
      return 'Outubro';
    case 'nov':
      return 'Novembro';
    case 'dez':
      return 'Dezembro';
    default:
      return '';
  }
};

export {
  getStatus,
  getUserType,
  getUrlShortcuts,
  getImportStatus,
  getStatusFinancial,
  getStatusOccurrence,
  getStatusOperational,
  getPositionShortcuts,
  getAddressType,
  getEmployeeStatus,
  getGender,
  getTypeBankAccount,
  getNameTax,
  getOrderStatus,
  getStatusCard,
  getStatusEmployee,
};
