import React, { useEffect } from 'react';
import { mask as FnMask, unMask } from 'remask';
import uuid from 'uuid-random';
import { Input, InputContainer, Label } from './style';

const BvsInput = ({
  label,
  name,
  placeholder,
  mask = null,
  onChange,
  onChangeNative,
  onBlur = () => {},
  required = false,
  disabled = false,
  password = false,
  maxLength = '',
  value = '',
  width,
  type = 'text',
}) => {
  const id = `${uuid()}_${name}`;
  const interceptChange = (e) => {
    // e.persist();
    let val = e.target.value;
    floatingEffect();
    if (mask) handleMask(val);
    else if (onChange) {
      onChange(val);
    }
  };

  const handleMask = (val) => {
    let curValue = unMask(val);
    switch (mask) {
      case 'phone':
        onChange &&
          onChange(FnMask(curValue, ['(99) 9999-9999', '(99) 9 9999-9999']));
        break;
      case 'rg':
        onChange && onChange(FnMask(curValue, ['99.999.999-S']));
        break;
      case 'cpf/cnpj':
        onChange &&
          onChange(FnMask(curValue, ['999.999.999-99', '99.999.999/9999-99']));
        break;
      case 'cpf':
        onChange && onChange(FnMask(curValue, ['999.999.999-99']));
        break;
      case 'cep':
        onChange && onChange(FnMask(curValue, ['99999-999']));
        break;
      case 'date':
        onChange && onChange(FnMask(curValue, ['99/99/99', '99/99/9999']));
        break;
      default:
        onChange && onChange(FnMask(curValue, mask));
        break;
    }
  };

  const floatingEffect = () => {
    let el = document.getElementById(id);

    if (el) {
      el.value === '' &&
        type === 'text' &&
        el.classList.contains('hasFilled') &&
        el.classList.remove('hasFilled');
      el.value &&
        !el.classList.contains('hasFilled') &&
        el.classList.add('hasFilled');
      type !== 'text' &&
        !el.classList.contains('hasFilled') &&
        el.classList.add('hasFilled');
    }
  };

  useEffect(() => {
    floatingEffect();
  }, [value]);

  return (
    <InputContainer style={{ width: `${width ? '100%' : ''}` }}>
      <Input
        type={type ?? (!password ? 'text' : 'password')}
        id={id}
        name={name}
        // className="form-control"
        placeholder={placeholder}
        autoComplete="off"
        onChange={(e) =>
          (onChangeNative && onChangeNative(e)) || interceptChange(e)
        }
        onBlur={onBlur}
        pattern=".+"
        isRequired={disabled ? false : required}
        disabled={disabled}
        required={required}
        maxLength={maxLength}
        value={value}
      />
      {label && (
        <Label htmlFor={id} isRequired={required} disabled={disabled}>
          {label}
          {!disabled && required && '*'}
        </Label>
      )}
      {!disabled &&
        required &&
        ['', null, undefined].includes(String(value).trim()) && (
          <Label isRequired={required} error>
            Campo obrigatório
            {required && '*'}
          </Label>
        )}
    </InputContainer>
  );
};

export default BvsInput;
