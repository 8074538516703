import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  socket: null,
  listEmployee: {
    components: {
      showBenefit: false,
    },
  },
  listOrder: {
    components: {
      showConfirmUploadNfe: false,
    },
  },
  historicEmployee: {
    components: {
      modalVisible: false,
    },
    state: {
      employeeId: null,
      data: null,
      loading: true,
    },
  },
  employeeHistoricOfBenefit: {
    components: {
      modalVisible: false,
      loading: false,
    },
    state: {
      data: null,
    },
  },
  bvsJustification: {
    components: {
      visible: false,
    },
    state: {
      justification: '',
    },
  },
  announcements: {
    components: {
      visible: false,
    },
  },
};

function app(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_SOCKET: {
      return produce(state, (draft) => {
        draft = { ...draft, ...action.payload };
        return draft;
      });
    }
    case types.RESET_SOCKET: {
      return produce(state, (draft) => {
        draft.socket = INITIAL_STATE.socket;
        return draft;
      });
    }

    case types.UPDATE_LIST_EMPLOYEE: {
      return produce(state, (draft) => {
        draft.listEmployee = { ...draft.listEmployee, ...action.payload };
        return draft;
      });
    }

    case types.UPDATE_LIST_ORDER: {
      return produce(state, (draft) => {
        draft.listOrder = { ...draft.listOrder, ...action.payload };
        return draft;
      });
    }

    case types.HISTORIC_EMPLOYEE.UPDATE: {
      return produce(state, (draft) => {
        draft.historicEmployee = action.payload;

        return draft;
      });
    }

    case types.HISTORIC_EMPLOYEE.RESET: {
      return produce(state, (draft) => {
        draft.historicEmployee.components =
          INITIAL_STATE.historicEmployee.components;

        return draft;
      });
    }

    case types.EMPLOYEE_HISTORIC_OF_BENEFITS.UPDATE: {
      return produce(state, (draft) => {
        draft.employeeHistoricOfBenefit = {
          ...draft.employeeHistoricOfBenefit,
          ...action.payload,
        };
        return draft;
      });
    }

    case types.BVS_JUSTIFICATION.UPDATE: {
      return produce(state, (draft) => {
        draft.bvsJustification = {
          ...draft.bvsJustification,
          ...action.payload,
        };
        return draft;
      });
    }

    case types.BVS_JUSTIFICATION.RESET: {
      return produce(state, (draft) => {
        draft.bvsJustification = INITIAL_STATE.bvsJustification;
        return draft;
      });
    }
    case types.ANNOUNCEMENTS.UPDATE: {
      return produce(state, (draft) => {
        draft.announcements = {
          ...draft.announcements,
          ...action.payload,
        };
      });
    }
    default:
      return state;
  }
}

export default app;
