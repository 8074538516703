import { useState } from 'react';
import { useSelector } from 'react-redux';
import { PAGINATE } from '~/system/enum';

const usePagination = () => {
  const { pagination: paginationControl } = useSelector(
    (state) => state.orderDetail
  );
  const { order } = useSelector((state) => state.order);
  const totalPage = Math.ceil(order.employees.length / paginationControl.limit);
  const offset = (paginationControl.page - 1) * paginationControl.limit;

  const [pagination] = useState({
    loading: false,
    options: [
      { title: '10 por página', value: PAGINATE.PER_PAGE_10 },
      { title: '25 por página', value: PAGINATE.PER_PAGE_25 },
      { title: '50 por página', value: PAGINATE.PER_PAGE_50 },
      { title: '100 por página', value: PAGINATE.PER_PAGE_100 },
      { title: '1000 por página', value: PAGINATE.PER_PAGE_1000 },
    ],
  });

  const onChangePage = () => {};

  const applyPagination = (data) => {
    return data.slice(
      offset,
      offset + paginationControl.limit > order.employees.length
        ? order.employees.length
        : offset + paginationControl.limit
    );
  };

  return {
    pagination,
    onChangePage,
    applyPagination,
  };
};

export default usePagination;
