/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import produce from 'immer';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import BvsButton from '~/components/BvsButton';
// import BvsDropdown from '~/components/BvsDropdown';
import BvsDropdownSearch from '~/components/BvsDropdownSearch';
import BvsForm from '~/components/BvsForm';
import BvsInputFile from '~/components/BvsInputFile';
import BvsModal from '~/components/BvsModal';
import BvsTableV4 from '~/components/BvsTableV4';
import BvsToastResult from '~/components/BvsToastResult';
import api from '~/services/api';
import { updateState as benefitUpdateState } from '~/store/modules/benefit/actions';
import { BENEFIT_GENERIC_STATUS } from '~/system/enum';

let didMount = false;
export const ModalLinkBenefitExternal = (props) => {
  const dispatch = useDispatch();
  const { '@module/generic': genericState } = useSelector(
    (state) => state.benefit
  );
  const onSave = () => {};

  const handleChange = (name, value) => {
    dispatch(
      benefitUpdateState({ module: '@module/generic', key: name, value })
    );
  };

  const importFile = async () => {
    dispatch(
      benefitUpdateState({
        module: '@module/generic',
        key: '@status',
        value: BENEFIT_GENERIC_STATUS.IMPORTING,
      })
    );

    if (!genericState['@form/file']) {
      return <BvsToastResult type="warn" message="Informe o arquivo" />;
    }

    toast.info(<BvsToastResult message="Processando o arquivo, aguarde..." />);

    let formData = new FormData();
    formData.append('file', genericState['@form/file']);
    const { data } = await api.post('/benefit/generic/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    toast.warn(
      <BvsToastResult
        type="warn"
        message="Arquivo lido com sucesso, agora vamos realizar o depara."
      />
    );

    dispatch(
      benefitUpdateState({
        module: '@module/generic',
        key: '@status',
        value: BENEFIT_GENERIC_STATUS.MAPPING,
      })
    );
    dispatch(
      benefitUpdateState({
        module: '@module/generic',
        key: '@data',
        value: data,
      })
    );
  };

  // const getBenefits = async () => {
  //   const { data } = await api.get('/benefits');
  //   dispatch(
  //     benefitUpdateState({
  //       module: '@module/generic',
  //       key: '@dropdownBenefits',
  //       value: data.map((i) => ({
  //         value: i.id,
  //         title: `${i.code} - ${i.name}`,
  //       })),
  //     })
  //   );
  // };

  // #region Controllers
  const columns = [
    {
      key: 'code',
      label: 'Código',
      sort: true,
    },
    {
      key: 'name',
      label: 'Beneficio',
    },
    {
      key: 'benvs_benefit',
      label: "Beneficio (Benv's)",
      sort: false,
      content: (row, ri) => (
        <>
          {JSON.stringify(row)}
          {/* teste:{row.code} */}
          <BvsDropdownSearch
            onSelect={(value) => {
              console.log('SELECIONEI !', value);
              dispatch(
                benefitUpdateState({
                  module: '@module/generic',
                  key: '@data',
                  value: produce(genericState?.['@data'], (draft) => {
                    let search = draft.find((i) => i.code === row.code);
                    if (search) search.benvs_benefit = value;
                    // draft[ri].benvs_benefit = value;
                    return draft;
                  }),
                })
              );
            }}
            route="/benefits"
            selected={row.benvs_benefit}
            render={(row) => ({
              value: row.id,
              title: `[${row.code}] ${row.name}`,
            })}
          />
        </>
        // <datalist style={{ width: '100%', border: '1px solid blue' }}>
        //   {genericState?.['@dropdownBenefits'].map((i) => (
        //     <option key={i.value} value={i.value}>
        //       {i.title}
        //     </option>
        //   ))}
        // </datalist>
        // <BvsDropdown options={genericState?.['@dropdownBenefits']} search />
      ),
    },
  ];

  const showImportButton =
    genericState?.['@form/file'] &&
    [BENEFIT_GENERIC_STATUS.INITIAL].includes(genericState?.['@status']);
  const showInitialForm = [BENEFIT_GENERIC_STATUS.INITIAL].includes(
    genericState?.['@status']
  );
  const showCompareTable =
    genericState?.['@status'] === BENEFIT_GENERIC_STATUS.MAPPING;

  // #endregion
  useEffect(() => {
    if (!didMount) {
      // getBenefits();
      didMount = true;
    }
  }, []);
  return (
    <BvsModal
      {...props}
      title="Vincular benefícios de outros sistemas"
      footer={() => (
        <div>
          {showImportButton && (
            <BvsButton
              title="Importar"
              onClick={importFile}
              style={{ marginLeft: 5, marginRight: 5 }}
            />
          )}
          <BvsButton title="Cancelar" onClick={props.onClose} />
        </div>
      )}
    >
      {showInitialForm && (
        <>
          <p>
            Selecione um arquivo <strong>(.xlsx)</strong> para começar fazer o
            depara.
          </p>

          <BvsForm onSubmit={onSave}>
            <div className="row">
              <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 px-3">
                <BvsInputFile
                  name="@form/file"
                  onChange={handleChange}
                  uri=""
                  value={genericState?.['@form/file']}
                />
              </div>
            </div>
          </BvsForm>
        </>
      )}
      {showCompareTable && (
        <>
          <p>Realize o depara entre os dados</p>
          <BvsTableV4
            columns={columns}
            rows={genericState?.['@data']}
            actions={[]}
          />
        </>
      )}
    </BvsModal>
  );
};
