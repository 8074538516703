/* eslint-disable import/prefer-default-export */
import types from './types';

export function updateOrder(payload) {
  return {
    type: types.UPDATE_ORDER,
    payload,
  };
}

export function resetOrder() {
  return {
    type: types.RESET_ORDER,
  };
}

export function resetDatesOrders() {
  return {
    type: types.RESET_DATE_ORDER,
  };
}
