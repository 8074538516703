import { useDispatch, useSelector } from 'react-redux';
import { resetJob, updateJob } from '~/store/modules/forms/FormJob/actions';

const useForm = () => {
  const dispatch = useDispatch();
  const { job, form } = useSelector((state) => state.formJob);

  const onFieldChange = (key, value) => {
    dispatch(
      updateJob({
        form: { ...form },
        job: {
          ...job,
          [key]: value,
        },
      })
    );
  };

  const clean = () => {
    dispatch(resetJob());
  };

  return { onFieldChange, clean };
};

export default useForm;
