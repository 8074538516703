/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';

export const Titulo = styled.p`
  font-size: 0.95rem;
  font-weight: bold;
  margin-left: auto;
  margin-top: 5px;
  margin-bottom: 5px;
`;
export const Data = styled.p`
  font-size: 0.95rem;
  margin-left: auto;
`;
export const Table = styled.table`
  line-height: 20px;
  width: 100%;
  font-size: 0.85rem;
`;
export const TableHead = styled.thead`
  font-weight: bold;
  font-size: 0.9rem;
`;
export const TableTH = styled.th`
  padding: 11px 0;
`;
export const TableTH2 = styled.th`
  background-color: rgba(169, 156, 156, 0.05);
  padding: 11px 0;
  width: 20%;
`;
export const TableTD = styled.td`
  padding: 11px 0;
  text-align: left;
  background-color: rgba(169, 156, 156, 0.05);
  border-bottom: 1px solid rgba(169, 156, 156, 0.5);
  border-top: 1px solid rgba(169, 156, 156, 0.5);
`;
export const TableTD2 = styled.td`
  padding: 11px 10px 11px 0px;
  font-weight: bold;
  text-align: right;
  border-top: 1.4px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1.4px solid rgba(0, 0, 0, 0.2);
`;
export const TableTD3 = styled.td`
  padding: 11px 10px 11px 0px;
  font-weight: bold;
  text-align: right;
  background-color: rgba(169, 156, 156, 0.05);
`;
