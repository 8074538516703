/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  form: {
    saving: false,
    disabled: false,
  },
  employeeBenefits: {
    registration: '',
    name: '',
    cpf: '',
    workday_id: null,
    benefit_id: null,
    status: null,
    month_quantity: '',
    daily_quantity: '',
    value_unity: '',
    card: '',
  },
};

function formEmployeeBenefits(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_EMPLOYEE_BENEFITS: {
      return produce(state, (draft) => {
        draft = { ...draft, ...action.payload };
        return draft;
      });
    }
    case types.RESET_EMPLOYEE_BENEFITS: {
      return produce(state, (draft) => {
        draft.employeeBenefits = INITIAL_STATE.employeeBenefits;
        return draft;
      });
    }
    default:
      return state;
  }
}

export default formEmployeeBenefits;
