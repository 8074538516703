/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetDatesOrders } from '~/store/modules/order/actions';
import { useHistory } from 'react-router-dom';
import { STATUS_FINANCIAL, STATUS_OPERATIONAL, USER_TYPE } from '~/system/enum';
import BvsContainer from '~/components/BvsContainer';
import BvsForm from '~/components/BvsForm';
import SubHeader from '~/components/BvsSubHeader';
import BvsInputCurrency from '~/components/BvsInputCurrency';
import BvsDropdown from '~/components/BvsDropdown';
import BvsAdmButton from '~/components/BvsAdmButton';
import BvsPanel from '~/components/BvsPanel';
import BvsInput from '~/components/BvsInput';
import useEnvelope from './hooks/useEnvelope';
import BvsButton from '~/components/BvsButton';
import useEmailRevenues from '~/pages/Order/Checkout/hooks/useEmailRevenues';
import useHelper from '~/pages/Order/Checkout/hooks/useHelper';
import useForm from '~/pages/Order/Checkout/hooks/useForm';
import useCheckout from '~/pages/Order/Checkout/hooks/useCheckout';
import BvsModal from '~/components/BvsModal';
import moment from 'moment';
import { isDateValid } from '~/system/helper';

const Checkout = ({ match, params }) => {
  const dispatch = useDispatch();
  const { id: orderId } = match.params;
  const history = useHistory();
  const { orderEnvelope } = useEnvelope();
  const { emails, fetch: getEmailRevenues } = useEmailRevenues();
  const { enterpriseActive } = useSelector((state) => state.enterprises);
  const { user } = useSelector((state) => state.auth);
  const { order } = useSelector((state) => state.order);
  const { getNameTax } = useHelper();
  const { onFieldChange } = useForm();
  const {
    onSubmitOrder,
    onPopulate,
    emitBolet,
    modalVisible,
    onCloseModal,
    boletUrl,
  } = useCheckout({
    orderId,
  });

  const getTotalOrder = () => {
    let final = 0;
    // console.log(creditHasChanged);
    if (order.has_credit_bag) {
      final = Number(order?.total) + Number(order?.creditBag);
    } else {
      final = Number(order?.total);
    }

    return final;
  };

  useEffect(() => {
    if (!history?.location?.state?.order) onPopulate();
    getEmailRevenues();
  }, [enterpriseActive?.hash]);

  return (
    <>
      <SubHeader
        title="Fazer Pedido Integral"
        subtitle="Personalizado por Usuário"
      />
      <BvsContainer>
        {user?.type === USER_TYPE.ADMIN && <BvsAdmButton />}
        <div className="col-md-12">
          <BvsPanel title="Selecione e preencha os dados necessários para finalizar seu pedido">
            {orderId &&
              (order?.financial_status === STATUS_FINANCIAL.CANCELLED ||
                order?.operational_status === STATUS_OPERATIONAL.CANCELLED) && (
                <div className="alert alert-danger" role="alert">
                  <span className="font-weight-bold">
                    O pedido foi cancelado
                  </span>
                </div>
              )}
            <BvsForm onSubmit={onSubmitOrder}>
              <div className="row">
                <div className="col-xs-6 col-sm-2 col-md-2 col-lg-2 p-3">
                  <BvsInputCurrency
                    name="totalBenefit"
                    label="Total de Benefícios (R$)"
                    currency="BRL"
                    value={order.totalBenefits}
                    disabled
                  />
                </div>
                <div className="col-xs-6 col-sm-2 col-md-2 col-lg-2 p-3">
                  <BvsInputCurrency
                    name="totalTransfer"
                    label="Total de Repasse (R$)"
                    currency="BRL"
                    value={order?.totalTransfer}
                    disabled
                  />
                </div>
                <div className="col-xs-6 col-sm-2 col-md-2 col-lg-2 p-3">
                  <BvsInputCurrency
                    name="managerGeneral"
                    label="Gestão Geral (R$)"
                    currency="BRL"
                    value={order?.taxManagerGeneral}
                    disabled
                  />
                </div>
                {(enterpriseActive?.info?.fees ?? []).map((item) => {
                  return (
                    <div className="col-xs-6 col-sm-2 col-md-2 col-lg-2 p-3">
                      <BvsInputCurrency
                        name={item.benefitGroupId}
                        label={`${getNameTax(
                          item.benefitGroupId,
                          item.benefitGroupName
                        )}`}
                        currency="BRL"
                        value={
                          order?.fees?.find((f) => f.id === item.benefitGroupId)
                            ?.total ?? 0
                        }
                        disabled
                      />
                    </div>
                  );
                })}
                <div className="col-xs-6 col-sm-2 col-md-2 col-lg-2 p-3">
                  <BvsInputCurrency
                    name="others_cost"
                    label="Outros Custos (R$)"
                    currency="BRL"
                    value={Number(order?.others_cost)}
                    disabled
                  />
                </div>
                <div className="col-xs-6 col-sm-2 col-md-2 col-lg-2 p-3">
                  <BvsInputCurrency
                    name="delivery_cost"
                    label="Entrega (R$)"
                    currency="BRL"
                    value={Number(order?.delivery_cost)}
                    disabled
                  />
                </div>
                <div className="col-xs-6 col-sm-2 col-md-2 col-lg-2 p-3">
                  <BvsInputCurrency
                    name="discount_cost"
                    label="Desconto (R$)"
                    currency="BRL"
                    value={Number(order?.discount_cost)}
                    disabled
                  />
                </div>
                <div className="col-xs-6 col-sm-2 col-md-2 col-lg-2 p-3">
                  <BvsInputCurrency
                    name="total"
                    label="Total do Pedido (R$)"
                    currency="BRL"
                    value={getTotalOrder()}
                    disabled
                  />
                </div>
                <div className="col-xs-6 col-sm-2 col-md-2 col-lg-2 p-3">
                  <BvsInput
                    required
                    label="Data do Pagamento"
                    name="payday"
                    type="date"
                    value={order?.payday}
                    onChange={(value) => {
                      onFieldChange('payday', value);
                    }}
                  />
                </div>
                <div className="col-xs-6 col-sm-2 col-md-2 col-lg-3 p-3">
                  <div className="d-flex">
                    <BvsInputCurrency
                      name="creditBag"
                      label="Bolsa de crédito"
                      currency="BRL"
                      value={Number(order?.creditBag)}
                      onChange={(_, value) => {
                        onFieldChange('creditBag', value);
                      }}
                      disabled={!order?.has_credit_bag}
                    />
                    <input
                      style={{ width: '15px', marginLeft: '10px' }}
                      type="checkbox"
                      checked={order?.has_credit_bag}
                      onChange={
                        (value) =>
                          onFieldChange('has_credit_bag', value.target.checked)
                        // console.log(value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row" />
              <h5 className="p-0">Periodo de utilização dos beneficios</h5>
              <div className="row">
                <div className="col-xs-6 col-sm-2 col-md-2 col-lg-2 px-3">
                  <BvsInput
                    required
                    name="start_period_use"
                    type="date"
                    value={order?.start_period_use}
                    onChange={(value) =>
                      onFieldChange('start_period_use', value)
                    }
                  />
                </div>
                <div className="col-xs-6 col-sm-2 col-md-2 col-lg-2 px-3">
                  <BvsInput
                    required
                    name="end_period_use"
                    type="date"
                    value={order?.end_period_use}
                    onChange={(value) => onFieldChange('end_period_use', value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsDropdown
                    title="Selecione um E-mail..."
                    name="emails"
                    label="Email de faturamento para constar na NF-e"
                    onSelectedItem={(value) => {}}
                    value={order?.billing_address_email}
                    loading={emails.loading}
                    options={emails.options}
                  />
                  <p className="p-1">
                    <strong>Meio de pagamento</strong> - Boleto simples Pré-pago
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsInput
                    name="name"
                    label="Nome do Responsável"
                    value={order?.responsible_name}
                    onChange={(value) =>
                      onFieldChange('responsible_name', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsInput
                    name="email"
                    label="Email do Responsável"
                    value={order?.responsible_email}
                    onChange={(value) =>
                      onFieldChange('responsible_email', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                  <BvsInput
                    name="phone"
                    mask="phone"
                    label="Telefone do Responável"
                    value={order?.responsible_phone}
                    onChange={(value) =>
                      onFieldChange('responsible_phone', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsDropdown
                    title="Selecione um sequência..."
                    name="order"
                    label="Ordem das sequencias dos envelopes / recibos"
                    value={order?.order_type}
                    onSelectedItem={(value) =>
                      onFieldChange('order_type', value)
                    }
                    loading={orderEnvelope.loading}
                    options={orderEnvelope.options}
                  />
                </div>
                <div
                  className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3"
                  style={{ marginTop: 'auto' }}
                >
                  <BvsInput
                    name="nfe_one"
                    label="Email Principal para envio Nfe"
                    required
                    value={order?.order_email_nfe}
                    disabled
                  />
                </div>
                <div
                  className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3"
                  style={{ marginTop: 'auto' }}
                >
                  <BvsInput
                    name="nfe_two"
                    label="Email Secundário para envio Nfe"
                    value={order?.order_email_nfe_extra}
                    onChange={(value) =>
                      onFieldChange('order_email_nfe_extra', value)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-3 d-flex justify-content-start">
                  <BvsButton
                    type="button"
                    title="Voltar"
                    icon="fa fa-undo"
                    className="mr-3"
                    onClick={async () => {
                      dispatch(resetDatesOrders());
                      if (orderId) history.replace('/orders');
                      else history.goBack();
                    }}
                  />
                  {orderId && (
                    <>
                      <BvsButton
                        type="button"
                        title="Importar Status do Pedido"
                        icon="fa fa-upload"
                        className="mr-3"
                        disabled
                        onClick={async () => {
                          // history.goBack();
                        }}
                      />
                      <BvsButton
                        type="button"
                        title="Visualizar Pedido"
                        icon="fa fa-eye"
                        className="mr-3"
                        onClick={async () => {
                          history.push(`/order/detail/${orderId}`);
                        }}
                      />
                      <BvsButton
                        type="button"
                        title="Gerar Boleto"
                        icon="fa fa-cart-arrow-down"
                        className="mr-3"
                        onClick={emitBolet}
                        disabled={
                          ((!order?.payday ||
                            order?.payday === 'Data inválida') &&
                            orderId &&
                            order?.financial_status ===
                              STATUS_FINANCIAL.CANCELLED) ||
                          order?.operational_status ===
                            STATUS_OPERATIONAL.CANCELLED
                        }
                      />
                    </>
                  )}
                  <BvsButton
                    type="submit"
                    title={!orderId ? 'Fechar Pedido' : 'Salvar'}
                    icon={`fa ${!orderId ? 'fa-check' : 'fa-save'}`}
                    disabled={
                      orderId &&
                      (order?.financial_status === STATUS_FINANCIAL.CANCELLED ||
                        order?.operational_status ===
                          STATUS_OPERATIONAL.CANCELLED)
                    }
                  />
                </div>
              </div>
            </BvsForm>
          </BvsPanel>
        </div>
      </BvsContainer>
      <BvsModal
        visible={modalVisible}
        title="Confirmação de Processamento do Pedido"
        footer={() => (
          <div>
            <BvsButton title="Fechar" onClick={onCloseModal} />
          </div>
        )}
      >
        <p className="lead">
          Seu pedido de número <strong>{order?.id}</strong> já foi processado e
          está previsto para ser pago em{' '}
          <strong>{moment(order?.payday).format('DD/MM/YYYY')}</strong>
        </p>
        {boletUrl?.status && (
          <>
            <form
              action="https://shopline.itau.com.br/shopline/shopline.asp"
              method="post"
              name="form1"
              target="SHOPLINE"
              onSubmit={() => {
                window.open(
                  '',
                  'SHOPLINE',
                  'toolbar=yes,menubar=yes,resizable=yes,status=no,scrollbars=yes,width=675,height=485'
                );
              }}
            >
              <input type="hidden" name="DC" value={boletUrl?.token} />
              <br />
              Para visualizar seu Boleto,{' '}
              <input
                type="submit"
                className="link"
                name="Shopline"
                value="Clique aqui"
              />
            </form>
          </>
        )}
        {!boletUrl?.status && (
          <div className="alert alert-info">
            Ocorreu um problema ao tentar gerar o boleto, verifique novamente
            mais tarde.
          </div>
        )}
        <br />
        <p className="big">
          <strong>
            A entrega do seu pedido será realizada no dia &nbsp;
            <strong>
              {moment(order?.payday)
                .add(enterpriseActive?.info?.delivery_day, 'days')
                .format('DD/MM/YYYY')}
            </strong>
            , caso seja confirmado o pagamento na data prevista, acima
            informada.
          </strong>
        </p>
        <p className="big">
          Lembramos que a realização do pagamento em data anterior ou posterior
          à <strong>informada</strong> no pedido implicará na &nbsp;
          <strong>antecipação</strong> ou &nbsp;
          <strong>prorrogação automática</strong> da entrega na mesma quantidade
          de dias úteis.
        </p>
        <p className="big">
          Caso necessite alterar o pedido, data de pagamento, incluir ou excluir
          informações e usuários, basta voltar e processar as alterações.
        </p>
        <p className="big">
          Após o pagamento do boleto seu pedido não poderá mais ser alterado. Se
          houver a necessidade de alguma alteração após o pagamento, entre em
          contato com seu <strong>Gestor de Contas</strong>
          que ele poderá te ajudar.
        </p>
        <p className="big">
          Obrigado por escolher nosso{' '}
          <strong>
            Serviço de Gestão de Vale Transporte e demais Benefícios
          </strong>
          .
        </p>
      </BvsModal>
    </>
  );
};

export default Checkout;
