import React from 'react';
import * as UI from './style';

const BvsButton = ({
  type,
  customStyle,
  title = '',
  icon,
  className,
  onClick,
  tooltip = '',
  small = false,
  fullWidth = false,
  disabled = false,
  ...attr
}) => {
  const RenderIcon = (_icon, _title = '') => {
    switch (typeof _icon) {
      case 'string':
        return (
          <>
            <i className={_icon} style={{ color: '#fff' }} />
            {_title && <> &nbsp;</>}
            {_title && (
              <>
                <span>&nbsp;</span> {_title}
              </>
            )}
          </>
        );
      case 'object':
        return (
          <>
            {_icon}
            {'&nbsp;' && _title}
          </>
        );
      case 'function':
        return (
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              boxSizing: 'border-box',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {_icon()}&nbsp; {_title}
          </span>
        );
      default:
        return <>{title}</>;
    }
  };
  return (
    <UI.BvsButton
      small={small}
      type={type}
      className={` ${className || ''}`}
      title={tooltip}
      style={{ ...customStyle }}
      onClick={(e) => {
        !disabled && onClick && onClick(e);
      }}
      disabled={disabled}
      fullWidth={fullWidth}
      {...attr}
    >
      {icon ? RenderIcon(icon, title) : <>{title}</>}
    </UI.BvsButton>
  );
};

export default BvsButton;
