/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import * as UI from './style';

const BvsPanel = ({
  title,
  content,
  children,
  style,
  visible: initialVisible = true,
}) => {
  const [visible, SetVisible] = useState(initialVisible);
  const [displaying, SetDisplaying] = useState(true);
  const [height, SetHeight] = useState('auto');
  // Só executa a função se a variavel visible mudar de valor.
  useEffect(() => {
    if (!visible) {
      setTimeout(() => {
        SetDisplaying(false);
        SetHeight('0px');
      }, 'auto');
    } else {
      SetDisplaying(true);
      setTimeout(() => {
        SetHeight('auto');
      });
    }
  }, [visible]);
  useEffect(() => {
    SetVisible(initialVisible);
  }, [initialVisible]);
  return (
    <UI.Panel>
      <UI.PanelHeader>
        <div className="pull-right">
          <i
            className={`fa ${visible ? 'fa-sort-down' : 'fa-sort-up'}`}
            onClick={(e) => {
              e.preventDefault();
              SetVisible(!visible);
            }}
          />
        </div>
        {typeof title === 'string' ? title : title()}
      </UI.PanelHeader>
      <UI.PanelBody
        visible={visible}
        displaying={displaying}
        height={height}
        style={style}
      >
        {content || children}
      </UI.PanelBody>
    </UI.Panel>
  );
};

export default BvsPanel;
