/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
import types from './types';

export function updateWorkday(payload) {
  return {
    type: types.UPDATE_WORKDAY,
    payload,
  };
}

export function resetWorkday() {
  return {
    type: types.RESET_WORKDAY,
  };
}

export function addWorkday(callback) {
  return {
    type: types.ADD_WORKDAY,
    callback,
  };
}
