import React, { useEffect, useLayoutEffect } from 'react';
import { mask as FnMask, unMask } from 'remask';
import uuid from 'uuid-random';
import { Input, InputContainer, Label } from './style';

const BvsInputV2 = React.forwardRef(
  (
    {
      label,
      name,
      placeholder,
      mask = [],
      handleChange,
      handleBlur = () => {},
      error,
      required = false,
      setValue,
      disabled = false,
      password = false,
    },
    ref
  ) => {
    const id = `${uuid()}_${name}`;
    const interceptChange = (e) => {
      let el = document.getElementById(id);
      if (el) {
        el.value === '' &&
          el.classList.contains('hasFilled') &&
          el.classList.remove('hasFilled');
        el.value &&
          !el.classList.contains('hasFilled') &&
          el.classList.add('hasFilled');
      }
      if (setValue && setValue !== undefined) {
        if (mask) handleMask(e);
        if (handleChange) {
          handleChange(e);
        }
      }
    };

    const handleMask = (e) => {
      let value = unMask(e.target.value);
      switch (mask) {
        case 'phone':
          setValue(name, FnMask(value, ['(99) 9999-9999', '(99) 9 9999-9999']));
          break;
        case 'rg':
          setValue(name, FnMask(value, ['99.999.999-S']));
          break;
        case 'cpf/cnpj':
          setValue(
            name,
            FnMask(value, ['999.999.999-99', '99.999.999/9999-99'])
          );
          break;
        case 'cep':
          setValue(name, FnMask(value, ['99999-999']));
          break;
        case 'date':
          setValue(name, FnMask(value, ['99/99/99', '99/99/9999']));
          break;
        default:
          setValue(name, FnMask(value, mask));
          break;
      }
    };

    const floatingEffect = () => {
      let el = document.getElementById(id);

      if (el) {
        el.value === '' &&
          el.classList.contains('hasFilled') &&
          el.classList.remove('hasFilled');
        el.value &&
          !el.classList.contains('hasFilled') &&
          el.classList.add('hasFilled');
      }
    };

    useLayoutEffect(() => {
      setTimeout(() => {
        floatingEffect();
      }, 800);
    });

    return (
      <InputContainer>
        <Input
          type={!password ? 'text' : 'password'}
          id={id}
          name={name}
          // className="form-control"
          placeholder={placeholder}
          autoComplete="off"
          ref={ref}
          onChange={interceptChange}
          onBlur={handleBlur}
          pattern=".+"
          isRequired={disabled ? false : required}
          disabled={disabled}
        />
        {label && (
          <Label htmlFor={id} isRequired={required} disabled={disabled}>
            {label}
            {!disabled && required && '*'}
          </Label>
        )}
        {!disabled && error && (
          <Label isRequired={required} error>
            Campo obrigatório
            {required && '*'}
          </Label>
        )}
      </InputContainer>
    );
  }
);

export default BvsInputV2;
