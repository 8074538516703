/* eslint-disable import/prefer-default-export */
import types from './types';

export function updateState(payload) {
  return {
    type: types.UPDATE_STATE,
    payload,
  };
}

export function resetState(payload) {
  return {
    type: types.RESET_STATE,
    payload,
  };
}
