import React from 'react';
import { useSelector } from 'react-redux';
import Announcements from '~/pages/Home/Announcements';
import { USER_TYPE } from '~/system/enum';
import DashboardAdmin from './DashboardAdmin';
import DashboardUser from './DashboardUser';
import LastLoginModal from './modal/LastLogin';

const Home = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <>
      {user?.type === USER_TYPE.ADMIN && <DashboardAdmin />}
      {user?.type === USER_TYPE.USER && <DashboardUser />}
      <LastLoginModal />
      <Announcements announcement="@cartao-bom/28-03-2022" />
    </>
  );
};

export default Home;
