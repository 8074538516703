import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import io from 'socket.io-client';
import { navigateTo } from '~/system/rootNavigation';
import { updateSocket } from '~/store/modules/app/actions';
import {
  getMyEnterprises,
  resetEnterprise,
} from '~/store/modules/enterpriseActive/actions';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';
import api from '~/services/api';
import { updateAuth, resetAuth } from './actions';
import types from './types';
import { resetAdministrativeCardFilter } from '~/store/modules/filter/FilterAdministrativeCard/actions';
import { resetChangePassword } from '~/store/modules/forms/FormChangePassword/actions';
import { resetDepartment } from '~/store/modules/forms/FormDepartment/actions';
import { resetEmployeeBenefits } from '~/store/modules/forms/FormEmployeeBenefits/actions';
import { resetEmployees } from '~/store/modules/forms/FormEmployees/actions';
import { resetFilter } from '~/store/modules/filter/FilterExport/actions';
import { resetUsers } from '~/store/modules/forms/FormUsers/actions';
import BvsToastResult from '~/components/BvsToastResult';
import { updateHome } from '../home/actions';

/**
 * @ANCHOR Responsavél pelo login
 * @param {*} payload Dados do Usuário para fazer login.
 */
export function* logIn({ payload }) {
  try {
    yield put(showLoading());
    const { components } = yield select((state) => state.home);

    const { data } = yield call(api.post, '/auth', payload);
    if (data.token) {
      api.defaults.headers.Authorization = `Bearer ${data.token}`;
    }
    yield put(
      updateAuth({
        user: data.user,
        token: data.token,
      })
    );

    if (data.message || data.error) throw data;
    yield put(getMyEnterprises());

    navigateTo('/home');
    toast.success(`Seja bem vindo ${data?.user?.name}!`);
    // aproveito para criar uma conexão com o socket
    const socket = yield call(io, process.env.REACT_APP_ENDPOINT, {
      jsonp: false,
      reconnection: true,
      forceNew: true,
      timeout: 7000,
    });

    yield put(
      updateSocket({
        socket,
      })
    );
    yield put(
      updateHome({
        components: { ...components, lastLogin: true },
      })
    );
  } catch (err) {
    toast.error(
      <BvsToastResult
        type="error"
        err={err}
        message="Falha ao realizar Login"
      />
    );
  } finally {
    yield put(hideLoading());
  }
}

export function* logOut() {
  yield put(resetEnterprise());
  yield put(resetAuth());
  yield put(resetAdministrativeCardFilter());
  yield put(resetChangePassword());
  yield put(resetDepartment());
  yield put(resetEmployeeBenefits());
  yield put(resetEmployees());
  yield put(resetFilter());
  yield put(resetUsers());
}
export default all([
  takeLatest(types.LOGIN, logIn),
  takeLatest(types.LOGOUT, logOut),
]);
