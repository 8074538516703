import styled from 'styled-components';

export const SubHeaderContainer = styled.div`
  background-color: #e8eaeb;
  height: 150px;
  display: block;
`;

export const SubHeaderWrapper = styled.div`
  padding: 38px 0;
  line-height: 23px;
  max-width: 1130px;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 3rem;
  font-weight: 500;
  font-family: 'Open Sans', Arial, sans-serif;
  min-width: 100%;
  color: rgb(51, 51, 51);
  @media (max-width: 576px) {
    font-size: 2rem !important;
    padding-left: 20px;
  }
`;

export const SubTitle = styled.span`
  font-size: 16.5px;
  margin-left: 10.5%;
  color: rgb(51, 51, 51);
`;
