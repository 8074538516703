import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateOrder } from '~/store/modules/order/actions';
import { updateChangeDays } from '~/store/modules/order/detail/actions';

const useChangeDays = ({ selecteds = [], setSelectedItems }) => {
  const dispatch = useDispatch();
  const { changeDays } = useSelector((state) => state.orderDetail);
  const { order } = useSelector((state) => state.order);

  const onChange = (value) => {
    dispatch(updateChangeDays(value));
  };

  const updateMonthDaysSelected = () => {
    if (selecteds.length === 0)
      toast.warning('Selecione ao menos um para atualizar os dias');

    dispatch(
      updateOrder({
        ...order,
        employees: order.employees.map((e) =>
          selecteds.includes(e.id)
            ? {
                ...e,
                benefits: e.benefits.map((b) => ({
                  ...b,
                  month_quantity: Number(changeDays),
                })),
              }
            : e
        ),
      })
    );
    setSelectedItems([]);
  };

  return {
    changeDays,
    onChange,
    updateMonthDaysSelected,
  };
};

export default useChangeDays;
