/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
/* eslint-disable no-debugger */
/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable import/order */

import React, { useEffect, useState, useRef } from 'react';

import BvsButton from '~/components/BvsButton';
import BvsContainer from '~/components/BvsContainer';
import BvsDropdown from '~/components/BvsDropdown';
import BvsForm from '~/components/BvsForm';
import BvsInput from '~/components/BvsInput';
import BvsPanel from '~/components/BvsPanel';
import BvsTableV4 from '../../components/BvsTableV4';
import { FontAwesome, AntDesign } from 'react-web-vector-icons';
import { RenderStatusIconButton, getMask } from '~/system/helper';
import SubHeader from '~/components/BvsSubHeader';
// import columns from './columns.json';
import { getAddressType, getStatus } from '~/system/convert';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  addResponsible,
  removeResponsible,
  resetShippingAddress,
  updateResponsible,
  updateShippingAddress,
} from '~/store/modules/forms/FormShippingAddress/actions';
import viacep from '~/services/viacep';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';
import api from '~/services/api';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';
import { ADDRESS_TYPE } from '~/system/enum';

const ShippingAddress = ({ match }) => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const containerForm = useRef();

  const { shipping_address, responsibles, form } = useSelector(
    (state) => state.formShippingAddress
  );
  const history = useHistory();
  let { enterpriseId, id } = match.params;

  const [department, setDepartment] = useState({
    loading: false,
    options: [],
  });

  const [job, setJob] = useState({
    loading: false,
    options: [],
  });

  const columns = [
    {
      key: 'code',
      sort: true,
      label: 'Código',
    },
    {
      key: 'title',
      sort: true,
      label: 'Titulo',
    },
    {
      key: 'address_type',
      sort: true,
      label: 'Tipo',
      content: (row) => {
        row.address_type_enum = row.address_type;
        row.address_type = getAddressType(row.address_type);
      },
    },
    {
      key: 'status',
      sort: true,
      label: 'Status',
      width: '100%',
      content: (row) => {
        return getStatus(row.status);
      },
    },
  ];

  const fetchShippingAddress = async () => {
    try {
      dispatch(showLoading());
      const { data } = await api.get(`/shippingAddress`);
      if (data?.message || data?.error) throw data;
      setList(data);
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          message="Falha ao consultar registros"
          err={err}
        />
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const onDelete = async (shippingAddressId) => {
    try {
      dispatch(showLoading());
      const { data } = await api.delete(
        `/shippingAddress/${shippingAddressId}`
      );
      if (data?.error || data?.message) throw data;
      toast.success(<BvsToastResult message="Excluido com sucesso." />);
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          message="Falha ao excluir registro"
          err={err}
        />
      );
    } finally {
      dispatch(hideLoading());
      await fetchShippingAddress();
    }
  };

  const onEdit = async (shippingAddressId) => {
    dispatch(resetShippingAddress());
    try {
      dispatch(showLoading());
      const { data } = await api.get(`/shippingAddress/${shippingAddressId}`);
      if (data?.message || data?.error) throw data;
      history.replace(
        `/enterprise/${enterpriseId}/shippingAddress/${shippingAddressId}`
      );
      dispatch(
        updateShippingAddress({
          form,
          shipping_address: {
            title: data.title,
            state_uf: data.state_uf,
            city: data.city,
            district: data.district,
            street: data.street,
            number: data.number,
            zipcode: data.zipcode,
            complement: data.complement,
            reference_point: data.reference_point,
            address_type: data.address_type,
            status: data.status,
            code: data.code,
          },
        })
      );
      data?.firstResponsible &&
        dispatch(
          addResponsible({
            id: data?.firstResponsible?.id,
            name: data?.firstResponsible?.name,
            phone: getMask('phone', data?.firstResponsible?.phone),
            email: data?.firstResponsible?.email,
            job_id: data?.firstResponsible?.job_id,
            department_id: data?.firstResponsible?.department_id,
          })
        ) &&
        dispatch(removeResponsible(1));
      data?.secondResponsible &&
        dispatch(
          addResponsible({
            id: data?.secondResponsible?.id,
            name: data?.secondResponsible?.name,
            phone: getMask('phone', data?.secondResponsible?.phone),
            email: data?.secondResponsible?.email,
            job_id: data?.secondResponsible?.job_id,
            department_id: data?.secondResponsible?.department_id,
          })
        );
      data?.thirdResponsible &&
        dispatch(
          addResponsible({
            id: data?.thirdResponsible?.id,
            name: data?.thirdResponsible?.name,
            phone: getMask('phone', data?.thirdResponsible?.phone),
            email: data?.thirdResponsible?.email,
            job_id: data?.thirdResponsible?.job_id,
            department_id: data?.thirdResponsible?.department_id,
          })
        );
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          message="Falha ao localizar registro"
          err={err}
        />
      );
    } finally {
      dispatch(hideLoading());
      await fetchShippingAddress();
      window.scrollTo({
        top: containerForm?.current.scrollTop,
        behavior: 'smooth',
      });
    }
  };

  const changeStatus = async (shippingAddressId) => {
    try {
      dispatch(showLoading());
      const { data } = await api.put(
        `/shippingAddress/${shippingAddressId}/status`
      );

      if (data.message || data.error) throw data;
      toast.success(<BvsToastResult message="Status atualizado com sucesso" />);
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          message="Falha ao atualizar o status"
          err={err}
        />
      );
    } finally {
      dispatch(hideLoading());
      await fetchShippingAddress();
    }
  };

  const handleDelete = (pIndex) => {
    dispatch(removeResponsible(pIndex));
  };

  const handleAdd = () => {
    dispatch(addResponsible());
  };

  const onSave = async (e) => {
    e.preventDefault();
    dispatch(showLoading());
    dispatch(
      updateShippingAddress({
        form: { saving: true },
        shipping_address,
      })
    );
    try {
      let shippingAddress = { ...shipping_address };
      if (responsibles.length === 0)
        throw new Error('Necessário informar ao menos um responsável');
      responsibles?.[0] &&
        (() => {
          return (shippingAddress.firstResponsible = {
            ...responsibles?.[0],
            phone: responsibles?.[0].phone.replace(/[^\d]/g, ''),
          });
        })();
      responsibles?.[1] &&
        (() => {
          return (shippingAddress.secondResponsible = {
            ...responsibles?.[1],
            phone: responsibles?.[1].phone.replace(/[^\d]/g, ''),
          });
        })();
      responsibles?.[2] &&
        (() => {
          return (shippingAddress.thirdResponsible = {
            ...responsibles?.[2],
            phone: responsibles?.[2].phone.replace(/[^\d]/g, ''),
          });
        })();
      const { data } = await api[id ? 'put' : 'post'](
        `/shippingAddress${id ? `/${id}` : ''}`,
        {
          ...shippingAddress,
        }
      );

      if (data.error || data.message) throw data;
      toast.success(
        <BvsToastResult message="registro atualizado com sucesso." />
      );

      await fetchShippingAddress();
      onCancel();
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          err={err}
          message="Falha ao atualizar registro"
        />
      );
    } finally {
      dispatch(hideLoading());
      dispatch(
        updateShippingAddress({
          form: { saving: false },
          ...shipping_address,
        })
      );
    }
  };

  const onCancel = () => {
    dispatch(resetShippingAddress());
    history.replace(`/enterprise/${enterpriseId}/shippingAddress`);
  };

  const setShippingAddress = (key, value) => {
    dispatch(
      updateShippingAddress({
        shipping_address: {
          ...shipping_address,
          [key]: value,
        },
      })
    );
  };

  const setResponsible = (index, key, value) => {
    // console.log(responsibles, index, key, value);
    // let tmp = clone(responsibles);
    // tmp[index] = { ...responsibles[index], [key]: value };
    dispatch(
      updateResponsible(
        responsibles.map((responsible, idx) =>
          idx === index
            ? {
                ...responsible,
                [key]: value,
              }
            : responsible
        )
      )
    );
    // responsibles[index] = { ...responsibles[index], [key]:value}
    // debugger;
    // console.log(
    //   index,
    //   key,
    //   value,
    //   {
    //     ...responsibles,
    //     [index]: { ...responsibles[index], [key]: value },
    //   },
    //   'teste',
    //   responsibles[index]
    // );
    // dispatch(
    //   updateResponsible({
    //     ...responsibles,
    //     [index]: [...responsibles[index], { [key]: value }],
    //   })
    // );
  };

  const getDepartment = async () => {
    setDepartment({ ...department, loading: true });
    let options = [];
    try {
      const { data } = await api.get('/departments');
      if (data.error || data.message) throw data;
      options = data.map((d) => ({ title: d.name, value: d.id }));
      setDepartment({ ...department, options });
    } catch (error) {
      setDepartment({ ...department, options });
    } finally {
      setDepartment({ ...department, options, loading: false });
    }
  };

  const getJob = async () => {
    setJob({ ...job, loading: true });
    let options = [];
    try {
      const { data } = await api.get('/jobs');
      if (data.error || data.message) throw data;
      options = data.map((d) => ({ title: d.name, value: d.id }));
      setJob({ ...job, options });
    } catch (error) {
      setJob({ ...job, options });
    } finally {
      setJob({ ...job, options, loading: false });
    }
  };

  useEffect(() => {
    fetchShippingAddress();
    getDepartment();
    getJob();
    return () => {
      dispatch(resetShippingAddress());
    };
  }, []);

  return (
    <>
      <SubHeader title="Listar Endereço de Entrega" subtitle="Dos Clientes" />
      <BvsContainer>
        <div className="row" ref={containerForm}>
          <div className="col-md-12">
            <BvsForm onSubmit={onSave}>
              <BvsPanel title="Cadastro">
                <div className="row">
                  <div className="col-xs-12 col-sm-6 col-md-4 col-lg-1 p-3">
                    <BvsInput
                      name="code"
                      label="Código"
                      value={shipping_address.code}
                      onChange={(value) => setShippingAddress('code', value)}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 p-3">
                    <BvsInput
                      name="title"
                      label="Titulo"
                      value={shipping_address.title}
                      onChange={(value) => setShippingAddress('title', value)}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-2 p-3">
                    <BvsInput
                      name="zipcode"
                      label="CEP"
                      mask="cep"
                      value={shipping_address.zipcode}
                      onChange={(value) => setShippingAddress('zipcode', value)}
                      onBlur={async (v) => {
                        let cep = v?.target?.value?.replace(/[^\d]/g, '');
                        if (cep.length >= 8) {
                          try {
                            const { data } = await viacep.get(`/${cep}/json/`);
                            dispatch(
                              updateShippingAddress({
                                shipping_address: {
                                  ...shipping_address,
                                  state_uf: data?.uf,
                                  ibge: data?.ibge,
                                  city: data?.localidade,
                                  street: data?.logradouro,
                                  district: data?.bairro,
                                },
                              })
                            );
                          } catch (err) {
                            return false;
                          }
                        }
                      }}
                      required
                    />
                  </div>
                  <div className="col-xs-12 col-sm-4 col-md-5 col-lg-4 p-3">
                    <BvsInput
                      name="city"
                      label="Cidade"
                      value={shipping_address.city}
                      onChange={(value) => setShippingAddress('city', value)}
                    />
                  </div>

                  <div className="col-xs-12 col-sm-4 col-md-2 col-lg-2 p-3">
                    <BvsInput
                      name="state_uf"
                      label="Estado"
                      value={shipping_address.state_uf}
                      onChange={(value) =>
                        setShippingAddress('state_uf', value)
                      }
                    />
                  </div>

                  <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 p-3">
                    <BvsInput
                      name="street"
                      label="Rua"
                      value={shipping_address.street}
                      onChange={(value) => setShippingAddress('street', value)}
                    />
                  </div>

                  <div className="col-xs-12 col-sm-2 col-md-4 col-lg-2 p-3">
                    <BvsInput
                      name="number"
                      label="Nº"
                      value={shipping_address.number}
                      onChange={(value) => setShippingAddress('number', value)}
                    />
                  </div>

                  <div className="col-xs-12 col-sm-5 col-md-4 col-lg-5 p-3">
                    <BvsInput
                      name="district"
                      label="Bairro"
                      value={shipping_address.district}
                      onChange={(value) =>
                        setShippingAddress('district', value)
                      }
                    />
                  </div>

                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3 ">
                    <BvsInput
                      name="reference_point"
                      label="Ponto de Referencia"
                      value={shipping_address.reference_point}
                      onChange={(value) =>
                        setShippingAddress('reference_point', value)
                      }
                    />
                  </div>

                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                    <BvsInput
                      name="complement"
                      label="Complemento"
                      value={shipping_address.complement}
                      onChange={(value) =>
                        setShippingAddress('complement', value)
                      }
                    />
                  </div>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                    <BvsDropdown
                      title="Selecione um tipo..."
                      name="address_type"
                      label="Tipo de Endereço"
                      value={shipping_address.address_type}
                      onSelectedItem={(value) =>
                        setShippingAddress('address_type', value)
                      }
                      options={Object.values(ADDRESS_TYPE).map((type) => ({
                        value: type,
                        title: getAddressType(type),
                      }))}
                    />
                  </div>
                </div>
              </BvsPanel>
              <BvsPanel
                title="Responsáveis de Entrega"
                style={{ marginBottom: 0 }}
              >
                {responsibles.map((responsible, index) => {
                  return (
                    <BvsPanel
                      key={`${responsible.id}`}
                      title="Nome do Responsavel"
                      style={{ display: responsible ? '' : 'none' }}
                    >
                      <div className="row">
                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-5 p-3">
                          <BvsInput
                            name={`name_${index}`}
                            label="Responsavel"
                            value={responsible.name}
                            onChange={(value) =>
                              setResponsible(index, 'name', value)
                            }
                          />
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 p-3">
                          <BvsInput
                            name={`phone_${index}`}
                            label="Telefone"
                            mask="phone"
                            value={responsible.phone}
                            onChange={(value) => {
                              setResponsible(index, 'phone', value);
                            }}
                            required
                          />
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                          <BvsInput
                            name={`email_${index}`}
                            label="E-mail"
                            value={responsible.email}
                            onChange={(value) =>
                              setResponsible(index, 'email', value)
                            }
                          />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 p-3">
                          <BvsDropdown
                            title="Selecione um Cargo..."
                            name="owner_job_id"
                            label="Cargo"
                            value={responsible.job_id}
                            onSelectedItem={(value) =>
                              setResponsible(index, 'job_id', value)
                            }
                            options={job.options}
                          />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 p-3">
                          <BvsDropdown
                            title="Selecione um Departamento..."
                            name="owner_department_id"
                            label="Departamento"
                            value={responsible.department_id}
                            onSelectedItem={(value) =>
                              setResponsible(index, 'department_id', value)
                            }
                            options={department.options}
                          />
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                          {responsibles.filter((d) => d).length > 1 && (
                            <BvsButton
                              type="button"
                              title="Excluir"
                              icon="fa fa-trash"
                              customStyle={{
                                margin: '20px 15px',
                                float: 'right',
                              }}
                              onClick={() => handleDelete(responsible.key)}
                            />
                          )}
                        </div>
                      </div>
                    </BvsPanel>
                  );
                })}
                <div className="row end-xs p-2">
                  {responsibles.filter((d) => d).length < 3 && (
                    <BvsButton
                      type="button"
                      title="Adicionar Responsável"
                      icon="fa fa-plus"
                      customStyle={{ margin: '15px 5px', float: 'right' }}
                      onClick={() => handleAdd()}
                    />
                  )}
                </div>
              </BvsPanel>
              <div className="d-flex justify-content-end align-items-center">
                <div className="row px-3 py-3">
                  <BvsButton
                    className="mx-3"
                    type="button"
                    title="Voltar"
                    icon="fa fa-undo"
                    onClick={() =>
                      history.replace(`/enterprises/${enterpriseId}`)
                    }
                    disabled={form.saving}
                  />
                  <BvsButton
                    type="submit"
                    title="Salvar"
                    icon="fa fa-save"
                    disabled={form.saving}
                  />
                </div>
              </div>
              <BvsPanel title="Endereços de Entrega">
                <BvsTableV4
                  tableKey="shippingAddress"
                  columns={columns}
                  rows={list}
                  onSearch={(text) => {
                    console.log('Procure por', text);
                  }}
                  renderRows={(row) => ({
                    ...row,
                  })}
                  onRefresh={() => {
                    fetchShippingAddress();
                  }}
                  rowKey="id"
                  actionOptions={{
                    width: '225px',
                  }}
                  actions={[
                    (row) => (
                      <BvsButton
                        type="button"
                        className="btn btn-xs"
                        customStyle={{ marginRight: '5px' }}
                        icon={
                          <FontAwesome name="edit" color="#fff" size={12} />
                        }
                        onClick={() => onEdit(row.id)}
                      />
                    ),
                    (row) => (
                      <BvsButton
                        type="button"
                        className="btn btn-xs"
                        customStyle={{ marginRight: '5px' }}
                        icon={
                          <FontAwesome name="close" color="#fff" size={12} />
                        }
                        onClick={() => onDelete(row.id)}
                      />
                    ),
                    (row) => (
                      <BvsButton
                        type="button"
                        className="btn btn-xs"
                        customStyle={{ marginRight: '5px' }}
                        icon={RenderStatusIconButton(row.status)}
                        onClick={() => changeStatus(row.id)}
                      />
                    ),
                  ]}
                />
              </BvsPanel>
            </BvsForm>
          </div>
        </div>
      </BvsContainer>
    </>
  );
};

export default ShippingAddress;
