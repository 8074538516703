import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BvsButton from '~/components/BvsButton';
import { ItemList, ListButton, Dropdown } from './style';

const BvsAdmButton = () => {
  const history = useHistory();
  const { enterpriseActive } = useSelector((state) => state.enterprises);
  return (
    <div className="">
      <div className="row d-flex justify-content-start  ">
        <div className="col text-nowrap p-2">
          <BvsButton
            fullWidth
            type="button"
            title="Dados da Empresa"
            icon="fa fa-print"
            onClick={() => {
              history.push(
                `/enterprises${
                  enterpriseActive?.info?.id
                    ? `/${enterpriseActive?.info?.id}`
                    : ''
                }`
              );
            }}
          />
        </div>
        <div className="col text-nowrap p-2">
          <BvsButton
            fullWidth
            type="button"
            title="Departamentos"
            icon="fa fa-print"
            onClick={() => history.push(`/departments`)}
          />
        </div>
        <div className="col text-nowrap p-2">
          <BvsButton
            fullWidth
            type="button"
            title="Cargos"
            icon="fa fa-print"
            onClick={() => history.push(`/jobs`)}
          />
        </div>
        <div className="col text-nowrap p-2">
          <BvsButton
            fullWidth
            type="button"
            title="Periodo de Trabalho"
            icon="fa fa-print"
            onClick={() => history.push(`/workedDays`)}
          />
        </div>
        <div className="col text-nowrap p-2">
          <BvsButton
            fullWidth
            type="button"
            title="Usuarios"
            icon="fa fa-users"
            onClick={() => history.push(`/employees`)}
          />
        </div>
        <div className="p-2">
          <Dropdown>
            <BvsButton
              fullWidth
              type="button"
              title="Pedidos"
              icon="fa fa-print"
            />
            <ListButton>
              <ItemList onClick={() => history.push(`/orders`)}>
                Listar Pedidos
              </ItemList>
              <ItemList onClick={() => history.push(`/orders/create`)}>
                Realizar Pedidos
              </ItemList>
              <ItemList onClick={() => history.push(`/createOrdersByImport`)}>
                Realizar pedidos por arquivos de Importação
              </ItemList>
            </ListButton>
          </Dropdown>
        </div>
        <div className="p-2">
          <Dropdown>
            <BvsButton
              fullWidth
              type="button"
              title="Implementação"
              icon="fa fa-print"
            />
            <ListButton>
              <ItemList onClick={() => history.push(`/importGeneralRegisters`)}>
                Importação de Cadastro
              </ItemList>
              <ItemList onClick={() => history.push(`/exportDownload`)}>
                Exportar Cadastros em Excel
              </ItemList>
            </ListButton>
          </Dropdown>
        </div>
        <div className=" text-nowrap p-2">
          <BvsButton
            fullWidth
            type="button"
            title="Cartoes Administrativos"
            icon="fa fa-print"
            onClick={() => history.push(`/administrativeCard`)}
          />
        </div>
      </div>
    </div>
  );
};
export default BvsAdmButton;
