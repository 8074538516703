/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  filter: {
    query: '',
  },
  orderBy: {
    type: '',
  },
  pagination: {
    page: 1,
    limit: 10,
  },
  changeDays: '',
};

function orderDetail(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_FILTER: {
      return produce(state, (draft) => {
        draft.filter = { ...draft.filter, ...action.payload };
        return draft;
      });
    }

    case types.UPDATE_ORDERBY: {
      return produce(state, (draft) => {
        draft.orderBy = { ...draft.orderBy, ...action.payload };
        return draft;
      });
    }
    case types.UPDATE_PAGINATE: {
      return produce(state, (draft) => {
        draft.pagination = { ...draft.pagination, ...action.payload };
        return draft;
      });
    }
    case types.RESET_PAGINATE: {
      return produce(state, (draft) => {
        draft.pagination = INITIAL_STATE.pagination;
        return draft;
      });
    }
    case types.RESET_ORDERBY: {
      return produce(state, (draft) => {
        draft.orderBy = INITIAL_STATE.orderBy;
      });
    }
    case types.RESET_FILTER: {
      return produce(state, (draft) => {
        draft.filter = INITIAL_STATE.filter;
        return draft;
      });
    }
    case types.UPDATE_CHANGE_DAYS: {
      return produce(state, (draft) => {
        draft.changeDays = action.payload;
        return draft;
      });
    }
    default:
      return state;
  }
}

export default orderDetail;
