/* eslint-disable import/order */
import { mask } from 'remask';
import * as UI from './style';

import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import { AntDesign } from 'react-web-vector-icons';
import BvsButton from '~/components/BvsButton';
import BvsContainer from '~/components/BvsContainer';
import BvsPanel from '~/components/BvsPanel';
import FooterReports from '~/pages/Reports/FooterReports';
import HeaderReports from '~/pages/Reports/HeaderReports';
import SubHeader from '~/components/BvsSubHeader';
import { toMoney } from '~/system/helper';
import { useHistory } from 'react-router';
import useController from './hooks/useController';
import { getStatusOperational } from '~/system/convert';

const ReportPurchaseHistoric = ({ match }) => {
  const history = useHistory();
  const { purchaseHistoric, getHistoric } = useController();
  let { id } = match.params;

  const getResume = (info) => {
    return {
      totalValue:
        info
          ?.map(
            (i) =>
              Number(i.unit_cost) *
              Number(i.daily_quantity) *
              Number(i.month_quantity)
          )
          .reduce((cur, prev) => cur + prev, 0) ?? 0,
    };
  };

  const GetHistoric = () => {
    return Object.keys(purchaseHistoric?.data.historic).map((reference) => (
      <BvsPanel title={reference}>
        <table className="table table-hover table-striped mh-5 p-0 mv-0">
          <thead className="">
            <tr>
              <th scope="col">Pedido</th>
              <th scope="col">Código</th>
              <th scope="col">Descrição</th>
              <th scope="col">Quantidade</th>
              <th scope="col">Unitário</th>
              <th scope="col">Total</th>
              <th scope="col" style={{ width: 120 }}>
                Status
              </th>
            </tr>
          </thead>

          <tbody>
            {purchaseHistoric?.data.historic[reference].map((i) => (
              <tr>
                <td>{i?.order_id}</td>

                <td>{i?.benefit?.code}</td>
                <td>{i?.benefit?.name}</td>
                <td>{i?.daily_quantity * i?.month_quantity}</td>
                <td>{toMoney(i?.unit_cost)}</td>
                <td>
                  {toMoney(
                    i?.daily_quantity * i?.month_quantity * Number(i?.unit_cost)
                  )}
                </td>
                <td className="">
                  <span data-tip={getStatusOperational(i?.status).tooltip}>
                    <span
                      className="badge d-flex justify-content-center badge-default"
                      style={{
                        height: 10,
                        backgroundColor: getStatusOperational(i?.status).color,
                      }}
                    >
                      {/* <strong>Pago</strong> */}
                    </span>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="row mh-5 p-0 mv-0">
          <div className="col-9" />
          <div className="col-3">
            <table className="table">
              <thead>
                <tr>
                  <th align="right">Valor Total</th>
                  <th>
                    {' '}
                    {toMoney(
                      getResume(purchaseHistoric?.data.historic[reference])
                        .totalValue
                    )}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <ReactTooltip />
      </BvsPanel>
    ));
  };

  useEffect(() => {
    getHistoric(id);
  }, []);

  return (
    <>
      <SubHeader title="Histórico de Compras" subtitle="" />
      <BvsContainer>
        <div>
          <BvsPanel title="Histórico de Compras">
            <BvsButton
              type="button"
              title="Voltar"
              onClick={() => {
                history.goBack();
              }}
            />
            <HeaderReports />
            {purchaseHistoric.loading && (
              <div className="p-3 row justify-content-center align-items-center">
                <div className="col-12 text-center">
                  <h4>Carregando...</h4>
                </div>
                <div className="col-4">
                  <div className="progress">
                    <div
                      className="progress-bar bg-warning progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
              </div>
            )}
            {!purchaseHistoric.loading && !purchaseHistoric?.data && (
              <div
                className="row middle-xs center-xs"
                style={{ padding: 60, textAlign: 'center' }}
              >
                <div className="col-md-12">
                  <h2>Não foi possivel encontrar registros</h2>
                  <h2 style={{ marginTop: '10px' }}>
                    <AntDesign name="frowno" />
                  </h2>
                </div>
              </div>
            )}

            {purchaseHistoric?.data && (
              <>
                <UI.Table style={{ marginBottom: 15 }}>
                  <UI.TableHead>
                    <tr>
                      <UI.TableTH>Nome</UI.TableTH>
                      <UI.TableTH>CPF</UI.TableTH>
                    </tr>
                  </UI.TableHead>

                  <tbody>
                    <UI.TableTD>
                      {purchaseHistoric?.data?.employee?.name}
                    </UI.TableTD>
                    <UI.TableTD>
                      {mask(purchaseHistoric?.data?.employee?.cpf, [
                        '999.999.999-99',
                        '99.999.999/9999-99',
                      ])}
                    </UI.TableTD>
                  </tbody>
                </UI.Table>
                <UI.Table>
                  <UI.TableHead>
                    <tr>
                      <UI.TableTH>Código</UI.TableTH>
                      <UI.TableTH>Razão Social</UI.TableTH>
                      <UI.TableTH>CNPJ</UI.TableTH>
                      <UI.TableTH>Inscrição Estadual</UI.TableTH>
                    </tr>
                  </UI.TableHead>

                  <tbody>
                    <UI.TableTD>{purchaseHistoric?.data.id}</UI.TableTD>
                    <UI.TableTD>{purchaseHistoric?.data.socialName}</UI.TableTD>
                    <UI.TableTD>{purchaseHistoric?.data?.cnpj}</UI.TableTD>
                    <UI.TableTD>
                      {purchaseHistoric?.data?.stateRegistration}
                    </UI.TableTD>
                  </tbody>
                </UI.Table>
                <UI.Table>
                  <UI.TableHead>
                    <tr>
                      <UI.TableTH>Endereço</UI.TableTH>
                      <UI.TableTH>CEP</UI.TableTH>
                      <UI.TableTH>Bairro</UI.TableTH>
                      <UI.TableTH>Municipio</UI.TableTH>
                      <UI.TableTH>Estado</UI.TableTH>
                    </tr>
                  </UI.TableHead>

                  <tbody>
                    <UI.TableTD>{purchaseHistoric?.data?.street}</UI.TableTD>
                    <UI.TableTD>{purchaseHistoric?.data?.zipcode}</UI.TableTD>
                    <UI.TableTD>{purchaseHistoric?.data?.district}</UI.TableTD>
                    <UI.TableTD>{purchaseHistoric?.data?.city}</UI.TableTD>
                    <UI.TableTD>{purchaseHistoric?.data?.state}</UI.TableTD>
                  </tbody>
                </UI.Table>
                <UI.Table>
                  <UI.TableHead>
                    <tr>
                      <UI.TableTH>Contato</UI.TableTH>
                      <UI.TableTH />
                      <UI.TableTH>Telefone</UI.TableTH>
                      <UI.TableTH>Email</UI.TableTH>
                      <UI.TableTH />
                    </tr>
                  </UI.TableHead>

                  <tbody>
                    <UI.TableTD colSpan="2">
                      {purchaseHistoric?.data?.ownerName}
                    </UI.TableTD>
                    <UI.TableTD>
                      {purchaseHistoric?.data?.ownerPhone}
                    </UI.TableTD>
                    <UI.TableTD colSpan="2">
                      {purchaseHistoric?.data?.ownerEmail}
                    </UI.TableTD>
                  </tbody>
                </UI.Table>
                <br />
                <GetHistoric />
                <FooterReports periodUse={purchaseHistoric?.data?.periodUse} />
              </>
            )}
          </BvsPanel>
        </div>
      </BvsContainer>
    </>
  );
};

export default ReportPurchaseHistoric;
