/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */

import React, { useEffect, useState } from 'react';

import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { USER_TYPE } from '~/system/enum';
import api from '~/services/api';
import BvsButton from '~/components/BvsButton';
import BvsContainer from '~/components/BvsContainer';
import BvsDropdown from '~/components/BvsDropdown';
import BvsForm from '~/components/BvsForm';
import BvsPanel from '~/components/BvsPanel';
import BvsToggle from '~/components/BvsToggle';
import BvsToggleGroup from '~/components/BvsToggleGroup';
import SubHeader from '~/components/BvsSubHeader';
import { showLoading } from '../../store/modules/loader/actions';
import BvsToastResult from '../../components/BvsToastResult';
import {
  resetPermission,
  updatePermission,
} from '../../store/modules/forms/FormPermission/actions';

const Permissions = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { permission } = useSelector((state) => state.formPermission);
  let { id } = match.params;

  const [enterprise, setEnterprise] = useState([]);
  const [idEnterprise, setIdEnterprise] = useState([]);

  /**
   * @anchor Combobox
   * @description Responsável pelos combobox da tela
   */

  // #region - Combobox
  const [userOptions, setUserOptions] = useState({
    loading: false,
    options: [],
  });

  const [privilageOptions, setPrivilageOptions] = useState({
    loading: false,
    options: [
      { title: 'Administrador', value: USER_TYPE.ADMIN },
      { title: 'Usuário', value: USER_TYPE.USER },
    ],
  });

  const getUserOptions = async () => {
    setUserOptions({ ...userOptions, loading: true });
    let options = [];
    try {
      const { data } = await api.get(`/users`);
      if (data.message || data.error) throw data;
      options = data.map((i) => ({ title: i.name, value: i.id }));
      setUserOptions({
        ...userOptions,
        loading: false,
        options,
      });
    } catch (err) {
      setUserOptions({ ...userOptions, options, loading: false });
      toast.error(
        <BvsToastResult
          type="error"
          message="Não foi possivel carregar os usuários"
          err={err}
        />
      );
    } finally {
      setUserOptions({ ...userOptions, options, loading: false });
    }
  };
  const getEnterprisesOptions = async () => {
    let options = [];
    try {
      const { data } = await api.get(`/enterprises`);
      if (data.message || data.error) throw data;
      data.map((x) => {
        options.push({
          title: x.social_name,
          value: x.id,
        });
      });
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          message="Não foi possivel atribuir as permissões"
          error={err}
        />
      );
    } finally {
      setEnterprise(options);
    }
  };

  // #endregion

  const populate = async () => {
    // const { data } = await api.get(``);
    // // setar os valores em UserOptions e em PrivilageOptions
    // if (data.message || data.error) {
    //   addToast(`${data.message || data.error.message}`, {
    //     appearance: 'warning',
    //     autoDismiss: true,
    //   });
    //   return;
    // }
    // setPermissions(data);
  };

  const onLinkPermission = async () => {
    const { privilege = USER_TYPE.USER } = permission;
    // Verifico tipo do meu usuario
    let permissions = Object.keys(permission).filter(
      (i) => !['privilege', 'user_id'].includes(i) && permission[i] === true
    );

    if ([null, undefined, ''].includes(permission.user_id)) {
      toast.warning(
        <BvsToastResult
          type="warn"
          message="Selecione um usuário para atribuir as permissões"
        />
      );
      return;
    }
    try {
      const { data } = await api.put(
        `/user/${permission.user_id}/permissions`,
        {
          permissions,
          privilege,
          idEnterprise,
        }
      );
      if (data.error || data.message || !data) throw data;

      toast.success(<BvsToastResult message="Atribuído com sucesso." />);
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          message="Não foi possivel atribuir as permissões"
          error={err}
        />
      );
    }
  };
  const setPermission = (key, value) => {
    if (key === 'master' && value) {
      dispatch(
        updatePermission({
          permission: {
            ...permission,
            [key]: value,
            operational: false,
            financial: true,
            register: true,
            order: true,
          },
        })
      );
    } else if (['financial', 'register', 'order'].includes(key) && value) {
      dispatch(
        updatePermission({
          permission: {
            ...permission,
            [key]: value,
            operational: false,
          },
        })
      );
    } else if (key === 'operational' && value) {
      dispatch(
        updatePermission({
          permission: {
            ...permission,
            [key]: value,
            master: false,
            financial: false,
            register: false,
            order: false,
          },
        })
      );
    } else {
      dispatch(
        updatePermission({
          permission: {
            ...permission,
            [key]: value,
          },
        })
      );
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    try {
      onLinkPermission();
    } catch (err) {
      toast.error(
        <BvsToastResult
          type="error"
          error={err}
          message="Não foi possivel atribuir as permissões"
        />
      );
    }
  };

  useEffect(() => {
    getEnterprisesOptions();
    getUserOptions();
    if (id) populate();
    return () => {
      dispatch(resetPermission());
    };
  }, []);

  return (
    <>
      <SubHeader title="Permissões de Acesso" subtitle="Dos Usuários" />
      <BvsContainer>
        <div className="col-md-12">
          <BvsPanel title="Gerenciar">
            <BvsForm onSubmit={onSubmit}>
              <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                  <BvsDropdown
                    title="Escolha a posição..."
                    name="userOptions"
                    label="Usuário"
                    search
                    value={permission.user_id}
                    onSelectedItem={(value) => setPermission('user_id', value)}
                    loading={userOptions.loading}
                    options={userOptions.options}
                  />
                </div>
                {user.type === USER_TYPE.ADMIN && (
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                    <BvsDropdown
                      title="Selecione um Previlégio..."
                      name="privilageOptions"
                      label="Previlégios de Usuário"
                      value={permission.privilege}
                      onSelectedItem={(value) =>
                        setPermission('privilege', value)
                      }
                      loading={privilageOptions.loading}
                      options={privilageOptions.options}
                    />
                  </div>
                )}
                {user.type === USER_TYPE.ADMIN && (
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-3">
                    <BvsDropdown
                      title="Selecione uma empresa..."
                      name="enterpriseOptions"
                      label="Empresas"
                      value={enterprise.value}
                      onSelectedItem={(value) => setIdEnterprise(value)}
                      options={enterprise}
                    />
                  </div>
                )}
              </div>
              <div className="row">
                <BvsToggleGroup>
                  <BvsToggle
                    orientation="horizontal"
                    dataTip="Dá aceite nos contratos, cria os demais usuários e acessa todos os serviços disponíveis no Portal."
                    invert
                    title="Master"
                    name="master"
                    onChange={(v) => setPermission('master', v)}
                    value={permission.master}
                  />
                  <BvsToggle
                    orientation="horizontal"
                    dataTip="Consulta pedidos para visualizar os Pedidos, os Relatórios e boletos."
                    invert
                    title="Financeiro"
                    name="financial"
                    onChange={(v) => setPermission('financial', v)}
                    value={permission.financial}
                  />
                  <BvsToggle
                    orientation="horizontal"
                    dataTip="Cria, edita e consulta cadastro dos usuários e seus benefícios, local de entrega, centro de custo, Departamentos, Cartões administrativos, contratos e desligamentos."
                    invert
                    title="Menu Cadastros e Consultas"
                    name="register"
                    onChange={(v) => setPermission('register', v)}
                    value={permission.register}
                  />
                  <BvsToggle
                    orientation="horizontal"
                    dataTip="Cria, edita e consulta pedidos e visualiza os relatórios."
                    invert
                    title="Menu Pedidos"
                    name="order"
                    onChange={(v) => setPermission('order', v)}
                    value={permission.order}
                  />
                  <BvsToggle
                    orientation="horizontal"
                    dataTip="Acessa todas as telas, porém não altera, edita ou inclui nada. Apenas visualiza. Na consulta de pedidos não visualiza boletos."
                    invert
                    title="Visualização Operacional"
                    name="operational"
                    onChange={(v) => setPermission('operational', v)}
                    value={permission.operational}
                  />
                  {/* 
                  <BvsToggle
                    orientation="horizontal"
                    invert
                    title="Segurança"
                    name="security"
                  /> */}
                </BvsToggleGroup>
              </div>
              <div className="row">
                <BvsButton
                  className="ml-2"
                  type="button"
                  title="Voltar"
                  icon="fa fa-undo"
                  onClick={() => history.goBack()}
                />
                <div className="mx-3 text-nowrap">
                  <BvsButton type="submit" title="Atribuir" icon="fa fa-lock" />
                </div>
                <div className=" text-nowrap">
                  <BvsButton
                    type="button"
                    title="Cadastrar Usuário"
                    icon="fa fa-user"
                    onClick={() => history.push('/users')}
                  />
                </div>
              </div>
            </BvsForm>
          </BvsPanel>
        </div>
      </BvsContainer>
      <ReactTooltip />
    </>
  );
};

export default Permissions;
