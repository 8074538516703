import React from 'react';
import { CalendarControl } from './style';

const CalendarControlNext = ({ onClick, next }) => {
  return (
    <CalendarControl onClick={() => onClick(next)}>
      <i className={`fa fa-chevron-${next ? 'right' : 'left'}`} />
    </CalendarControl>
  );
};

export default CalendarControlNext;
