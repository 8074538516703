/* eslint-disable no-shadow */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import api from '~/services/api';
import { employeeHistoricOfBenefits, updateHistoricEmployee } from './actions';
import types from './types';

function* getHistoric() {
  let historicInfo = null;
  const {
    historicEmployee: {
      components,
      state: { employeeId, ...state },
    },
  } = yield select((state) => state.app);
  try {
    const { data } = yield call(api.get, `employee/${employeeId}/historic`);
    if (data.error || data.message) throw data;

    historicInfo = data;

    yield put(
      updateHistoricEmployee({
        components,
        state: {
          ...state,
          employeeId,
          data: historicInfo,
        },
      })
    );
  } catch (err) {
    yield put(
      updateHistoricEmployee({
        components,
        state: {
          ...state,
          employeeId,
          data: historicInfo,
          loading: false,
        },
      })
    );
  } finally {
    yield put(
      updateHistoricEmployee({
        components,
        state: {
          ...state,
          employeeId,
          data: historicInfo,
          loading: false,
        },
      })
    );
  }
}

function* getEmployeeHistoricOfBenefits({ payload }) {
  const {
    employeeHistoricOfBenefit: { components, state },
  } = yield select((state) => state.app);
  try {
    const { data } = yield call(
      api.get,
      `/employee/benefits/historic/${payload}`
    );
    if (data.error || data.message) throw data;

    yield put(
      employeeHistoricOfBenefits('update', {
        components: {
          ...components,
          loading: false,
        },
        state: {
          ...state,
          data,
        },
      })
    );
  } catch (err) {
    yield put(
      employeeHistoricOfBenefits('update', {
        components: {
          ...components,
          loading: false,
        },
        state: {
          ...state,
          data: null,
        },
      })
    );
  }
}

export default all([
  takeLatest(types.HISTORIC_EMPLOYEE.FETCH, getHistoric),
  takeLatest(
    types.EMPLOYEE_HISTORIC_OF_BENEFITS.FETCH,
    getEmployeeHistoricOfBenefits
  ),
]);
