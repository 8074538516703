import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrder } from '~/store/modules/order/actions';

const useEmailRevenues = () => {
  const dispatch = useDispatch();
  const { order } = useSelector((state) => state.order);
  const { enterpriseActive } = useSelector((state) => state.enterprises);
  const [emails, setEmails] = useState({
    options: [],
    loading: false,
  });

  const fetch = () => {
    let tmp = [];
    if (enterpriseActive?.info?.email)
      tmp.push({
        value: enterpriseActive?.info?.email,
        title: enterpriseActive?.info?.email,
      });

    setEmails({ options: tmp, loading: false });
    dispatch(
      updateOrder({
        ...order,
        billing_address_email: enterpriseActive?.info?.email,
        order_email_nfe: enterpriseActive?.info?.email,
      })
    );
  };

  return { emails, fetch };
};

export default useEmailRevenues;
