import types from './types';

export function updatePermission(payload) {
  return {
    type: types.UPDATE_PERMISSION,
    payload,
  };
}

export function resetPermission() {
  return {
    type: types.RESET_PERMISSION,
  };
}
