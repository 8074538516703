import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from '~/services/api';

const useController = () => {
  const [orders, setOrders] = useState([]);
  const { enterpriseActive } = useSelector((state) => state.enterprises);

  const getLastOrder = async () => {
    try {
      const { data } = await api.get('orders/last');
      if (data.error || data.message) throw data;
      setOrders(data);
    } catch (err) {
      setOrders([]);
    }
  };

  useEffect(() => {
    if (enterpriseActive?.info) getLastOrder();
  }, [enterpriseActive?.info]);
  return {
    orders,
    getLastOrder,
  };
};

export default useController;
