import { useState } from 'react';
import api from '~/services/api';

const useWorkdays = () => {
  const [workdays, setWorkdays] = useState({
    options: [],
    loading: false,
  });

  const fetch = async () => {
    setWorkdays({ ...workdays, loading: true });
    let options = [];
    try {
      const { data } = await api.get('/workdays');
      if (data.error || data.message) throw data;
      options = data.map((d) => ({ title: d.description, value: d.id }));
      setWorkdays({ ...workdays, options });
      options.unshift({ title: 'Nenhum', value: null });
    } catch (error) {
      setWorkdays({ ...workdays, options });
    } finally {
      setWorkdays({ ...workdays, options, loading: false });
    }
  };
  return {
    workdays,
    fetch,
  };
};

export default useWorkdays;
