/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';

import * as ENUM from '~/system/enum';
import BvsButton from '~/components/BvsButton';
import BvsContainer from '~/components/BvsContainer';
import BvsForm from '~/components/BvsForm';
import BvsInput from '~/components/BvsInput';
import BvsInputFile from '~/components/BvsInputFile';
import BvsPanel from '~/components/BvsPanel';
import BvsTableV4 from '../../components/BvsTableV4';
import { DownloadFile, toMoney } from '~/system/helper';
import SubHeader from '~/components/BvsSubHeader';
import { getImportStatus } from '~/system/convert';
import BvsAdmButton from '~/components/BvsAdmButton';
import { useSelector } from 'react-redux';
import useController from './hooks/useController';
import useForm from './hooks/useForm';
import { FontAwesome } from 'react-web-vector-icons';
import moment from 'moment';
import BvsInputCurrency from '../../components/BvsInputCurrency';
import BvsModal from '../../components/BvsModal';
import api from '../../services/api';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';
import { useHistory } from 'react-router';
import BvsDropdown from '../../components/BvsDropdown';
import { ImportOrderTypeEnum } from '../../enums/import-order-type';

const CreateOrderByImport = () => {
  const { import_orders } = useSelector((state) => state.formImportOrders);
  const { user, token } = useSelector((state) => state.auth);
  const { onFieldChange, reset } = useForm();
  const history = useHistory();
  const {
    imports,
    fetch: getImportOrders,
    onSave,
    getLog,
    setCursorLog,
    cursorLog,
    completeOrder,
    setCompleteOrder,
  } = useController({
    whenImportComplete: (data) => {
      reset();
      getImportOrders();
    },
  });

  // #region Methods
  const download = (logId) => {
    let link = document.createElement('a');
    link.download = `${logId}.xlsx`;
    link.target = '_blank';
    link.href =
      // '' +
      `${api.defaults.baseURL}/order/imports/${logId}/download?token=${token}`;
    link.click();
  };

  // #endregion

  // #region - Constants
  const columns = [
    {
      key: 'id',
      label: 'Protocolo',
      sort: true,
    },
    {
      key: 'createdAt',
      label: 'Data e Hora',
      sort: true,
      content: (row) => moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      key: 'importedBy',
      label: 'Importado por',
      sort: true,
      content: (row) => row?.createdBy?.name ?? '',
    },
    {
      key: 'type',
      label: 'Tipo',
      sort: true,
    },
    {
      key: 'value',
      label: 'Valor',
      sort: true,
      content: (row) => toMoney(Number(row.value)),
    },
    {
      key: 'name',
      label: 'Identificado',
      sort: true,
      width: '100%',
    },
    {
      key: 'status',
      label: 'Status',
      sort: true,
      content: (row) => getImportStatus(row.status),
    },
  ];
  const typeOptions = [
    {
      title: 'Padrão',
      value: ImportOrderTypeEnum.default,
    },
    {
      title: 'Folha Senior',
      value: ImportOrderTypeEnum.folha_senior,
    },
  ];
  // #endregion

  // #region - Effects
  useEffect(() => {
    getImportOrders();

    return () => {
      reset();
    };
  }, []);
  // #endregion
  return (
    <>
      <SubHeader title="Importar Pedido" subtitle="todos os dados" />
      <BvsContainer>
        {user?.type === ENUM.USER_TYPE.ADMIN && <BvsAdmButton />}
        <div className="col-md-12">
          <BvsPanel title="Selecione o arquivo para importação de dados e clique em importar">
            <BvsForm onSubmit={onSave}>
              <div className="row">
                <div
                  className="col-xs-12 col-sm-3 col-md-3 col-lg-3 p-3"
                  style={{ marginTop: 'auto' }}
                >
                  <BvsInput
                    name="identification"
                    label="Identificador"
                    value={import_orders?.identification}
                    onChange={(value) => onFieldChange('identification', value)}
                  />
                </div>
                <div
                  className="col-xs-12 col-sm-3 col-md-3 col-lg-3 p-3"
                  style={{ marginTop: 'auto' }}
                >
                  <BvsInputCurrency
                    name="value_order"
                    label="Valor Total do Pedido (R$)"
                    currency="BRL"
                    onChange={(_, value) => {
                      onFieldChange('value', value);
                    }}
                    value={import_orders?.value}
                  />
                </div>

                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 p-3">
                  <div className="mt-2">&nbsp;</div>
                  <BvsInputFile
                    onChange={onFieldChange}
                    name="file"
                    value={import_orders?.file}
                  />
                </div>

                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 p-3">
                  <BvsDropdown
                    title="Tipo"
                    name="type"
                    label="Tipo de Importação"
                    value={import_orders?.type}
                    default
                    onSelectedItem={(value) => {
                      onFieldChange('type', value);
                    }}
                    options={typeOptions}
                  />
                </div>

                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 p-3">
                  <BvsButton
                    type="submit"
                    title="Importar"
                    icon="fa fa-upload"
                  />
                </div>
              </div>
            </BvsForm>
          </BvsPanel>
        </div>

        <div className="col-md-12">
          <BvsPanel title="Histórico de Importação">
            <div className="col-md-12">
              <BvsTableV4
                tableKey="imports"
                columns={columns}
                rows={imports.options}
                onRefresh={() => getImportOrders()}
                rowKey="id"
                actionOptions={{ width: '165px' }}
                actions={[
                  (row) =>
                    (row.status === ENUM.IMPORT_STATUS.SUCCESS && (
                      <BvsButton
                        type="button"
                        className="btn btn-xs"
                        customStyle={{ marginRight: '5px' }}
                        icon={
                          <FontAwesome name="download" color="#fff" size={12} />
                        }
                        onClick={(e) => download(row.id)}
                      />
                    )) ||
                    (row.status === ENUM.IMPORT_STATUS.FAILED && (
                      <>
                        <BvsButton
                          type="button"
                          className="btn btn-xs"
                          customStyle={{ marginRight: '5px' }}
                          icon={
                            <FontAwesome
                              name="download"
                              color="#fff"
                              size={12}
                            />
                          }
                          onClick={(e) => download(row.id)}
                        />
                        <BvsButton
                          type="button"
                          className="btn btn-xs"
                          customStyle={{ marginRight: '5px' }}
                          icon={
                            <FontAwesome
                              name="exclamation-circle"
                              color="#fff"
                              size={12}
                            />
                          }
                          onClick={(e) => getLog(row.id)}
                        />
                      </>
                    )),
                ]}
              />

              <div>
                <BvsButton
                  type="button"
                  title="Modelo de Importação (XLSX)"
                  icon="fa fa-file-excel-o"
                  customStyle={{ margin: '15px 2px' }}
                  onClick={async () => {
                    toast.info(
                      <BvsToastResult message="Seu download iniciará em breve, já recebemos sua solicitação" />
                    );
                    DownloadFile(
                      '/model-import',
                      `modelo_importação-${moment().format(
                        'DD.MM.YYYY HH:mm:ss'
                      )}.xlsx`
                    );
                  }}
                />
              </div>
            </div>
          </BvsPanel>
        </div>
      </BvsContainer>
      <BvsModal
        visible={!!cursorLog}
        onClose={() => {
          setCursorLog(null);
        }}
        footer={() => (
          <div className="row d-flex justify-content-end">
            <div className="text-nowrap px-2">
              <BvsButton
                title="Fechar"
                onClick={() => {
                  setCursorLog(null);
                }}
              />
            </div>
          </div>
        )}
      >
        <div className="container">
          <div className="row">
            <span className="col-12 my-2">
              <strong>Criado em:</strong>&nbsp;
              {!!cursorLog &&
                moment(cursorLog?.createdAt, 'YYYY-MM-DD hh:mm:ss')?.format(
                  'DD/MM/YYYY hh:mm:ss'
                )}
            </span>

            <span className="col-12 my-2">
              <strong>Criado por:</strong>&nbsp;
              {cursorLog?.orderImport?.createdBy?.name}
            </span>

            <span className="col-12 my-2">
              <strong>Arquivo Original:</strong>&nbsp;
              <a
                href="javascript:;"
                target="_blank"
                onClick={() => download(cursorLog?.order_import_id)}
                rel="noreferrer"
              >
                {cursorLog?.orderImport?.name}
              </a>
            </span>

            <p className="col-12">
              <strong>Erro:</strong>&nbsp;
              <br />
              {cursorLog?.error?.split(',').map((error) => (
                <p className="p-0 m-1">{error};</p>
              ))}
            </p>
          </div>
        </div>
      </BvsModal>
      <BvsModal
        visible={!!completeOrder}
        onClose={() => {
          setCompleteOrder(null);
        }}
        footer={() => (
          <div className="row d-flex justify-content-end">
            <div className="text-nowrap px-2">
              <BvsButton
                title="Prosseguir"
                onClick={() => {
                  history.push(`/orders/checkout/${completeOrder?.id}`);
                }}
              />
            </div>
          </div>
        )}
      >
        <div className="row">
          <div className="col-12">
            Importado com sucesso, clique em <b>Prosseguir</b> para finalizar o
            pedido.
          </div>
        </div>
      </BvsModal>
    </>
  );
};

export default CreateOrderByImport;
