/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
import types from './types';

export function updateUser(payload) {
  return {
    type: types.UPDATE_USER,
    payload,
  };
}

export function resetUser() {
  return {
    type: types.RESET_USER,
  };
}
