import { useEffect, useState } from 'react';
import { getStatusEmployee } from '~/system/convert';
import { STATUS_EMPLOYEES } from '~/system/enum';

const useStatusEmployee = () => {
  const [statusEmployee, setStatusEmployee] = useState({
    options: [],
    loading: false,
  });

  useEffect(() => {
    let options = Object.keys(STATUS_EMPLOYEES).map((i) => ({
      value: STATUS_EMPLOYEES[i],
      title: getStatusEmployee(STATUS_EMPLOYEES[i]),
    }));
    options.unshift({ title: 'Nenhum', value: null });
    setStatusEmployee({
      options,
      loading: false,
    });
  }, []);

  return {
    statusEmployee,
  };
};

export default useStatusEmployee;
