import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import BvsToastResult from '~/components/BvsToastResult';
import api from '~/services/api';
import { updateOrder } from '~/store/modules/order/actions';

const useController = ({ orderId = null }) => {
  // const [employees, setEmployef
  const dispatch = useDispatch();
  const { order } = useSelector((state) => state.order);

  const onPopulate = async () => {
    if (!orderId) return;
    try {
      const { data } = await api.get(`/order/detail/${orderId}`);
      dispatch(
        updateOrder({
          ...order,
          employees: data.employees,
          financial_status: data?.status?.financial_status,
          operational_status: data?.status?.operational_status,
        })
      );
    } catch (err) {
      toast.error(
        <BvsToastResult
          err={err}
          message="Falha ao consultar pedido"
          type="error"
        />
      );
    }
  };

  return {
    onPopulate,
  };
};

export default useController;
