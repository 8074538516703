import { useState } from 'react';
import { useDispatch } from 'react-redux';
import api from '~/services/api';
import { hideLoading, showLoading } from '~/store/modules/loader/actions';

const useDepartments = ({ loading = false, dropdown = true }) => {
  const dispatch = useDispatch();
  const [departments, setDepartments] = useState({
    options: [],
    loading: false,
  });

  const fetch = async () => {
    if (loading) dispatch(showLoading());
    setDepartments({ ...departments, loading: true });
    let options = [];
    try {
      const { data } = await api.get('/departments');
      if (data.error || data.message) throw data;
      options = data.map((d) => ({ title: d.name, value: d.id, _original: d }));
      if (dropdown) options.unshift({ title: 'Nenhum', value: null });

      setDepartments({ ...departments, options });
    } catch (error) {
      setDepartments({ ...departments, options });
    } finally {
      setDepartments({ ...departments, options, loading: false });
      if (loading) dispatch(hideLoading());
    }
  };
  return {
    departments,
    fetch,
  };
};

export default useDepartments;
