import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import BvsButton from '~/components/BvsButton';
import {
  // ModalContainer,
  // ModalHeader,
  // HeaderTitle,
  // ModalBody,
  // ModalFooter,
  // HeaderAction,
  // Modal,
  ModalBg,
} from './style';

function BvsModal({
  onClose,
  visible,
  autoDismiss,
  children,
  title,
  footer,
  width,
  size = 'lg',
}) {
  const ref = useRef();
  const closeDismiss = (event) => {
    // event.stopPropagation();
    const contain = ref.current
      .querySelector('+ .modal')
      .contains(event.target);
    if (!contain) {
      if (onClose) onClose();
    }
  };

  useEffect(() => {
    if (autoDismiss) {
      if (ref.current) {
        ref.current.addEventListener('click', closeDismiss);
      }
    }

    return () => {
      if (autoDismiss) {
        if (ref.current) ref.current.removeEventListener('click', closeDismiss);
      }
    };
  });

  return !visible ? (
    false
  ) : (
    <>
      <ModalBg />
      <div
        className={`modal fade ${visible ? 'show' : ''}`}
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        style={{ zIndex: 101 }}
      >
        <div className={`modal-dialog modal-${size}`} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Fechar"
                onClick={onClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{children}</div>
            <div className="modal-footer">
              {footer()}
              {!footer && (
                <BvsButton
                  title="Fechar"
                  onClick={() => {
                    if (onClose) onClose();
                  }}
                />
              )}
              {/* {<button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fechar
              </button>
              <button type="button" className="btn btn-primary">
                Salvar mudanças
              </button>} */}
            </div>
          </div>
        </div>
      </div>
      {/* <ModalContainer className="show modal" width={width}>
        <ModalHeader>
          <HeaderTitle>{title}</HeaderTitle>
          <HeaderAction>
            <span onClick={onClose} aria-hidden="true">
              <FontAwesome
                name="close"
                size={18}
                style={{ cursor: 'pointer' }}
              />
            </span>
          </HeaderAction>
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          {footer()}
          {!footer && (
            <BvsButton
              title="Fechar"
              onClick={() => {
                if (onClose) onClose();
              }}
            />
          )}
        </ModalFooter>
      </ModalContainer> */}
    </>
  );
}
BvsModal.propTypes = {
  autoDismiss: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  footer: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

BvsModal.defaultProps = {
  autoDismiss: false,
  visible: false,
  onClose: () => {},
  title: "Benv's Gestão de Benefícios",
  footer: null,
};

export default BvsModal;
