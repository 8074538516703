import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  justification: {
    components: {
      visible: false,
    },
    form: {
      justification: '',
    },
  },
};

function employee(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_FORM_JUSTIFICATION: {
      return produce(state, (draft) => {
        draft.justification = {
          ...draft.justification,
          ...action.payload,
        };
        return draft;
      });
    }
    case types.RESET_FORM_JUSTIFICATION: {
      return produce(state, (draft) => {
        draft.justification.form = INITIAL_STATE.justification.form;
        return draft;
      });
    }

    default:
      return state;
  }
}

export default employee;
