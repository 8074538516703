const Announcement = () => {
  return (
    <div>
      Prezados clientes,
      <p className="font-weight-bold">Atenção,</p>
      <p>
        As recargas de vale transporte no{' '}
        <span className="font-weight-bold">CARTÃO BOM</span> serão efetuadas até
        o dia 31/03/2022, onde as recargas direcionadas a este cartão podem ser
        utilizadas até a finalização do saldo.
      </p>
      <p>
        A partir de 01/04/2022 as recargaras serão automaticamente efetuadas no
        CARTÃO TOP. Nesse momento só o próprio usuário poderá solicitar e
        retirar a 1ª via do CARTÃO TOP.
      </p>
      <p>
        <span className="font-weight-bold">Como solicitar o CARTÃO TOP </span>
      </p>
      <p>
        1. Através dos aplicativos disponibilizados no sistema Android e IOS,
        sem custo adicional.
      </p>
      <a
        href="https://www.boradetop.com.br/#/cartao-de-transporte"
        target="_blank"
        rel="noreferrer"
      >
        https://www.boradetop.com.br/#/cartao-de-transporte
      </a>
      <br />
      <br />
      <p>
        2. Através do link &nbsp;
        <a
          href="https://vico158.gendo.app/v2/reserve"
          target="_blank"
          rel="noreferrer"
        >
          https://vico158.gendo.app/v2/#/reserve
        </a>{' '}
        , o CARTÂO TOP pode ser retirado por agendamento através de uma
        credenciada das Lojas Pernambucanas ou entregue em domicílio, por uma
        taxa de R$ 22,30 cobrada pelo próprio emissor.
      </p>
      <p className="font-weight-bold">O que acontecerá com o CARTÃO BOM </p>
      <p>1. O cartão Bom não será desativado;</p>
      <p>
        2. As recargas existentes no cartão serão validas até a sua finalização
        (mas é importante orientar o colaborador a usá-la);
      </p>
      <p>
        3. Perdeu o CARTÃO BOM, tem créditos, solicitar a 2º via do CARTÃO BOM;
        4. As recargas do CARTÃO BOM, não serão transferidas para o CARTÃO TOP.
      </p>
    </div>
  );
};

export default Announcement;
