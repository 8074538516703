/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  filter: {
    filtering: false,
    disabled: false,
  },
  query: {
    shipping_address_id: null,
    department_id: null,
    workday_id: null,
    benefit_group_id: null,
    employee_id: null,
  },
};

function filterCreateOrder(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_FIELDS_FILTER: {
      return produce(state, (draft) => {
        draft = { ...draft, ...action.payload };
        return draft;
      });
    }
    case types.RESET_FIELDS: {
      return produce(state, (draft) => {
        draft = INITIAL_STATE;
        return draft;
      });
    }
    default:
      return state;
  }
}

export default filterCreateOrder;
