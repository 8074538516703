/* eslint-disable no-undef */
import styled from 'styled-components';

export const MenuTop = styled.div`
  max-width: 992px;
  margin-top: 50px;
  margin-left: 2.2px;
  & .rc-menu-root {
    overflow: hidden;
    background-color: transparent;
    cursor: pointer;
  }
  & .rc-menu-vertical .rc-menu-item {
    cursor: pointer;
  }
`;
export const MenuItem = styled.div`
  width: 100%;
  font-size: '1rem';
  margin: 10px 0px;

  i {
    position: absolute;
    right: 30px;
    width: 100%;

    transition: all 0.5s linear 0.4s;
    &:hover {
      transform: rotate(180deg);
    }
  }
`;
