/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
import types from './types';

export function updateShippingAddress(payload) {
  return {
    type: types.UPDATE_SHIPPING_ADDRESS,
    payload,
  };
}

export function addResponsible(payload) {
  return {
    type: types.ADD_RESPONSIBLE,
    payload,
  };
}

export function removeResponsible(payload) {
  return {
    type: types.REMOVE_RESPONSIBLE,
    payload,
  };
}

export function updateResponsible(payload) {
  return {
    type: types.UPDATE_RESPONSIBLE,
    payload,
  };
}

export function resetShippingAddress() {
  return {
    type: types.RESET_SHIPPING_ADDRESS,
  };
}

export function addShippingAddress(callback) {
  return {
    type: types.ADD_SHIPPING_ADDRESS,
    callback,
  };
}
