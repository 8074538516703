/* eslint-disable no-param-reassign */
import produce from 'immer';
import { STATUS } from '~/system/enum';
import types from './types';

const INITIAL_STATE = {
  order: {
    email: null,
    start_period_use: null,
    end_period_use: null,
    payday: null,
    creditBag: null,
    has_credit_bag: false,
    enterprise_id: null,
    billing_address_email: null,
    nfe_email: null,
    responsible_name: null,
    responsible_email: null,
    responsible_phone: null,
    financial_status: null,
    others_cost: null,
    discount_cost: null,
    total_payable: null,
    status: null,
    order_type: null,
    order_email_nfe: null,
    order_email_nfe_extra: null,
    delivery_date: null,
    employees: [],
  },
};

function order(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_ORDER: {
      return produce(state, (draft) => {
        draft.order = { ...draft.order, ...action.payload };
        return draft;
      });
    }
    case types.RESET_ORDER: {
      return produce(state, (draft) => {
        draft.order = INITIAL_STATE.order;
        return draft;
      });
    }
    case types.RESET_DATE_ORDER: {
      return produce(state, (draft) => {
        draft.order.payday = undefined;
        draft.order.start_period_use = undefined;
        draft.order.end_period_use = undefined;
        draft.order.billing_address_email =
          INITIAL_STATE.order.billing_address_email;
        draft.order.creditBag = INITIAL_STATE.order.creditBag;
        draft.order.responsible_email = INITIAL_STATE.order.responsible_email;
        draft.order.responsible_phone = INITIAL_STATE.order.responsible_phone;
        draft.order.order_type = INITIAL_STATE.order.order_type;
        draft.order.order_email_nfe = INITIAL_STATE.order.order_email_nfe;
        draft.order.order_email_nfe_extra =
          INITIAL_STATE.order.order_email_nfe_extra;
        draft.order.has_credit_bag = INITIAL_STATE.order.has_credit_bag;
        return draft;
      });
    }
    default:
      return state;
  }
}

export default order;
